import React, {useEffect, useRef, useState} from 'react'
import axios from 'axios'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import CustomInput from '../../../../_components/CustomInput'
import {MEDICATION_URL} from '../../../../../_const'
import {customStyles, toastStyles} from '../../../../../_styles'
import {Medication} from '../../../../../../models/_schedule'
import Select from 'react-select'
import {
  medicationSchema,
  options_du,
  options_f,
  options_fu,
} from '../../../../../../schemas/_schedules'
import {MedicationType} from '../../../../../../models/_medicationType'
import FormikTextArea from '../../../../_components/FormikTextArea'
import {useThemeMode} from '../../../../../../_metronic/partials'
import WarningBlock from '../../../../_components/inputs/WarningBlock'
import FormikErrors from '../../../../_components/FormikErrors'
import Modal from 'react-bootstrap/Modal'

interface Props {
  updateList: (medication: Medication) => void
  medication: Medication
  types: MedicationType[]
}

interface Option {
  readonly label: string
  readonly value: string
}

export function EditMedModal(props: Props) {
  const intl = useIntl()
  const {menuMode} = useThemeMode()
  const [initialValues, setInitialValues] = useState<Medication>(props.medication)
  const closeBtn = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    const fetchData = () => {
      setInitialValues(props.medication)
    }
    fetchData()
  }, [props])

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: medicationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      await axios
        .patch(`${MEDICATION_URL}/${props.medication.id}`, values)
        .then((res: {data: {data: Medication}}) => {
          props.updateList(res.data.data)
          toast.success(intl.formatMessage({id: 'Medicação Editada'}), toastStyles)
          setSubmitting(false)
          closeBtn?.current?.click()
        })
        .catch((err: any) => {
          Object.keys(err.response.data.errors).forEach((key) => {
            formik.setFieldError(key, err.response.data.errors[key][0] ?? 'undefined')
          })

          setSubmitting(false)
        })
    },
  })

  return (
    <>
      <div className='modal fade modal-lg' tabIndex={-1} id='kt_modal_edit_medication'>
        <div className='modal-dialog modal-md'>
          <div className='modal-content '>
            <div className='modal-header'>
              <h3 className='card-title text-primary'>
                {intl.formatMessage({id: 'Editar Tipo de Medicação'})}
              </h3>

              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <span className='svg-icon svg-icon-1 text-dark' style={{fontSize: '1.5em'}}>
                  X
                </span>
              </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <Modal.Body className='modal-body'>
                <div className='row'>
                  <WarningBlock
                    show={formik.isValid}
                    warning={'Campos obrigatórios (*), preencha os campos corretamente!'}
                  />
                  <div className='col-6'>
                    <CustomInput
                      required={true}
                      formikFields={formik.getFieldProps('name')}
                      label={'Nome'}
                      type={'text'}
                      touched={formik.touched.name}
                      errors={formik.errors.name}
                      name={'name'}
                    />
                    <label className='form-label required '>
                      {intl.formatMessage({id: 'Unidade de Dosagem'})}
                    </label>
                    <Select
                      options={options_du}
                      getOptionLabel={(option: Option) => intl.formatMessage({id: option.label})}
                      getOptionValue={(option: Option) => `${option.value}`}
                      {...formik.getFieldProps('dosage_unit')}
                      value={options_du.find((value) => {
                        return `${value.value}` == formik.values.dosage_unit
                      })}
                      onBlur={(event) => {
                        formik.setFieldTouched('dosage_unit', true)
                        formik.setErrors({...formik.errors, dosage_unit: undefined})
                      }}
                      onChange={(value) => {
                        formik.setFieldValue('dosage_unit', `${value?.value}`)
                      }}
                      styles={customStyles(
                        menuMode,
                        formik.errors.dosage_unit,
                        formik.touched.dosage_unit
                      )}
                    />
                    <FormikErrors
                      errors={formik.errors.dosage_unit}
                      touched={formik.touched.dosage_unit}
                    />

                    <label className='form-label required mt-2'>
                      {intl.formatMessage({id: 'Unidade de Frequência'})}
                    </label>
                    <Select
                      options={options_fu}
                      getOptionLabel={(option: Option) => intl.formatMessage({id: option.label})}
                      getOptionValue={(option: Option) => `${option.value}`}
                      {...formik.getFieldProps('frequency_unit')}
                      value={options_fu.find((value) => {
                        return `${value.value}` == formik.values.frequency_unit
                      })}
                      onBlur={(event) => {
                        formik.setFieldTouched('frequency_unit', true)
                        formik.setErrors({...formik.errors, frequency_unit: undefined})
                      }}
                      onChange={(value) => {
                        formik.setFieldValue('frequency_unit', `${value?.value}`)
                      }}
                      styles={customStyles(
                        menuMode,
                        formik.errors.frequency_unit,
                        formik.touched.frequency_unit
                      )}
                    />
                    <FormikErrors
                      errors={formik.errors.frequency_unit}
                      touched={formik.touched.frequency_unit}
                    />
                    <label className='form-label required mt-2'>
                      {intl.formatMessage({id: 'Tipo de medicamento'})}
                    </label>
                    <Select
                      options={props.types}
                      getOptionLabel={(type: MedicationType) => type.name}
                      getOptionValue={(type: MedicationType) => `${type.id}`}
                      {...formik.getFieldProps('medicationType_id')}
                      value={props.types.find((value) => {
                        return `${value.id}` == `${formik.values.medicationType_id}`
                      })}
                      onBlur={(event) => {
                        formik.setFieldTouched('medicationType_id', true)
                        formik.setErrors({...formik.errors, medicationType_id: undefined})
                      }}
                      onChange={(value) => {
                        formik.setFieldValue('medicationType_id', `${value?.id}`)
                      }}
                      styles={customStyles(
                        menuMode,
                        formik.errors.medicationType_id,
                        formik.touched.medicationType_id
                      )}
                    />
                    <FormikErrors
                      errors={formik.errors.medicationType_id}
                      touched={formik.touched.medicationType_id}
                    />
                  </div>
                  <div className='col-6'>
                    <CustomInput
                      required={true}
                      formikFields={formik.getFieldProps('description')}
                      label={'Descrição'}
                      type={'text'}
                      touched={formik.touched.description}
                      errors={formik.errors.description}
                      name={'description'}
                    />
                    <CustomInput
                      required={true}
                      formikFields={formik.getFieldProps('dosage')}
                      label={'Dosagem'}
                      type={'number'}
                      touched={formik.touched.dosage}
                      errors={formik.errors.dosage}
                      name={'dosage'}
                    />
                    <label className='form-label required '>
                      {intl.formatMessage({id: 'Frequência'})}
                    </label>
                    <Select
                      options={options_f}
                      getOptionLabel={(option: Option) => intl.formatMessage({id: option.label})}
                      getOptionValue={(option: Option) => `${option.value}`}
                      {...formik.getFieldProps('frequency')}
                      value={options_f.find((value) => {
                        return `${value.value}` == formik.values.frequency
                      })}
                      onBlur={(event) => {
                        formik.setFieldTouched('frequency', true)
                        formik.setErrors({...formik.errors, frequency: undefined})
                      }}
                      onChange={(value) => {
                        formik.setFieldValue('frequency', `${value?.value}`)
                      }}
                      styles={customStyles(
                        menuMode,
                        formik.errors.frequency,
                        formik.touched.frequency
                      )}
                    />
                    <FormikErrors
                      errors={formik.errors.frequency}
                      touched={formik.touched.frequency}
                    />
                  </div>
                  <FormikTextArea
                    formikFields={formik.getFieldProps('observation')}
                    label={'Observações'}
                    touched={formik.touched.observation}
                    errors={formik.errors.observation}
                    name={'observation'}
                  />
                </div>
              </Modal.Body>

              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-light'
                  data-bs-dismiss='modal'
                  ref={closeBtn}
                >
                  {intl.formatMessage({id: 'Close'})}
                </button>
                <button
                  type='submit'
                  className='btn btn-primary'
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  {intl.formatMessage({id: 'Confirmar'})}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
