import axios, {AxiosResponse} from 'axios'
import {ClINICS_COLLAB_URL, USERS_URL} from '../app/_const'
import {CollabQueryResponse} from '../models/_collab'

const getCollab = (query: string, id: string): Promise<CollabQueryResponse> => {
  return axios
    .get(`${ClINICS_COLLAB_URL}/${id}?${query}`) /**/
    .then((d: AxiosResponse<CollabQueryResponse>) => d.data)
}

const getAllCollab = (query: string): Promise<CollabQueryResponse> => {
  return axios
    .get(`${USERS_URL}/by-role-paginated?role=collaborator&${query}`) /**/
    .then((d: AxiosResponse<CollabQueryResponse>) => d.data)
}

export {getCollab, getAllCollab}
