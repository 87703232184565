import React, {useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import axios, {AxiosResponse} from 'axios'
import {CONTACTS_FUNCTION_URL} from '../../../../../_const'
import {toast} from 'react-toastify'
import {toastStyles} from '../../../../../_styles'
import CustomInput from '../../../../_components/CustomInput'
import {FunctionRole} from '../../../../clinic/_model'
import {functionRoleSchema} from '../../../../../../schemas/_contact'
import CustomModalFooter from '../../../../_components/modals/CustomModalFooter'
import WarningBlock from '../../../../_components/inputs/WarningBlock'
import {generalize} from '../../../../../_helpers'

interface Props {
  updateTable: (functionRole: FunctionRole) => void
}

export function AddFunctionRoleModal({updateTable}: Props) {
  const intl = useIntl()
  const [show, setShow] = useState(false)

  const handleClose = () => {
    formik.resetForm()
    setShow(false)
  }
  const handleShow = () => setShow(true)
  const initialValues = {name: ''}
  const formik = useFormik({
    initialValues,
    validationSchema: functionRoleSchema,
    onSubmit: async (values, {setSubmitting}) => {
      await axios
        .post(`${CONTACTS_FUNCTION_URL}`, values)
        .then((res: AxiosResponse<{data: FunctionRole}>) => {
          updateTable(res.data.data)
          toast.success(intl.formatMessage({id: 'Função Criada'}), toastStyles)
          handleClose()
          setSubmitting(false)
        })
        .catch((err: any) => {
          Object.keys(err.response.data.errors).forEach((key) => {
            formik.setFieldError(key, err.response.data.errors[key][0] ?? 'undefined')
          })
          formik.setTouched(generalize(initialValues, true), false)
          setSubmitting(false)
        })
    },
  })

  return (
    <>
      <button type='button' className='btn btn-primary' onClick={handleShow}>
        {intl.formatMessage({id: 'Criar Função'})}
      </button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <h3 className='card-title text-primary'>{intl.formatMessage({id: 'Criar Função'})}</h3>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <WarningBlock
              show={formik.isValid}
              warning={'Campos obrigatórios (*), preencha os campos corretamente!'}
            />
            <div className='row'>
              <CustomInput
                required={true}
                formikFields={formik.getFieldProps('name')}
                label={'Função'}
                type={'text'}
                touched={formik.touched.name}
                errors={formik.errors.name}
                name={'name'}
              />
            </div>
          </Modal.Body>
          <CustomModalFooter
            isValid={formik.isValid}
            isSubmitting={formik.isSubmitting}
            handleClose={handleClose}
            closeLabel={'Close'}
            submitLabel={'Confirmar'}
          />
        </form>
      </Modal>
    </>
  )
}
