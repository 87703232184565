// @ts-nocheck
import clsx from 'clsx'
import {FC} from 'react'
import {Row} from 'react-table'
import {ID} from '../../../../../../../_metronic/helpers'
import {IntlShape} from 'react-intl'
import {Prescription} from '../../../../../../../models/_schedule'

type Props = {
  row: Row<Prescription>
  openModal: (id: ID) => void
  openModalDelete: (id: ID) => void
  intl: IntlShape
}

const CustomRow: FC<Props> = ({row, openModal, openModalDelete, intl}) => (
  <tr {...row.getRowProps()}>
    {row.cells.map((cell) => {
      return (
        <td
          {...cell.getCellProps()}
          className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}
        >
          {cell.column.id !== 'actions' ? cell.render('Cell') : cell.render('Cell')}
        </td>
      )
    })}
  </tr>
)

export {CustomRow}
