import {useEffect, useMemo, useState} from 'react'
import {ColumnInstance, Row, useTable} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {ListLoading} from '../../../../../_components/dataTable/loading/ListLoading'
import {ID, KTCardBody, stringifyRequestQuery} from '../../../../../../../_metronic/helpers'
import {DevicesListPagination} from '../components/header/DevicesListPagination'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {useParams} from 'react-router-dom'
import axios, {AxiosResponse} from 'axios'
import {DEVICES_URL} from '../../../../../../_const'
import {useIntl} from 'react-intl'
import {DeleteItemModal} from '../../../../../../modals/DeleteItemModal'
import {DevicessListSearchComponent} from '../components/header/DevicessListSearchComponent'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {Device, DevicesQueryResponse} from '../../../../../../../models/_device'
import {devicesColumns} from './columns/_columns'
import {AddDeviceModal} from '../components/modals/AddDeviceModal'
import {DeviceModal} from '../components/modals/DeviceModal'
import {useAuth} from '../../../../../../modules/auth'
import {havePerm} from '../../../../../../_helpers'

const DevicesTable = () => {
  const {id} = useParams()
  const intl = useIntl()
  const {auth} = useAuth()
  const {state} = useQueryRequest()
  const [deletable, setDeletable] = useState<ID>(-1)
  const [editable, setEditable] = useState<Device>({} as Device)
  const [devices, setDevices] = useState<Device[]>([])
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    MenuComponent.reinitialization()
    setLoading(true)
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
    const fetchData = async () => {
      const res = await axios
        .get(`${DEVICES_URL}?clinic_id=${id}&${updatedQuery}`) /**/
        .then((d: AxiosResponse<DevicesQueryResponse>) => d.data)
      setDevices(res.data || [])
    }
    fetchData()
    setLoading(false)
  }, [updatedQuery])

  const columns = useMemo(() => devicesColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data: devices,
  })

  const openModalFunc = async (id: ID) => {
    await setEditable(devices.find((element) => (element.id ?? 0) === id) ?? devices[0])
  }
  const openModalDel = async (id: ID) => {
    await setDeletable(devices.find((element) => (element.id ?? 0) === id)?.id ?? devices[0].id)
  }

  return (
    <>
      <div className='card-header border-0 pt-6'>
        <DevicessListSearchComponent />
        {/* begin::Card toolbar */}
        <div className='card-toolbar'>
          {havePerm('devices:create', auth) && havePerm('devicemodels:list', auth) && (
            <AddDeviceModal
              updateTable={(device: Device) => {
                setDevices([...devices, device])
              }}
            />
          )}
        </div>
        {/* end::Card toolbar */}
      </div>
      <KTCardBody className='py-4'>
        <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            {...getTableProps()}
          >
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gies-0'>
                {headers.map((column: ColumnInstance<Device>) => (
                  <CustomHeaderColumn key={column.id} column={column} />
                ))}
              </tr>
            </thead>
            <tbody className='text-gray-600' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<Device>, i) => {
                  prepareRow(row)
                  return (
                    <CustomRow
                      row={row}
                      key={`row-${i}-${row.id}`}
                      openModal={(id: ID) => openModalFunc(id)}
                      openModalDelete={(id: ID) => openModalDel(id)}
                      intl={intl}
                    />
                  )
                })
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      {intl.formatMessage({id: 'Não foram encontrados resultados'})}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <DevicesListPagination />
        {isLoading && <ListLoading />}

        <DeleteItemModal
          toastTitle={'Dispositivo Eliminado'}
          url={DEVICES_URL}
          id={deletable}
          deleteRecord={(id: ID) => {
            setDevices((current) =>
              current.filter((obj) => {
                return obj.id !== id
              })
            )
          }}
        />
        <DeviceModal
          device={editable}
          updateList={(device: Device) => {
            setDevices((current) =>
              current.map((obj) => {
                if (obj.id === device.id) {
                  return device
                }
                return obj
              })
            )
          }}
        />
      </KTCardBody>
    </>
  )
}

export {DevicesTable}
