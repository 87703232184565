import axios, {AxiosResponse} from 'axios'
import {MODELS_URL} from "../app/_const";
import {ModelsQueryResponse} from "../models/_deviceModel";


const getModels = (query: string, id: string): Promise<ModelsQueryResponse> => {
    return axios
        .get(`${MODELS_URL}?${query}`)
        .then((d: AxiosResponse<ModelsQueryResponse>) => d.data)
}

export {getModels}
