import axios, {AxiosResponse} from 'axios'
import {SpecialtyQueryResponse} from "../models/_specialty";
import {SPECIALTIES_URL} from "../app/_const";


const getSpecialties = (query: string): Promise<SpecialtyQueryResponse> => {
    return axios
        .get(`${SPECIALTIES_URL}?${query}`)/**/
        .then((d: AxiosResponse<SpecialtyQueryResponse>) => d.data)
}

export {getSpecialties}