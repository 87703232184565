/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import {useAuth} from '../../../../app/modules/auth'
import {Languages} from './Languages'
import {useIntl} from 'react-intl'
import {API_IMG_URL} from '../../../../app/_const'
import {MySettingsModal} from '../../../../app/modals/MySettingsModal'

type Props = {
  updateFunc: (newAuth: any) => void
}

const HeaderUserMenu = (props: Props) => {
  const {auth, logout, setCurrentUser, currentUser} = useAuth()
  const intl = useIntl()
  const [src, setSrc] = useState(API_IMG_URL + '/' + (auth?.data?.photo || ''))

  return (
    <>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <div className='menu-content d-flex align-items-center px-3'>
            <div className='symbol symbol-50px me-5'>
              <img alt='Logo' src={src} />
            </div>
            <div className='d-flex flex-column'>
              <div className='fw-bolder d-flex align-items-center fs-5'>
                {currentUser?.full_name}
              </div>
              <a href='' className='fw-bold text-muted text-hover-primary fs-7'>
                {currentUser?.email}
              </a>
            </div>
          </div>
        </div>

        <div className='separator my-2'></div>

        <MySettingsModal
          updateInfo={(newAuth) => {
            setCurrentUser(newAuth.data)
            setSrc('')
            setSrc(API_IMG_URL + '/' + (newAuth.data.photo || ''))
            props.updateFunc(newAuth)
          }}
        />

        <Languages />

        <div className='menu-item px-5'>
          <a onClick={logout} className='menu-link px-5'>
            {intl.formatMessage({id: 'Sign Out'})}
          </a>
        </div>
      </div>
    </>
  )
}

export {HeaderUserMenu}
