// @ts-nocheck
import { Column } from "react-table";
import { ActionsCell } from "./ActionsCell";
import { CustomHeader } from "./CustomHeader";
import { DailyCheck } from "../../../../../../../models/_dailyCheck";

const dcColumns: ReadonlyArray<Column<DailyCheck>> = [
    {
        Header: (props) => <CustomHeader tableProps={props} title='Frequência Cardiaca' className='min-w-125px'/>,
        accessor: 'hr',
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='PI' className='min-w-125px'/>,
        accessor: 'pi',
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='OXY' className='min-w-125px'/>,
        accessor: 'oxy',
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='PR' className='min-w-125px'/>,
        accessor: 'pr',
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='ST' className='min-w-125px'/>,
        accessor: 'st',
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='QRS' className='min-w-125px'/>,
        accessor: 'qrs',
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='QTC' className='min-w-125px'/>,
        accessor: 'qtc',
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='QTC' className='min-w-125px'/>,
        accessor: 'qt',
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='PVCS' className='min-w-125px'/>,
        accessor: 'pvcs',
    },
    {
        Header: (props) => <CustomHeader tableProps={props} title='Data' className='min-w-125px'/>,
        accessor: 'date',
    },
    {
        Header: (props) => (
            <CustomHeader tableProps={props} title='Selecionar' className='text-end min-w-100px'/>
        ),
        id: 'actions',
        Cell: ({...props}) => <ActionsCell dc={props.data[props.row.index]}/>,
    },
]

export {dcColumns}