export const API_URL = process.env.REACT_APP_API_URL
export const API_IMG_URL = process.env.REACT_APP_API
export const CLINICS_URL = `${API_URL}/clinics`
export const ClINICS_COLLAB_URL = `${CLINICS_URL}/collab`
export const ClINICS_PATIENT_URL = `${CLINICS_URL}/patient`
export const ClINICS_NOTES_URL = `${CLINICS_URL}/observations`
export const PRIORITIES_URL = `${API_URL}/appointment-priorities`
export const SCHEDULES_URL = `${API_URL}/schedules/user-schedules`
export const PRESCRIPTIONS_URL = `${API_URL}/prescriptions/user-prescriptions`
export const PRESCRIPTIONS_STORE_URL = `${API_URL}/prescriptions`
export const PERMISSIONS_URL = `${API_URL}/permissions`
export const APPOINTMENT_CATEGORIES_URL = `${API_URL}/appointment-categories`
export const ATTACHMENT_CATEGORIES_URL = `${API_URL}/attachment-categories`
export const SPECIALTIES_URL = `${API_URL}/specialties`
export const NOTES_URL = `${API_URL}/observations`
export const RELATIONSHIPS_URL = `${API_URL}/relationships`
export const PATHOLOGIES_URL = `${API_URL}/pathology-types`
export const EMAIL_TEMPLATES_URL = `${API_URL}/email-templates`
export const SMTP_URL = `${API_URL}/smtps`
export const DEVICES_URL = `${API_URL}/devices`
export const USER_DEVICES_URL = `${API_URL}/user-devices`
export const BRANDS_URL = `${API_URL}/brands`
export const MODELS_URL = `${API_URL}/device-models`
export const MEDICATION_TYPE_URL = `${API_URL}/medication-types`
export const MEDICATION_URL = `${API_URL}/medications`
export const USERS_URL = `${API_URL}/users`
export const APPOINTMENT_URL = `${API_URL}/appointments`
export const COUNTRY_URL = `${API_URL}/countries`
export const CLINIC_CONTACTS_URL = `${CLINICS_URL}/contacts`
export const CONTACTS_URL = `${API_URL}/contacts`
export const CONTACTS_FUNCTION_URL = `${API_URL}/function-roles`
export const AUTH_URL = `${API_URL}/auth`
export const ATTACHMENT_URL = `${API_URL}/attachments`
export const ROLE_URL = `${API_URL}/roles`
export const AGGREGATE_URL = `${API_URL}/aggregates`
export const PROFESSION_URL = `${API_URL}/professions`
export const RELATIVES_URL = `${API_URL}/relatives`
