import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import axios, {AxiosResponse} from 'axios'
import {toast} from 'react-toastify'
import {PROFESSION_URL, RELATIONSHIPS_URL, RELATIVES_URL} from '../../../../../../_const'
import {Relative} from '../../../../../../../models/_relative'
import {customStyles, toastStyles} from '../../../../../../_styles'
import CustomInput from '../../../../../_components/CustomInput'
import {relativeSchema} from '../../../../../../../schemas/_relative'
import FormikTextArea from '../../../../../_components/FormikTextArea'
import Select from 'react-select'
import {Relationship} from '../../../../../../../models/_relationship'
import {useThemeMode} from '../../../../../../../_metronic/partials'
import {Profession} from '../../../../../../../models/_profession'
import CustomModalFooter from '../../../../../_components/modals/CustomModalFooter'
import {generalize} from '../../../../../../_helpers'
import WarningBlock from '../../../../../_components/inputs/WarningBlock'
import FormikErrors from '../../../../../_components/FormikErrors'

interface Props {
  updateTable: (relative: Relative) => void
}

export function AddRelativeModal({updateTable}: Props) {
  const intl = useIntl()
  const [show, setShow] = useState(false)
  const [relationships, setRelationships] = useState<Relationship[]>([])
  const [professions, setProfessions] = useState<Profession[]>([])
  const {menuMode} = useThemeMode()

  const handleClose = () => {
    setShow(false)
    formik.resetForm()
  }
  const handleShow = () => setShow(true)

  useEffect(() => {
    const fetch = async () => {
      const response = await axios
        .get(`${PROFESSION_URL}`)
        .then((d: AxiosResponse<{data: Profession[]}>) => d.data)
      setProfessions(response.data)
      const res = await axios
        .get(`${RELATIONSHIPS_URL}`)
        .then((d: AxiosResponse<{data: Relationship[]}>) => d.data)
      setRelationships(res.data)
    }
    if (show) fetch()
  }, [show])

  const initialValues = {
    name: '',
    email: '',
    niss: '',
    phone: '',
    mobile_phone: '',
    qualifications: '',
    notes: '',
    user_id: '',
    relationship_id: '',
    profession_id: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: relativeSchema,
    onSubmit: async (values, {setSubmitting}) => {
      await axios
        .post(`${RELATIVES_URL}`, values)
        .then((res: AxiosResponse<{data: Relative}>) => {
          updateTable(res.data.data)
          toast.success(intl.formatMessage({id: 'Parente Criada'}), toastStyles)
          handleClose()
          setSubmitting(false)
        })
        .catch((err: any) => {
          Object.keys(err.response.data.errors).forEach((key) => {
            formik.setFieldError(key, err.response.data.errors[key][0] ?? 'undefined')
          })
          formik.setTouched(generalize(initialValues, true), false)
          setSubmitting(false)
        })
    },
  })

  return (
    <>
      <button type='button' className='btn btn-primary' onClick={handleShow}>
        {intl.formatMessage({id: 'Criar Parente'})}
      </button>

      <Modal show={show} onHide={handleClose} size={'lg'}>
        <Modal.Header closeButton>
          <h3 className='card-title text-primary'>{intl.formatMessage({id: 'Criar Parente'})}</h3>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className='row'>
              <WarningBlock
                show={formik.isValid}
                warning={'Campos obrigatórios (*), preencha os campos corretamente!'}
              />
              <div className='col-12 col-sm-6'>
                <CustomInput
                  required={true}
                  formikFields={formik.getFieldProps('name')}
                  label={'Nome'}
                  type={'text'}
                  touched={formik.touched.name}
                  errors={formik.errors.name}
                  name={'name'}
                />
                <CustomInput
                  required={true}
                  formikFields={formik.getFieldProps('email')}
                  label={'Email'}
                  type={'email'}
                  touched={formik.touched.email}
                  errors={formik.errors.email}
                  name={'email'}
                />
                <CustomInput
                  required={true}
                  formikFields={formik.getFieldProps('niss')}
                  label={'NISS'}
                  type={'text'}
                  touched={formik.touched.niss}
                  errors={formik.errors.niss}
                  name={'niss'}
                />
                <div className='mb-5 w-100'>
                  <label className=' form-label required'>
                    {intl.formatMessage({id: 'Profissão'})}
                  </label>
                  <Select
                    options={professions}
                    getOptionLabel={(profession: Profession) => profession.name}
                    getOptionValue={(profession: Profession) => `${profession.id}`}
                    {...formik.getFieldProps('profession_id')}
                    value={professions.find((value) => {
                      return `${value.id}` == `${formik.values.profession_id}`
                    })}
                    onBlur={(event) => {
                      formik.setFieldTouched('profession_id', true)
                      formik.setErrors({...formik.errors, profession_id: undefined})
                    }}
                    onChange={(value) => {
                      formik.setFieldValue('profession_id', `${value?.id}`)
                    }}
                    styles={customStyles(
                      menuMode,
                      formik.errors.profession_id,
                      formik.touched.profession_id
                    )}
                  />
                  <FormikErrors
                    errors={formik.errors.profession_id}
                    touched={formik.touched.profession_id}
                  />
                </div>
              </div>
              <div className='col-12 col-sm-6'>
                <CustomInput
                  required={true}
                  formikFields={formik.getFieldProps('phone')}
                  label={'Telefone'}
                  type={'text'}
                  touched={formik.touched.phone}
                  errors={formik.errors.phone}
                  name={'phone'}
                />
                <CustomInput
                  required={true}
                  formikFields={formik.getFieldProps('mobile_phone')}
                  label={'Telemóvel'}
                  type={'text'}
                  touched={formik.touched.mobile_phone}
                  errors={formik.errors.mobile_phone}
                  name={'mobile_phone'}
                />
                <CustomInput
                  required={true}
                  formikFields={formik.getFieldProps('qualifications')}
                  label={'Qualificações'}
                  type={'text'}
                  touched={formik.touched.qualifications}
                  errors={formik.errors.qualifications}
                  name={'qualifications'}
                />
                <div className='mb-5 w-100'>
                  <label className='form-label required'>
                    {intl.formatMessage({id: 'Grau de Parentesco'})}
                  </label>
                  <Select
                    options={relationships}
                    getOptionLabel={(relationship: Relationship) => relationship.name}
                    getOptionValue={(relationship: Relationship) => `${relationship.id}`}
                    {...formik.getFieldProps('relationship_id')}
                    value={professions.find((value) => {
                      return `${value.id}` == `${formik.values.relationship_id}`
                    })}
                    onBlur={(event) => {
                      formik.setFieldTouched('relationship_id', true)
                      formik.setErrors({...formik.errors, relationship_id: undefined})
                    }}
                    onChange={(value) => {
                      formik.setFieldValue('relationship_id', `${value?.id}`)
                    }}
                    styles={customStyles(
                      menuMode,
                      formik.errors.relationship_id,
                      formik.touched.relationship_id
                    )}
                  />
                  <FormikErrors
                    errors={formik.errors.relationship_id}
                    touched={formik.touched.relationship_id}
                  />
                </div>
              </div>
              <FormikTextArea
                formikFields={formik.getFieldProps('notes')}
                label={'Observações'}
                touched={formik.touched.notes}
                errors={formik.errors.notes}
                name={'notes'}
              />
            </div>
          </Modal.Body>
          <CustomModalFooter
            isValid={formik.isValid}
            isSubmitting={formik.isSubmitting}
            handleClose={handleClose}
            closeLabel={'Close'}
            submitLabel={'Confirmar'}
          />
        </form>
      </Modal>
    </>
  )
}
