/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import axios from 'axios'
import {ID} from '../../_metronic/helpers'
import {toastStyles} from '../_styles'

type Props = {
  url: string
  toastTitle: string
  id: ID
  deleteRecord: (id: ID) => void
}

export function DeleteItemModal(props: Props) {
  const intl = useIntl()

  const deleteRecord = () => {
    axios
      .delete(props.url + '/' + props.id)
      .then(() => {
        toast.success(intl.formatMessage({id: props.toastTitle}), toastStyles)
        props.deleteRecord(props.id)
      })
      .catch(() => {
        toast.error(intl.formatMessage({id: 'Erro ao Eliminar'}), toastStyles)
      })
  }
  return (
    <>
      <div className='modal fade' tabIndex={-1} id='kt_modal_delete_item'>
        <div className='modal-dialog modal-lg'>
          <div className='modal-content '>
            <div className='modal-header border-0'>
              <h3 className='card-title text-primary'></h3>
              <button
                type='button'
                className='btn-close'
                aria-label='Close'
                data-bs-dismiss='modal'
              ></button>
            </div>
            <div className='modal-body'>
              <div className='d-flex justify-content-around'>
                <i className='fa-solid fa-trash-can text-danger m-4' style={{fontSize: '10em'}}></i>
              </div>
              <div className='d-flex justify-content-around'>
                <div className='alert-text font-weight-bold'>
                  {intl.formatMessage({id: 'Têm a certeza que pretende eliminar este registo?'})}
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                {intl.formatMessage({id: 'Close'})}
              </button>
              <button
                type='button'
                className='btn btn-danger'
                onClick={() => deleteRecord()}
                data-bs-dismiss='modal'
              >
                {intl.formatMessage({id: 'Confirmar'})}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
