// @ts-nocheck
import clsx from "clsx";
import { FC } from "react";
import { Row } from "react-table";
import { ID } from "../../../../../../../_metronic/helpers";
import { IntlShape } from "react-intl";
import { Attachment } from "../../../../../../../models/_attachment";
import { DownloadCell } from "./DownloadCell";

type Props = {
    row: Row<Attachment>
    openModal: (id: ID) => void
    openModalDelete: (id: ID) => void
    intl: IntlShape
}

const CustomRow: FC<Props> = ({row, openModalDelete}) => (
    <tr {...row.getRowProps()}>
        {row.cells.map((cell) => {
            return (
                <td
                    {...cell.getCellProps()}
                    className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}
                >
                    {cell.column.id !== 'actions' ? cell.render('Cell') :
                        <DownloadCell attachment={row.original}
                                      openModalDelete={() => openModalDelete(row.original.id)}/>}
                </td>
            )
        })}
    </tr>
)

export {CustomRow}
