import { ListViewProvider } from "./core/ListViewProvider";
import { QueryRequestProvider } from "./core/QueryRequestProvider";
import { QueryResponseProvider } from "./core/QueryResponseProvider";
import { ProfessionsTable } from "./table/ProfessionsTable";
import { KTCard } from "../../../../_metronic/helpers";

const ProfessionsListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <KTCard>
          <ProfessionsTable />
        </KTCard>
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {ProfessionsListWrapper}
