import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {KTCard} from '../../../../_metronic/helpers'
import {FunctionRolesTable} from './table/FunctionRolesTable'

const FunctionRolesList = () => {
  return (
    <>
      <KTCard>
        <FunctionRolesTable />
      </KTCard>
    </>
  )
}

const FunctionRolesListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <FunctionRolesList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {FunctionRolesListWrapper}
