import React, {useEffect, useState} from 'react'
import {useAuth} from '../../../modules/auth'
import {useParams} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {havePerm} from '../../../_helpers'
import CustomInput from '../../_components/CustomInput'
import FormikTextArea from '../../_components/FormikTextArea'
import {useFormik} from 'formik'
import axios, {AxiosResponse} from 'axios'
import {AGGREGATE_URL} from '../../../_const'
import {toast} from 'react-toastify'
import {toastStyles} from '../../../_styles'
import {aggregateSchema} from '../../../../schemas/_aggregate'
import {Aggregate} from '../../../../models/_aggregate'
import WarningBlock from '../../_components/inputs/WarningBlock'

const AggregateCard = () => {
  const intl = useIntl()
  const {id} = useParams()
  const {auth} = useAuth()
  const perm = havePerm('aggregates:create', auth)
  const [initialValues, setinitialValues] = useState<Aggregate>({} as Aggregate)
  useEffect(() => {
    const fetch = async () => {
      const response = await axios
        .get(`${AGGREGATE_URL}/${id}`)
        .then((d: AxiosResponse<{data: Aggregate}>) => d.data)
      setinitialValues(response.data)
    }

    fetch()
  }, [])

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: aggregateSchema,
    onSubmit: async (values, {setSubmitting}) => {
      await axios.post(`${AGGREGATE_URL}`, {...values, user_id: id}).then(() => {
        toast.success(intl.formatMessage({id: 'Contacto de Emergência Atualizado'}), toastStyles)
        setSubmitting(false)
      })
    },
  })

  return (
    <div className='card mb-5'>
      <form onSubmit={formik.handleSubmit}>
        <div className='card-header'>
          <h3 className='card-title text-primary'>
            {intl.formatMessage({id: 'Contacto de Emergência'})}
          </h3>
          <div className='card-toolbar'>
            {perm && (
              <button
                type='submit'
                className='btn btn-primary btn-sm'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {intl.formatMessage({id: 'Guardar'})}
              </button>
            )}
          </div>
        </div>

        <div className='card-body'>
          <div className='row'>
            <WarningBlock
              show={formik.isValid}
              warning={'Campos obrigatórios (*), preencha os campos corretamente!'}
            />
            <div className='col-12 col-sm-6'>
              <CustomInput
                required={true}
                formikFields={formik.getFieldProps('emergency_relative')}
                label={'Contacto de Emergência'}
                type={'text'}
                touched={formik.touched.emergency_relative}
                errors={formik.errors.emergency_relative}
                name={'emergency_relative'}
              />
            </div>
            <div className='col-12 col-sm-6'>
              <CustomInput
                required={true}
                formikFields={formik.getFieldProps('closest_location')}
                label={'Morada'}
                type={'text'}
                touched={formik.touched.closest_location}
                errors={formik.errors.closest_location}
                name={'closest_location'}
              />
            </div>
            <FormikTextArea
              formikFields={formik.getFieldProps('notes')}
              label={'Observações'}
              touched={formik.touched.notes}
              errors={formik.errors.notes}
              name={'notes'}
            />
          </div>
        </div>
      </form>
    </div>
  )
}

export default AggregateCard
