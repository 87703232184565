// @ts-nocheck
import { Column } from "react-table";
import { RolesCustomHeader } from "./RolesCustomHeader";
import { Role } from "../../../../../../models/_role";

export const rolesColumns: ReadonlyArray<Column<Role>> = [
  {
    Header: (props) => (
      <RolesCustomHeader tableProps={props} title='Nome' className='min-w-125px' />
    ),
    accessor: 'name',
  },
  {
    Header: (props) => (
      <RolesCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'actions',
  },
]
