import { ListViewProvider } from "./core/ListViewProvider";
import { QueryRequestProvider } from "./core/QueryRequestProvider";
import { QueryResponseProvider } from "./core/QueryResponseProvider";
import { KTCard } from "../../../../_metronic/helpers";
import { ModelsTable } from "./table/ModelsTable";

const ModelsList = () => {
    return (
        <>
            <KTCard>
                <ModelsTable/>
            </KTCard>
        </>
    )
}

const ModelsListWrapper = () => (
    <QueryRequestProvider>
        <QueryResponseProvider>
            <ListViewProvider>
                <ModelsList/>
            </ListViewProvider>
        </QueryResponseProvider>
    </QueryRequestProvider>
)

export {ModelsListWrapper}
