import axios, {AxiosResponse} from 'axios'
import {ROLE_URL} from '../app/_const'
import {ID, Response} from '../_metronic/helpers'
import * as Yup from 'yup'

export type Role = {
  id: ID
  name: string
  isDefault: ID
  permissions: string[]
}

export type RolesQueryResponse = Response<Array<Role>>
export const getRoles = (query: string): Promise<RolesQueryResponse> => {
  return axios.get(`${ROLE_URL}?${query}`).then((d: AxiosResponse<RolesQueryResponse>) => d.data)
}

export const roleSchema = Yup.object().shape({
  name: Yup.string().required('Field Required'),
})
