import clsx from 'clsx'
import {HeaderUserMenu, ThemeModeSwitcher} from '../../../partials'
import {useState} from "react";
import {API_IMG_URL} from "../../../../app/_const";
import {useAuth} from "../../../../app/modules/auth";
import {Image} from "react-bootstrap";

const itemClass = 'ms-1 ms-lg-3'
const userAvatarClass = 'symbol-35px symbol-md-40px'


const Navbar = () => {
    const {auth} = useAuth()
    const [src, setSrc] = useState(API_IMG_URL + '/' + (auth?.data?.photo || ''))

    return (
        <div className='app-navbar flex-shrink-0 d-flex flex-end'>
            <div className={clsx('app-navbar-item', itemClass)}>
                <ThemeModeSwitcher toggleBtnClass={clsx('btn-active-light-primary btn-custom')}/>
            </div>

            <div className={clsx('app-navbar-item', itemClass)}>
                <div
                    className={clsx('cursor-pointer symbol', userAvatarClass)}
                    data-kt-menu-trigger="{default: 'click'}"
                    data-kt-menu-attach='parent'
                    data-kt-menu-placement='bottom-end'
                >
                    <Image src={src} alt=''/>
                </div>
                <HeaderUserMenu updateFunc={(newAuth) => {
                    setSrc('')
                    setSrc(API_IMG_URL + '/' + (newAuth.data.photo || ''))
                }}/>
            </div>
        </div>
    )
}

export {Navbar}
