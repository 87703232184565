import {useEffect, useMemo, useState} from 'react'
import {ColumnInstance, Row, useTable} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {ListLoading} from '../../../_components/dataTable/loading/ListLoading'
import {ID, KTCardBody, stringifyRequestQuery} from '../../../../../_metronic/helpers'
import {pathologiesColumns} from './columns/_columns'
import {PathologiesListPagination} from '../components/header/PathologiesListPagination'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {useParams} from 'react-router-dom'
import axios, {AxiosResponse} from 'axios'
import {PATHOLOGIES_URL} from '../../../../_const'
import {useIntl} from 'react-intl'
import {DeleteItemModal} from '../../../../modals/DeleteItemModal'
import {PathologiesListSearchComponent} from '../components/header/PathologiesListSearchComponent'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {Pathology, PathologysQueryResponse} from '../../../../../models/_pathology'
import {AddPathologyModal} from '../components/modals/AddPathologyModal'
import {EditPathologyModal} from '../components/modals/EditPathologyModal'
import {useAuth} from '../../../../modules/auth'
import {havePerm, havePerms} from '../../../../_helpers'

const PathologiesTable = () => {
  const {id} = useParams()
  const intl = useIntl()
  const {auth} = useAuth()
  const {state} = useQueryRequest()
  const [deletable, setDeletable] = useState<ID>(-1)
  const [editable, setEditable] = useState<Pathology>({} as Pathology)
  const [pathologies, setPathologies] = useState<Pathology[]>([])
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    MenuComponent.reinitialization()
    setLoading(true)
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
    const fetchData = async () => {
      const res = await axios
        .get(`${PATHOLOGIES_URL}?${updatedQuery}`) /**/
        .then((d: AxiosResponse<PathologysQueryResponse>) => d.data)
      setPathologies(res.data || [])
    }
    fetchData()
    setLoading(false)
  }, [updatedQuery])

  const columns = useMemo(() => pathologiesColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data: pathologies,
  })

  const openModalFunc = async (id: ID) => {
    await setEditable(pathologies.find((element) => (element.id ?? 0) === id) ?? pathologies[0])
  }
  const openModalDel = async (id: ID) => {
    await setDeletable(
      pathologies.find((element) => (element.id ?? 0) === id)?.id ?? pathologies[0].id
    )
  }

  return (
    <>
      <div className='card-header border-0 pt-6'>
        <PathologiesListSearchComponent />
        {/* begin::Card toolbar */}
        <div className='card-toolbar'>
          {havePerm('pathologytypes:create', auth) && (
            <AddPathologyModal
              updateTable={(pathology: Pathology) => {
                setPathologies([...pathologies, pathology])
              }}
            />
          )}
        </div>
        {/* end::Card toolbar */}
      </div>
      <KTCardBody className='py-4'>
        <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            {...getTableProps()}
          >
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gies-0'>
                {headers.map(
                  (column: ColumnInstance<Pathology>) =>
                    (havePerms(['pathologytypes:update', 'pathologytypes:delete'], auth) ||
                      column.id != 'actions') && (
                      <CustomHeaderColumn key={column.id} column={column} />
                    )
                )}
              </tr>
            </thead>
            <tbody className='text-gray-600' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<Pathology>, i) => {
                  prepareRow(row)
                  return (
                    <CustomRow
                      row={row}
                      key={`row-${i}-${row.id}`}
                      openModal={(id: ID) => openModalFunc(id)}
                      openModalDelete={(id: ID) => openModalDel(id)}
                      intl={intl}
                    />
                  )
                })
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      {intl.formatMessage({id: 'Não foram encontrados resultados'})}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <PathologiesListPagination />
        {isLoading && <ListLoading />}

        <DeleteItemModal
          toastTitle={'Patologia Eliminada'}
          url={PATHOLOGIES_URL}
          id={deletable}
          deleteRecord={(id: ID) => {
            setPathologies((current) =>
              current.filter((obj) => {
                return obj.id !== id
              })
            )
          }}
        />
        <EditPathologyModal
          pathology={editable}
          updateList={(pathology: Pathology) => {
            setPathologies((current) =>
              current.map((obj) => {
                if (obj.id === pathology.id) {
                  return pathology
                }
                return obj
              })
            )
          }}
        />
      </KTCardBody>
    </>
  )
}

export {PathologiesTable}
