import React, { useState } from "react";
import { Link, Outlet, useLocation, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import { DateRangePicker, DateRangePickerValue } from "@mantine/dates";
import { useAuth } from "../../../modules/auth";
import { havePerm } from "../../../_helpers";

function PatientRecordsLayout() {
  const intl = useIntl()
  const {auth} = useAuth()
  const {id} = useParams()
  const location = useLocation()
  const today = new Date()
  const prev = today.setMonth(today.getMonth() - 1)
  const [interval, setInterval] = useState<DateRangePickerValue>([new Date(prev), new Date()])
  return (
    <>
      <div className='card '>
        <div className='card-header '>
          <div className='card-title scroll'>
            <div className='d-flex overflow-auto h-55px '>
              <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
                {havePerm('ecgs:read', auth) && (
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/patient/records/' + id + '/ecgs' && 'active')
                      }
                      to={'/patient/records/' + id + '/ecgs'}
                    >
                      <span className='menu-icon'>
                        <i className={'fa-solid fa-file-waveform me-1'}></i>
                      </span>
                      {intl.formatMessage({id: 'Ecgs'})}
                    </Link>
                  </li>
                )}
                {havePerm('bloodpressures:read', auth) && (
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/patient/records/' + id + '/blood-pressure' &&
                          'active')
                      }
                      to={'/patient/records/' + id + '/blood-pressure'}
                    >
                      <span className='menu-icon'>
                        <i className={'fa-solid fa-heart-pulse me-1'}></i>
                      </span>
                      {intl.formatMessage({id: 'Pressão Sanguinea'})}
                    </Link>
                  </li>
                )}
                {havePerm('bloodglucoses:read', auth) && (
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/patient/records/' + id + '/blood-glucose' &&
                          'active')
                      }
                      to={'/patient/records/' + id + '/blood-glucose'}
                    >
                      <span className='menu-icon'>
                        <i className={'fa-solid fa-vial me-1'}></i>
                      </span>
                      {intl.formatMessage({id: 'Glucose'})}
                    </Link>
                  </li>
                )}
                {havePerm('dailychecks:read', auth) && (
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/patient/records/' + id + '/daily-check' &&
                          'active')
                      }
                      to={'/patient/records/' + id + '/daily-check'}
                    >
                      <span className='menu-icon'>
                        <i className={'fa-solid fa-clipboard-check me-1'}></i>
                      </span>
                      {intl.formatMessage({id: 'Checkup Diário'})}
                    </Link>
                  </li>
                )}
                {havePerm('oximeters:read', auth) && (
                  <li className='nav-item'>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/patient/records/' + id + '/oximeters' && 'active')
                      }
                      to={'/patient/records/' + id + '/oximeters'}
                    >
                      <span className='menu-icon'>
                        <i className={'fa-solid fa-file-lines me-1'}></i>
                      </span>
                      {intl.formatMessage({id: 'Oximetro'})}
                    </Link>
                  </li>
                )}
                {havePerm('thermometers:read', auth) && (
                  <li className='nav-item '>
                    <Link
                      className={
                        `nav-link text-active-primary me-6 ` +
                        (location.pathname === '/patient/records/' + id + '/thermometers' &&
                          'active')
                      }
                      to={'/patient/records/' + id + '/thermometers'}
                    >
                      <span className='menu-icon '>
                        <i className={'fa-solid fa-temperature-three-quarters me-1'}></i>
                      </span>
                      {intl.formatMessage({id: 'Temperatura'})}
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div className='card-toolbar d-flex justify-content-center align-content-center '>
            <DateRangePicker
              placeholder='Pick dates range'
              value={interval}
              inputFormat='YYYY/MM/DD'
              onChange={(value) => setInterval(value)}
            />
          </div>
        </div>

        <div className='card-body'>
          <Outlet context={interval} />
        </div>
      </div>
    </>
  )
}

export default PatientRecordsLayout
