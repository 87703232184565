import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import PageTitle from '../_components/PageTitle'
import axios, {AxiosResponse} from 'axios'
import {ROLE_URL} from '../../_const'
import {ResponsePermissionRole} from '../../../models/_permission'
import {useFormik} from 'formik'
import {toast} from 'react-toastify'
import {toastStyles} from '../../_styles'
import {havePerm, haveRole} from '../../_helpers'
import {useAuth} from '../../modules/auth'
import {Navigate, useNavigate, useParams} from 'react-router-dom'
import {Role} from '../../../models/_role'

const PermissionsPage = () => {
  const intl = useIntl()
  const {auth} = useAuth()
  const {id} = useParams()
  const [permissions, setPermissions] = useState<string[]>([])
  const [initialValues, setInitialValues] = useState({...({} as Role), permissions: []} as Role)
  const navigate = useNavigate()

  useEffect(() => {
    const fetchData = async () => {
      const res = await axios
        .get(`${ROLE_URL}/${id}`)
        .then((d: AxiosResponse<ResponsePermissionRole>) => d.data)
      setPermissions(res.data.permissions)
      setInitialValues(res.data.role)
    }
    fetchData()
  }, [])

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {
      axios
        .patch(`${ROLE_URL}/${initialValues.id}`, values)
        .then(() => {
          navigate('/roles')
          toast.success(intl.formatMessage({id: 'Permissões Atualizadas'}), toastStyles)
        })
        .catch(() => {
          toast.error(intl.formatMessage({id: 'Erro ao Eliminar'}), toastStyles)
        })
    },
  })

  if (!(haveRole('super-admin', auth) || haveRole('admin', auth)))
    return <Navigate to={'/error/404'} />

  return (
    <>
      <PageTitle title='Permissões' division='Tabelas de Suporte' />
      <form onSubmit={formik.handleSubmit}>
        <div className='card card-custom'>
          <div className='card-header'>
            <h3 className='card-title text-primary'>{intl.formatMessage({id: 'Permissões'})}</h3>
            <div className='card-toolbar'>
              <button type='submit' className='btn btn-sm btn-primary'>
                {intl.formatMessage({id: 'Guardar'})}
              </button>
            </div>
          </div>
          <div className='card-body'>
            <div className='accordion' id='roles'>
              {initialValues.permissions && (
                <div className='accordion-item'>
                  <h2 className='accordion-header ' id={`Heading_permissions`}>
                    <button
                      className={'accordion-button'}
                      type='button'
                      data-bs-toggle='collapse'
                      data-bs-target={`#collapse_permissions`}
                      aria-expanded='true'
                      aria-controls={`collapse_permissions`}
                    >
                      {initialValues.name}
                    </button>
                  </h2>
                  <div
                    id={`collapse_permissions`}
                    className={'accordion-collapse collapse show'}
                    aria-labelledby={`Headingpermissions`}
                    data-bs-parent='#roles'
                  >
                    <div className='accordion-body'>
                      <div className='row'>
                        {permissions.map((permission) => {
                          return (
                            <div className='col-12 col-md-4'>
                              <div className='accordion' id={permission + '_admin'}>
                                <div className='accordion-item mb-2'>
                                  <h2
                                    className='accordion-header'
                                    id={`Heading_${permission + '_admin'}`}
                                  >
                                    <button
                                      className={'accordion-button collapsed'}
                                      type='button'
                                      data-bs-toggle='collapse'
                                      data-bs-target={`#collapse_${permission + '_admin'}`}
                                      aria-expanded='true'
                                      aria-controls={`collapse_${permission + '_admin'}`}
                                    >
                                      {intl.formatMessage({id: permission})}
                                    </button>
                                  </h2>
                                  <div
                                    id={`collapse_${permission + '_admin'}`}
                                    className={'accordion-collapse collapse'}
                                    aria-labelledby={`Heading${permission + '_admin'}`}
                                    data-bs-parent='#permissions'
                                  >
                                    <div className='accordion-body'>
                                      {havePerm(`${permission}:read`, auth) && (
                                        <div className='mb-10'>
                                          <div className='form-check form-check-custom form-check-solid'>
                                            <input
                                              checked={formik.values.permissions.includes(
                                                `${permission}:read`
                                              )}
                                              className='form-check-input'
                                              type='checkbox'
                                              value={`${permission}:read`}
                                              onChange={formik.handleChange}
                                              name={'permissions'}
                                              id={`read_${permission}_permissions`}
                                            />
                                            <label
                                              className='form-check-label'
                                              htmlFor={`read_${permission}_permissions`}
                                            >
                                              {intl.formatMessage({id: 'Ler'})}
                                            </label>
                                          </div>
                                        </div>
                                      )}
                                      {havePerm(`${permission}:list`, auth) && (
                                        <div className='mb-10'>
                                          <div className='form-check form-check-custom form-check-solid'>
                                            <input
                                              checked={formik.values.permissions.includes(
                                                `${permission}:list`
                                              )}
                                              className='form-check-input'
                                              type='checkbox'
                                              value={`${permission}:list`}
                                              onChange={formik.handleChange}
                                              name={'permissions'}
                                              id={`list_${permission}_permissions`}
                                            />
                                            <label
                                              className='form-check-label'
                                              htmlFor={`list_${permission}_permissions`}
                                            >
                                              {intl.formatMessage({id: 'Listar'})}
                                            </label>
                                          </div>
                                        </div>
                                      )}
                                      {havePerm(`${permission}:update`, auth) && (
                                        <div className='mb-10'>
                                          <div className='form-check form-check-custom form-check-solid'>
                                            <input
                                              checked={formik.values.permissions.includes(
                                                `${permission}:update`
                                              )}
                                              className='form-check-input'
                                              type='checkbox'
                                              value={`${permission}:update`}
                                              onChange={formik.handleChange}
                                              name={'permissions'}
                                              id={`update_${permission}_permissions`}
                                            />
                                            <label
                                              className='form-check-label'
                                              htmlFor={`update_${permission}_permissions`}
                                            >
                                              {intl.formatMessage({id: 'Editar'})}
                                            </label>
                                          </div>
                                        </div>
                                      )}
                                      {havePerm(`${permission}:create`, auth) && (
                                        <div className='mb-10'>
                                          <div className='form-check form-check-custom form-check-solid'>
                                            <input
                                              checked={formik.values.permissions.includes(
                                                `${permission}:create`
                                              )}
                                              className='form-check-input'
                                              type='checkbox'
                                              value={`${permission}:create`}
                                              onChange={formik.handleChange}
                                              name={'permissions'}
                                              id={`create_${permission}_permissions`}
                                            />
                                            <label
                                              className='form-check-label'
                                              htmlFor={`create_${permission}_permissions`}
                                            >
                                              {intl.formatMessage({id: 'Criar'})}
                                            </label>
                                          </div>
                                        </div>
                                      )}
                                      {havePerm(`${permission}:delete`, auth) && (
                                        <div className='mb-10'>
                                          <div className='form-check form-check-custom form-check-solid'>
                                            <input
                                              checked={formik.values.permissions.includes(
                                                `${permission}:delete`
                                              )}
                                              className='form-check-input'
                                              type='checkbox'
                                              name={'permissions'}
                                              value={`${permission}:delete`}
                                              onChange={formik.handleChange}
                                              id={`delete_${permission}_permissions`}
                                            />
                                            <label
                                              className='form-check-label'
                                              htmlFor={`delete_${permission}_permissions`}
                                            >
                                              {intl.formatMessage({id: 'Eliminar'})}
                                            </label>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default PermissionsPage
