/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from "react";
import clsx from "clsx";
import { Image } from "react-bootstrap";
import { API_IMG_URL } from "../../../../_const";
import { MenuComponent } from "../../../../../_metronic/assets/ts/components";

type Props = {
    photo?: string
}

const PhotoCell: FC<Props> = ({photo}) => {

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])
    return (
        <>
            <div
                className={clsx('symbol', 'symbol-35px symbol-md-40px')}
            >
                <Image src={API_IMG_URL + '/' + (photo ?? 'assets/avatars/blank.png')} alt=''/>
            </div>

        </>
    )
}

export {PhotoCell}
