import React from 'react'
import {useIntl} from 'react-intl'

interface Props {
  show: boolean
  warning: string
}

function WarningBlock({show, warning}: Props) {
  const intl = useIntl()

  return (
    <>
      {!show && (
        <div className='d-flex justify-content-around '>
          <div className='mb-lg-5 alert alert-danger w-100'>
            <div className='alert-text font-weight-bold'>{intl.formatMessage({id: warning})}</div>
          </div>
        </div>
      )}
    </>
  )
}

export default WarningBlock
