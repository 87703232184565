import React, {useEffect, useState} from 'react'
import axios, {AxiosResponse} from 'axios'
import {ROLE_URL, USERS_URL} from '../../../_const'
import {useParams} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {useThemeMode} from '../../../../_metronic/partials'
import Select from 'react-select'
import {customStyles, toastStyles} from '../../../_styles'
import {toast} from 'react-toastify'

export function CollaboratorSettings() {
  const intl = useIntl()
  const {id} = useParams()
  const {menuMode} = useThemeMode()
  const [roles, setRoles] = useState<{name: string}[]>([])
  const [selected, setSelected] = useState('')
  const [def, setDefault] = useState('')

  useEffect(() => {
    const fetch = async () => {
      const response = await axios
        .get(`${USERS_URL}/roles/${id}`)
        .then((response: AxiosResponse<string>) => response.data)
      setSelected(response)
      setDefault(response)
      const res = await axios
        .get(`${ROLE_URL}/list`)
        .then((response: AxiosResponse<{data: {name: string}[]}>) => response.data)
      setRoles(res.data)
    }
    fetch()
  }, [])

  return (
    <div className='card card-custom'>
      <div className='card-header'>
        <h3 className='card-title text-primary'>{intl.formatMessage({id: 'Atribuir Função'})}</h3>
      </div>
      <div className='card-body'>
        <div className='row'>
          <div className='col-12 col-sm-6'>
            <label className=' form-label'>{intl.formatMessage({id: 'Atribuir '})}</label>
            <Select
              options={roles}
              placeholder={selected}
              getOptionLabel={(option: {name: string}) => option.name}
              getOptionValue={(option: {name: string}) => option.name}
              onChange={(option) => {
                setSelected(option?.name ?? '')
              }}
              styles={customStyles(menuMode)}
            />
            <button
              className={'btn btn-sm btn-primary mt-4'}
              type={'button'}
              disabled={selected == def}
              onClick={async () => {
                await axios
                  .post(`${USERS_URL}/update-role`, {user_id: id, name: selected})
                  .then((response: AxiosResponse<any>) =>
                    toast.success(
                      intl.formatMessage({id: 'Função Atribuida com sucesso'}),
                      toastStyles
                    )
                  )
              }}
            >
              {intl.formatMessage({id: 'Confirmar '})}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
