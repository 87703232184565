import axios, {AxiosResponse} from 'axios'
import {ATTACHMENT_CATEGORIES_URL} from "../app/_const";
import {AttachmentCategoryQueryResponse} from "../models/_attachmentCategory";


const getAttachmentCategories = (query: string): Promise<AttachmentCategoryQueryResponse> => {
    return axios
        .get(`${ATTACHMENT_CATEGORIES_URL}?${query}`)/**/
        .then((d: AxiosResponse<AttachmentCategoryQueryResponse>) => d.data)
}

export {getAttachmentCategories}