import React, {useEffect, useRef, useState} from 'react'
import axios from 'axios'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import CustomInput from '../../../../_components/CustomInput'
import {PATHOLOGIES_URL} from '../../../../../_const'
import {toastStyles} from '../../../../../_styles'
import {pathologySchema} from '../../../../../../schemas/_pathologies'
import {Pathology} from '../../../../../../models/_pathology'
import CustomModalHeader from '../../../../_components/modals/CustomModalHeader'
import WarningBlock from '../../../../_components/inputs/WarningBlock'
import Modal from 'react-bootstrap/Modal'

interface Props {
  updateList: (pathology: Pathology) => void
  pathology: Pathology
}

export function EditPathologyModal(props: Props) {
  const intl = useIntl()
  const [initialValues, setInitialValues] = useState<Pathology>(props.pathology)
  const closeBtn = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    const fetchData = () => {
      setInitialValues(props.pathology)
    }
    fetchData()
  }, [props])

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: pathologySchema,
    onSubmit: async (values, {setSubmitting}) => {
      await axios
        .patch(`${PATHOLOGIES_URL}/${props.pathology.id}`, values)
        .then((res) => {
          props.updateList(res.data)
          toast.success(intl.formatMessage({id: 'Patologia Editada'}), toastStyles)
          setSubmitting(false)
          closeBtn?.current?.click()
        })
        .catch((err: any) => {
          Object.keys(err.response.data.errors).forEach((key) => {
            formik.setFieldError(key, err.response.data.errors[key][0] ?? 'undefined')
          })

          setSubmitting(false)
        })
    },
  })

  return (
    <>
      <div className='modal fade' tabIndex={-1} id='kt_modal_edit_pathology'>
        <div className='modal-dialog modal-lg'>
          <div className='modal-content '>
            <CustomModalHeader title={'Editar Patologia'} />
            <form onSubmit={formik.handleSubmit}>
              <Modal.Body>
                <WarningBlock
                  show={formik.isValid}
                  warning={'Campos obrigatórios (*), preencha os campos corretamente!'}
                />
                <div className='row'>
                  <div className='col-9'>
                    <CustomInput
                      required={true}
                      formikFields={formik.getFieldProps('name')}
                      label={'Patologia'}
                      type={'text'}
                      touched={formik.touched.name}
                      errors={formik.errors.name}
                      name={'name'}
                    />
                  </div>
                  <div className='col-3'>
                    <CustomInput
                      required={true}
                      formikFields={formik.getFieldProps('code')}
                      label={'Código'}
                      type={'text'}
                      touched={formik.touched.code}
                      errors={formik.errors.code}
                      name={'code'}
                    />
                  </div>
                </div>
              </Modal.Body>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-light'
                  data-bs-dismiss='modal'
                  ref={closeBtn}
                >
                  {intl.formatMessage({id: 'Close'})}
                </button>
                <button
                  type='submit'
                  className='btn btn-primary'
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  {intl.formatMessage({id: 'Confirmar'})}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
