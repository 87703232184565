// @ts-nocheck
import { Column } from "react-table";
import { RelationshipsCustomHeader } from "./RelationshipsCustomHeader";
import { Relationship } from "../../../../../../models/_relationship";

const relationshipColumns: ReadonlyArray<Column<Relationship>> = [
    {
        Header: (props) => <RelationshipsCustomHeader tableProps={props} title='Grau de Parentesco'
                                                      className='min-w-125px'/>,
        accessor: 'name',
    },
    {
        Header: (props) => (
            <RelationshipsCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px'/>
        ),
        id: 'actions',
    },
]

export {relationshipColumns}
