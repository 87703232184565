import * as Yup from 'yup'

export const medicalHealthRecordsSchema = Yup.object().shape({
  height: Yup.number().optional().nullable(),
  description: Yup.string().nullable().optional(),
  another_pathologies: Yup.string().nullable().optional(),
  pathologies: Yup.array(),
  medical_center_name: Yup.string().nullable().optional(),
  medical_center_assistant_doctor: Yup.string().nullable().optional(),
  medical_center_postcode: Yup.string().nullable().optional(),
  medical_center_contact: Yup.string().nullable().optional(),
  external_doctor_name: Yup.string().nullable().optional(),
  external_doctor_address: Yup.string().nullable().optional(),
  external_doctor_postcode: Yup.string().nullable().optional(),
  external_doctor_contact: Yup.string().nullable().optional(),
  food_cares: Yup.string().nullable().optional(),
  allergies: Yup.string().nullable().optional(),
  diseases: Yup.string().nullable().optional(),
  operations: Yup.string().nullable().optional(),
  dependencies: Yup.string().nullable().optional(),
  special_cares: Yup.string().nullable().optional(),
  user_id: Yup.number().nullable().optional(),
  blood_group_id: Yup.number().nullable().optional(),
  medical_center_address: Yup.string().nullable().optional(),
})
