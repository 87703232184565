import {ThemeModeType} from '../_metronic/partials'
import {ToastOptions} from 'react-toastify'

export const customStyles = (
  menuMode: ThemeModeType,
  errors?: boolean | string,
  touched?: boolean,
  perm?: boolean
) => {
  const colorText = menuMode === 'dark' ? '#7b7b8a' : '#65697e'
  let background =
    errors && touched
      ? menuMode === 'dark'
        ? '#1e1e2d'
        : '#ffffff'
      : menuMode === 'dark'
      ? '#1e1e2d'
      : '#ffffff'
  if (perm != undefined && !perm) background = menuMode === 'dark' ? '#2B2B40' : '#eff2f5'

  return {
    menu: (provided: any) => ({...provided, zIndex: 9999}),
    control: (base: any, state: {isFocused: any}) => ({
      ...base,
      border:
        errors && touched
          ? '1px solid #f1416c'
          : state.isFocused
          ? menuMode === 'dark'
            ? '1px solid #474761'
            : '1px solid #E4E6EF'
          : menuMode === 'dark'
          ? '1px solid #323248'
          : '1px solid #E4E6EF',
      background: background,

      zIndex: 0,
      boxShadow: null,
      color: colorText,
      fontSize: '1.1rem',
      padding: '0.26rem 0.65rem',
      fontWeight: '350',
      fontFamily: 'Font Awesome 6 Free',
      '&:hover': {},
    }),
    placeholder: (defaultStyles: any) => ({
      ...defaultStyles,
      color: colorText,
      zIndex: 1000,
    }),
    singleValue: (defaultStyles: any) => ({
      ...defaultStyles,
      zIndex: 9999,
      color: colorText,
    }),
    option: (defaultStyles: any) => ({
      ...defaultStyles,
      color: colorText,
      fontWeight: '350',
      fontFamily: 'Font Awesome 6 Free',
      zIndex: 9999,
    }),
    zIndex: 100,
  }
}

export const rteStyles = (menuMode: ThemeModeType) => ({
  border: menuMode === 'dark' ? '1px solid #323248' : '1px solid #E4E6EF',
})

export const toastStyles: ToastOptions = {
  position: 'top-right',
  autoClose: 3000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
}
