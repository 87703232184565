import { ListViewProvider } from "./core/ListViewProvider";
import { QueryRequestProvider } from "./core/QueryRequestProvider";
import { QueryResponseProvider } from "./core/QueryResponseProvider";
import { KTCard } from "../../../../_metronic/helpers";
import { RelationshipsTable } from "./table/RelationshipsTable";

const RelationshipsList = () => {
    return (
        <>
            <KTCard>
                <RelationshipsTable/>
            </KTCard>
        </>
    )
}

const RelationshipsListWrapper = () => (
    <QueryRequestProvider>
        <QueryResponseProvider>
            <ListViewProvider>
                <RelationshipsList/>
            </ListViewProvider>
        </QueryResponseProvider>
    </QueryRequestProvider>
)

export {RelationshipsListWrapper}
