import {ListViewProvider} from './core/ListViewProvider'
import {QueryRequestProvider} from './core/QueryRequestProvider'
import {QueryResponseProvider} from './core/QueryResponseProvider'
import {RelativesTable} from './table/RelativesTable'
import {KTCard} from '../../../../../_metronic/helpers'
import AggregateCard from '../../pages/AggregateCard'

const RelativesListWrapper = () => (
  <>
    <AggregateCard />
    <QueryRequestProvider>
      <QueryResponseProvider>
        <ListViewProvider>
          <KTCard>
            <RelativesTable />
          </KTCard>
        </ListViewProvider>
      </QueryResponseProvider>
    </QueryRequestProvider>
  </>
)

export {RelativesListWrapper}
