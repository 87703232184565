import {useEffect, useMemo, useState} from 'react'
import {ColumnInstance, Row, useTable} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {ListLoading} from '../../../_components/dataTable/loading/ListLoading'
import {ID, KTCardBody, stringifyRequestQuery} from '../../../../../_metronic/helpers'
import {relationshipColumns} from './columns/_columns'
import {RelationshipsListPagination} from '../components/header/RelationshipsListPagination'
import {useQueryRequest} from '../core/QueryRequestProvider'
import axios, {AxiosResponse} from 'axios'
import {RELATIONSHIPS_URL} from '../../../../_const'
import {useIntl} from 'react-intl'
import {DeleteItemModal} from '../../../../modals/DeleteItemModal'
import {RelationshipsListSearchComponent} from '../components/header/RelationshipsListSearchComponent'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {Relationship, RelationshipQueryResponse} from '../../../../../models/_relationship'
import {EditRelationshipModal} from '../components/modals/EditRelationshipModal'
import {AddRelationshipModal} from '../components/modals/AddRelationshipModal'
import {useAuth} from '../../../../modules/auth'
import {havePerm, havePerms} from '../../../../_helpers'

const RelationshipsTable = () => {
  const intl = useIntl()
  const {auth} = useAuth()
  const {state} = useQueryRequest()
  const [deletable, setDeletable] = useState<ID>(-1)
  const [editable, setEditable] = useState<Relationship>({} as Relationship)
  const [relationships, setRelationships] = useState<Relationship[]>([])
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    MenuComponent.reinitialization()
    setLoading(true)
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
    const fetchData = async () => {
      const res = await axios
        .get(`${RELATIONSHIPS_URL}?${updatedQuery}`) /**/
        .then((d: AxiosResponse<RelationshipQueryResponse>) => d.data)
      setRelationships(res.data || [])
    }
    fetchData()
    setLoading(false)
  }, [updatedQuery])

  const columns = useMemo(() => relationshipColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data: relationships,
  })

  const openModalFunc = async (id: ID) => {
    await setEditable(relationships.find((element) => (element.id ?? 0) === id) ?? relationships[0])
  }
  const openModalDel = async (id: ID) => {
    await setDeletable(
      relationships.find((element) => (element.id ?? 0) === id)?.id ?? relationships[0].id
    )
  }

  return (
    <>
      <div className='card-header border-0 pt-6'>
        <RelationshipsListSearchComponent />
        {/* begin::Card toolbar */}
        <div className='card-toolbar'>
          {havePerm('relationships:create', auth) && (
            <AddRelationshipModal
              updateTable={(pathology: Relationship) => {
                setRelationships([...relationships, pathology])
              }}
            />
          )}
        </div>
        {/* end::Card toolbar */}
      </div>
      <KTCardBody className='py-4'>
        <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            {...getTableProps()}
          >
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gies-0'>
                {headers.map(
                  (column: ColumnInstance<Relationship>) =>
                    (havePerms(['relationships:update', 'relationships:delete'], auth) ||
                      column.id != 'actions') && (
                      <CustomHeaderColumn key={column.id} column={column} />
                    )
                )}
              </tr>
            </thead>
            <tbody className='text-gray-600' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<Relationship>, i) => {
                  prepareRow(row)
                  return (
                    <CustomRow
                      row={row}
                      key={`row-${i}-${row.id}`}
                      openModal={(id: ID) => openModalFunc(id)}
                      openModalDelete={(id: ID) => openModalDel(id)}
                      intl={intl}
                    />
                  )
                })
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      {intl.formatMessage({id: 'Não foram encontrados resultados'})}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <RelationshipsListPagination />
        {isLoading && <ListLoading />}

        <DeleteItemModal
          toastTitle={'Grau de Parentesco Eliminado'}
          url={RELATIONSHIPS_URL}
          id={deletable}
          deleteRecord={(id: ID) => {
            setRelationships((current) =>
              current.filter((obj) => {
                return obj.id !== id
              })
            )
          }}
        />
        <EditRelationshipModal
          relationship={editable}
          updateList={(relationship: Relationship) => {
            setRelationships((current) =>
              current.map((obj) => {
                if (obj.id === relationship.id) {
                  return relationship
                }
                return obj
              })
            )
          }}
        />
      </KTCardBody>
    </>
  )
}

export {RelationshipsTable}
