import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import axios from 'axios'
import {USERS_URL} from '../../../_const'
import {toast} from 'react-toastify'
import {customStyles, toastStyles} from '../../../_styles'
import Select from 'react-select'
import {useThemeMode} from '../../../../_metronic/partials'
import {getPossibleCollabs} from '../../../../requests/_users'
import {User} from '../../../../models/_user'

type Props = {
  patient_id: string
}

export function AssociateCollaborator(props: Props) {
  const intl = useIntl()
  const {menuMode} = useThemeMode()
  const [show, setShow] = useState(false)
  const [initialValues, setInitialValues] = useState({patient_id: '0', user_id: '0'})
  const [collabs, setCollabs] = useState<User[]>([])

  const handleClose = () => {
    formik.resetForm()
    setShow(false)
  }
  const handleShow = () => {
    setShow(true)
  }

  useEffect(() => {
    setInitialValues({...initialValues, patient_id: props.patient_id})
    const fetch = async () => {
      const response = getPossibleCollabs(props.patient_id.toString())
      setCollabs(await response)
    }

    if (show) fetch()
  }, [show])

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, {setSubmitting}) => {
      await axios
        .post(`${USERS_URL}/associate-collaborator`, {
          user_id: values.user_id,
          patient_id: initialValues.patient_id,
        })
        .then(() => {
          toast.success(intl.formatMessage({id: 'Observação Criada'}), toastStyles)
          handleClose()
        })
        .catch(() => {
          toast.error(intl.formatMessage({id: 'Erro ao criar'}), toastStyles)
        })
    },
  })

  return (
    <>
      <button
        className='btn btn-light btn-active-light-primary btn-sm me-1 p-3 ps-4 pe-4'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        onClick={handleShow}
      >
        <i className='fa-sharp fa-solid fa-handshake text-primary p-0'></i>
      </button>

      <Modal size='lg' show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <h3 className='card-title text-primary'>
            {intl.formatMessage({id: 'Associar Colaborador'})}
          </h3>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <Select
              options={collabs}
              placeholder={intl.formatMessage({id: 'Selecionar...'})}
              getOptionLabel={(option: User) => {
                return option.full_name
              }}
              getOptionValue={(option: User) => option.id?.toString() || ''}
              isMulti={false}
              backspaceRemovesValue={true}
              onChange={(newValue) => {
                formik.setFieldValue('user_id', newValue?.id)
              }}
              styles={customStyles(menuMode)}
            />
          </Modal.Body>
          <Modal.Footer>
            <button
              type='button'
              className='btn btn-light'
              onClick={handleClose}
              data-bs-dismiss='modal'
            >
              {intl.formatMessage({id: 'Close'})}
            </button>
            <button
              type='submit'
              className='btn btn-primary'
              disabled={formik.values.user_id == '0'}
            >
              {intl.formatMessage({id: 'Associar'})}
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}
