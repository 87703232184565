import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { useFormik } from "formik";
import { useIntl } from "react-intl";
import { toast } from "react-toastify";
import CustomInput from "../../../../_components/CustomInput";
import { BRANDS_URL } from "../../../../../_const";
import { toastStyles } from "../../../../../_styles";
import { Brand } from "../../../../../../models/_brand";
import { brandSchema } from "../../../../../../schemas/_brands";
import WarningBlock from "../../../../_components/inputs/WarningBlock";
import FormikTextArea from "../../../../_components/FormikTextArea";
import Modal from "react-bootstrap/Modal";
import CustomModalHeader from "../../../../_components/modals/CustomModalHeader";

interface Props {
  updateList: (brand: Brand) => void
  brand: Brand
}

export function EditBrandModal(props: Props) {
  const intl = useIntl()
  const [initialValues, setInitialValues] = useState<Brand>(props.brand)
  const closeBtn = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    const fetchData = () => {
      setInitialValues(props.brand)
    }
    fetchData()
  }, [props])

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: brandSchema,
    onSubmit: async (values, { setSubmitting }) => {
      await axios
        .patch(`${BRANDS_URL}/${props.brand.id}`, values)
        .then((res) => {
          props.updateList(res.data.data)
          toast.success(intl.formatMessage({ id: 'Marca Editada' }), toastStyles)
          setSubmitting(false)
          closeBtn?.current?.click()
        })
        .catch((err: any) => {
          Object.keys(err.response.data.errors).forEach((key) => {
            formik.setFieldError(key, err.response.data.errors[key][0] ?? 'undefined')
          })
          setSubmitting(false)
        })
    },
  })

  return (
    <>
      <div className='modal fade' tabIndex={-1} id='kt_modal_edit_brand'>
        <div className='modal-dialog modal-lg'>
          <div className='modal-content '>
            <CustomModalHeader title={'Editar Marca'} />

            <form onSubmit={formik.handleSubmit}>
              <Modal.Body>
                <WarningBlock
                  show={formik.isValid}
                  warning={'Campos obrigatórios (*), preencha os campos corretamente!'}
                />
                <div className='row'>
                  <CustomInput
                    required={true}
                    formikFields={formik.getFieldProps('name')}
                    label={'Marca'}
                    type={'text'}
                    touched={formik.touched.name}
                    errors={formik.errors.name}
                    name={'name'}
                  />
                  <FormikTextArea
                    formikFields={formik.getFieldProps('observation')}
                    label={'Observação'}
                    touched={formik.touched.observation}
                    errors={formik.errors.observation}
                    name={'observation'}
                  />
                </div>
              </Modal.Body>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-light'
                  data-bs-dismiss='modal'
                  ref={closeBtn}
                >
                  {intl.formatMessage({ id: 'Close' })}
                </button>
                <button
                  type='submit'
                  className='btn btn-primary'
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  {intl.formatMessage({ id: 'Confirmar' })}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}