/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {useOutletContext, useParams} from 'react-router-dom'
import axios, {AxiosResponse} from 'axios'
import {ClinicSchema, CountryOption, Response} from '../_model'
import Select from 'react-select'
import {useFormik} from 'formik'
import clsx from 'clsx'
import {toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {useThemeMode} from '../../../../_metronic/partials'
import {CLINICS_URL, COUNTRY_URL, USERS_URL} from '../../../_const'
import {customStyles, toastStyles} from '../../../_styles'
import {User} from '../../../../models/_user'
import {Clinic} from '../../../../models/_clinic'
import CustomInput from '../../_components/CustomInput'
import {generalize, havePerm} from '../../../_helpers'
import {useAuth} from '../../../modules/auth'
import WarningBlock from '../../_components/inputs/WarningBlock'
import FormikErrors from '../../_components/FormikErrors'
import FormikTextArea from '../../_components/FormikTextArea'

export function ClinicGeneral() {
  const intl = useIntl()
  const {id} = useParams()
  const {auth} = useAuth()
  const [clinicInfo, setClinic]: [Clinic, any] = useOutletContext()
  const [initialValues, setinitialValues] = useState<Clinic>({} as Clinic)
  const [users, setUsers] = useState<User[]>([])
  const [countries, setCountries] = useState<CountryOption[]>([])
  const {menuMode} = useThemeMode()
  const perm = havePerm('clinics:update', auth)

  type ResponseCountries = {
    data: CountryOption[]
  }

  useEffect(() => {
    const fetchData = async () => {
      const rclinics: AxiosResponse<Response> = await axios.get(`${CLINICS_URL}/${id}`)
      setinitialValues(rclinics.data.data)
      const response: AxiosResponse<{data: User[]}> = await axios.get(
        `${USERS_URL}/my-collaborators-allow`
      )
      setUsers(response.data.data)
      const countries: AxiosResponse<ResponseCountries> = await axios.get(`${COUNTRY_URL}`)
      setCountries(countries.data.data)
    }

    fetchData()
  }, [])

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: ClinicSchema,
    onSubmit: async (values, {setSubmitting}) => {
      await axios
        .put(CLINICS_URL + '/' + initialValues.id, {
          ...values,
        })
        .then((res: {data: {data: Clinic}}) => {
          setinitialValues(values)
          setClinic(res.data.data)
          toast.success(intl.formatMessage({id: 'Clinic updated'}), toastStyles)
          setSubmitting(false)
        })
        .catch((err) => {
          Object.keys(err.response.data.errors).forEach((key) => {
            formik.setFieldError(key, err.response.data.errors[key][0] ?? 'undefined')
          })
          formik.setTouched(generalize(initialValues, true), false)
          setSubmitting(false)
        })
    },
  })

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className='card card-custom'>
          <div className='card-header'>
            <h3 className='card-title text-primary'>{intl.formatMessage({id: 'Dados Clínica'})}</h3>
            <div className='card-toolbar'>
              {perm && (
                <button
                  type='submit'
                  className='btn btn-primary btn-sm'
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  {intl.formatMessage({id: 'Guardar'})}
                </button>
              )}
            </div>
          </div>
          <div className='card-body'>
            <div className='row'>
              <WarningBlock
                show={formik.isValid}
                warning={'Campos obrigatórios (*), preencha os campos corretamente!'}
              />
              <div className='col-md-6 col-12'>
                <CustomInput
                  required={true}
                  formikFields={formik.getFieldProps('name')}
                  label={'Nome'}
                  type={'text'}
                  touched={formik.touched.name}
                  errors={formik.errors.name}
                />
              </div>
              <div className='col-md-6 col-12'>
                <CustomInput
                  required={true}
                  formikFields={formik.getFieldProps('email')}
                  label={'Email'}
                  type={'text'}
                  touched={formik.touched.email}
                  errors={formik.errors.email}
                />
              </div>
              <div className='col-md-6 col-12'>
                <CustomInput
                  required={true}
                  formikFields={formik.getFieldProps('mobilephone')}
                  label={'Telemóvel'}
                  type={'text'}
                  touched={formik.touched.mobilephone}
                  errors={formik.errors.mobilephone}
                />
              </div>
              <div className='col-md-6 col-12'>
                <CustomInput
                  required={true}
                  formikFields={formik.getFieldProps('phone')}
                  label={'Telefone'}
                  type={'text'}
                  touched={formik.touched.phone}
                  errors={formik.errors.phone}
                />
              </div>
              <div className='col-md-6 col-12'>
                <CustomInput
                  required={true}
                  formikFields={formik.getFieldProps('tax_number')}
                  label={'NIF'}
                  type={'text'}
                  touched={formik.touched.tax_number}
                  errors={formik.errors.tax_number}
                />
              </div>
              <div className='col-md-6 col-12'>
                <div className='w-100'>
                  <label className=' form-label required'>{intl.formatMessage({id: 'Pais'})}</label>
                  <Select
                    options={countries}
                    getOptionLabel={(country: CountryOption) => country.name}
                    getOptionValue={(country: CountryOption) => country.id}
                    {...formik.getFieldProps('country_id')}
                    value={countries.find((value) => {
                      return `${value.id}` == `${formik.values.country_id?.toString()}`
                    })}
                    onBlur={(event) => {
                      formik.setFieldTouched('country_id', true)
                      formik.setErrors({...formik.errors, country_id: undefined})
                    }}
                    onChange={(value) => {
                      formik.setFieldValue('country_id', `${value?.id}`)
                    }}
                    styles={customStyles(
                      menuMode,
                      formik.errors.country_id,
                      formik.touched.country_id
                    )}
                  />
                  <FormikErrors
                    errors={formik.errors.country_id}
                    touched={formik.touched.country_id}
                  />
                </div>
              </div>
              <div className='col-md-6 col-12'>
                <CustomInput
                  required={false}
                  formikFields={formik.getFieldProps('city')}
                  label={'Distrito'}
                  type={'text'}
                  touched={formik.touched.city}
                  errors={formik.errors.city}
                />
              </div>
              <div className='col-md-6 col-12'>
                <CustomInput
                  required={false}
                  formikFields={formik.getFieldProps('address')}
                  label={'Morada'}
                  type={'text'}
                  touched={formik.touched.address}
                  errors={formik.errors.address}
                />
              </div>
              <div className='col-md-6 col-12'>
                <CustomInput
                  required={false}
                  formikFields={formik.getFieldProps('postcode')}
                  label={'Código Postal'}
                  type={'text'}
                  touched={formik.touched.postcode}
                  errors={formik.errors.postcode}
                />
              </div>
              <div className='col-md-6 col-12'>
                <div className='mb-5 w-100'>
                  <label className=' form-label required'>
                    {intl.formatMessage({id: 'Responsável'})}
                  </label>
                  <Select
                    options={users}
                    getOptionLabel={(user: User) => user.full_name}
                    getOptionValue={(user: User) => `${user.id}`}
                    {...formik.getFieldProps('responsible_id')}
                    value={users.find((value) => {
                      return `${value.id}` == formik.values.responsible_id
                    })}
                    onBlur={(event) => {
                      formik.setFieldTouched('responsible_id', true)
                      formik.setErrors({...formik.errors, responsible_id: undefined})
                    }}
                    onChange={(value) => {
                      formik.setFieldValue('responsible_id', `${value?.id}`)
                    }}
                    styles={customStyles(
                      menuMode,
                      formik.errors.responsible_id,
                      formik.touched.responsible_id
                    )}
                  />
                  <FormikErrors
                    errors={formik.errors.responsible_id}
                    touched={formik.touched.responsible_id}
                  />
                </div>
              </div>
              <div className='col-md-6 col-12'>
                <CustomInput
                  formikFields={formik.getFieldProps('website')}
                  label={'Website'}
                  type={'text'}
                  touched={formik.touched.website}
                  errors={formik.errors.website}
                />
              </div>
              <div className='col-md-6 col-12'>
                <div className='w-100 mb-5'>
                  <div className='row fv-row'>
                    <div className='col-6'>
                      <label className='form-label'>{intl.formatMessage({id: 'Longitude'})}</label>
                    </div>
                    <div className='col-6'>
                      <label className='form-label'>{intl.formatMessage({id: 'Latitude'})}</label>
                    </div>
                  </div>
                  <div className='input-group'>
                    <input
                      type='number'
                      className={clsx('form-control ', {
                        'is-invalid': formik.errors.longitude && formik.touched.longitude,
                      })}
                      placeholder={intl.formatMessage({id: 'Longitude'})}
                      id='longitude'
                      {...formik.getFieldProps('longitude')}
                    />

                    <input
                      {...formik.getFieldProps('latitude')}
                      type='number'
                      className={clsx('form-control ', {
                        'is-invalid': formik.errors.latitude && formik.touched.latitude,
                      })}
                      placeholder={intl.formatMessage({id: 'Latitude'})}
                      id='latitude'
                      name='latitude'
                    />
                  </div>
                  <div className='row'>
                    <div className='col-6'>
                      <FormikErrors
                        errors={formik.errors.longitude}
                        touched={formik.touched.longitude}
                      />
                    </div>
                    <div className='col-6'>
                      <FormikErrors
                        errors={formik.errors.latitude}
                        touched={formik.touched.latitude}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <FormikTextArea
              formikFields={formik.getFieldProps('observations')}
              label={'observations'}
              touched={formik.touched.observations}
              errors={formik.errors.observations}
              name={'observations'}
            />
          </div>
        </div>
      </form>
    </>
  )
}
