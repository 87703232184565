import {useEffect, useMemo, useState} from 'react'
import {ColumnInstance, Row, useTable} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {ListLoading} from '../../../../_components/dataTable/loading/ListLoading'
import {ID, KTCardBody, stringifyRequestQuery} from '../../../../../../_metronic/helpers'
import {attachmentsColumns} from './columns/_columns'
import {AttachmentsListPagination} from '../components/header/AttachmentsListPagination'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {useParams} from 'react-router-dom'
import axios, {AxiosResponse} from 'axios'
import {ATTACHMENT_URL} from '../../../../../_const'
import {useIntl} from 'react-intl'
import {AttachmentsListSearchComponent} from '../components/header/AttachmentsListSearchComponent'
import {DeleteItemModal} from '../../../../../modals/DeleteItemModal'
import {Attachment, AttachmentQueryResponse} from '../../../../../../models/_attachment'
import {CreateAttachmentModal} from '../../../../../modals/CreateAttachmentModal'
import {useAuth} from '../../../../../modules/auth'
import {havePerm, havePerms} from '../../../../../_helpers'

const AttachmentsTable = () => {
  const {id} = useParams()
  const intl = useIntl()
  const {state} = useQueryRequest()
  const {auth} = useAuth()
  const [deletable, setDeletable] = useState<ID>(-1)
  const [editable, setEditable] = useState<Attachment>({} as Attachment)
  const [attachments, setAttachments] = useState<Attachment[]>([])
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
    const fetchData = async () => {
      const res = await axios
        .get(`${ATTACHMENT_URL}?user_id=${id}&${updatedQuery}`) /**/
        .then((d: AxiosResponse<AttachmentQueryResponse>) => d.data)
      setAttachments(res.data || [])
    }
    fetchData()
    setLoading(false)
  }, [updatedQuery])

  const columns = useMemo(() => attachmentsColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data: attachments,
  })

  const openModalFunc = (id: ID) => {
    setEditable(attachments.find((element) => (element.id ?? 0) === id) ?? attachments[0])
  }
  const openModalDel = async (id: ID) => {
    await setDeletable(
      attachments.find((element) => (element.id ?? 0) === id)?.id ?? attachments[0].id
    )
  }

  return (
    <>
      <div className='card-header border-0 pt-6'>
        <AttachmentsListSearchComponent />
        <div className='card-toolbar'>
          {havePerm('attachments:create', auth) && (
            <CreateAttachmentModal
              updateAttachments={(attachment) => {
                setAttachments([...attachments, attachment])
              }}
              context={'user_id'}
            />
          )}
        </div>
      </div>
      <KTCardBody className='py-4'>
        {!isLoading ? (
          <>
            <div className='table-responsive'>
              <table
                id='kt_table_users'
                className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                {...getTableProps()}
              >
                <thead>
                  <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                    {headers.map(
                      (column: ColumnInstance<Attachment>) =>
                        (havePerms(['attachments:update', 'attachments:delete'], auth) ||
                          column.id != 'actions') && (
                          <CustomHeaderColumn key={column.id} column={column} />
                        )
                    )}
                  </tr>
                </thead>
                <tbody className='text-gray-600' {...getTableBodyProps()}>
                  {rows.length > 0 ? (
                    rows.map((row: Row<Attachment>, i) => {
                      prepareRow(row)
                      return (
                        <CustomRow
                          row={row}
                          key={`row-${i}-${row.id}`}
                          openModal={(id: ID) => openModalFunc(id)}
                          openModalDelete={(id: ID) => openModalDel(id)}
                          intl={intl}
                        />
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={6}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                          {intl.formatMessage({id: 'Não foram encontrados resultados'})}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <AttachmentsListPagination />
          </>
        ) : (
          <ListLoading />
        )}
        <DeleteItemModal
          toastTitle={'Ficheiro Eliminado'}
          url={ATTACHMENT_URL}
          id={deletable}
          deleteRecord={(id: ID) => {
            setAttachments((current) =>
              current.filter((obj) => {
                return obj.id !== id
              })
            )
          }}
        />
      </KTCardBody>
    </>
  )
}

export {AttachmentsTable}
