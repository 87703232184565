import * as Yup from "yup";

export const SmtpSchema = Yup.object().shape({
    hostname: Yup.string()
        .required('Field Required'),
    port: Yup.string()
        .required('Field Required'),
    mail_username: Yup.string()
        .required('Field Required'),
    ssl: Yup.string()
        .required('Field Required'),
    mail_password: Yup.string()
        .required('Field Required'),
    general_email: Yup.string()
        .required('Field Required').email('Formato inválido'),
    general_name: Yup.string()
        .required('Field Required'),
})

export const emailTemplateSchema = Yup.object().shape({
    content: Yup.string()
        .required('Field Required'),

})