import {useEffect, useMemo, useState} from 'react'
import {ColumnInstance, Row, useTable} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {ListLoading} from '../../../../_components/dataTable/loading/ListLoading'
import {KTCardBody, stringifyRequestQuery} from '../../../../../../_metronic/helpers'
import {ClinicsPatientsListPagination} from '../components/header/ClinicsPatientsListPagination'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {useParams} from 'react-router-dom'
import axios, {AxiosResponse} from 'axios'
import {ClINICS_PATIENT_URL} from '../../../../../_const'
import {useIntl} from 'react-intl'
import {ClinicsPatientsListSearchComponent} from '../components/header/ClinicsPatientsListSearchComponent'
import {Patient, PatientQueryResponse} from '../../../../../../models/_patient'
import {clinicPatientsColumns} from './columns/_columns'
import {AssociatePatientModal} from '../../../../../modals/AssociatePatientModal'
import {useAuth} from '../../../../../modules/auth'
import {havePerm} from '../../../../../_helpers'

const ClinicsPatientsTable = () => {
  const intl = useIntl()
  const {id} = useParams()
  const {auth} = useAuth()
  const {state} = useQueryRequest()
  const [patients, setPatients] = useState<Patient[]>([])
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
    const fetchData = async () => {
      const res = await axios
        .get(`${ClINICS_PATIENT_URL}/${id}?${updatedQuery}`) /**/
        .then((d: AxiosResponse<PatientQueryResponse>) => d.data)
      setPatients(res.data || [])
    }
    fetchData()
    setLoading(false)
  }, [updatedQuery])

  const columns = useMemo(() => clinicPatientsColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data: patients,
  })

  return (
    <>
      <div className='card-header border-0 pt-6'>
        <ClinicsPatientsListSearchComponent />
        <div className='card-toolbar'>
          {havePerm('patients:create', auth) && havePerm('clinic_' + id, auth) && (
            <AssociatePatientModal
              updatePatient={(patient) => {
                setPatients([...patients, patient])
              }}
            />
          )}
        </div>
      </div>
      <KTCardBody className='py-4'>
        {!isLoading ? (
          <>
            <div className='table-responsive'>
              <table
                id='kt_table_users'
                className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                {...getTableProps()}
              >
                <thead>
                  <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                    {headers.map((column: ColumnInstance<Patient>) => (
                      <CustomHeaderColumn key={column.id} column={column} />
                    ))}
                  </tr>
                </thead>
                <tbody className='text-gray-600' {...getTableBodyProps()}>
                  {rows.length > 0 ? (
                    rows.map((row: Row<Patient>, i) => {
                      prepareRow(row)
                      return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                    })
                  ) : (
                    <tr>
                      <td colSpan={7}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                          {intl.formatMessage({id: 'Não foram encontrados resultados'})}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <ClinicsPatientsListPagination />
          </>
        ) : (
          <ListLoading />
        )}
      </KTCardBody>
    </>
  )
}

export {ClinicsPatientsTable}
