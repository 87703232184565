/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from "react";
import { Attachment } from "../../../../../../../models/_attachment";


type Props = {
    attachment: Attachment

}

const PrivacBadge: FC<Props> = ({attachment}) => {
    return (
        <>
            {attachment.privacy ?
                <span className="badge badge-light-dark w-50 d-flex justify-content-center"><i
                    className="fa-solid fa-lock text-danger "></i></span>
                :
                <span className="badge badge-light-dark w-50 d-flex justify-content-center"><i
                    className="fa-solid fa-unlock text-success "></i></span>}
        </>
    )
}

export {PrivacBadge}
