// @ts-nocheck
import { Column } from "react-table";
import { MedicationsCustomHeader } from "./MedicationsCustomHeader";
import { Medication } from "../../../../../../models/_schedule";

const medicationsColumns: ReadonlyArray<Column<Medication>> = [
    {
        Header: (props) => <MedicationsCustomHeader tableProps={props} title='Nome' className='min-w-125px'/>,
        accessor: 'name',
    },
    {
        Header: (props) => <MedicationsCustomHeader tableProps={props} title='Descrição' className='min-w-125px'/>,
        accessor: 'description',
    },
    {
        Header: (props) => <MedicationsCustomHeader tableProps={props} title='Dosagem Padrão' className='min-w-125px'/>,
        accessor: 'dosage',
    },
    {
        Header: (props) => <MedicationsCustomHeader tableProps={props} title='Unidade Padrão' className='min-w-125px'/>,
        accessor: 'label_dosage_unit',
    },
    {
        Header: (props) => <MedicationsCustomHeader tableProps={props} title='Frequência Padrão'
                                                    className='min-w-125px'/>,
        accessor: 'label_frequency',
    },
    {
        Header: (props) => <MedicationsCustomHeader tableProps={props} title='Unidade de Frequência Padrão'
                                                    className='min-w-125px'/>,
        accessor: 'label_frequency_unit',
    },
    {
        Header: (props) => <MedicationsCustomHeader tableProps={props} title='Observação' className='min-w-125px'/>,
        accessor: 'observation',
    },
    {
        Header: (props) => <MedicationsCustomHeader tableProps={props} title='Tipo de Medicação'
                                                    className='min-w-125px'/>,
        accessor: 'type.name',
    },
    {
        Header: (props) => (
            <MedicationsCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px'/>
        ),
        id: 'actions',
    },
]

export {medicationsColumns}
