import React from 'react'
import {useIntl} from 'react-intl'
import {FieldInputProps} from 'formik'
import clsx from 'clsx'
import FormikErrors from './FormikErrors'

interface Props {
  formikFields: FieldInputProps<any>
  label: string
  touched: boolean | undefined
  errors: string | undefined
  name: string
  disable?: boolean
  required?: boolean
}

const FormikTextArea = ({errors, formikFields, touched, label, disable, required}: Props) => {
  const intl = useIntl()
  return (
    <>
      {/* begin::Form group */}
      <div className='fv-row mb-3'>
        <label
          className={clsx('form-label', {
            required: required,
          })}
        >
          {intl.formatMessage({id: label})}
        </label>
        <textarea
          placeholder={intl.formatMessage({id: label})}
          autoComplete='off'
          {...formikFields}
          className={clsx('form-control ', {
            'is-invalid': touched && errors,
          })}
          disabled={disable != undefined ? !disable : false}
          style={{height: '15vh'}}
          data-kt-autosize='true'
        ></textarea>
        <FormikErrors errors={errors} touched={touched} />
      </div>
      {/* end::Form group */}
    </>
  )
}

export default FormikTextArea
