import React, {useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import axios, {AxiosResponse} from 'axios'
import {toast} from 'react-toastify'
import {toastStyles} from '../../../../../_styles'
import CustomInput from '../../../../_components/CustomInput'
import {AppointmentCategory} from '../../../../../../models/_appointmentCategory'
import {appointmentCategorySchema} from '../../../../../../schemas/_appointmentCategories'
import {APPOINTMENT_CATEGORIES_URL} from '../../../../../_const'
import CustomModalFooter from '../../../../_components/modals/CustomModalFooter'

interface Props {
  updateTable: (appointmentCategorie: AppointmentCategory) => void
}

export function AddAppointmentCategoryModal({updateTable}: Props) {
  const intl = useIntl()
  const [show, setShow] = useState(false)

  const handleClose = () => {
    formik.resetForm()
    setShow(false)
  }
  const handleShow = () => setShow(true)

  const formik = useFormik({
    initialValues: {name: ''},
    validationSchema: appointmentCategorySchema,
    onSubmit: async (values, {setSubmitting}) => {
      await axios
        .post(`${APPOINTMENT_CATEGORIES_URL}`, values)
        .then((res: AxiosResponse<AppointmentCategory>) => {
          updateTable(res.data)
          toast.success(intl.formatMessage({id: 'Tipo de Marcação Criada'}), toastStyles)
          handleClose()
        })
        .catch((err: any) => {
          Object.keys(err.response.data.errors).forEach((key) => {
            formik.setFieldError(key, err.response.data.errors[key][0] ?? 'undefined')
          })
          setSubmitting(false)
        })
    },
  })

  return (
    <>
      <button type='button' className='btn btn-primary' onClick={handleShow}>
        {intl.formatMessage({id: 'Adicionar Tipo de Marcação'})}
      </button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <h3 className='card-title text-primary'>
            {intl.formatMessage({id: 'Adicionar Tipo de Marcação'})}
          </h3>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className='row'>
              <CustomInput
                required={true}
                formikFields={formik.getFieldProps('name')}
                label={'Nome do Tipo de Marcação'}
                type={'text'}
                touched={formik.touched.name}
                errors={formik.errors.name}
                name={'name'}
              />
            </div>
          </Modal.Body>
          <CustomModalFooter
            isValid={formik.isValid}
            isSubmitting={formik.isSubmitting}
            handleClose={handleClose}
            closeLabel={'Close'}
            submitLabel={'Confirmar'}
          />
        </form>
      </Modal>
    </>
  )
}
