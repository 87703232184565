// @ts-nocheck
import { Column } from "react-table";
import { PrioritiesCustomHeader } from "./PrioritiesCustomHeader";
import { Priority } from "../../../../../../models/_priority";

const prioritiesColumns: ReadonlyArray<Column<Priority>> = [
    {
        Header: (props) => <PrioritiesCustomHeader tableProps={props} title='Prioridade' className='min-w-125px'/>,
        accessor: 'name',
    },

    {
        Header: (props) => (
            <PrioritiesCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px'/>
        ),
        id: 'actions',
    },
]

export {prioritiesColumns}
