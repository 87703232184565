import axios, {AxiosResponse} from 'axios'
import {API_URL} from '../app/_const'
import {EcgsQueryResponse} from '../models/_ecg'
import {DailyChecksQueryResponse} from '../models/_dailyCheck'

const getUserEcg = (query: string, id: string, interval: Date[]): Promise<EcgsQueryResponse> => {
  const date_start: string =
    interval[0].getFullYear() +
    '-' +
    (interval[0].getMonth() + 1 > 10
      ? interval[0].getMonth() + 1
      : '0' + (interval[0].getMonth() + 1)) +
    '-' +
    interval[0].getDate()
  const date_end: string =
    interval[1].getFullYear() +
    '-' +
    (interval[1].getMonth() + 1 > 10
      ? interval[1].getMonth() + 1
      : '0' + (interval[1].getMonth() + 1)) +
    '-' +
    interval[1].getDate()
  return axios
    .get(
      `${API_URL}/ecgs?user_id=${id}&date_start=${date_start} 00:00:00&date_end=${date_end} 23:59:59&${query}`
    ) /**/
    .then((d: AxiosResponse<EcgsQueryResponse>) => d.data)
}

const getUserDailyCheck = (
  query: string,
  id: string,
  interval: Date[]
): Promise<DailyChecksQueryResponse> => {
  const date_start: string =
    interval[0].getFullYear() +
    '-' +
    (interval[0].getMonth() + 1 > 10
      ? interval[0].getMonth() + 1
      : '0' + (interval[0].getMonth() + 1)) +
    '-' +
    interval[0].getDate()
  const date_end: string =
    interval[1].getFullYear() +
    '-' +
    (interval[1].getMonth() + 1 > 10
      ? interval[1].getMonth() + 1
      : '0' + (interval[1].getMonth() + 1)) +
    '-' +
    interval[1].getDate()
  return axios
    .get(
      `${API_URL}/daily-checks?user_id=${id}&date_start=${date_start} 00:00:00&date_end=${date_end} 23:59:59&${query}`
    ) /**/
    .then((d: AxiosResponse<DailyChecksQueryResponse>) => d.data)
}

export {getUserEcg, getUserDailyCheck}
