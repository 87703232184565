// @ts-nocheck
import { Column } from "react-table";
import { PathologiesCustomHeader } from "./PathologiesCustomHeader";
import { Pathology } from "../../../../../../models/_pathology";

const pathologiesColumns: ReadonlyArray<Column<Pathology>> = [
    {
        Header: (props) => <PathologiesCustomHeader tableProps={props} title='Patologia' className='min-w-125px'/>,
        accessor: 'name',
    },
    {
        Header: (props) => <PathologiesCustomHeader tableProps={props} title='Código' className='min-w-125px'/>,
        accessor: 'code',
    },
    {
        Header: (props) => (
            <PathologiesCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px'/>
        ),
        id: 'actions',
    },
]

export {pathologiesColumns}
