import React, {useEffect, useState} from 'react'
import FullCalendar, {EventClickArg, EventSourceInput} from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin, {DateClickArg} from '@fullcalendar/interaction'
import {useIntl} from 'react-intl'
import axios, {AxiosResponse} from 'axios'
import {API_IMG_URL, APPOINTMENT_URL} from '../../../_const'
import {Appointment} from '../../../../models/_appointment'
import clsx from 'clsx'
import {havePerm, hourToString} from '../../../_helpers'
import {ScheduleAppointmentModal} from '../modals/ScheduleAppointmentModal'
import {AppointmentModal} from '../modals/AppointmentModal'
import {useAuth} from '../../../modules/auth'
import {useParams} from 'react-router-dom'

export function CollaboratorSchedule() {
  const {auth} = useAuth()
  const {id} = useParams()
  const intl = useIntl()
  const [events, setEvents] = useState<EventSourceInput>([])
  const [show, setShow] = useState(false)
  const [showData, setShowData] = useState(false)
  const [date, setDate] = useState({} as DateClickArg)
  const [event, setEvent] = useState({} as EventClickArg)

  const handleShow = () => setShow(true)
  const handleShowData = () => setShowData(true)

  useEffect(() => {
    const fetch = async () => {
      const response: AxiosResponse<{data: Appointment[]}> = await axios.get(
        `${APPOINTMENT_URL}/` + id
      )
      const temp_events: EventSourceInput = []
      response.data.data.map((appointment) => {
        temp_events.push({
          title: appointment.appointment_category.name,
          start: appointment.date_begin,
          end: appointment.date_end,
          extendedProps: appointment,
        })
      })

      setEvents(temp_events)
    }
    fetch()
  }, [])

  function renderEventContent(eventInfo: any) {
    if (eventInfo.view.type == 'dayGridMonth')
      return (
        <>
          <li className=' ms-1 d-flex align-items-center'>
            <b className='bullet bullet-dot bg-primary me-1'></b>
            {eventInfo.event._def.extendedProps.appointment_category.name}
          </li>
        </>
      )

    return (
      <div className='border rounded h-100 w-100 border-primary'>
        <div className='border rounded  bg-primary bg-opacity-25 overflow-hidden d-flex justify-content-between w-100'>
          <b className={'m-1'}>{eventInfo.event._def.extendedProps.appointment_category.name}</b>
          <b className={'m-1'}>{hourToString(eventInfo.event.start)}</b>
        </div>
        <div className=' overflow-hidden m-2 '>
          <div className='d-flex justify-content-between'>
            <div className={clsx('symbol', 'symbol-25px symbol-md-20px')}>
              <img src={API_IMG_URL + '/' + eventInfo.event._def.extendedProps.user.photo} alt='' />
            </div>
            <span>{eventInfo.event._def.extendedProps.user.full_name} </span>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className='card card-custom'>
      <div className='card-body'>
        <FullCalendar
          aspectRatio={2}
          locale={intl.locale}
          initialView='dayGridMonth'
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: 'prev next today',
            center: 'title',
            right: 'dayGridMonth,timeGridWeek',
          }}
          dateClick={(e) => {
            if (
              havePerm('appointments:create', auth) &&
              havePerm('appointmentpriorities:list', auth) &&
              havePerm('appointmentcategories:list', auth)
            ) {
              setDate(e)
              handleShow()
              /*} else {
                toast.info('Não podes marcar merdas para ontem conas', toastStyles)
              }*/
            }
          }}
          eventClick={(e) => {
            if (havePerm('appointments:read', auth)) {
              setEvent(e)
              handleShowData()
            }
          }}
          events={events}
          eventContent={renderEventContent}
          eventBackgroundColor={'rgb(0,0,0,0)'}
          eventBorderColor={'rgb(0,0,0,0)'}
          eventTextColor={'#7E8299'}
        />
      </div>
      <ScheduleAppointmentModal
        date={date}
        open={show}
        updateOpen={() => {
          setShow(false)
        }}
        updateAppointment={(appointment) => {
          const temp_events = events as []
          setEvents([
            ...temp_events,
            {
              title: appointment.appointment_category.name,
              start: appointment.date_begin,
              end: appointment.date_end,
              extendedProps: appointment,
            },
          ])
        }}
      />
      <AppointmentModal
        event={event}
        open={showData}
        updateOpen={() => {
          setShowData(false)
        }}
        updateEvents={(nAppointment: Appointment) => {
          const fetch = async () => {
            const response: AxiosResponse<{data: Appointment[]}> = await axios.get(
              `${APPOINTMENT_URL}/` + id
            )
            const temp_events: EventSourceInput = []
            response.data.data.map((appointment) => {
              temp_events.push({
                title: appointment.appointment_category.name,
                start: appointment.date_begin,
                end: appointment.date_end,
                extendedProps: appointment,
              })
            })
            setEvents(temp_events)
          }
          fetch()
        }}
      />
    </div>
  )
}
