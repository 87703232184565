/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from "react";
import { MenuComponent } from "../../../../../../../_metronic/assets/ts/components";
import { ID } from "../../../../../../../_metronic/helpers";
import { useNavigate } from "react-router-dom";

type Props = {
    id: ID
}

const ActionsCell: FC<Props> = ({id}) => {
    const navigation = useNavigate()

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])

    const goto = (id: ID) => {
        navigation(`/collaborator/perfil/${id}`)
    }

    return (
        <>
            <button
                className='btn btn-light btn-active-light-primary btn-sm'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                onClick={(e) => {
                    goto(id)
                }}
            >
                <i className="fa-sharp fa-solid fa-eye text-primary p-0"></i>
            </button>

        </>
    )
}

export {ActionsCell}
