import React from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import PageTitle from '../pages/_components/PageTitle'
import {useAuth} from '../modules/auth'
import Dashboard from '../pages/Dashboard'
import {SpecialtiesListWrapper} from '../pages/support-table/specialties-table/SpecialtiesList'
import {PathologiesListWrapper} from '../pages/support-table/pathologies-list/PathologiesList'
import {MedicationTypesListWrapper} from '../pages/support-table/medication-types-list/MedicationTypesList'
import {PrioritiesListWrapper} from '../pages/support-table/priorities-list/PrioritiesList'
import {RelationshipsListWrapper} from '../pages/support-table/relationship-list/RelationshipsList'
import {AppointmentCategoriesListWrapper} from '../pages/support-table/appointment-category-list/AppointmentCategoriesList'
import {AttachmentCategoriesListWrapper} from '../pages/support-table/attachment-categories-list/AttachmentCategoriesList'
import {ClinicsContactsListWrapper} from '../pages/clinic/datatable/clinic-contacts-list/ClinicsContactsList'
import {ClinicsCollabListWrapper} from '../pages/clinic/datatable/clinic-collab-list/ClinicsCollabList'
import {ClinicsNotesListWrapper} from '../pages/clinic/datatable/clinic-notes-list/ClinicsNotesList'
import {ClinicsListWrapper} from '../pages/clinic/datatable/clinics-list/ClinicsList'
import {ClinicsPatientsListWrapper} from '../pages/clinic/datatable/clinic-patient-list/ClinicsPatientsList'
import {ClinicGeneral} from '../pages/clinic/pages/ClinicGeneral'
import ClinicLayout from '../pages/clinic/pages/ClinicLayout'
import PatientLayout from '../pages/patient/pages/PatientLayout'
import MedicalHealthRecordPage from '../pages/patient/pages/MedicalHealthRecordPage'
import {PatientListWrapper} from '../pages/patient/datatables/patients-list/PatientList'
import {PatientsNotesListWrapper} from '../pages/patient/datatables/patient-observtions-list/PatientsNotesList'
import {PatientPerfil} from '../pages/patient/pages/PatientPerfil'
import PatientRecordsLayout from '../pages/patient/pages/PatientRecordsLayout'
import {EcgListWrapper} from '../pages/patient/datatables/ecgs-list/EcgListWrapper'
import BloodGlucosePage from '../pages/patient/pages/BloodGlucosePage'
import BloodPressurePage from '../pages/patient/pages/BloodPressurePage'
import OximeterPage from '../pages/patient/pages/OximeterPage'
import TemperaturePage from '../pages/patient/pages/TemperaturePage'
import {DcsListWrapper} from '../pages/patient/datatables/daily-check-list/DcsList'
import CollaboratorLayout from '../pages/collaborator/pages/CollaboratorLayout'
import {CollaboratorPerfil} from '../pages/collaborator/pages/CollaboratorPerfil'
import {BrandsListWrapper} from '../pages/support-table/brand-list/BrandsList'
import {ModelsListWrapper} from '../pages/support-table/device-models-list/ModelsList'
import {PrescriptionsListWrapper} from '../pages/patient/datatables/medications-list/PrescriptionsList'
import {MedicationsListWrapper} from '../pages/support-table/medication-list/MedicationsList'
import {CollaboratorSchedule} from '../pages/collaborator/pages/ScheduleCollab'
import {AttachmentsListWrapper} from '../pages/patient/datatables/attachments-list/AttachmentsList'
import CollaboratorDashboard from '../pages/CollaboratorDashboard'
import PatientDashboard from '../pages/PatientDashboard'
import {ClinicAttachmentsListWrapper} from '../pages/clinic/datatable/attachments-list/AttachmentsList'
import {CollabAttachmentsListWrapper} from '../pages/collaborator/datatable/attachments-list/AttachmentsList'
import {ClinicsEmailTemplatesListWrapper} from '../pages/clinic/datatable/clinic-emails-list/ClinicsEmailTemplatesList'
import {havePerm, havePerms, haveRole} from '../_helpers'
import {DevicesListWrapper} from '../pages/clinic/datatable/clinic-devices-list/devices-list/DevicesList'
import {FunctionRolesListWrapper} from '../pages/support-table/function-roles-list/FunctionRolesList'
import {RolesListWrapper} from '../pages/support-table/roles-list/RolesList'
import PermissionsPage from '../pages/support-table/PermissionsPage'
import {CollaboratorSettings} from '../pages/collaborator/pages/CollaboratorSettings'
import {ProfessionsListWrapper} from '../pages/support-table/professions-list/ProfessionsList'
import {RelativesListWrapper} from '../pages/patient/datatables/relatives-list/RelativesList'
import {CollabListWrapper} from '../pages/collaborator/datatable/collab-list/CollabList'

const PrivateRoutes = () => {
  const {auth} = useAuth()

  let dash = <CollaboratorDashboard />

  if (haveRole('super-admin', auth) || haveRole('admin', auth)) dash = <Dashboard />
  if (haveRole('collaborator', auth)) dash = <CollaboratorDashboard />
  if (haveRole('patient', auth)) dash = <PatientDashboard />

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirecionar utilizador para alguma página depois do login*/}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />

        {/* Dashboard */}
        <Route path='dashboard' element={dash} />

        {/* Clinicas */}
        {havePerm('clinics:read', auth) && (
          <>
            <Route
              path='clinics'
              element={
                <>
                  <PageTitle title='Principal' division='Clinica' />
                  <ClinicsListWrapper />
                </>
              }
            />
            {/* Páginas de uma Clinica */}
            {
              <Route path='clinic' element={<ClinicLayout />}>
                <Route path={''} element={<Navigate to='/error/404' />} />
                {havePerm('clinics:read', auth) && (
                  <Route path='general/:id' element={<ClinicGeneral />} />
                )}
                {havePerm('contacts:read', auth) && (
                  <Route path='contacts/:id' element={<ClinicsContactsListWrapper />} />
                )}
                {havePerm('patients:read', auth) && (
                  <Route path='patients/:id' element={<ClinicsPatientsListWrapper />} />
                )}
                {havePerm('collaborators:read', auth) && (
                  <Route path='collaborators/:id' element={<ClinicsCollabListWrapper />} />
                )}
                {havePerm('attachments:read', auth) && (
                  <Route path='attachments/:id' element={<ClinicAttachmentsListWrapper />} />
                )}
                {havePerm('observations:read', auth) && (
                  <Route path='notes/:id' element={<ClinicsNotesListWrapper />} />
                )}
                {havePerms(['emailtemplates:read', 'smtps:read'], auth) && (
                  <Route path='mail-settings/:id' element={<ClinicsEmailTemplatesListWrapper />} />
                )}
                {havePerm('devices:read', auth) && (
                  <Route path='devices/:id' element={<DevicesListWrapper />} />
                )}
              </Route>
            }
          </>
        )}
        {/*Patients*/}
        {!haveRole('patient', auth) && (
          <Route
            path='/patients'
            element={
              <>
                <PageTitle title='Principal' division='Pacientes' />
                <PatientListWrapper />
              </>
            }
          />
        )}

        <Route path='patient' element={<PatientLayout />}>
          <Route path={''} element={<Navigate to='/error/404' />} />
          {havePerm('patients:read', auth) && <Route path='base/:id' element={<PatientPerfil />} />}
          {havePerm('medicalhealthrecords:read', auth) && (
            <Route path='health/:id' element={<MedicalHealthRecordPage />} />
          )}
          {havePerm('prescriptions:read', auth) && (
            <Route path='medications/:id' element={<PrescriptionsListWrapper />} />
          )}
          {havePerm('observations:read', auth) && (
            <Route path='observations/:id' element={<PatientsNotesListWrapper />} />
          )}
          {havePerm('attachments:read', auth) && (
            <Route path='attachments/:id' element={<AttachmentsListWrapper />} />
          )}
          {havePerm('aggregates:read', auth) && (
            <Route path='aggregate/:id' element={<RelativesListWrapper />} />
          )}

          {havePerms(
            [
              'ecgs:read',
              'bloodglucoses:read',
              'bloodpressures:read',
              'dailychecks:read',
              'oximeters:read',
              'thermometers:read',
            ],
            auth
          ) && (
            <Route path='records/:id' element={<PatientRecordsLayout />}>
              <Route path={''} element={<Navigate to='/error/404' />} />
              {havePerm('ecgs:read', auth) && <Route path='ecgs' element={<EcgListWrapper />} />}
              {havePerm('bloodpressures:read', auth) && (
                <Route path='blood-pressure' element={<BloodPressurePage />} />
              )}
              {havePerm('bloodglucoses:read', auth) && (
                <Route path='blood-glucose' element={<BloodGlucosePage />} />
              )}
              {havePerm('dailychecks:read', auth) && (
                <Route path='daily-check' element={<DcsListWrapper />} />
              )}
              {havePerm('oximeters:read', auth) && (
                <Route path='oximeters' element={<OximeterPage />} />
              )}
              {havePerm('thermometers:read', auth) && (
                <Route path='thermometers' element={<TemperaturePage />} />
              )}
            </Route>
          )}
        </Route>

        {!haveRole('patient', auth) && (
          <>
            {/*Colaboradores*/}
            <Route
              path='/collaborators'
              element={
                <>
                  <PageTitle title='Principal' division='Colaboradores' />
                  <CollabListWrapper />
                </>
              }
            />
            <Route path='collaborator' element={<CollaboratorLayout />}>
              <Route path={''} element={<Navigate to='/error/404' />} />
              {havePerm('collaborators:read', auth) && (
                <Route path='perfil/:id' element={<CollaboratorPerfil />} />
              )}
              {(haveRole('admin', auth) || haveRole('super-admin', auth)) && (
                <Route path='tecnical/:id' element={<CollaboratorSettings />} />
              )}
              {havePerm('appointments:read', auth) && (
                <Route path='schedules/:id' element={<CollaboratorSchedule />} />
              )}
              {havePerm('attachments:read', auth) && (
                <Route path='attachments/:id' element={<CollabAttachmentsListWrapper />} />
              )}
            </Route>
          </>
        )}

        {/*Devices*/}
        {havePerm('devices:read', auth) && (
          <Route
            path='/devices'
            element={
              <>
                <PageTitle title='Dispositivos' division='Clinica' />
                <ClinicsListWrapper />
              </>
            }
          />
        )}

        {/*Tabelas de Apoio*/}

        {havePerm('specialties:read', auth) && (
          <Route
            path='/specialties'
            element={
              <>
                <PageTitle title='Especialidades' division='Tabelas de Suporte' />
                <SpecialtiesListWrapper />
              </>
            }
          />
        )}
        {havePerm('pathologytypes:read', auth) && (
          <Route
            path='/pathologies'
            element={
              <>
                <PageTitle title='Patologias' division='Tabelas de Suporte' />
                <PathologiesListWrapper />
              </>
            }
          />
        )}
        {havePerm('medicationtypes:read', auth) && (
          <Route
            path='/medication-types'
            element={
              <>
                <PageTitle title='Tipo de Medicação' division='Tabelas de Suporte' />
                <MedicationTypesListWrapper />
              </>
            }
          />
        )}
        {havePerm('medications:read', auth) && !haveRole('patient', auth) && (
          <Route
            path='/medications'
            element={
              <>
                <PageTitle title='Medicamentos' division='Tabelas de Suporte' />
                <MedicationsListWrapper />
              </>
            }
          />
        )}
        {havePerm('appointmentcategories:read', auth) && (
          <Route
            path='/schedule-types'
            element={
              <>
                <PageTitle title='Tipo de Marcação' division='Tabelas de Suporte' />
                <AppointmentCategoriesListWrapper />
              </>
            }
          />
        )}
        {havePerm('appointmentpriorities:read', auth) && (
          <Route
            path='/priorities'
            element={
              <>
                <PageTitle title='Prioridades' division='Tabelas de Suporte' />
                <PrioritiesListWrapper />
              </>
            }
          />
        )}
        {havePerm('relationships:read', auth) && (
          <Route
            path='/relationships'
            element={
              <>
                <PageTitle title='Grau de Parentesco' division='Tabelas de Suporte' />
                <RelationshipsListWrapper />
              </>
            }
          />
        )}
        {havePerm('attachmentcategories:read', auth) && (
          <Route
            path='/attachment-types'
            element={
              <>
                <PageTitle title='Tipos de Anexos' division='Tabelas de Suporte' />
                <AttachmentCategoriesListWrapper />
              </>
            }
          />
        )}
        {havePerm('brands:read', auth) && (
          <Route
            path='/brands'
            element={
              <>
                <PageTitle title='Marcas' division='Tabelas de Suporte' />
                <BrandsListWrapper />
              </>
            }
          />
        )}
        {havePerm('devicemodels:read', auth) && (
          <Route
            path='/device-models'
            element={
              <>
                <PageTitle title='Modelos' division='Tabelas de Suporte' />
                <ModelsListWrapper />
              </>
            }
          />
        )}
        {havePerm('functionroles:read', auth) && (
          <Route
            path='/function-roles'
            element={
              <>
                <PageTitle title='Funções dos Contactos' division='Tabelas de Suporte' />
                <FunctionRolesListWrapper />
              </>
            }
          />
        )}
        {havePerm('professions:read', auth) && (
          <Route
            path='/professions'
            element={
              <>
                <PageTitle title='Profissões' division='Tabelas de Suporte' />
                <ProfessionsListWrapper />
              </>
            }
          />
        )}

        {/*Definições*/}
        {havePerm('emailtemplates:read', auth) && (
          <Route
            path='/mail-settings'
            element={
              <>
                <PageTitle title='Definições Mail' division='Clinica' />
                <ClinicsListWrapper />
              </>
            }
          />
        )}

        {(haveRole('super-admin', auth) || haveRole('admin', auth)) && (
          <>
            <Route
              path='/roles'
              element={
                <>
                  <PageTitle title='Funções' division='Definições' />
                  <RolesListWrapper />
                </>
              }
            />
            <Route path='role/:id' element={<PermissionsPage />} />
          </>
        )}

        {/* Page Not Found */}
        <Route path='/*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}
export {PrivateRoutes}
