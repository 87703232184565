import React, { FC } from "react";
import { useIntl } from "react-intl";

interface Breadcrumb {
    title: string,
    division: string
}

const PageTitle: FC<Breadcrumb> = (props): JSX.Element => {
    const intl = useIntl()
    return <>
        <div className="app-toolbar py-3 py-lg-6 ">
            <div className="app-container d-flex flex-stack container-fluid  ">
                <div data-kt-swapper="true" data-kt-swapper-mode="prepend"
                     className="page-title d-flex flex-wrap me-3 flex-column justify-content-center"><h1
                    className="page-heading d-flex text-dark fw-bold fs-3 my-0 flex-column justify-content-center">{intl.formatMessage({id: props.title})}</h1>
                    <ul className="breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0">
                        <li className="breadcrumb-item text-muted"><span className="text-muted"
                        >{intl.formatMessage({id: props.division})}</span>
                        </li>
                        <li className="breadcrumb-item"><span className="bullet bg-gray-500 w-5px h-2px"></span>
                        </li>
                        <li className="breadcrumb-item text-dark">{intl.formatMessage({id: props.title})}</li>
                    </ul>
                </div>
            </div>
        </div>
    </>;
}

export default PageTitle;

