import React, {useEffect, useState} from 'react'
import {Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, XAxis, YAxis} from 'recharts'
import {useIntl} from 'react-intl'
import axios, {AxiosResponse} from 'axios'
import {API_URL} from '../_const'
import PageTitle from './_components/PageTitle'
import {Appointment} from '../../models/_appointment'

interface Part {
  name: string
  value: number
  latest: {
    date: string
  }
}

interface Appointments {
  next: Appointment | null
  counter: number
}

const CollaboratorDashboard = () => {
  const intl = useIntl()

  const [dataRecords, setDataRecords] = useState<Part[]>([])
  const [appointments, setAppointments] = useState<Appointments>()

  useEffect(() => {
    const fetchData = async () => {
      const res: AxiosResponse<{records: Part[]; appointments: Appointments}> = await axios.get(
        `${API_URL}/statistics/collaborator`
      )
      setAppointments(res.data.appointments)
      setDataRecords(res.data.records)
    }
    fetchData()
  }, [])

  const COLORS_RECORDS = ['#53cef4', '#005070', '#53cef4', '#005070', '#53cef4', '#005070']

  return (
    <>
      <PageTitle title={'Dashboard'} division={'Home'} />
      <div className='app-container container-fluid'>
        <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
          <div className='col-xxl-6'>
            <div className='row'>
              <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-5 mb-xl-0 h-100'>
                <div className='card card-flush bgi-no-repeat bgi-size-contain bgi-position-x-end mb-5 mb-xl-10'>
                  <div className='d-flex '>
                    <i
                      className='fa-solid fa-calendar-days text-primary m-4'
                      style={{fontSize: '5em'}}
                    ></i>
                    <div className='mt-5'>
                      <h4>{intl.formatMessage({id: 'Marcações Hoje'})}</h4>
                      <h1 className='text-muted'>{appointments?.counter}</h1>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6 mb-md-5 mb-xl-0 h-100'>
                <div className='card card-flush mb-5 mb-xl-10 h-100'>
                  <div className='d-flex '>
                    <i
                      className='fa-solid fa-stethoscope text-primary m-4'
                      style={{fontSize: '5em'}}
                    ></i>
                    <div className='mt-5'>
                      <h4>{intl.formatMessage({id: 'Próxima Marcação'})}</h4>
                      {appointments?.next != null ? (
                        <>
                          <h5 className='text-muted'>
                            {intl.formatMessage({id: 'Responsável'}) +
                              ': ' +
                              appointments.next.user.full_name}
                          </h5>
                          <h5 className='text-muted'>
                            {intl.formatMessage({id: 'Data'}) + ': ' + appointments.next.date_begin}
                          </h5>
                        </>
                      ) : (
                        <h5 className='text-muted'>{intl.formatMessage({id: 'Sem Marcações'})}</h5>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-xxl-6'>
            <div className='row mb-5'>
              <div className='col-12'>
                <div className='card card-flush '>
                  <div className='card-header'>
                    <div className='card-title m-0'>
                      <h4>{intl.formatMessage({id: 'Total de Registos(Mensal)'})}</h4>
                    </div>
                  </div>
                  <div className='card-body d-flex flex-column justify-content-between bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0 pt-0 pe-0 scroll'>
                    <div className='h-300px scroll' style={{minWidth: '400px'}}>
                      <ResponsiveContainer width='100%' height='100%'>
                        <BarChart width={300} height={400} data={dataRecords} barSize={50}>
                          <CartesianGrid strokeDasharray='3 3' />
                          <Bar dataKey={'value'} label={{position: 'top'}} fill={COLORS_RECORDS[1]}>
                            {dataRecords.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={COLORS_RECORDS[index]} />
                            ))}
                          </Bar>
                          <XAxis dataKey='name' />
                          <YAxis />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CollaboratorDashboard
