// @ts-nocheck
import { Column } from "react-table";
import { BrandsCustomHeader } from "./BrandsCustomHeader";
import { Brand } from "../../../../../../models/_brand";

const brandsColumns: ReadonlyArray<Column<Brand>> = [
    {
        Header: (props) => <BrandsCustomHeader tableProps={props} title='Marca' className='min-w-125px'/>,
        accessor: 'name',
    },
    {
        Header: (props) => <BrandsCustomHeader tableProps={props} title='Observação' className='min-w-125px'/>,
        accessor: 'observation',
    },
    {
        Header: (props) => (
            <BrandsCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px'/>
        ),
        id: 'actions',
    },
]

export {brandsColumns}
