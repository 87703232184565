import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {REQUEST_PASSWORD_URL, SUBMIT_PASSWORD_URL} from '../core/_requests'
import {toast} from 'react-toastify'
import {toastStyles} from '../../../_styles'
import axios from 'axios'
import {useIntl} from 'react-intl'

const initialValues = {
  email: '',
  password: '',
  password_confirmation: '',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .min(6, 'Minimo 3 símbolos')
    .max(50, 'Máximo 50 símbolos')
    .required('É necessária uma palavra-passe'),
  password: Yup.string()
    .min(3, 'Minimo 3 símbolos')
    .max(50, 'Máximo 50 símbolos')
    .required('É necessário um Email'),
  password_confirmation: Yup.string()
    .oneOf([Yup.ref('password'), null], 'As palavras-passe devem corresponder')
    .required('É necessária a confirmação da palavra-passe'),
})

export function ChangePassword() {
  const intl = useIntl()
  const {token} = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    const fetch = async () => {
      await axios
        .post(REQUEST_PASSWORD_URL + '/verify', {token: token})
        .then()
        .catch((err) => {
          toast.error(intl.formatMessage({id: 'Token inválido'}), toastStyles)
          navigate('/auth')
        })
    }
    fetch()
  }, [])

  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values) => {
      setLoading(true)
      axios
        .post(SUBMIT_PASSWORD_URL, {...values, token: token})
        .then((res) => {
          toast.success(intl.formatMessage({id: 'Palavra-passe Alterada'}), toastStyles)
          navigate('/auth/login')
          setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          toast.error(intl.formatMessage({id: err}), toastStyles)
        })
    },
  })

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>
          {intl.formatMessage({id: 'Forgot Password ?'})}
        </h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-500 fw-semibold fs-6'>
          {intl.formatMessage({id: 'Enter your email to reset your password.'})}
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>
          {intl.formatMessage({id: 'Email'})}
        </label>
        <input
          type='email'
          placeholder=''
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control ',
            {'is-invalid': formik.touched.email && formik.errors.email},
            {
              'is-valid': formik.touched.email && !formik.errors.email,
            }
          )}
        />
        {formik.touched.email && formik.errors.email && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.email}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>
          {intl.formatMessage({id: 'Password'})}
        </label>
        <input
          type='password'
          placeholder=''
          autoComplete='off'
          {...formik.getFieldProps('password')}
          className={clsx(
            'form-control ',
            {'is-invalid': formik.touched.password && formik.errors.password},
            {
              'is-valid': formik.touched.password && !formik.errors.password,
            }
          )}
        />
        {formik.touched.password && formik.errors.password && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>
          {intl.formatMessage({id: 'Password Confirmation'})}
        </label>
        <input
          type='password'
          placeholder=''
          autoComplete='off'
          {...formik.getFieldProps('password_confirmation')}
          className={clsx(
            'form-control ',
            {
              'is-invalid':
                formik.touched.password_confirmation && formik.errors.password_confirmation,
            },
            {
              'is-valid':
                formik.touched.password_confirmation && !formik.errors.password_confirmation,
            }
          )}
        />
        {formik.touched.password_confirmation && formik.errors.password_confirmation && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.password_confirmation}</span>
            </div>
          </div>
        )}
      </div>

      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button type='submit' id='kt_password_reset_submit' className='btn btn-primary me-4'>
          <span className='indicator-label'>{intl.formatMessage({id: 'Submit'})}</span>
          {loading && (
            <span className='indicator-progress'>
              {intl.formatMessage({id: 'Please wait...'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            {intl.formatMessage({id: 'Cancel'})}
          </button>
        </Link>{' '}
      </div>
      {/* end::Form group */}
    </form>
  )
}
