/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect} from 'react'
import {Outlet} from 'react-router-dom'
import {setLanguage, useLang} from '../../../_metronic/i18n/Metronici18n'
import clsx from 'clsx'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const languages = [
  {
    lang: 'en',
    name: 'PT',
    flag: toAbsoluteUrl('/media/flags/portugal.svg'),
  },
  {
    lang: 'pt',
    name: 'EN',
    flag: toAbsoluteUrl('/media/flags/united-states.svg'),
  },
]

const AuthLayout = () => {
  const intl = useIntl()
  const lang = useLang()
  const currentLanguage = languages.find((x) => x.lang === lang)

  useEffect(() => {
    const root = document.getElementById('root')
    if (root) {
      root.style.height = '100%'
    }
    return () => {
      if (root) {
        root.style.height = 'auto'
      }
    }
  }, [])

  return (
    <>
      <div className='position-absolute top-0 end-0 m-5'>
        <div className='d-flex flex-row'>
          {languages.map(
            (l) =>
              l.lang !== currentLanguage?.lang && (
                <a
                  href=''
                  key={l.lang}
                  onClick={() => {
                    setLanguage(l.lang)
                  }}
                  className={clsx('btn btn-sm btn-light-primary d-flex mx-2 ')}
                >
                  <span className='symbol symbol-20px me-4'>
                    <img className='rounded-1' src={l.flag} alt='metronic' />
                  </span>
                  {intl.formatMessage({id: l.name})}
                </a>
              )
          )}
        </div>
      </div>
      <div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
        {/* begin::Aside */}
        <div
          className='d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-2 order-lg-1'
          style={{
            backgroundImage: `url(${toAbsoluteUrl('/media/misc/auth-bg.png')})`,
            backgroundColor: '#005070',
          }}
        >
          {/* begin::Content */}
          <div className='d-flex flex-column flex-center py-15 px-5 px-md-15 w-100'>
            {/* begin::Logo */}
            <div className='text-center  '>
              <img src='/media/logos/logobranca.png' alt='logo' className='h-90px' />
            </div>
            {/* end::Logo */}

            {/* begin::Image */}
            <img
              className='mx-auto w-375px w-md-50 w-xl-600px mb-10 mb-lg-20'
              src={toAbsoluteUrl('/media/misc/auth-screens.png')}
            />
            {/* end::Image */}

            {/* begin::Title */}
            <h1 className='text-white fs-2qx fw-bolder text-center mb-7'>
              Real-Time Healthcare. Your Health, Our Mission!
            </h1>
            {/* end::Title */}

            <div className='text-white fs-base text-center'>
              The Scan4myHealth platform provides an integrated solution for managing and monitoring
              patient vital signs.
              <br />
              Through the integrated solution of Web Platform, Mobile App and medical devices with
              CE and FDA approval, it allows the
              <br /> entity to remotely receive data in real time as well as allowing a proactive
              monitoring of the data.
            </div>
          </div>
          {/* end::Content */}
        </div>
        {/* end::Aside */}

        {/* begin::Body */}
        <div className='d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-1 order-lg-2'>
          {/* begin::Form */}
          <div className='d-flex flex-center flex-column flex-lg-row-fluid'>
            {/* begin::Wrapper */}
            <div className='w-lg-500px p-10'>
              <Outlet />
            </div>
            {/* end::Wrapper */}
          </div>
          {/* end::Form */}

          {/* begin::Footer */}
          {/*<div className='d-flex flex-center flex-wrap px-5'>*/}
          {/* begin::Links */}
          {/*<div className='d-flex fw-semibold text-primary fs-base'>
                            <a href='#' className='px-5' target='_blank'>
                                Terms
                            </a>

                            <a href='#' className='px-5' target='_blank'>
                                Plans
                            </a>

                            <a href='#' className='px-5' target='_blank'>
                                Contact Us
                            </a>
                        </div>*/}
          {/* end::Links */}
          {/*</div>*/}
          {/* end::Footer */}
        </div>
        {/* end::Body */}
      </div>
    </>
  )
}

export {AuthLayout}
