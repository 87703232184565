import * as Yup from 'yup'

export const collabSchema = Yup.object().shape({
  username: Yup.string().required('Field Required'),
  email: Yup.string().required('Field Required').email('Wrong email format'),
  full_name: Yup.string().required('Field Required'),
  mobilephone: Yup.string().required('Field Required'),
})

export const collabAssociateSchema = Yup.object().shape({
  username: Yup.string().required('Field Required'),
  email: Yup.string().required('Field Required').email('Wrong email format'),
  full_name: Yup.string().required('Field Required'),
  mobilephone: Yup.string().required('Field Required'),
  clinic_id: Yup.string().required('Field Required'),
})
