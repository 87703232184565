// @ts-nocheck
import { FC } from "react";
import { ColumnInstance } from "react-table";
import { Pathology } from "../../../../../../models/_pathology";

type Props = {
    column: ColumnInstance<Pathology>
}

const CustomHeaderColumn: FC<Props> = ({column}) => (
    <>
        {column.Header && typeof column.Header === 'string' ? (
            <th {...column.getHeaderProps()}>{column.render('Header')}</th>
        ) : (
            column.render('Header')
        )}
    </>
)

export {CustomHeaderColumn}
