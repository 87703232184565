/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useRef} from 'react'
import axios from 'axios'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {NOTES_URL} from '../../../_const'
import {toast} from 'react-toastify'
import {toastStyles} from '../../../_styles'
import {Note} from '../../../../models/_note'
import FormikTextArea from '../../_components/FormikTextArea'
import CustomModalHeader from '../../_components/modals/CustomModalHeader'
import WarningBlock from '../../_components/inputs/WarningBlock'
import {NoteSchema} from '../../clinic/_model'

type Props = {
  note: Note
  updateList: (note: Note) => void
}

export function EditNoteModal(props: Props) {
  const intl = useIntl()
  const initialValues = props.note
  const closeBtn = useRef<HTMLButtonElement>(null)

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: NoteSchema,
    onSubmit: async (values, {setSubmitting}) => {
      await axios
        .patch(`${NOTES_URL}/${props.note.id}`, {
          content: values.content,
          user_id: values.user_id,
        })
        .then((res) => {
          toast.success(intl.formatMessage({id: 'Nota Atualizada'}), toastStyles)
          props.updateList(values)
          setSubmitting(false)
          closeBtn?.current?.click()
        })
        .catch((err: any) => {
          Object.keys(err.response.data.errors).forEach((key) => {
            formik.setFieldError(key, err.response.data.errors[key][0] ?? 'undefined')
          })

          setSubmitting(false)
        })
    },
  })

  return (
    <>
      <div className='modal fade' tabIndex={-1} id='kt_modal_edit_clinic_note'>
        <div className='modal-dialog modal-lg'>
          <div className='modal-content '>
            <form onSubmit={formik.handleSubmit}>
              <CustomModalHeader title={'Editar Nota'} />
              <div className='modal-body'>
                <WarningBlock
                  show={formik.isValid}
                  warning={'Campos obrigatórios (*), preencha os campos corretamente!'}
                />
                <FormikTextArea
                  required={true}
                  formikFields={formik.getFieldProps('content')}
                  label={'Nota'}
                  touched={formik.touched.content}
                  errors={formik.errors.content}
                  name={'content'}
                />
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-light'
                  data-bs-dismiss='modal'
                  ref={closeBtn}
                >
                  {intl.formatMessage({id: 'Close'})}
                </button>
                <button
                  type='submit'
                  className='btn btn-primary'
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  {intl.formatMessage({id: 'Confirmar'})}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

/*



 */
