import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import {useIntl} from 'react-intl'
import {ClinicSchema, CountryOption} from '../_model'
import Select from 'react-select'
import {useThemeMode} from '../../../../_metronic/partials'
import axios, {AxiosResponse} from 'axios'
import {CLINICS_URL, COUNTRY_URL, USERS_URL} from '../../../_const'
import {useFormik} from 'formik'
import {toast} from 'react-toastify'
import {customStyles, toastStyles} from '../../../_styles'
import {Clinic} from '../../../../models/_clinic'
import {User} from '../../../../models/_user'
import CustomInput from '../../_components/CustomInput'
import FormikErrors from '../../_components/FormikErrors'
import {generalize} from '../../../_helpers'
import FormikTextArea from '../../_components/FormikTextArea'
import CustomModalFooter from '../../_components/modals/CustomModalFooter'
import WarningBlock from '../../_components/inputs/WarningBlock'
import clsx from 'clsx'

type Props = {
  updateList: (clinic: Clinic) => void
}

type ResponseCountries = {
  data: CountryOption[]
}

export function AddClinicModal(props: Props) {
  const intl = useIntl()
  const [countries, setCountries] = useState<CountryOption[]>([])
  const [show, setShow] = useState(false)
  const {menuMode} = useThemeMode()

  const handleClose = () => {
    formik.resetForm()
    setShow(false)
  }
  const handleShow = () => setShow(true)

  useEffect(() => {
    const fetchData = async () => {
      const countries: AxiosResponse<ResponseCountries> = await axios.get(`${COUNTRY_URL}`)
      setCountries(countries.data.data)
    }
    fetchData()
  }, [])

  const initialValues = {
    name: '',
    email: '',
    mobilephone: '',
    phone: '',
    tax_number: '',
    country_id: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: ClinicSchema,
    onSubmit: async (values, {setSubmitting}) => {
      await axios
        .post(`${CLINICS_URL}`, {
          ...values,
        })
        .then((res: AxiosResponse<{data: Clinic}>) => {
          toast.success(intl.formatMessage({id: 'Clinica Criada'}), toastStyles)
          props.updateList(res.data.data)
          handleClose()
          setSubmitting(false)
        })
        .catch((err: any) => {
          Object.keys(err.response.data.errors).forEach((key) => {
            formik.setFieldError(key, err.response.data.errors[key][0] ?? 'undefined')
          })
          formik.setTouched(generalize(initialValues, true), false)
          setSubmitting(false)
        })
    },
  })

  console.log(formik.errors)

  return (
    <>
      <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
        {/* begin::Add Clinic */}
        <button type='button' className='btn btn-primary' onClick={handleShow}>
          {intl.formatMessage({id: 'Adicionar Clinica'})}
        </button>
        {/* end::Add Clinic */}
      </div>
      {show && (
        <Modal size='xl' show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <h3 className='card-title text-primary'>
              {intl.formatMessage({id: 'Registar Clinica'})}
            </h3>
          </Modal.Header>

          <form onSubmit={formik.handleSubmit} noValidate>
            <Modal.Body>
              <div className='row'>
                <WarningBlock
                  show={formik.isValid}
                  warning={'Campos obrigatórios (*), preencha os campos corretamente!'}
                />
                <div className='col-md-6 col-12'>
                  <CustomInput
                    required={true}
                    formikFields={formik.getFieldProps('name')}
                    label={'Nome'}
                    type={'text'}
                    touched={formik.touched.name}
                    errors={formik.errors.name}
                  />
                </div>
                <div className='col-md-6 col-12'>
                  <CustomInput
                    required={true}
                    formikFields={formik.getFieldProps('email')}
                    label={'Email'}
                    type={'text'}
                    touched={formik.touched.email}
                    errors={formik.errors.email}
                  />
                </div>
                <div className='col-md-6 col-12'>
                  <div className='w-100'>
                    <label className=' form-label required'>
                      {intl.formatMessage({id: 'Pais'})}
                    </label>
                    <Select
                      options={countries}
                      getOptionLabel={(country: CountryOption) => country.name}
                      getOptionValue={(country: CountryOption) => country.id}
                      {...formik.getFieldProps('country_id')}
                      value={countries.find((value) => {
                        return value.id == formik.values.country_id
                      })}
                      onBlur={(event) => {
                        formik.setFieldTouched('country_id', true)
                        formik.setErrors({...formik.errors, country_id: undefined})
                      }}
                      onChange={(value) => {
                        formik.setFieldValue('country_id', `${value?.id}`)
                      }}
                      styles={customStyles(
                        menuMode,
                        formik.errors.country_id,
                        formik.touched.country_id
                      )}
                    />
                    <FormikErrors
                      errors={formik.errors.country_id}
                      touched={formik.touched.country_id}
                    />
                  </div>
                </div>
                <div className='col-md-6 col-12'>
                  <CustomInput
                    required={true}
                    formikFields={formik.getFieldProps('mobilephone')}
                    label={'Telemóvel'}
                    type={'text'}
                    touched={formik.touched.mobilephone}
                    errors={formik.errors.mobilephone}
                  />
                </div>
                <div className='col-md-6 col-12'>
                  <CustomInput
                    required={true}
                    formikFields={formik.getFieldProps('phone')}
                    label={'Telefone'}
                    type={'text'}
                    touched={formik.touched.phone}
                    errors={formik.errors.phone}
                  />
                </div>
                <div className='col-md-6 col-12'>
                  <CustomInput
                    required={true}
                    formikFields={formik.getFieldProps('tax_number')}
                    label={'NIF'}
                    type={'text'}
                    touched={formik.touched.tax_number}
                    errors={formik.errors.tax_number}
                  />
                </div>
              </div>
            </Modal.Body>
            <CustomModalFooter
              isValid={formik.isValid}
              isSubmitting={formik.isSubmitting}
              handleClose={handleClose}
              closeLabel={'Close'}
              submitLabel={'Confirmar'}
            />
          </form>
        </Modal>
      )}
    </>
  )
}
