import {useEffect, useMemo, useState} from 'react'
import {ColumnInstance, Row, useTable} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {ListLoading} from '../../../_components/dataTable/loading/ListLoading'
import {ID, KTCardBody, stringifyRequestQuery} from '../../../../../_metronic/helpers'
import {FunctionRolesListPagination} from '../components/header/FunctionRolesListPagination'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {useParams} from 'react-router-dom'
import axios, {AxiosResponse} from 'axios'
import {CONTACTS_FUNCTION_URL} from '../../../../_const'
import {useIntl} from 'react-intl'
import {DeleteItemModal} from '../../../../modals/DeleteItemModal'
import {FunctionRolesListSearchComponent} from '../components/header/FunctionRolesListSearchComponent'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {AddFunctionRoleModal} from '../components/modals/AddFunctionRoleModal'
import {havePerm, havePerms} from '../../../../_helpers'
import {useAuth} from '../../../../modules/auth'
import {EditFunctionRoleModal} from '../components/modals/EditFunctionRoleModal'
import {FunctionRole} from '../../../clinic/_model'
import {FunctionRoleQueryResponse} from '../../../../../models/_clinic'
import {functionRolesColumns} from './columns/_columns'

const FunctionRolesTable = () => {
  const intl = useIntl()
  const {auth} = useAuth()
  const {id} = useParams()
  const {state} = useQueryRequest()
  const [deletable, setDeletable] = useState<ID>(-1)
  const [editable, setEditable] = useState<FunctionRole>({} as FunctionRole)
  const [functionRoles, setFunctionRoles] = useState<FunctionRole[]>([])
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    MenuComponent.reinitialization()
    setLoading(true)
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
    const fetchData = async () => {
      const res = await axios
        .get(`${CONTACTS_FUNCTION_URL}?${updatedQuery}`) /**/
        .then((d: AxiosResponse<FunctionRoleQueryResponse>) => d.data)
      setFunctionRoles(res.data || [])
    }
    fetchData()
    setLoading(false)
  }, [updatedQuery])

  const columns = useMemo(() => functionRolesColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data: functionRoles,
  })

  const openModalFunc = async (id: ID) => {
    await setEditable(functionRoles.find((element) => (element.id ?? 0) === id) ?? functionRoles[0])
  }
  const openModalDel = async (id: ID) => {
    await setDeletable(
      functionRoles.find((element) => (element.id ?? 0) === id)?.id ?? functionRoles[0].id
    )
  }

  return (
    <>
      <div className='card-header border-0 pt-6'>
        <FunctionRolesListSearchComponent />
        {/* begin::Card toolbar */}
        <div className='card-toolbar'>
          {havePerm('functionroles:create', auth) && (
            <AddFunctionRoleModal
              updateTable={(functionRole: FunctionRole) => {
                setFunctionRoles([...functionRoles, functionRole])
              }}
            />
          )}
        </div>
        {/* end::Card toolbar */}
      </div>
      <KTCardBody className='py-4'>
        <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            {...getTableProps()}
          >
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gies-0'>
                {headers.map(
                  (column: ColumnInstance<FunctionRole>) =>
                    (havePerms(['functionRoles:update', 'functionRoles:delete'], auth) ||
                      column.id != 'actions') && (
                      <CustomHeaderColumn key={column.id} column={column} />
                    )
                )}
              </tr>
            </thead>
            <tbody className='text-gray-600' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<FunctionRole>, i) => {
                  prepareRow(row)
                  return (
                    <CustomRow
                      row={row}
                      key={`row-${i}-${row.id}`}
                      openModal={(id: ID) => openModalFunc(id)}
                      openModalDelete={(id: ID) => openModalDel(id)}
                      intl={intl}
                    />
                  )
                })
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      {intl.formatMessage({id: 'Não foram encontrados resultados'})}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <FunctionRolesListPagination />
        {isLoading && <ListLoading />}

        <DeleteItemModal
          toastTitle={'Função Eliminada'}
          url={CONTACTS_FUNCTION_URL}
          id={deletable}
          deleteRecord={(id: ID) => {
            setFunctionRoles((current) =>
              current.filter((obj) => {
                return obj.id !== id
              })
            )
          }}
        />
        <EditFunctionRoleModal
          functionRole={editable}
          updateList={(functionRole: FunctionRole) => {
            setFunctionRoles((current) =>
              current.map((obj) => {
                if (obj.id === functionRole.id) {
                  return functionRole
                }
                return obj
              })
            )
          }}
        />
      </KTCardBody>
    </>
  )
}

export {FunctionRolesTable}
