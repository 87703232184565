import axios from 'axios'
import {Auth} from '../../../../models/_user'

const API_URL = process.env.REACT_APP_API_URL

export const LOGIN_URL = `${API_URL}/auth/login/web`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/new-password`
export const RGPD_URL = `${API_URL}/rgpds`
export const SUBMIT_PASSWORD_URL = `${API_URL}/new-password/submit-password `

// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<Auth>(LOGIN_URL, {
    email,
    password,
  })
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}
