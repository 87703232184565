// @ts-nocheck
import {Column} from 'react-table'
import {AttachmentCategoriesCustomHeader} from './AttachmentCategoriesCustomHeader'
import {AttachmentCategory} from '../../../../../../models/_attachmentCategory'

const attachmentCategoriesColumns: ReadonlyArray<Column<AttachmentCategory>> = [
  {
    Header: (props) => (
      <AttachmentCategoriesCustomHeader
        tableProps={props}
        title='Categoria de Ficheiro'
        className='min-w-125px'
      />
    ),
    accessor: 'name',
  },

  {
    Header: (props) => (
      <AttachmentCategoriesCustomHeader
        tableProps={props}
        title='Ações'
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
  },
]

export {attachmentCategoriesColumns}
