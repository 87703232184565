import {useEffect, useMemo, useState} from 'react'
import {ColumnInstance, Row, useTable} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {ListLoading} from '../../../_components/dataTable/loading/ListLoading'
import {ID, KTCardBody, stringifyRequestQuery} from '../../../../../_metronic/helpers'
import {prioritiesColumns} from './columns/_columns'
import {PrioritiesListPagination} from '../components/header/PrioritiesListPagination'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {useParams} from 'react-router-dom'
import axios, {AxiosResponse} from 'axios'
import {PRIORITIES_URL} from '../../../../_const'
import {useIntl} from 'react-intl'
import {DeleteItemModal} from '../../../../modals/DeleteItemModal'
import {PrioritiesListSearchComponent} from '../components/header/PrioritiesListSearchComponent'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {EditPriorityModal} from '../components/modals/EditPriorityModal'
import {Priority, PriorityQueryResponse} from '../../../../../models/_priority'
import {AddPriorityModal} from '../components/modals/AddPriorityModal'
import {useAuth} from '../../../../modules/auth'
import {havePerm, havePerms} from '../../../../_helpers'

const PrioritiesTable = () => {
  const {id} = useParams()
  const intl = useIntl()
  const {auth} = useAuth()
  const {state} = useQueryRequest()
  const [deletable, setDeletable] = useState<ID>(-1)
  const [editable, setEditable] = useState<Priority>({name: ''} as Priority)
  const [priorities, setPriorities] = useState<Priority[]>([])
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    MenuComponent.reinitialization()
    setLoading(true)
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
    const fetchData = async () => {
      const res = await axios
        .get(`${PRIORITIES_URL}?${updatedQuery}`) /**/
        .then((d: AxiosResponse<PriorityQueryResponse>) => d.data)
      setPriorities(res.data || [])
    }
    fetchData()
    setLoading(false)
  }, [updatedQuery])

  const columns = useMemo(() => prioritiesColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data: priorities,
  })

  const openModalFunc = async (id: ID) => {
    await setEditable(priorities.find((element) => (element.id ?? 0) === id) ?? priorities[0])
  }
  const openModalDel = async (id: ID) => {
    await setDeletable(
      priorities.find((element) => (element.id ?? 0) === id)?.id ?? priorities[0].id
    )
  }

  return (
    <>
      <div className='card-header border-0 pt-6'>
        <PrioritiesListSearchComponent />
        {/* begin::Card toolbar */}
        <div className='card-toolbar'>
          {havePerm('appointmentpriorities:create', auth) && (
            <AddPriorityModal
              updateTable={(priority: Priority) => {
                setPriorities([...priorities, priority])
              }}
            />
          )}
        </div>
        {/* end::Card toolbar */}
      </div>
      <KTCardBody className='py-4'>
        <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            {...getTableProps()}
          >
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gies-0'>
                {headers.map(
                  (column: ColumnInstance<Priority>) =>
                    (havePerms(
                      ['appointmentpriorities:update', 'appointmentpriorities:delete'],
                      auth
                    ) ||
                      column.id != 'actions') && (
                      <CustomHeaderColumn key={column.id} column={column} />
                    )
                )}
              </tr>
            </thead>
            <tbody className='text-gray-600' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<Priority>, i) => {
                  prepareRow(row)
                  return (
                    <CustomRow
                      row={row}
                      key={`row-${i}-${row.id}`}
                      openModal={(id: ID) => openModalFunc(id)}
                      openModalDelete={(id: ID) => openModalDel(id)}
                      intl={intl}
                    />
                  )
                })
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      {intl.formatMessage({id: 'Não foram encontrados resultados'})}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <PrioritiesListPagination />
        {isLoading && <ListLoading />}

        <DeleteItemModal
          toastTitle={'Prioridade Eliminada'}
          url={PRIORITIES_URL}
          id={deletable}
          deleteRecord={(id: ID) => {
            setPriorities((current) =>
              current.filter((obj) => {
                return obj.id !== id
              })
            )
          }}
        />
        <EditPriorityModal
          priority={editable}
          updateList={(pathology: Priority) => {
            setPriorities((current) =>
              current.map((obj) => {
                if (obj.id === pathology.id) {
                  return pathology
                }
                return obj
              })
            )
          }}
        />
      </KTCardBody>
    </>
  )
}

export {PrioritiesTable}
