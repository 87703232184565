// @ts-nocheck
import clsx from "clsx";
import { FC } from "react";
import { Row } from "react-table";
import { ID } from "../../../../../../_metronic/helpers";
import { IntlShape } from "react-intl";
import { Pathology } from "../../../../../../models/_pathology";
import { havePerm, havePerms } from "../../../../../_helpers";
import { useAuth } from "../../../../../modules/auth";

type Props = {
    row: Row<Pathology>
    openModal: (id: ID) => void
    openModalDelete: (id: ID) => void
    intl: IntlShape
}

const CustomRow: FC<Props> = ({row, openModal, openModalDelete, intl}) => {
    const {auth} = useAuth()


    return (

        <tr {...row.getRowProps()}>
            {row.cells.map((cell) => {
                return (
                    ((havePerms(["pathologytypes:update", "pathologytypes:delete"], auth) && cell.column.id === 'actions') || cell.column.id !== 'actions') &&
                    <td
                        {...cell.getCellProps()}
                        className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}
                    >
                        {cell.column.id !== 'actions' ? cell.render('Cell') : <>
                            {havePerm("pathologytypes:update", auth) && <button
                                className='btn btn-light btn-active-light-primary btn-sm me-1 p-3 ps-4 pe-4 '
                                data-bs-toggle="modal" data-bs-target="#kt_modal_edit_pathology"
                                onClick={() => openModal(row.original.id)}
                            >
                                <i className="fa-sharp fa-solid p-0 fa-pen text-primary"></i>
                            </button>}
                            {havePerm("pathologytypes:delete", auth) &&
                                <button
                                    className='btn btn-light btn-active-light-danger btn-sm p-3 ps-4 pe-4 '
                                    data-bs-toggle="modal" data-bs-target="#kt_modal_delete_item"
                                    onClick={() => openModalDelete(row.original.id)}
                                >
                                    <i className="fa-sharp fa-solid p-0 fa-trash-can text-danger"></i>
                                </button>}
                        </>
                        }
                    </td>
                )
            })}
        </tr>
    )
}

export {CustomRow}
