import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import axios, {AxiosResponse} from 'axios'
import {EMAIL_TEMPLATES_URL} from '../../../../../../_const'
import {toast} from 'react-toastify'
import {customStyles, toastStyles} from '../../../../../../_styles'
import {EmailTemplate} from '../../../../../../../models/_email'
import {useParams} from 'react-router-dom'
import 'react-quill/dist/quill.snow.css'
import SunEditor from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css'
import {emailTemplateSchema} from '../../../../../../../schemas/_smtp'
import Select from 'react-select'
import {useThemeMode} from '../../../../../../../_metronic/partials'
import {havePerm} from '../../../../../../_helpers'
import {useAuth} from '../../../../../../modules/auth'

type Props = {
  template: EmailTemplate
  updateList: (template: EmailTemplate) => void
}

type TagsOptions = {
  label: string
  value: string
}

const tagsPatientOptions: TagsOptions[] = [
  {
    label: 'Username',
    value: '{#username}',
  },
  {
    label: 'Email',
    value: '{#email}',
  },
  {
    label: 'Password',
    value: '{#password}',
  },
  {
    label: 'Nome da Clinica',
    value: '{#clinic_name}',
  },
  {
    label: 'URL do site',
    value: '{#url}',
  },
  {
    label: 'URL do rgpd',
    value: '{#url_rgpd}',
  },
]

const tagsCollaboratorOptions: TagsOptions[] = [
  {
    label: 'Username',
    value: '{#username}',
  },
  {
    label: 'Email',
    value: '{#email}',
  },
  {
    label: 'Password',
    value: '{#password}',
  },
  {
    label: 'Nome da Clinica',
    value: '{#clinic_name}',
  },
  {
    label: 'URL do site',
    value: '{#url}',
  },
]

const tagsNewPasswordOptions: TagsOptions[] = [
  {
    label: 'Username',
    value: '{#username}',
  },
  {
    label: 'Nome da Clinica',
    value: '{#clinic_name}',
  },
  {
    label: 'Email',
    value: '{#email}',
  },
  {
    label: 'URL do site',
    value: '{#url}',
  },
  {
    label: 'URL de recuperação',
    value: '{#url_pwd}',
  },
]

const tagsAppointmentOptions: TagsOptions[] = [
  {
    label: 'Username',
    value: '{#username}',
  },
  {
    label: 'Responsável',
    value: '{#responsible_username}',
  },
  {
    label: 'Data de Inicio',
    value: '{#start_at}',
  },
  {
    label: 'Data Final',
    value: '{#end_at}',
  },
  {
    label: 'Assunto',
    value: '{#subject}',
  },
  {
    label: 'Local',
    value: '{#local}',
  },
  {
    label: 'Nome da Clinica',
    value: '{#clinic_name}',
  },
]

const tagsRgpdrdOptions: TagsOptions[] = [
  {
    label: 'Username',
    value: '{#username}',
  },
  {
    label: 'Nome da Clinica',
    value: '{#clinic_name}',
  },
  {
    label: 'URL do site',
    value: '{#url}',
  },
]

const tagsAnonimousOptions: TagsOptions[] = [
  {
    label: 'Username',
    value: '{#username}',
  },
]

const tagsPortableOptions: TagsOptions[] = [
  {
    label: 'Username',
    value: '{#username}',
  },
  {
    label: 'Nome da Clinica',
    value: '{#clinic_name}',
  },
]

export function TemplateMailModal(props: Props) {
  const intl = useIntl()
  const {id} = useParams()
  const {auth} = useAuth()
  const [show, setShow] = useState(false)
  const [initialValues, setInitialValues] = useState({} as EmailTemplate)
  const {menuMode} = useThemeMode()
  const [options, setOptions] = useState(tagsPatientOptions)

  useEffect(() => {
    const fetch = async () => {
      const res = await axios
        .get(`${EMAIL_TEMPLATES_URL}/show/${props.template.id}`) /**/
        .then((d: AxiosResponse<{data: EmailTemplate}>) => d.data)
      setInitialValues(res.data)
      await formik.setFieldValue('content', res.data.content)
      await formik.setFieldValue('subject', props.template.subject)
      switch (props.template.subject) {
        case 'Novo Utente':
          setOptions(tagsPatientOptions)
          break
        case 'Novo Colaborador':
          setOptions(tagsCollaboratorOptions)
          break
        case 'Recuperação de palavra passe':
          setOptions(tagsNewPasswordOptions)
          break
        case 'Agendamento':
          setOptions(tagsAppointmentOptions)
          break
        case 'Gestão de Solicitação RGPD':
          setOptions(tagsRgpdrdOptions)
          break
        case 'Pedido de Anonimização':
          setOptions(tagsAnonimousOptions)
          break
        case 'Portabilidade de dados pessoais':
          setOptions(tagsPortableOptions)
          break
      }
    }

    if (show) fetch()
  }, [show])

  const handleClose = () => {
    setShow(false)
  }
  const handleShow = () => {
    setShow(true)
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: emailTemplateSchema,
    onSubmit: async (values, {setSubmitting}) => {
      await axios
        .patch(`${EMAIL_TEMPLATES_URL}/${props.template.id}`, {
          ...values,
          clinic_id: id,
        })
        .then((res: AxiosResponse<{data: EmailTemplate}>) => {
          toast.success(intl.formatMessage({id: 'Template Atualizado'}), toastStyles)
          props.updateList(res.data.data)
          handleClose()
        })
        .catch(() => {
          toast.error(intl.formatMessage({id: 'Erro ao criar'}), toastStyles)
        })
    },
  })

  return (
    <>
      <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
        <button
          className='btn btn-light btn-active-light-primary btn-sm me-1 p-3 ps-4 pe-4 '
          onClick={() => handleShow()}
        >
          <i className='fa-sharp fa-solid p-0 fa-envelope text-primary'></i>
        </button>
      </div>
      <Modal size='xl' show={show} onHide={handleClose}>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <h3 className='card-title text-primary'>
              Email: {intl.formatMessage({id: props.template.subject})}
            </h3>
          </Modal.Header>
          <Modal.Body>
            <h4>{intl.formatMessage({id: 'Adicionar Tags'})}</h4>
            <hr />
            <div className='w-100 w-sm-50'>
              <Select
                options={options}
                placeholder={intl.formatMessage({id: 'Adicionar Campo'})}
                getOptionLabel={(tag: TagsOptions) =>
                  intl.formatMessage({id: tag.label}) + ` ${tag.value}`
                }
                getOptionValue={(tag: TagsOptions) => tag.value}
                isMulti={false}
                backspaceRemovesValue={true}
                onChange={(option) => {
                  formik.setFieldValue('content', option?.value + formik.values.content)
                }}
                styles={customStyles(menuMode)}
              />
            </div>
            <h4 className={'mt-3'}>{intl.formatMessage({id: 'Editar Template'})}</h4>

            <SunEditor
              setContents={formik.values.content ?? props.template.content}
              height={'40vh'}
              setOptions={{
                buttonList: [
                  ['font', 'fontSize', 'formatBlock'],
                  ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                  ['align', 'horizontalRule', 'list', 'table'],
                  ['fontColor', 'hiliteColor'],
                  ['outdent', 'indent'],
                  ['undo', 'redo'],
                  ['removeFormat'],
                  ['outdent', 'indent'],
                  ['link', 'image'],
                  ['preview', 'print'],
                  ['fullScreen', 'showBlocks', 'codeView'],
                ],
              }}
              onChange={(value) => {
                formik.setFieldValue('content', value)
              }}
            />
            <div className={'d-flex flex-end w-100 mt-3'}>
              {havePerm('emailtemplates:update', auth) && (
                <button
                  type={'button'}
                  className='btn btn-light btn-active-light-danger btn-sm me-1 p-3 ps-4 pe-4  '
                  onClick={() => {
                    axios
                      .post(`${EMAIL_TEMPLATES_URL}/reset-default`, {
                        subject: formik.values.subject,
                        clinic_id: id,
                        template_id: formik.values.id,
                      })
                      .then((res: AxiosResponse<{data: EmailTemplate}>) => {
                        toast.success(intl.formatMessage({id: 'Template Padrão'}), toastStyles)
                        props.updateList(res.data.data)
                        handleClose()
                      })
                      .catch()
                  }}
                >
                  <span>
                    <i className='fa-sharp fa-solid p-0 fa-arrow-rotate-right text-danger me-1 mb-1'></i>
                    {intl.formatMessage({id: 'Reset'})}
                  </span>
                </button>
              )}
              <button
                type={'button'}
                className='btn btn-light btn-active-light-primary btn-sm me-1 p-3 ps-4 pe-4  '
                onClick={() => {
                  axios
                    .post(`${EMAIL_TEMPLATES_URL}/test`, {
                      value: formik.values.content ?? props.template.content,
                      clinic_id: id,
                      subject: props.template.subject,
                    })
                    .then()
                    .catch()
                }}
              >
                <span>
                  <i className='fa-sharp fa-solid p-0 fa-paper-plane text-primary me-1'></i>
                  {intl.formatMessage({id: 'Testar'})}
                </span>
              </button>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type='button'
              className='btn btn-light'
              onClick={handleClose}
              data-bs-dismiss='modal'
            >
              {intl.formatMessage({id: 'Close'})}
            </button>
            {havePerm('emailtemplates:update', auth) && (
              <button type='submit' className='btn btn-primary'>
                {intl.formatMessage({id: 'Confirmar'})}
              </button>
            )}
          </Modal.Footer>
        </form>
      </Modal>
    </>
  )
}
