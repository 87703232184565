import {useEffect, useMemo, useState} from 'react'
import {ColumnInstance, Row, useTable} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {ListLoading} from '../../../../_components/dataTable/loading/ListLoading'
import {KTCardBody} from '../../../../../../_metronic/helpers'
import {CollabsListPagination} from '../components/header/CollabsListPagination'
import {useIntl} from 'react-intl'
import {CollabsListSearchComponent} from '../components/header/CollabsListSearchComponent'
import {collabsColumns} from './columns/_columns'
import {Collaborator} from '../../../../../../models/_collab'
import {useAuth} from '../../../../../modules/auth'
import {havePerm} from '../../../../../_helpers'
import {Navigate} from 'react-router-dom'
import {CreateCollaborator} from '../modals/CreateCollaborator'

const CollabsTable = () => {
  const intl = useIntl()
  const {auth} = useAuth()
  const collabs = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => collabs, [collabs])
  const [rowsTable, setRowsTable] = useState(data)
  const columns = useMemo(() => collabsColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data: rowsTable,
  })
  useEffect(() => {
    setRowsTable(data)
  }, [data])

  if (!havePerm('collaborators:read', auth)) <Navigate to={'/error/404'} />

  return (
    <>
      <div className='card-header border-0 pt-6'>
        <CollabsListSearchComponent />
        <div className='card-toolbar'>
          {havePerm('collaborators:create', auth) && (
            <CreateCollaborator
              updateCollab={(collab) => {
                setRowsTable([...data, collab])
              }}
            />
          )}
        </div>
      </div>
      <KTCardBody className='py-4'>
        {!isLoading ? (
          <>
            <div className='table-responsive'>
              <table
                id='kt_table_users'
                className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                {...getTableProps()}
              >
                <thead>
                  <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                    {headers.map((column: ColumnInstance<Collaborator>) => (
                      <CustomHeaderColumn key={column.id} column={column} />
                    ))}
                  </tr>
                </thead>
                <tbody className='text-gray-600' {...getTableBodyProps()}>
                  {rows.length > 0 ? (
                    rows.map((row: Row<Collaborator>, i) => {
                      prepareRow(row)
                      return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                    })
                  ) : (
                    <tr>
                      <td colSpan={9}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                          {intl.formatMessage({id: 'Não foram encontrados resultados'})}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <CollabsListPagination />
          </>
        ) : (
          <ListLoading />
        )}
      </KTCardBody>
    </>
  )
}

export {CollabsTable}
