// @ts-nocheck
import { Column } from "react-table";
import { FunctionRolesCustomHeader } from "./FunctionRolesCustomHeader";
import { FunctionRole } from "../../../../clinic/_model";

const functionRolesColumns: ReadonlyArray<Column<FunctionRole>> = [
  {
    Header: (props) => (
      <FunctionRolesCustomHeader
        tableProps={props}
        title='Função do Contacto'
        className='min-w-125px'
      />
    ),
    accessor: 'name',
  },
  {
    Header: (props) => (
      <FunctionRolesCustomHeader
        tableProps={props}
        title='Ações'
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
  },
]

export {functionRolesColumns}
