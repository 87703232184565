import axios, {AxiosResponse} from 'axios'
import {ContactsQueryResponse} from '../models/_contacts'
import {CLINIC_CONTACTS_URL, CONTACTS_FUNCTION_URL} from '../app/_const'
import {FunctionRoleQueryResponse} from '../models/_clinic'

const getContacts = (query: string, id: string): Promise<ContactsQueryResponse> => {
  return axios
    .get(`${CLINIC_CONTACTS_URL}/${id}?${query}`) /**/
    .then((d: AxiosResponse<ContactsQueryResponse>) => d.data)
}

const getFunctionRoles = (query: string): Promise<FunctionRoleQueryResponse> => {
  return axios
    .get(`${CONTACTS_FUNCTION_URL}?${query}`) /**/
    .then((d: AxiosResponse<FunctionRoleQueryResponse>) => d.data)
}

export {getContacts, getFunctionRoles}
