import React, {useEffect, useState} from 'react'
import {useOutletContext, useParams} from 'react-router-dom'
import axios, {AxiosResponse} from 'axios'
import {API_URL} from '../../../_const'
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'
import {OximeterPoint, OximeterWarning} from '../../../../models/_oximeter'

// @ts-ignore
import logo from './../../../assets/ecg.gif'
import CustomInput from '../../_components/CustomInput'
import {toastStyles} from '../../../_styles'
import {WarningValue} from '../../../../models/_warningRecords'
import {useFormik} from 'formik'
import {warningValuesSchema} from '../../../../schemas/_records'
import {toast} from 'react-toastify'
import {useIntl} from 'react-intl'
import {generalize, havePerm} from '../../../_helpers'
import {useAuth} from '../../../modules/auth'

function OximeterPage() {
  const intl = useIntl()
  const {id} = useParams()
  const {auth} = useAuth()
  const [data, setData] = useState<OximeterPoint[]>([])
  const interval: Date[] = useOutletContext()
  const [initialValues, setInitialValues] = useState<WarningValue>({} as WarningValue)
  const [warnings, setWarnings] = useState<{icon: string; oxi: OximeterWarning; text: string}>({
    icon: 'face-smile',
    text: 'success',
    oxi: {x: ''} as OximeterWarning,
  })

  useEffect(() => {
    const fetchData = async () => {
      const date_start: string =
        interval[0].getFullYear() +
        '-' +
        (interval[0].getMonth() + 1 > 10
          ? interval[0].getMonth() + 1
          : '0' + (interval[0].getMonth() + 1)) +
        '-' +
        interval[0].getDate()
      const date_end: string =
        interval[1].getFullYear() +
        '-' +
        (interval[1].getMonth() + 1 > 10
          ? interval[1].getMonth() + 1
          : '0' + (interval[1].getMonth() + 1)) +
        '-' +
        interval[1].getDate()
      const data_temp: OximeterPoint[] = []
      const res: AxiosResponse<OximeterPoint[]> = await axios.get(
        `${API_URL}/oximeters?user_id=${id}&date_start=${date_start} 00:00:00&date_end=${date_end} 23:59:59`
      )
      res.data.forEach((point) => {
        data_temp.push({...point})
      })
      setData(data_temp)

      if (havePerm('warningvalues:read', auth)) {
        const {data}: AxiosResponse<{data: WarningValue}> = await axios.get(
          `${API_URL}/warning-values/${id}`
        )
        setInitialValues(data.data)
      }

      if (res.data.length > 0) {
        showWarning(data_temp[data_temp.length - 1])
      }
    }
    if (interval[0] && interval[1]) fetchData()
  }, [interval])

  useEffect(() => {
    const fetchData = async () => {
      showWarning(data[data.length - 1])
    }
    fetchData()
  }, [initialValues])

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: warningValuesSchema,
    onSubmit: async (values, {setSubmitting}) => {
      await axios
        .post(`${API_URL}/warning-values`, {
          pi_min: values.pi_min ?? null,
          pi_max: values.pi_max ?? null,
          oxy_min: values.oxy_min ?? null,
          oxy_max: values.oxy_max ?? null,
          pr_min: values.pr_min ?? null,
          pr_max: values.pr_max ?? null,
          user_id: id,
        })
        .then((res) => {
          setInitialValues({...values})
          toast.success(intl.formatMessage({id: 'Intervalo Definido'}), toastStyles)
          setSubmitting(false)
        })
        .catch((err) => {
          Object.keys(err.response.data.errors).forEach((key) => {
            formik.setFieldError(key, err.response.data.errors[key][0] ?? 'undefined')
          })
          formik.setTouched(generalize(initialValues, true), false)
          setSubmitting(false)
        })
    },
  })

  const showWarning = (point: OximeterPoint) => {
    const warns = {
      icon: 'face-smile',
      text: 'success',
      oxi: {
        ...point,
        pr_icon: 'face-smile',
        pr_warning: 'success',
        oxygen_icon: 'face-smile',
        oxygen_warning: 'success',
        pi_icon: 'face-smile',
        pi_warning: 'success',
      },
    }
    if (point)
      if ((initialValues.pr_min ?? 0) > point.pr || (initialValues.pr_max ?? 2000) < point.pr) {
        warns.icon = 'face-frown'
        warns.text = 'danger'
        warns.oxi.pr_icon = 'face-frown'
        warns.oxi.pr_warning = 'danger'
      }
    if (point)
      if ((initialValues.pi_min ?? 0) > point.pi || (initialValues.pi_max ?? 2000) < point.pi) {
        warns.icon = 'face-frown'
        warns.text = 'danger'
        warns.oxi.pi_icon = 'face-frown'
        warns.oxi.pi_warning = 'danger'
      }
    if (point)
      if (
        (initialValues.oxy_min ?? 0) > point.oxygen ||
        (initialValues.oxy_max ?? 2000) < point.oxygen
      ) {
        warns.icon = 'face-frown'
        warns.text = 'danger'
        warns.oxi.oxygen_icon = 'face-frown'
        warns.oxi.oxygen_warning = 'danger'
      }
    setWarnings(warns)
  }

  return (
    <>
      {data.length !== 0 ? (
        <div className=' h-300px scroll'>
          <ResponsiveContainer height='100%' width='100%' className={'scroll'}>
            <LineChart
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
              onClick={({activePayload}) => {
                showWarning(activePayload ? activePayload[0].payload : {})
              }}
            >
              <CartesianGrid strokeDasharray='2 2' />
              <XAxis dataKey='x' />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type='monotone' dataKey='pr' stroke='#009EF7' dot={false} />
              <Line type='monotone' dataKey='pi' stroke='#f75719' dot={false} />
              <Line type='monotone' dataKey='oxygen' stroke='#f7C619' dot={false} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <>
          <div className='w-100 h-100 d-flex justify-content-center'>
            <img src={logo} alt='' />
          </div>
          <h3 className={'text-center text-muted w-100'}>
            {intl.formatMessage({id: 'Sem Registos'})}
          </h3>
        </>
      )}
      {havePerm('warningvalues:read', auth) && data.length !== 0 && (
        <div className='row'>
          <div className='col-12'>
            <h4>{intl.formatMessage({id: 'Avisos'})}</h4>
            <hr />
          </div>
          {havePerm('warningvalues:update', auth) && (
            <div className='col-12 col-md-4 mb-3'>
              <form onSubmit={formik.handleSubmit}>
                <div className='row'>
                  <div className='col-6'>
                    <CustomInput
                      formikFields={formik.getFieldProps('pr_min')}
                      label={'PR Min'}
                      type={'number'}
                      touched={formik.touched.pr_min}
                      errors={formik.errors.pr_min}
                      name={'pr_min'}
                    />
                  </div>
                  <div className='col-6'>
                    <CustomInput
                      formikFields={formik.getFieldProps('pr_max')}
                      label={'PR Max'}
                      type={'number'}
                      touched={formik.touched.pr_max}
                      errors={formik.errors.pr_max}
                      name={'pr_max'}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-6'>
                    <CustomInput
                      formikFields={formik.getFieldProps('oxy_min')}
                      label={'OXY Min'}
                      type={'number'}
                      touched={formik.touched.oxy_min}
                      errors={formik.errors.oxy_min}
                      name={'oxy_min'}
                    />
                  </div>
                  <div className='col-6'>
                    <CustomInput
                      formikFields={formik.getFieldProps('oxy_max')}
                      label={'OXY Max'}
                      type={'number'}
                      touched={formik.touched.oxy_max}
                      errors={formik.errors.oxy_max}
                      name={'oxy_max'}
                    />
                  </div>
                </div>
                <div className='row'>
                  <div className='col-6'>
                    <CustomInput
                      formikFields={formik.getFieldProps('pi_min')}
                      label={'PI Min'}
                      type={'number'}
                      touched={formik.touched.pi_min}
                      errors={formik.errors.pi_min}
                      name={'pi_min'}
                    />
                  </div>
                  <div className='col-6'>
                    <CustomInput
                      formikFields={formik.getFieldProps('pi_max')}
                      label={'PI Max'}
                      type={'number'}
                      touched={formik.touched.pi_max}
                      errors={formik.errors.pi_max}
                      name={'pi_max'}
                    />
                  </div>
                </div>
                <button className={'btn btn-sm btn-light-primary'} type={'submit'}>
                  {intl.formatMessage({id: 'Guardar'})}
                </button>
              </form>
            </div>
          )}
          {havePerm('warningvalues:read', auth) && data.length !== 0 && warnings.oxi && (
            <div className='col-12 col-md-4'>
              <div className={`border border-${warnings.text} rounded h-100`}>
                <div className='row d-flex justify-content-center'>
                  <div
                    className={`col-sm-5 col-8 border border-${warnings.text} rounded-bottom border-top-0 bg-${warnings.text} bg-opacity-25 `}
                  >
                    <p className={'m-3 text-center'}>{warnings.oxi.x}</p>
                  </div>
                </div>
                <i
                  className={`fa-solid fa-${warnings.icon} text-center text-${warnings.text} mt-5 mb-5 w-100`}
                  style={{fontSize: '3em'}}
                ></i>
                <div className='m-5 mt-0 border rounded border-gray-400 '>
                  <div className='d-flex justify-content-between'>
                    <p className={'m-2'} style={{fontSize: '1.3em'}}>
                      {intl.formatMessage({id: 'Oxigénio:'}) + ' ' + warnings.oxi.oxygen + '%'}
                    </p>
                    <i
                      className={`fa-solid fa-${warnings.oxi.oxygen_icon}  text-${warnings.oxi.oxygen_warning} m-2`}
                      style={{fontSize: '1.8em'}}
                    ></i>
                  </div>
                  <hr className={'m-0'} />
                  <div className='d-flex justify-content-between'>
                    <p className={'m-2'} style={{fontSize: '1.3em'}}>
                      {intl.formatMessage({id: 'PR:'}) + ' ' + warnings.oxi.pr + '/min'}
                    </p>
                    <i
                      className={`fa-solid fa-${warnings.oxi.pr_icon}  text-${warnings.oxi.pr_warning} m-2`}
                      style={{fontSize: '1.8em'}}
                    ></i>
                  </div>
                  <hr className={'m-0'} />
                  <div className='d-flex justify-content-between'>
                    <p className={'m-2'} style={{fontSize: '1.3em'}}>
                      {intl.formatMessage({id: 'PI:'}) + ' ' + warnings.oxi.pi / 10 + ''}
                    </p>
                    <i
                      className={`fa-solid fa-${warnings.oxi.pi_icon}  text-${warnings.oxi.pi_warning} m-2`}
                      style={{fontSize: '1.8em'}}
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default OximeterPage
