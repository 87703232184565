import axios, {AxiosResponse} from 'axios'
import {ATTACHMENT_URL} from "../app/_const";
import {AttachmentQueryResponse} from "../models/_attachment";


const getAttachments = (query: string, id: string): Promise<AttachmentQueryResponse> => {
    return axios
        .get(`${ATTACHMENT_URL}?user_id=${id}&${query}`)
        .then((d: AxiosResponse<AttachmentQueryResponse>) => d.data)
}

const getAttachmentsClinic = (query: string, id: string): Promise<AttachmentQueryResponse> => {
    return axios
        .get(`${ATTACHMENT_URL}?clinic_id=${id}&${query}`)
        .then((d: AxiosResponse<AttachmentQueryResponse>) => d.data)
}

export {getAttachments, getAttachmentsClinic}