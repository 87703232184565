/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef, useState } from "react";
import axios, { AxiosResponse } from "axios";
import { useFormik } from "formik";
import { ContactSchema, FunctionRole } from "../_model";
import { useIntl } from "react-intl";
import Select from "react-select";
import { CONTACTS_FUNCTION_URL, CONTACTS_URL } from "../../../_const";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { customStyles, toastStyles } from "../../../_styles";
import { useThemeMode } from "../../../../_metronic/partials";
import { Contact } from "../../../../models/_contacts";
import { havePerm } from "../../../_helpers";
import { useAuth } from "../../../modules/auth";
import CustomInput from "../../_components/CustomInput";
import CustomModalHeader from "../../_components/modals/CustomModalHeader";
import WarningBlock from "../../_components/inputs/WarningBlock";
import FormikErrors from "../../_components/FormikErrors";
import FormikTextArea from "../../_components/FormikTextArea";

type Response = {
  data: FunctionRole[]
}

type Props = {
  contact: Contact
  updateList: (contact: Contact) => void
}

export function EditContactModal(props: Props) {
  const intl = useIntl()
  const { id } = useParams()
  const { auth } = useAuth()
  const { menuMode } = useThemeMode()
  const [initialValues, setInitialValues] = useState<Contact>(props.contact)
  const [funcRoles, setfuncRole] = useState<FunctionRole[]>([])
  const closeBtn = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    const fetchData = async () => {
      if (props.contact.id !== -1) {
        const roles: AxiosResponse<Response> = await axios.get(`${CONTACTS_FUNCTION_URL}/get-all`)
        setfuncRole(roles.data.data)
      }
      setInitialValues(props.contact)
    }
    fetchData()
  }, [props])
  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: ContactSchema,
    onSubmit: async (values, { setSubmitting }) => {
      await axios
        .patch(`${CONTACTS_URL}/${props.contact?.id}`, {
          ...values,
          user_id: undefined,
          clinic_id: id || '',
        })
        .then((res) => {
          toast.success(intl.formatMessage({ id: 'Contacto Atualizado' }), toastStyles)
          props.updateList({
            ...values,
            user_id: undefined,
            id: res.data.data.id,
            clinic_id: id,
            function_role: {
              id: res.data.data.function_role.id,
              name: res.data.data.function_role.name,
            },
          })
          setSubmitting(false)
          closeBtn?.current?.click()
        })
        .catch((err: any) => {
          Object.keys(err.response.data.errors).forEach((key) => {
            formik.setFieldError(key, err.response.data.errors[key][0] ?? 'undefined')
          })

          setSubmitting(false)
        })
    },
  })

  return (
    <>
      <div className='modal fade' tabIndex={-1} id='kt_modal_edit_clinic_contact'>
        <div className='modal-dialog modal-lg'>
          <div className='modal-content '>
            <CustomModalHeader title={'Editar Contacto'} />
            <form onSubmit={formik.handleSubmit}>
              <div className='modal-body'>
                <div className='row'>
                  <WarningBlock
                    show={formik.isValid}
                    warning={'Campos obrigatórios (*), preencha os campos corretamente!'}
                  />
                  <div className="col-md-6 col-12">
                    <CustomInput
                      required={true}
                      formikFields={formik.getFieldProps("name")}
                      label={"Nome"}
                      type={"text"}
                      touched={formik.touched.name}
                      errors={formik.errors.name}
                      name={"name"}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <CustomInput
                      required={true}
                      formikFields={formik.getFieldProps("email")}
                      label={"Email"}
                      type={"text"}
                      touched={formik.touched.email}
                      errors={formik.errors.email}
                    />
                  </div>
                  <div className="col-md-6 col-12">
                    <CustomInput
                      required={true}
                      formikFields={formik.getFieldProps("phone")}
                      label={"Telemóvel"}
                      type={"text"}
                      touched={formik.touched.phone}
                      errors={formik.errors.phone}
                      name={"phone"}
                    />
                  </div>
                  <div className="col-md-6 col-12 fv-row">
                    <label className="form-label required">
                      {intl.formatMessage({ id: "Função" })}
                    </label>
                    {havePerm("functionroles:list", auth) && (
                      <>
                        <Select
                          options={funcRoles}
                          getOptionLabel={(role: FunctionRole) => role.name}
                          getOptionValue={(role: FunctionRole) => `${role.id}`}
                          {...formik.getFieldProps("functionRole_id")}
                          value={funcRoles.find((value) => {
                            return `${value.id}` == formik.values.functionRole_id;
                          })}
                          onBlur={(event) => {
                            formik.setFieldTouched("functionRole_id", true);
                            formik.setErrors({ ...formik.errors, functionRole_id: undefined });
                          }}
                          onChange={(value) => {
                            formik.setFieldValue("functionRole_id", `${value?.id}`);
                          }}
                          styles={customStyles(
                            menuMode,
                            formik.errors.functionRole_id,
                            formik.touched.functionRole_id
                          )}
                        />
                        <FormikErrors
                          errors={formik.errors.functionRole_id}
                          touched={formik.touched.functionRole_id}
                        />
                      </>
                    )}
                  </div>


                  <FormikTextArea
                    formikFields={formik.getFieldProps("observation")}
                    label={"Descrição"}
                    touched={formik.touched.observation}
                    errors={formik.errors.observation}
                    name={"observation"}
                  />
                </div>
              </div>

              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-light'
                  data-bs-dismiss='modal'
                  ref={closeBtn}
                >
                  {intl.formatMessage({ id: 'Close' })}
                </button>
                <button
                  type='submit'
                  className='btn btn-primary'
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  {intl.formatMessage({ id: 'Confirmar' })}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}