import React, {FC} from 'react'
import {useIntl} from 'react-intl'

interface FormikError {
  errors: string | undefined
  touched: boolean | undefined
}

const FormikErrors: FC<FormikError> = ({errors, touched}): JSX.Element => {
  const intl = useIntl()

  return (
    <>
      {touched && errors && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert'>{intl.formatMessage({id: `${errors}`})}</span>
          </div>
        </div>
      )}
    </>
  )
}

export default FormikErrors
