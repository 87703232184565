import React, {useEffect, useRef, useState} from 'react'
import Select from 'react-select'
import {useFormik} from 'formik'
import axios, {AxiosResponse} from 'axios'
import {toast} from 'react-toastify'
import {useAuth} from '../../../modules/auth'
import {GenderOption, UserSchema} from '../../clinic/_model'
import {UserForm} from '../../../../models/_user'
import {API_IMG_URL, USERS_URL} from '../../../_const'
import {customStyles, toastStyles} from '../../../_styles'
import {useThemeMode} from '../../../../_metronic/partials'
import {useParams} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {dateToStringWithoutHours, generalize, generalizeOnNull, havePerm} from '../../../_helpers'
import ReactDatePicker from 'react-datepicker'
import CustomInput from '../../_components/CustomInput'
import FormikErrors from '../../_components/FormikErrors'
import WarningBlock from '../../_components/inputs/WarningBlock'
import clsx from 'clsx'

export function CollaboratorPerfil() {
  const intl = useIntl()
  const {id} = useParams()
  const {auth} = useAuth()
  const menuMode = useThemeMode()
  const ref = useRef<HTMLInputElement>(null)
  const [file, setFile] = useState<File | null>(null)
  const [initialValues, setInitialValues] = useState({
    address: '',
    birthday: '',
    cc: '',
    city: '',
    country_id: '',
    email: '',
    full_name: '',
    gender: '',
    mobilephone: '',
    niss: '',
    phone: '',
    photo: '',
    postcode: '',
    sns_number: '',
    tax_number: '',
    username: '',
  } as UserForm)
  const perm = havePerm('collaborators:update', auth) || `${auth?.data.id}` == id
  const handleClick = () => {
    if (null !== ref.current) {
      ref.current.click()
    }
  }

  const genders: GenderOption[] = [
    {
      label: 'male',
      value: 'male',
    },
    {
      label: 'female',
      value: 'female',
    },
    {
      label: 'Outro',
      value: 'other',
    },
  ]

  useEffect(() => {
    const fetch = async () => {
      const user: AxiosResponse<{data: UserForm}> = await axios.get(`${USERS_URL}/${id}`)
      setInitialValues({
        ...generalizeOnNull(user.data.data, ''),
        photo: API_IMG_URL + '/' + (user.data.data.photo ?? 'assets/avatars/blank.png'),
      })
    }

    fetch()
  }, [])

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: UserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      let formData = new FormData()
      Object.keys(values).forEach((key) => {
        if (values[key as keyof UserForm]) {
          if (key != 'photo') formData.append(key, `${values[key as keyof UserForm]}`)
        }
      })
      if (file) {
        var reader = new FileReader()
        reader.readAsText(file)
        formData.append('photo', file)
      }
      formData.append('_method', 'PATCH')
      await axios
        .post(`${USERS_URL}/${id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          setSubmitting(false)
          toast.success(intl.formatMessage({id: 'Colaborador Associado'}), toastStyles)
        })
        .catch((err) => {
          Object.keys(err.response.data.errors).forEach((key) => {
            formik.setFieldError(key, err.response.data.errors[key][0] ?? 'undefined')
          })
          formik.setTouched(generalize(initialValues, true), false)
          setSubmitting(false)
        })
    },
  })

  return (
    <div className='card card-custom'>
      <form onSubmit={formik.handleSubmit}>
        <div className='card-header'>
          <h3 className='card-title text-primary'>
            {intl.formatMessage({id: 'Perfil do Colaborador'})}
          </h3>
          <div className='card-toolbar'>
            {perm && (
              <button
                type='submit'
                disabled={!formik.isValid || formik.isSubmitting}
                className='btn btn-primary btn-sm'
              >
                {intl.formatMessage({id: 'Guardar'})}
              </button>
            )}
          </div>
        </div>
        <div className='card-body'>
          <div className='row'>
            <WarningBlock
              show={formik.isValid}
              warning={'Campos obrigatórios (*), preencha os campos corretamente!'}
            />
            <div className='col-12 col-sm-6'>
              <div className='symbol symbol-100px position-relative mb-5'>
                {perm && (
                  <div
                    className='position-absolute top-0 start-100 translate-middle bg-primary rounded-3 bg-opacity-50 bg-hover-primary '
                    onClick={handleClick}
                    style={{cursor: 'pointer'}}
                  >
                    <i className='fa-solid fa-pen p-1 text-light'></i>
                  </div>
                )}
                <img alt='Logo' src={formik.values.photo} className='img-fluid of' />
              </div>
              <input
                type='file'
                accept='.jpg,.jpeg,.png'
                ref={ref}
                hidden
                onChange={(event) => {
                  if (event.target.files) {
                    formik.setFieldValue('photo', URL.createObjectURL(event.target.files[0]))
                    setFile(event.target.files[0])
                  }
                }}
              />
              <CustomInput
                required={true}
                disable={perm}
                formikFields={{...formik.getFieldProps('username')}}
                label={'Nome do Utilizador'}
                type={'text'}
                touched={formik.touched.username}
                errors={formik.errors.username}
                name={'username'}
              />
              <CustomInput
                required={true}
                disable={perm}
                formikFields={formik.getFieldProps('full_name')}
                label={'Nome Completo'}
                type={'text'}
                touched={formik.touched.full_name}
                errors={formik.errors.full_name}
                name={'full_name'}
              />
              <CustomInput
                required={true}
                disable={perm}
                formikFields={formik.getFieldProps('email')}
                label={'Email'}
                type={'mail'}
                touched={formik.touched.email}
                errors={formik.errors.email}
                name={'email'}
              />
              <div className='mb-5 w-100'>
                <label htmlFor='exampleFormControlInput1' className='required form-label'>
                  {intl.formatMessage({id: 'Data de Nascimento'})}
                </label>

                <ReactDatePicker
                  disabled={!perm}
                  className={clsx('form-control', {
                    'is-invalid': formik.errors.birthday && formik.touched.birthday,
                  })}
                  placeholderText={
                    formik.values.birthday == ''
                      ? 'Selecionar data...'
                      : formik.values.birthday ?? 'Selecionar data...'
                  }
                  onChange={(date: Date) =>
                    formik.setFieldValue('birthday', dateToStringWithoutHours(date))
                  }
                  selected={formik.values.birthday ? new Date(formik.values.birthday) : null}
                  dateFormat='yyyy-MM-dd'
                  popperPlacement='top'
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                />
                <FormikErrors errors={formik.errors.birthday} touched={formik.touched.birthday} />
              </div>
              <CustomInput
                required={true}
                disable={perm}
                formikFields={formik.getFieldProps('mobilephone')}
                label={'Telemóvel'}
                type={'text'}
                touched={formik.touched.mobilephone}
                errors={formik.errors.mobilephone}
                name={'mobilephone'}
              />
            </div>
            <div className='col-12 col-sm-6'>
              <CustomInput
                required={true}
                disable={perm}
                formikFields={formik.getFieldProps('city')}
                label={'Cidade'}
                type={'text'}
                touched={formik.touched.city}
                errors={formik.errors.city}
                name={'city'}
              />
              <CustomInput
                required={true}
                disable={perm}
                formikFields={formik.getFieldProps('address')}
                label={'Morada'}
                type={'text'}
                touched={formik.touched.address}
                errors={formik.errors.address}
                name={'address'}
              />
              <CustomInput
                required={true}
                disable={perm}
                formikFields={formik.getFieldProps('postcode')}
                label={'Código Postal'}
                type={'text'}
                touched={formik.touched.postcode}
                errors={formik.errors.postcode}
                name={'postcode'}
              />
              <CustomInput
                required={true}
                disable={perm}
                formikFields={formik.getFieldProps('tax_number')}
                label={'NIF'}
                type={'number'}
                touched={formik.touched.tax_number}
                errors={formik.errors.tax_number}
                name={'tax_number'}
              />
              <CustomInput
                required={true}
                disable={perm}
                formikFields={formik.getFieldProps('niss')}
                label={'Número de Identificação de Segurança Social'}
                type={'text'}
                touched={formik.touched.niss}
                errors={formik.errors.niss}
                name={'niss'}
              />
              <CustomInput
                required={true}
                disable={perm}
                formikFields={formik.getFieldProps('cc')}
                label={'Numero de Identificação'}
                type={'number'}
                touched={formik.touched.cc}
                errors={formik.errors.cc}
                name={'cc'}
              />
              <CustomInput
                required={true}
                disable={perm}
                formikFields={formik.getFieldProps('sns_number')}
                label={'Numero de Utente'}
                type={'number'}
                touched={formik.touched.sns_number}
                errors={formik.errors.sns_number}
                name={'sns_number'}
              />
              <div className='mb-5 w-100'>
                <label className=' form-label required'>{intl.formatMessage({id: 'Género'})}</label>
                <Select
                  isDisabled={!perm}
                  options={genders}
                  getOptionLabel={(gender: GenderOption) => intl.formatMessage({id: gender.label})}
                  getOptionValue={(gender: GenderOption) => `${gender.value}`}
                  {...formik.getFieldProps('gender')}
                  value={genders.find((value) => {
                    return `${value.value}` == `${formik.values.gender}`
                  })}
                  onBlur={(event) => {
                    formik.setFieldTouched('gender', true)
                    formik.setErrors({...formik.errors, gender: undefined})
                  }}
                  onChange={(value) => {
                    formik.setFieldValue('gender', `${value?.value}`)
                  }}
                  styles={customStyles(
                    menuMode.menuMode,
                    formik.errors.gender,
                    formik.touched.gender,
                    perm
                  )}
                />
                <FormikErrors errors={formik.errors.gender} touched={formik.touched.gender} />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  )
}
