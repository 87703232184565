import { ListViewProvider } from "./core/ListViewProvider";
import { QueryRequestProvider } from "./core/QueryRequestProvider";
import { QueryResponseProvider } from "./core/QueryResponseProvider";
import { KTCard } from "../../../../../_metronic/helpers";
import { ClinicsEmailTemplatesTable } from "./table/ClinicsEmailTemplatesTable";

const ClinicsEmailTemplatesList = () => {
    return (
        <>
            <KTCard>
                <ClinicsEmailTemplatesTable/>
            </KTCard>
        </>
    )
}

const ClinicsEmailTemplatesListWrapper = () => (
    <QueryRequestProvider>
        <QueryResponseProvider>
            <ListViewProvider>
                <ClinicsEmailTemplatesList/>
            </ListViewProvider>
        </QueryResponseProvider>
    </QueryRequestProvider>
)

export {ClinicsEmailTemplatesListWrapper}
