// @ts-nocheck
import {Column} from 'react-table'
import {ActionsCell} from './ActionsCell'
import {CustomHeader} from './CustomHeader'
import {Patient} from '../../../core/_models'
import {PhotoCell} from '../../../../../_components/dataTable/cells/PhotoCell'
import {RgpdStatus} from '../../../../../clinic/datatable/clinic-patient-list/table/columns/RgpdStatus'
import {DevicesCustomHeader} from '../../../../../clinic/datatable/clinic-devices-list/devices-list/table/columns/DevicesCustomHeader'

const patientsColumns: ReadonlyArray<Column<Patient>> = [
  {
    Header: (props) => <CustomHeader tableProps={props} title='Foto' className='min-w-125px' />,
    accessor: 'photo',
    Cell: ({...props}) => <PhotoCell photo={props.data[props.row.index].photo} />,
  },

  {
    Header: (props) => <CustomHeader tableProps={props} title='Nome' className='min-w-125px' />,
    accessor: 'full_name',
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='Email' className='min-w-125px' />,
    accessor: 'email',
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Telemóvel' className='min-w-125px' />
    ),
    accessor: 'mobilephone',
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='NISS' className='min-w-125px' />,
    accessor: 'niss',
  },
  {
    Header: (props) => <CustomHeader tableProps={props} title='NIF' className='min-w-125px' />,
    accessor: 'tax_number',
  },

  {
    Header: (props) => (
      <DevicesCustomHeader tableProps={props} title='RGPD' className='min-w-125px' />
    ),
    Cell: (props) => <RgpdStatus success={props.row.original.is_gdpr_accepted} />,
    accessor: 'is_gdpr_accepted',
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Clinica Responsavel' className='min-w-125px' />
    ),
    accessor: 'clinic.name',
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Selecionar' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ActionsCell id={props.data[props.row.index].id} />,
  },
]

export {patientsColumns}
