import * as Yup from 'yup'

export const attachmentSchema = Yup.object().shape({
  description: Yup.string().required('Field Required'),
  privacy: Yup.number().required('Field Required'),
  /*    clinic_id: Yup.string()
          .required('Field Required'),
      user_id: Yup.string()
          .required('Field Required'),
      responsible_id: Yup.string()
          .required('Field Required'),*/
  attachmentCategory_id: Yup.string().required('Field Required'),
  file: Yup.mixed().required('Field Required'),
})
