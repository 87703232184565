/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from "react";
import { MenuComponent } from "../../../../../../../_metronic/assets/ts/components";
import { ID } from "../../../../../../../_metronic/helpers";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";

type Props = {
    id: ID
}

const ClinicActionsCell: FC<Props> = ({id}) => {
    const navigation = useNavigate()
    const location = useLocation()

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])

    const goto = (id: ID) => {
        let path = ''
        switch (location.pathname) {
            case '/clinics':
                path = "/clinic/general/"
                break;
            case '/collaborators':
                path = "/collaborator/perfil/"
                break;
            case '/patients':
                path = "/patient/base/"
                break;
            case '/devices':
                path = "/clinic/devices/"
                break;
            case '/reports':
                path = "/clinic/reports/"
                break;
            case '/statistics':
                path = "/clinic/statistics/"
                break;
            case '/autos':
                path = "/clinic/autos/"
                break;
            case '/mail-settings':
                path = "/clinic/mail-settings/"
                break;
            case '/sms-settings':
                path = "/clinic/sms-settings/"
                break;
            case '/sip-settings':
                path = "/clinic/sip-settings/"
                break;

        }
        navigation(`${path}${id}`)
    }

    return (
        <>
            <button
                className='btn btn-light btn-active-light-primary btn-sm'
                data-kt-menu-trigger='click'
                data-kt-menu-placement='bottom-end'
                onClick={(e) => {
                    goto(id)
                }}
            >
                <i className="fa-sharp fa-solid fa-eye text-primary p-0 p-0"></i>
            </button>

        </>
    )
}

export {ClinicActionsCell}
