// @ts-nocheck
import { Column } from "react-table";
import { ClinicActionsCell } from "./ClinicActionsCell";
import { ClinicCustomHeader } from "./ClinicCustomHeader";
import { Clinic } from "../../../../../../../models/_clinic";

const clinicsColumns: ReadonlyArray<Column<Clinic>> = [
  {
    Header: (props) => (
      <ClinicCustomHeader tableProps={props} title='Clinica' className='min-w-125px' />
    ),
    accessor: 'name',
  },
  {
    Header: (props) => (
      <ClinicCustomHeader tableProps={props} title='Responsável' className='min-w-125px' />
    ),
    accessor: 'responsible.full_name',
  },
  {
    Header: (props) => (
      <ClinicCustomHeader tableProps={props} title='Email' className='min-w-125px' />
    ),
    accessor: 'email',
  },
  {
    Header: (props) => (
      <ClinicCustomHeader tableProps={props} title='Telemóvel' className='min-w-125px' />
    ),
    accessor: 'mobilephone',
  },
  {
    Header: (props) => (
      <ClinicCustomHeader tableProps={props} title='Website' className='min-w-125px' />
    ),
    accessor: 'website',
  },
  {
    Header: (props) => (
      <ClinicCustomHeader tableProps={props} title='NIF' className='min-w-125px' />
    ),
    accessor: 'tax_number',
  },
  {
    Header: (props) => (
      <ClinicCustomHeader tableProps={props} title='Cidade' className='min-w-125px' />
    ),
    accessor: 'city',
  },
  {
    Header: (props) => (
      <ClinicCustomHeader tableProps={props} title='Selecionar' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ClinicActionsCell id={props.data[props.row.index].id} />,
  },
]

export {clinicsColumns}
