import React, {useEffect, useRef, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import {useIntl} from 'react-intl'
import CustomInput from '../pages/_components/CustomInput'
import Select from 'react-select'
import {GenderOption, UserSchema} from '../pages/clinic/_model'
import {customStyles, toastStyles} from '../_styles'
import {UserForm} from '../../models/_user'
import {useFormik} from 'formik'
import axios, {AxiosResponse} from 'axios'
import {API_IMG_URL, USERS_URL} from '../_const'
import {setAuth, useAuth} from '../modules/auth'
import {toast} from 'react-toastify'
import {useThemeMode} from '../../_metronic/partials'
import ReactDatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import {dateToStringWithoutHours, generalize, generalizeOnNull} from '../_helpers'
import CustomModalFooter from '../pages/_components/modals/CustomModalFooter'
import WarningBlock from '../pages/_components/inputs/WarningBlock'
import clsx from 'clsx'
import FormikErrors from '../pages/_components/FormikErrors'

interface Props {
  updateInfo: (newAuth: any) => void
}

export function MySettingsModal(props: Props) {
  const intl = useIntl()
  const {auth} = useAuth()
  const menuMode = useThemeMode()
  const ref = useRef<HTMLInputElement>(null)
  const [file, setFile] = useState<File | null>(null)
  const [initialValues, setInitialValues] = useState({
    address: '',
    birthday: '',
    cc: '',
    city: '',
    country_id: '',
    email: '',
    full_name: '',
    gender: '',
    mobilephone: '',
    niss: '',
    phone: '',
    photo: '',
    postcode: '',
    sns_number: '',
    tax_number: '',
    username: '',
  } as UserForm)
  const [show, setShow] = useState(false)

  const handleClose = () => {
    formik.resetForm()
    setShow(false)
  }
  const handleShow = () => {
    setShow(true)
  }

  const genders: GenderOption[] = [
    {
      label: 'male',
      value: 'male',
    },
    {
      label: 'female',
      value: 'female',
    },
    {
      label: 'Outro',
      value: 'other',
    },
  ]

  const handleClick = (e: any) => {
    if (null !== ref.current) {
      ref.current.click()
    }
  }

  useEffect(() => {
    const fetch = async () => {
      const user: AxiosResponse<{data: UserForm}> = await axios.get(`${USERS_URL}/${auth?.data.id}`)
      setInitialValues({
        ...generalizeOnNull(user.data.data, ''),
        photo: API_IMG_URL + '/' + (user.data.data.photo ?? 'assets/avatars/blank.png'),
      })
    }

    fetch()
  }, [])

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: UserSchema,
    onSubmit: async (values, {setSubmitting}) => {
      let formData = new FormData()
      Object.keys(values).forEach((key) => {
        if (key != 'photo' && key != 'email_verified_at')
          formData.append(key, `${values[key as keyof UserForm]}`)
      })
      if (file) {
        var reader = new FileReader()
        reader.readAsText(file)
        formData.append('photo', file)
      }
      formData.append('_method', 'PATCH')
      await axios
        .post(`${USERS_URL}/${auth?.data?.id}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then((res) => {
          let user = {
            ...res.data.data,
          }
          setInitialValues(res.data.data)
          let newAuth = {
            access_token: auth?.access_token || '',
            success: auth?.success || false,
            data: user,
          }
          setAuth(newAuth)
          props.updateInfo(newAuth)
          toast.success(intl.formatMessage({id: 'Conta Atualizada'}), toastStyles)
          handleClose()
          setSubmitting(false)
        })
        .catch((err) => {
          Object.keys(err.response.data.errors).forEach((key) => {
            formik.setFieldError(key, err.response.data.errors[key][0] ?? 'undefined')
          })
          formik.setTouched(generalize(initialValues, true), false)
          setSubmitting(false)
        })
    },
  })

  return (
    <>
      <div className='menu-item px-5' onClick={handleShow}>
        <a className='menu-link px-5'>{intl.formatMessage({id: 'Meu Perfil'})}</a>
      </div>

      <Modal size={'xl'} show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <h3 className='card-title text-primary'>{intl.formatMessage({id: 'Editar Perfil'})}</h3>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <>
            <Modal.Body>
              <div className='row'>
                <WarningBlock
                  show={formik.isValid}
                  warning={'Campos obrigatórios (*), preencha os campos corretamente!'}
                />
                <div className='col-12 col-sm-6'>
                  <div className='symbol symbol-100px position-relative mb-5'>
                    <div
                      className='position-absolute top-0 start-100 translate-middle bg-primary rounded-3 bg-opacity-50 bg-hover-primary '
                      onClick={handleClick}
                      style={{cursor: 'pointer'}}
                    >
                      <i className='fa-solid fa-pen p-1 text-light'></i>
                    </div>
                    <img alt='Logo' src={formik.values.photo} className='img-fluid of' />
                  </div>
                  <input
                    type='file'
                    accept='.jpg,.jpeg,.png'
                    ref={ref}
                    hidden
                    onChange={(event) => {
                      if (event.target.files) {
                        formik.setFieldValue('photo', URL.createObjectURL(event.target.files[0]))
                        setFile(event.target.files[0])
                      }
                    }}
                  />
                  <CustomInput
                    required={true}
                    formikFields={{...formik.getFieldProps('username')}}
                    label={'Nome do Utilizador'}
                    type={'text'}
                    touched={formik.touched.username}
                    errors={formik.errors.username}
                    name={'username'}
                  />
                  <CustomInput
                    required={true}
                    formikFields={formik.getFieldProps('full_name')}
                    label={'Nome Completo'}
                    type={'text'}
                    touched={formik.touched.full_name}
                    errors={formik.errors.full_name}
                    name={'full_name'}
                  />
                  <CustomInput
                    required={true}
                    formikFields={formik.getFieldProps('email')}
                    label={'Email'}
                    type={'mail'}
                    touched={formik.touched.email}
                    errors={formik.errors.email}
                    name={'email'}
                  />
                  <CustomInput
                    required={true}
                    formikFields={formik.getFieldProps('mobilephone')}
                    label={'Telemóvel'}
                    type={'text'}
                    touched={formik.touched.mobilephone}
                    errors={formik.errors.mobilephone}
                    name={'mobilephone'}
                  />
                  <div className='mb-5 w-100 d-flex flex-column'>
                    <label htmlFor='exampleFormControlInput1' className='form-label'>
                      {intl.formatMessage({id: 'Data de Nascimento'})}
                    </label>

                    <ReactDatePicker
                      className={clsx('form-control', {
                        'is-invalid': formik.errors.birthday && formik.touched.birthday,
                      })}
                      placeholderText={
                        formik.values.birthday == ''
                          ? 'Selecionar data...'
                          : formik.values.birthday ?? 'Selecionar data...'
                      }
                      onChange={(date: Date) =>
                        formik.setFieldValue('birthday', dateToStringWithoutHours(date))
                      }
                      selected={formik.values.birthday ? new Date(formik.values.birthday) : null}
                      dateFormat='yyyy-MM-dd'
                      popperPlacement='top'
                      showMonthDropdown
                      showYearDropdown
                      scrollableYearDropdown
                    />
                    <FormikErrors
                      errors={formik.errors.birthday}
                      touched={formik.touched.birthday}
                    />
                  </div>
                </div>
                <div className='col-12 col-sm-6'>
                  <CustomInput
                    required={false}
                    formikFields={formik.getFieldProps('city')}
                    label={'Cidade'}
                    type={'text'}
                    touched={formik.touched.city}
                    errors={formik.errors.city}
                    name={'city'}
                  />
                  <CustomInput
                    required={false}
                    formikFields={formik.getFieldProps('address')}
                    label={'Morada'}
                    type={'text'}
                    touched={formik.touched.address}
                    errors={formik.errors.address}
                    name={'address'}
                  />
                  <CustomInput
                    required={false}
                    formikFields={formik.getFieldProps('postcode')}
                    label={'Código Postal'}
                    type={'text'}
                    touched={formik.touched.postcode}
                    errors={formik.errors.postcode}
                    name={'postcode'}
                  />
                  <CustomInput
                    required={false}
                    formikFields={formik.getFieldProps('tax_number')}
                    label={'NIF'}
                    type={'number'}
                    touched={formik.touched.tax_number}
                    errors={formik.errors.tax_number}
                    name={'tax_number'}
                  />
                  <CustomInput
                    required={false}
                    formikFields={formik.getFieldProps('niss')}
                    label={'Número de Identificação de Segurança Social'}
                    type={'text'}
                    touched={formik.touched.niss}
                    errors={formik.errors.niss}
                    name={'niss'}
                  />
                  <CustomInput
                    required={false}
                    formikFields={formik.getFieldProps('cc')}
                    label={'Numero de Identificação'}
                    type={'number'}
                    touched={formik.touched.cc}
                    errors={formik.errors.cc}
                    name={'cc'}
                  />
                  <CustomInput
                    required={false}
                    formikFields={formik.getFieldProps('sns_number')}
                    label={'Numero de Utente'}
                    type={'number'}
                    touched={formik.touched.sns_number}
                    errors={formik.errors.sns_number}
                    name={'sns_number'}
                  />
                  <div className='mb-5 w-100'>
                    <label className=' form-label'>{intl.formatMessage({id: 'Género'})}</label>
                    <Select
                      options={genders}
                      getOptionLabel={(gender: GenderOption) =>
                        intl.formatMessage({id: gender.label})
                      }
                      getOptionValue={(gender: GenderOption) => `${gender.value}`}
                      {...formik.getFieldProps('gender')}
                      value={genders.find((value) => {
                        return `${value.value}` == `${formik.values.gender}`
                      })}
                      onBlur={(event) => {
                        formik.setFieldTouched('gender', true)
                        formik.setErrors({...formik.errors, gender: undefined})
                      }}
                      onChange={(value) => {
                        formik.setFieldValue('gender', `${value?.value}`)
                      }}
                      styles={customStyles(
                        menuMode.menuMode,
                        formik.errors.gender,
                        formik.touched.gender
                      )}
                    />
                    <FormikErrors errors={formik.errors.gender} touched={formik.touched.gender} />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <CustomModalFooter
              isValid={formik.isValid}
              isSubmitting={formik.isSubmitting}
              handleClose={handleClose}
              closeLabel={'Close'}
              submitLabel={'Confirmar'}
            />
          </>
        </form>
      </Modal>
    </>
  )
}
