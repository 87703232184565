import {useEffect, useMemo, useState} from 'react'
import {ColumnInstance, Row, useTable} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {RelativesListPagination} from '../components/header/RelativesListPagination'
import {useIntl} from 'react-intl'
import {RelativesListSearchComponent} from '../components/header/RelativesListSearchComponent'
import {RELATIVES_URL} from '../../../../../_const'
import {ID, KTCardBody} from '../../../../../../_metronic/helpers'
import {ListLoading} from '../../../../_components/dataTable/loading/ListLoading'
import {useAuth} from '../../../../../modules/auth'
import {DeleteItemModal} from '../../../../../modals/DeleteItemModal'
import {relativesColumns} from './columns/_columns'
import {Relative} from '../../../../../../models/_relative'
import {AddRelativeModal} from '../components/modals/AddRelativeModal'
import {havePerm} from '../../../../../_helpers'
import {EditRelativeModal} from '../components/modals/EditRelativeModal'

export const RelativesTable = () => {
  const intl = useIntl()
  const {auth} = useAuth()
  const relatives = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => relatives, [relatives])
  const [rowsTable, setRowsTable] = useState(data)
  const columns = useMemo(() => relativesColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data: rowsTable,
  })

  const [deletable, setDeletable] = useState<ID>(-1)
  const [editable, setEditable] = useState<Relative>({} as Relative)

  useEffect(() => {
    setRowsTable(data)
  }, [data])

  const openModalFunc = async (id: ID) => {
    await setEditable(rowsTable.find((element) => (element.id ?? 0) === id) ?? relatives[0])
  }
  const openModalDel = async (id: ID) => {
    await setDeletable(rowsTable.find((element) => (element.id ?? 0) === id)?.id ?? relatives[0].id)
  }

  return (
    <>
      <div className='card-header border-0 pt-6'>
        <RelativesListSearchComponent />
        {/* begin::Card toolbar */}
        <div className='card-toolbar'>
          {havePerm('relatives:create', auth) && (
            <AddRelativeModal
              updateTable={(relative) => {
                setRowsTable([relative, ...rowsTable])
              }}
            />
          )}
        </div>
        {/* end::Card toolbar */}
      </div>
      <KTCardBody className='py-4'>
        {!isLoading ? (
          <>
            <div className='table-responsive'>
              <table
                id='kt_table_users'
                className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer  '
                {...getTableProps()}
              >
                <thead>
                  <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                    {headers.map((column: ColumnInstance<Relative>) => (
                      <CustomHeaderColumn key={column.id} column={column} />
                    ))}
                  </tr>
                </thead>
                <tbody className='text-gray-600' {...getTableBodyProps()}>
                  {rows.length > 0 ? (
                    rows.map((row: Row<Relative>, i) => {
                      prepareRow(row)
                      return (
                        <CustomRow
                          row={row}
                          key={`row-${i}-${row.id}`}
                          openModal={(id: ID) => openModalFunc(id)}
                          openModalDelete={(id: ID) => openModalDel(id)}
                        />
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={9}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                          {intl.formatMessage({id: 'Não foram encontrados resultados'})}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <RelativesListPagination />
          </>
        ) : (
          <ListLoading />
        )}

        <DeleteItemModal
          toastTitle={'Parente Eliminado'}
          url={RELATIVES_URL}
          id={deletable}
          deleteRecord={(id: ID) => {
            setRowsTable((current) =>
              current.filter((obj) => {
                return obj.id !== id
              })
            )
          }}
        />
        <EditRelativeModal
          relative={editable}
          updateList={(relative: Relative) => {
            setRowsTable((current) =>
              current.map((obj) => {
                if (obj.id === relative.id) {
                  return relative
                }
                return obj
              })
            )
          }}
        />
      </KTCardBody>
    </>
  )
}
