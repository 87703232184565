import React, {useEffect, useRef, useState} from 'react'
import axios, {AxiosResponse} from 'axios'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import CustomInput from '../../../../../../_components/CustomInput'
import {DEVICES_URL, MODELS_URL, USER_DEVICES_URL} from '../../../../../../../_const'
import {customStyles, toastStyles} from '../../../../../../../_styles'
import Select from 'react-select'
import {useThemeMode} from '../../../../../../../../_metronic/partials'
import {Device} from '../../../../../../../../models/_device'
import {Model} from '../../../../../../../../models/_deviceModel'
import {deviceSchema} from '../../../../../../../../schemas/_device'
import CustomModalHeader from '../../../../../../_components/modals/CustomModalHeader'

// @ts-ignore
import logo from '../../../../../../../assets/device.png'
// @ts-ignore
import logoOff from '../../../../../../../assets/device_off.png'
import WarningBlock from '../../../../../../_components/inputs/WarningBlock'
import FormikTextArea from '../../../../../../_components/FormikTextArea'
import {generalize} from '../../../../../../../_helpers'

interface Props {
  updateList: (device: Device) => void
  device: Device
}

export function DeviceModal(props: Props) {
  const intl = useIntl()
  const [initialValues, setInitialValues] = useState<Device>(props.device)
  const {menuMode} = useThemeMode()
  const [models, setModels] = useState<Model[]>([])
  const [user, setUser] = useState('')
  const closeBtn = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    const fetchData = async () => {
      setInitialValues(props.device)
      const response: AxiosResponse<{data: Model[]}> = await axios.get(`${MODELS_URL}/all`)
      setModels(response.data.data)
      const r: AxiosResponse<string> = await axios.get(`${USER_DEVICES_URL}/${props.device.id}`)
      setUser(r.data)
    }
    props.device.id && fetchData()
  }, [props])

  const handleClose = async () => {
    await axios
      .delete(`${USER_DEVICES_URL}/${props.device.id}`)
      .then(() => {
        props.updateList({...props.device, status: false})
        toast.success('Utilizador Dessassociado', toastStyles)
      })
      .catch(() => {
        toast.error('Erro ao Dessassociar', toastStyles)
      })
  }

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: deviceSchema,
    onSubmit: async (values, {setSubmitting}) => {
      await axios
        .patch(`${DEVICES_URL}/${props.device.id}`, values)
        .then((res) => {
          props.updateList(res.data.data)
          toast.success(intl.formatMessage({id: 'Dispositvo Editado'}), toastStyles)
          setSubmitting(false)
          closeBtn?.current?.click()
        })
        .catch((err: any) => {
          Object.keys(err.response.data.errors).forEach((key) => {
            formik.setFieldError(key, err.response.data.errors[key][0] ?? 'undefined')
          })
          formik.setTouched(
            generalize({mac_address: false, observation: false, device_model_id: false}, true),
            false
          )
          setSubmitting(false)
        })
    },
  })

  return (
    <>
      <div className='modal fade' tabIndex={-1} id='kt_modal_edit_device'>
        <div className='modal-dialog modal-lg'>
          <div className='modal-content '>
            <CustomModalHeader title={'Editar Dispositivo'} />
            <form onSubmit={formik.handleSubmit} noValidate>
              <div className='modal-body'>
                <div className='row'>
                  <WarningBlock
                    show={formik.isValid}
                    warning={'Campos obrigatórios (*), preencha os campos corretamente!'}
                  />
                  <div className='col-12 col-md-6 '>
                    <CustomInput
                      required={true}
                      formikFields={formik.getFieldProps('mac_address')}
                      label={'MAC Address'}
                      type={'text'}
                      touched={formik.touched.mac_address}
                      errors={formik.errors.mac_address}
                      name={'mac_address'}
                    />
                    <label className='form-label mb-0 required'>
                      {intl.formatMessage({id: 'Modelo'})}
                    </label>
                    <Select
                      options={models}
                      placeholder={initialValues.model?.name}
                      getOptionLabel={(model: Model) => model.name}
                      getOptionValue={(model: Model) => (model.id ? model.id.toString() : '0')}
                      isMulti={false}
                      backspaceRemovesValue={true}
                      onChange={(newValue) => {
                        formik.setFieldValue('device_model_id', newValue?.id)
                      }}
                      styles={customStyles(menuMode)}
                    />
                  </div>
                  <div className='col-12 col-md-6 mt-5 mt-md-0'>
                    <div className='d-flex justify-content-center'>
                      <div className='container position-relative text-center '>
                        {initialValues.status ? (
                          <>
                            <img src={logo} alt='' className='w-75 m-auto' />
                            <div
                              style={{
                                position: 'absolute',
                                top: '50%',
                                left: '48%',
                                transform: 'translate(-50%, -50%)',
                              }}
                            >
                              {intl.formatMessage({id: 'Associado a:'})} <br /> {user}
                            </div>
                          </>
                        ) : (
                          <>
                            <img src={logoOff} alt='' className='w-75 m-auto' />
                            <div
                              style={{
                                position: 'absolute',
                                top: '50%',
                                left: '48%',
                                transform: 'translate(-50%, -50%)',
                              }}
                            >
                              {intl.formatMessage({id: 'Sem utilizador'})}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                  <FormikTextArea
                    formikFields={formik.getFieldProps('observation')}
                    label={'observations'}
                    touched={formik.touched.observation}
                    errors={formik.errors.observation}
                    name={'observations'}
                  />
                </div>
              </div>
              <div className='modal-footer'>
                {initialValues.status ? (
                  <button
                    type='button'
                    className='btn btn-light-danger'
                    data-bs-dismiss='modal'
                    onClick={handleClose}
                  >
                    {intl.formatMessage({id: 'Dessassociar'})}
                  </button>
                ) : (
                  <></>
                )}
                <button
                  type='submit'
                  className='btn btn-primary'
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  {intl.formatMessage({id: 'Alterar'})}
                </button>
                <button
                  type='button'
                  ref={closeBtn}
                  className='btn btn-light-danger d-none'
                  data-bs-dismiss='modal'
                ></button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
