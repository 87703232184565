import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useIntl } from "react-intl";
import { useParams } from "react-router-dom";
import { ContactSchema, FunctionRole } from "../_model";
import { useThemeMode } from "../../../../_metronic/partials";
import { useFormik } from "formik";
import axios, { AxiosResponse } from "axios";
import { CONTACTS_FUNCTION_URL, CONTACTS_URL } from "../../../_const";
import { toast } from "react-toastify";
import { customStyles, toastStyles } from "../../../_styles";
import Select from "react-select";
import { Contact } from "../../../../models/_contacts";
import CustomInput from "../../_components/CustomInput";
import { generalize, havePerm } from "../../../_helpers";
import { useAuth } from "../../../modules/auth";
import FormikTextArea from "../../_components/FormikTextArea";
import FormikErrors from "../../_components/FormikErrors";
import CustomModalFooter from "../../_components/modals/CustomModalFooter";
import WarningBlock from "../../_components/inputs/WarningBlock";

type Response = {
  data: FunctionRole[]
}

interface Option {
  readonly label: string;
  readonly value: string;
}

type Props = {
  updateList: (contact: Contact) => void
}

export function AddContactModal(props: Props) {
  const intl = useIntl();
  const { id } = useParams();
  const { auth } = useAuth();
  const [funcRoles, setfuncRoles] = useState<FunctionRole[]>([]);
  const [show, setShow] = useState(false);
  const { menuMode } = useThemeMode();

  useEffect(() => {
    const fetchData = async () => {
      const roles: AxiosResponse<Response> = await axios.get(`${CONTACTS_FUNCTION_URL}/get-all`);
      setfuncRoles(roles.data.data);
    };
    if (show) fetchData();
  }, [show]);

  const handleClose = () => {
    formik.resetForm();
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  const initialValues = {
    name: "",
    phone: "",
    email: "",
    clinic_id: `${id}`,
    functionRole_id: "",
    observation: ""
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ContactSchema,
    onSubmit: async (values, { setSubmitting }) => {
      await axios
        .post(`${CONTACTS_URL}`, {
          ...values,
          clinic_id: id
        })
        .then((res) => {
          toast.success(intl.formatMessage({ id: "Contacto Criado" }), toastStyles);
          props.updateList({
            ...values,
            user_id: undefined,
            id: res.data.data.id,
            clinic_id: id,
            function_role: {
              id: res.data.data.function_role.id,
              name: res.data.data.function_role.name
            }
          });
          handleClose();
          setSubmitting(false);
        })
        .catch((err: any) => {
          Object.keys(err.response.data.errors).forEach((key) => {
            formik.setFieldError(key, err.response.data.errors[key][0] ?? "undefined");
          });
          formik.setTouched(generalize(initialValues, true), false);
          setSubmitting(false);
        });
    }
  });

  return (
    <>
      <div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
        <button type="button" className="btn btn-primary" onClick={handleShow}>
          {intl.formatMessage({ id: "Adicionar Contacto" })}
        </button>
      </div>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <h3 className="card-title text-primary">
            {intl.formatMessage({ id: "Adicionar Contacto" })}
          </h3>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className="row">
              <WarningBlock
                show={formik.isValid}
                warning={"Campos obrigatórios (*), preencha os campos corretamente!"}
              />
              <div className="col-md-6 col-12">
                <CustomInput
                  required={true}
                  formikFields={formik.getFieldProps("name")}
                  label={"Nome"}
                  type={"text"}
                  touched={formik.touched.name}
                  errors={formik.errors.name}
                  name={"name"}
                />
              </div>
              <div className="col-md-6 col-12">
                <CustomInput
                  required={true}
                  formikFields={formik.getFieldProps("email")}
                  label={"Email"}
                  type={"text"}
                  touched={formik.touched.email}
                  errors={formik.errors.email}
                />
              </div>
              <div className="col-md-6 col-12">
                <CustomInput
                  required={true}
                  formikFields={formik.getFieldProps("phone")}
                  label={"Telemóvel"}
                  type={"text"}
                  touched={formik.touched.phone}
                  errors={formik.errors.phone}
                  name={"phone"}
                />
              </div>
              <div className="col-md-6 col-12 fv-row">
                <label className="form-label required">
                  {intl.formatMessage({ id: "Função" })}
                </label>
                {havePerm("functionroles:list", auth) && (
                  <>
                    <Select
                      options={funcRoles}
                      getOptionLabel={(role: FunctionRole) => role.name}
                      getOptionValue={(role: FunctionRole) => `${role.id}`}
                      {...formik.getFieldProps("functionRole_id")}
                      value={funcRoles.find((value) => {
                        return `${value.id}` == formik.values.functionRole_id;
                      })}
                      onBlur={(event) => {
                        formik.setFieldTouched("functionRole_id", true);
                        formik.setErrors({ ...formik.errors, functionRole_id: undefined });
                      }}
                      onChange={(value) => {
                        formik.setFieldValue("functionRole_id", `${value?.id}`);
                      }}
                      styles={customStyles(
                        menuMode,
                        formik.errors.functionRole_id,
                        formik.touched.functionRole_id
                      )}
                    />
                    <FormikErrors
                      errors={formik.errors.functionRole_id}
                      touched={formik.touched.functionRole_id}
                    />
                  </>
                )}
              </div>


              <FormikTextArea
                formikFields={formik.getFieldProps("observation")}
                label={"Descrição"}
                touched={formik.touched.observation}
                errors={formik.errors.observation}
                name={"observation"}
              />
            </div>
          </Modal.Body>
          <CustomModalFooter
            isValid={formik.isValid}
            isSubmitting={formik.isSubmitting}
            handleClose={handleClose}
            closeLabel={"Close"}
            submitLabel={"Confirmar"}
          />
        </form>
      </Modal>
    </>
  );
}