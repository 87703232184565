import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useIntl } from "react-intl";
import { useFormik } from "formik";
import axios, { AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { ATTACHMENT_CATEGORIES_URL, ATTACHMENT_URL } from "../_const";
import { useThemeMode } from "../../_metronic/partials";
import { customStyles, toastStyles } from "../_styles";
import { useParams } from "react-router-dom";
import { AttachmentCategory } from "../../models/_attachmentCategory";
import { Attachment } from "../../models/_attachment";
import CustomInput from "../pages/_components/CustomInput";
import Select, { SingleValue } from "react-select";
import { useAuth } from "../modules/auth";
import { attachmentSchema } from "../../schemas/_attachment";
import clsx from "clsx";
import CustomModalFooter from "../pages/_components/modals/CustomModalFooter";
import FormikErrors from "../pages/_components/FormikErrors";
import WarningBlock from "../pages/_components/inputs/WarningBlock";
import { generalize } from "../_helpers";

interface Props {
  updateAttachments: (attachment: Attachment) => void;
  context: string;
}

export function CreateAttachmentModal({ updateAttachments, context }: Props) {
  const intl = useIntl();
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const [acs, setAcs] = useState<AttachmentCategory[]>([]);
  const { auth } = useAuth();
  const [file, setFile] = useState<File | null>(null);
  const { menuMode } = useThemeMode();

  useEffect(() => {
    const fetchData = async () => {
      const response: AxiosResponse<{ data: AttachmentCategory[] }> = await axios.get(
        `${ATTACHMENT_CATEGORIES_URL}/all`
      );
      setAcs(response.data.data);
    };
    fetchData();
  }, []);

  const handleShow = () => {
    setShow(true);
  };
  const handleClose = () => {
    formik.resetForm();
    setShow(false);
  };

  const onChangeHandler = (newValue: SingleValue<AttachmentCategory> | undefined) => {
    formik.setFieldValue("attachmentCategory_id", newValue?.id);
  };

  const initialValues = {
    privacy: 0,
    description: "",
    attachmentCategory_id: "",
    file: ""
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: attachmentSchema,
    onSubmit: async (values, { setSubmitting }) => {
      values.privacy = values.privacy ? 1 : 0;
      let formData = new FormData();

      formData.append("privacy", `${values["privacy"]}`);
      formData.append("description", `${values["description"]}`);
      formData.append("attachmentCategory_id", `${values["attachmentCategory_id"]}`);
      if (file) {
        var reader = new FileReader();
        reader.readAsText(file);
        formData.append("file", file);
        formData.append(context, id ? id.toString() : "");
        formData.append("responsible_id", auth ? auth.data.id.toString() : "");
        await axios
          .post(`${ATTACHMENT_URL}`, formData)
          .then((res: { data: AxiosResponse<Attachment> }) => {
            updateAttachments(res.data.data);
            toast.success(intl.formatMessage({ id: "Anexo Guardado" }), toastStyles);
            formik.setFieldValue("file", undefined);
            handleClose();
            setSubmitting(false);
          })
          .catch((err: any) => {
            Object.keys(err.response.data.errors).forEach((key) => {
              formik.setFieldError(key, err.response.data.errors[key][0] ?? "undefined");
            });
            formik.setTouched(generalize(initialValues, true), false);
            setSubmitting(false);
          });
      } else {
        formik.setFieldError("file", "Field Required");
      }
    }
  });

  return (
    <>
      {/* begin::Add Clinic */}
      <button type="button" className="btn btn-primary" onClick={handleShow}>
        {intl.formatMessage({ id: "Anexar ficheiro" })}
      </button>
      {/* end::Add Clinic */}

      <Modal show={show} onHide={handleClose} size={"lg"}>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <h3 className="card-title text-primary">
              {intl.formatMessage({ id: "Anexar Ficheiro" })}
            </h3>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <WarningBlock
                show={formik.isValid}
                warning={"Campos obrigatórios (*), preencha os campos corretamente!"}
              />
              <div className="col-12 col-sm-6">
                <label className="form-label required" htmlFor={"files"}>
                  {intl.formatMessage({ id: "Selecionar Ficheiro" })}
                </label>
                <input
                  id={"files"}
                  type="file"
                  className={clsx("form-control", {
                    "is-invalid": formik.errors.file && formik.touched.file
                  })}
                  onClick={() => {
                    formik.setFieldTouched("file", true);
                  }}
                  onChange={(event) => {
                    if (event.target.files) {
                      setFile(event.target.files[0]);
                      formik.setFieldError("file", "");
                      formik.setFieldValue("file", "not null");
                    }
                  }}
                />
                <FormikErrors
                  errors={file == null ? "Field Required" : formik.errors.file ?? ""}
                  touched={formik.touched.file}
                />
              </div>
              <div className="col-12 col-sm-6">
                <CustomInput
                  required={true}
                  formikFields={formik.getFieldProps("description")}
                  label={"Descrição"}
                  type={"text"}
                  touched={formik.touched.description}
                  errors={formik.errors.description}
                  name={"description"}
                />
              </div>
              <div className="col-12 col-sm-6">
                <label className="form-label required">
                  {intl.formatMessage({ id: "Categoria do Ficheiro" })}
                </label>
                <div className="mb-5 w-100">
                  <Select
                    options={acs}
                    getOptionLabel={(category: AttachmentCategory) => category.name}
                    getOptionValue={(category: AttachmentCategory) =>
                      category?.id?.toString() ?? ""
                    }
                    {...formik.getFieldProps("attachmentCategory_id")}
                    value={acs.find((value) => {
                      return `${value.id}` == formik.values.attachmentCategory_id;
                    })}
                    onBlur={(event) => {
                      formik.setFieldTouched("attachmentCategory_id", true);
                      formik.setErrors({ ...formik.errors, attachmentCategory_id: undefined });
                    }}
                    onChange={(value) => {
                      formik.setFieldValue("attachmentCategory_id", `${value?.id}`);
                    }}
                    styles={customStyles(
                      menuMode,
                      formik.errors.attachmentCategory_id,
                      formik.touched.attachmentCategory_id
                    )}
                  />
                  <FormikErrors
                    errors={formik.errors.attachmentCategory_id}
                    touched={formik.touched.attachmentCategory_id}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <label className=" form-label">
                  {intl.formatMessage({ id: "Apenas para mim?" })}
                </label>
                <div className="form-check form-switch form-check-custom form-check-solid ">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value={"true"}
                    onChange={(value) => {
                      formik.setFieldValue("privacy", value.target.checked ? 1 : 0);
                    }}
                    id="flexSwitchDefault"
                  />
                  <label className="form-check-label" htmlFor="flexSwitchDefault">
                    {intl.formatMessage({ id: "Privado" })}
                  </label>
                </div>
              </div>
            </div>
          </Modal.Body>
          <CustomModalFooter
            isValid={formik.isValid}
            isSubmitting={formik.isSubmitting}
            handleClose={handleClose}
            closeLabel={"Close"}
            submitLabel={"Confirmar"}
          />
        </form>
      </Modal>
    </>
  );
}