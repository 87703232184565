import {useEffect, useMemo, useState} from 'react'
import {ColumnInstance, Row, useTable} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {ListLoading} from '../../../../_components/dataTable/loading/ListLoading'
import {KTCardBody, stringifyRequestQuery} from '../../../../../../_metronic/helpers'
import {emailTemplatesColumns} from './columns/_columns'
import {ClinicsEmailListPagination} from '../components/header/ClinicsEmailListPagination'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {useParams} from 'react-router-dom'
import axios, {AxiosResponse} from 'axios'
import {EMAIL_TEMPLATES_URL} from '../../../../../_const'
import {useIntl} from 'react-intl'
import {ClinicsEmailListSearchComponent} from '../components/header/ClinicsEmailListSearchComponent'
import {EmailTemplate, EmailTemplatesQueryResponse} from '../../../../../../models/_email'
import {SmtpSettingsModal} from '../components/modals/SmtpSettingsModal'
import {havePerm, havePerms} from '../../../../../_helpers'
import {useAuth} from '../../../../../modules/auth'

const ClinicsEmailTemplatesTable = () => {
  const {id} = useParams()
  const intl = useIntl()
  const {auth} = useAuth()
  const {state} = useQueryRequest()
  const [emails, setEmailTemplates] = useState<EmailTemplate[]>([])
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  const [isLoading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
    const fetchData = async () => {
      const res = await axios
        .get(`${EMAIL_TEMPLATES_URL}/${id}?${updatedQuery}`) /**/
        .then((d: AxiosResponse<EmailTemplatesQueryResponse>) => d.data)
      setEmailTemplates(res.data || [])
    }
    fetchData()
    setLoading(false)
  }, [updatedQuery])

  const columns = useMemo(() => emailTemplatesColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data: emails,
  })

  const updateList = (template: EmailTemplate) => {
    setEmailTemplates((current) =>
      current.map((obj) => {
        if (obj.subject === template.subject) {
          return template
        }
        return obj
      })
    )
  }

  return (
    <>
      <div className='card-header border-0 pt-6'>
        <ClinicsEmailListSearchComponent />
        <div className='card-toolbar'>
          {havePerm('smtps:update', auth) && <SmtpSettingsModal />}
        </div>
      </div>
      <KTCardBody className='py-4'>
        {!isLoading ? (
          <>
            <div className='table-responsive'>
              <table
                id='kt_table_users'
                className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                {...getTableProps()}
              >
                <thead>
                  <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                    {headers.map(
                      (column: ColumnInstance<EmailTemplate>) =>
                        (havePerms(['emailtemplates:update', 'emailtemplates:read'], auth) ||
                          column.id != 'actions') && (
                          <CustomHeaderColumn key={column.id} column={column} />
                        )
                    )}
                  </tr>
                </thead>
                <tbody className='text-gray-600' {...getTableBodyProps()}>
                  {rows.length > 0 ? (
                    rows.map((row: Row<EmailTemplate>, i) => {
                      prepareRow(row)
                      return (
                        <CustomRow
                          row={row}
                          key={`row-${i}-${row.id}`}
                          updateList={(template: EmailTemplate) => updateList(template)}
                        />
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={7}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                          {intl.formatMessage({id: 'Não foram encontrados resultados'})}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <ClinicsEmailListPagination />
          </>
        ) : (
          <ListLoading />
        )}
      </KTCardBody>
    </>
  )
}

export {ClinicsEmailTemplatesTable}
