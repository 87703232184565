// @ts-nocheck
import { Column } from "react-table";
import { PrescriptionCustomHeader } from "./PrescriptionCustomHeader";
import { Prescription } from "../../../../../../../models/_schedule";
import { ActionsCell } from "./ActionsCell";
import { CustomHeader } from "./CustomHeader";
import { StatusCell } from "./StatusCell";

const prescriptionsColumns: ReadonlyArray<Column<Prescription>> = [
    {
        Header: (props) => <PrescriptionCustomHeader tableProps={props} title='Medicamento' className='min-w-125px'/>,
        accessor: 'medication.name',
    },

    {
        Header: (props) => <PrescriptionCustomHeader tableProps={props} title='Data de inicio'
                                                     className='min-w-125px'/>,
        accessor: 'start_at',
    },
    {
        Header: (props) => <PrescriptionCustomHeader tableProps={props} title='Data de fim' className='min-w-125px'/>,
        accessor: 'end_at',
    },
    {
        Header: (props) => <PrescriptionCustomHeader tableProps={props} title='Dosagem' className='min-w-125px'/>,
        accessor: 'dosage',
    },
    {
        Header: (props) => <PrescriptionCustomHeader tableProps={props} title='Unidade' className='min-w-125px'/>,
        accessor: 'label_dosage_unit',
    },
    {
        Header: (props) => <PrescriptionCustomHeader tableProps={props} title='Estado' className='min-w-125px'/>,
        id: "status",
        Cell: ({...props}) => <StatusCell prescription={props.data[props.row.index]}/>,
    },
    {
        Header: (props) => <PrescriptionCustomHeader tableProps={props} title='Receitado por' className='min-w-125px'/>,
        accessor: 'responsible.full_name',
    },
    {
        Header: (props) => (
            <CustomHeader tableProps={props} title='Selecionar' className='text-end min-w-100px'/>
        ),
        id: 'actions',
        Cell: ({...props}) => <ActionsCell prescription={props.data[props.row.index]}/>,
    },
]

export {prescriptionsColumns}
