import {useEffect, useMemo, useState} from 'react'
import {ColumnInstance, Row, useTable} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {ListLoading} from '../../../../_components/dataTable/loading/ListLoading'
import {KTCardBody} from '../../../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import {PatientsListSearchComponent} from '../components/header/PatientsListSearchComponent'
import {PatientsListPagination} from '../components/header/PatientsListPagination'
import {patientsColumns} from './columns/_columns'
import {Patient} from '../../../../../../models/_patient'
import {CreatePatient} from '../modals/CreatePatient'

const PatientsTable = () => {
  const intl = useIntl()
  const patients = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => patients, [patients])
  const [rowsTable, setRowsTable] = useState(data)
  const columns = useMemo(() => patientsColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data: rowsTable,
  })
  useEffect(() => {
    setRowsTable(data)
  }, [data])
  return (
    <>
      <div className='card-header border-0 pt-6'>
        <PatientsListSearchComponent />
        {/* begin::Card toolbar */}
        <div className='card-toolbar'>
          <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
            <CreatePatient updatePatient={(patient) => setRowsTable([...rowsTable, patient])} />
          </div>
        </div>
        {/* end::Card toolbar */}
      </div>

      <KTCardBody className='py-4'>
        {!isLoading ? (
          <>
            <div className='table-responsive'>
              <table
                id='kt_table_users'
                className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                {...getTableProps()}
              >
                <thead>
                  <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                    {headers.map((column: ColumnInstance<Patient>) => (
                      <CustomHeaderColumn key={column.id} column={column} />
                    ))}
                  </tr>
                </thead>
                <tbody className='text-gray-600' {...getTableBodyProps()}>
                  {rows.length > 0 ? (
                    rows.map((row: Row<Patient>, i) => {
                      prepareRow(row)
                      return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                    })
                  ) : (
                    <tr>
                      <td colSpan={9}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                          {intl.formatMessage({id: 'Não foram encontrados resultados'})}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <PatientsListPagination />
          </>
        ) : (
          <ListLoading />
        )}
      </KTCardBody>
    </>
  )
}

export {PatientsTable}
