import React, {useEffect, useRef, useState} from 'react'
import axios from 'axios'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import CustomInput from '../../../../_components/CustomInput'
import {PROFESSION_URL} from '../../../../../_const'
import {toastStyles} from '../../../../../_styles'
import {Profession} from '../../../../../../models/_profession'
import {professionSchema} from '../../../../../../schemas/_profession'
import CustomModalHeader from '../../../../_components/modals/CustomModalHeader'
import WarningBlock from '../../../../_components/inputs/WarningBlock'

interface Props {
  updateList: (profession: Profession) => void
  profession: Profession
}

export function EditProfessionModal(props: Props) {
  const intl = useIntl()
  const [initialValues, setInitialValues] = useState<Profession>(props.profession)
  const closeBtn = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    const fetchData = () => {
      setInitialValues(props.profession)
    }
    fetchData()
  }, [props])

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: professionSchema,
    onSubmit: async (values, {setSubmitting}) => {
      await axios
        .patch(`${PROFESSION_URL}/${props.profession.id}`, values)
        .then((res) => {
          props.updateList(res.data.data)
          toast.success(intl.formatMessage({id: 'Profissão Editada'}), toastStyles)
          setSubmitting(false)
          closeBtn?.current?.click()
        })
        .catch((err: any) => {
          Object.keys(err.response.data.errors).forEach((key) => {
            formik.setFieldError(key, err.response.data.errors[key][0] ?? 'undefined')
          })
          setSubmitting(false)
        })
    },
  })

  return (
    <>
      <div className='modal fade' tabIndex={-1} id='kt_modal_edit_profession'>
        <div className='modal-dialog modal-md'>
          <div className='modal-content '>
            <CustomModalHeader title={'Editar Profissão'} />
            <form onSubmit={formik.handleSubmit}>
              <div className='modal-body'>
                <WarningBlock
                  show={formik.isValid}
                  warning={'Campos obrigatórios (*), preencha os campos corretamente!'}
                />
                <div className='row'>
                  <CustomInput
                    formikFields={formik.getFieldProps('name')}
                    label={'Profissão'}
                    type={'text'}
                    touched={formik.touched.name}
                    errors={formik.errors.name}
                    name={'name'}
                  />
                </div>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-light'
                  data-bs-dismiss='modal'
                  ref={closeBtn}
                >
                  {intl.formatMessage({id: 'Close'})}
                </button>
                <button
                  type='submit'
                  className='btn btn-primary'
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  {intl.formatMessage({id: 'Confirmar'})}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
