import axios, {AxiosResponse} from 'axios'
import {EMAIL_TEMPLATES_URL} from "../app/_const";
import {EmailTemplatesQueryResponse} from "../models/_email";


const getEmailTemplates = (query: string, id: string): Promise<EmailTemplatesQueryResponse> => {
    return axios
        .get(`${EMAIL_TEMPLATES_URL}/${id}?${query}`)/**/
        .then((d: AxiosResponse<EmailTemplatesQueryResponse>) => d.data)
}

export {getEmailTemplates}
