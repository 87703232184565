// @ts-nocheck
import { Column } from "react-table";
import { ProfessionCustomHeader } from "./ProfessionCustomHeader";
import { Profession } from "../../../../../../models/_profession";

const professionsColumns: ReadonlyArray<Column<Profession>> = [
  {
    Header: (props) => (
      <ProfessionCustomHeader tableProps={props} title='Profissão' className='min-w-125px' />
    ),
    accessor: 'name',
  },
  {
    Header: (props) => (
      <ProfessionCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'actions',
  },
]

export {professionsColumns}
