import * as Yup from "yup";

export const warningValuesSchema = Yup.object().shape({
    temp_min: Yup.number().nullable()
        .optional(),
    oxy_min: Yup.number().nullable()
        .optional(),
    pi_min: Yup.number().nullable()
        .optional(),
    pr_min: Yup.number().nullable()
        .optional(),
    qrs_min: Yup.number().nullable()
        .optional(),
    qt_min: Yup.number().nullable()
        .optional(),
    pvcs_min: Yup.number().nullable()
        .optional(),
    hr_min: Yup.number().nullable()
        .optional(),
    qtc_min: Yup.number().nullable()
        .optional(),
    sys_min: Yup.number().nullable()
        .optional(),
    dia_min: Yup.number().nullable()
        .optional(),
    dia_max: Yup.number().nullable()
        .optional(),
    sys_max: Yup.number().nullable()
        .optional(),
    oxy_max: Yup.number().nullable()
        .optional(),
    pi_max: Yup.number().nullable()
        .optional(),
    pr_max: Yup.number().nullable()
        .optional(),
    qrs_max: Yup.number().nullable()
        .optional(),
    qt_max: Yup.number().nullable()
        .optional(),
    pvcs_max: Yup.number().nullable()
        .optional(),
    hr_max: Yup.number().nullable()
        .optional(),
    qtc_max: Yup.number().nullable()
        .optional(),
    bs_max: Yup.number().nullable()
        .optional()
})