import React, {useEffect, useRef, useState} from 'react'
import axios from 'axios'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {specialtySchema} from '../../../../clinic/_model'
import CustomInput from '../../../../_components/CustomInput'
import {SPECIALTIES_URL} from '../../../../../_const'
import {toastStyles} from '../../../../../_styles'
import {Specialty} from '../../../../../../models/_specialty'
import WarningBlock from '../../../../_components/inputs/WarningBlock'
import Modal from 'react-bootstrap/Modal'
import CustomModalHeader from '../../../../_components/modals/CustomModalHeader'

interface Props {
  updateList: (specialty: Specialty) => void
  specialty: Specialty
}

export function EditSpecialtyModal(props: Props) {
  const intl = useIntl()
  const [initialValues, setInitialValues] = useState<Specialty>(props.specialty)
  const closeBtn = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    const fetchData = () => {
      setInitialValues(props.specialty)
    }
    fetchData()
  }, [props])

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: specialtySchema,
    onSubmit: async (values, {setSubmitting}) => {
      await axios
        .patch(`${SPECIALTIES_URL}/${props.specialty.id}`, values)
        .then((res) => {
          props.updateList(res.data)
          toast.success(intl.formatMessage({id: 'Especialidade Editada'}), toastStyles)
          setSubmitting(false)
          closeBtn?.current?.click()
        })
        .catch((err: any) => {
          Object.keys(err.response.data.errors).forEach((key) => {
            formik.setFieldError(key, err.response.data.errors[key][0] ?? 'undefined')
          })
          setSubmitting(false)
        })
    },
  })

  return (
    <>
      <div className='modal fade modal-lg' tabIndex={-1} id='kt_modal_edit_specialty'>
        <div className='modal-dialog modal-md'>
          <div className='modal-content '>
            <CustomModalHeader title={'Editar Especialidade'} />
            <form onSubmit={formik.handleSubmit}>
              <Modal.Body>
                <WarningBlock
                  show={formik.isValid}
                  warning={'Campos obrigatórios (*), preencha os campos corretamente!'}
                />
                <div className='row'>
                  <div className='col-9'>
                    <CustomInput
                      required={true}
                      formikFields={formik.getFieldProps('name')}
                      label={'Especialidade'}
                      type={'text'}
                      touched={formik.touched.name}
                      errors={formik.errors.name}
                      name={'name'}
                    />
                  </div>
                  <div className='col-3'>
                    <CustomInput
                      required={true}
                      formikFields={formik.getFieldProps('code')}
                      label={'Código'}
                      type={'text'}
                      touched={formik.touched.code}
                      errors={formik.errors.code}
                      name={'code'}
                    />
                  </div>
                </div>
              </Modal.Body>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-light'
                  data-bs-dismiss='modal'
                  ref={closeBtn}
                >
                  {intl.formatMessage({id: 'Close'})}
                </button>
                <button
                  type='submit'
                  className='btn btn-primary'
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  {intl.formatMessage({id: 'Confirmar'})}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
