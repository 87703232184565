import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import {useIntl} from 'react-intl'
import {customStyles, toastStyles} from '../../../../../_styles'
import {CLINICS_URL} from '../../../../../_const'
import {useFormik} from 'formik'
import axios, {AxiosResponse} from 'axios'
import {toast} from 'react-toastify'
import CustomInput from '../../../../../pages/_components/CustomInput'
import {Clinic} from '../../../../../../models/_clinic'
import Select from 'react-select'
import {useThemeMode} from '../../../../../../_metronic/partials'
import {Patient} from '../../../../../../models/_patient'
import {patientAssociateSchema} from '../../../../../../schemas/_patients'
import {generalize} from '../../../../../_helpers'
import FormikErrors from '../../../../_components/FormikErrors'
import CustomModalFooter from '../../../../_components/modals/CustomModalFooter'

interface Props {
  updatePatient: (patient: Patient) => void
}

export function CreatePatient({updatePatient}: Props) {
  const intl = useIntl()
  const {menuMode} = useThemeMode()
  const [show, setShow] = useState(false)
  const [clinics, setClinics] = useState<Clinic[]>([])

  const handleShow = () => setShow(true)
  const handleClose = () => {
    formik.resetForm()
    setShow(false)
  }

  useEffect(() => {
    const fetchData = async () => {
      const {data}: AxiosResponse<{data: Clinic[]}> = await axios.get(`${CLINICS_URL}/list`)
      setClinics(data.data)
    }
    fetchData()
  }, [])

  const initialValues = {
    username: '',
    email: '',
    full_name: '',
    mobilephone: '',
    clinic_default_id: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema: patientAssociateSchema,
    onSubmit: async (values, {setSubmitting}) => {
      await axios
        .post(`${CLINICS_URL}/associate-create-patient`, {
          ...values,
          clinic_default_id: values.clinic_default_id,
        })
        .then((res: AxiosResponse<{data: Patient}>) => {
          updatePatient({...res.data.data})
          toast.success(intl.formatMessage({id: 'Utente Criado'}), toastStyles)
          handleClose()
          setSubmitting(false)
        })
        .catch((err: any) => {
          setSubmitting(false)
          Object.keys(err.response.data.errors).forEach((key) => {
            formik.setFieldError(key, err.response.data.errors[key][0] ?? 'undefined')
          })
          formik.setTouched(generalize(initialValues, true), false)
          setSubmitting(false)
        })
    },
  })

  return (
    <>
      {/* begin::Add Clinic */}
      <button type='button' className='btn btn-primary' onClick={handleShow}>
        {intl.formatMessage({id: 'Criar Utente'})}
      </button>
      {/* end::Add Clinic */}

      <Modal show={show} onHide={handleClose} size={'lg'}>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Header closeButton>
            <h3 className='card-title text-primary'>{intl.formatMessage({id: 'Criar Utente'})}</h3>
          </Modal.Header>
          <Modal.Body>
            <div className='row'>
              <div className='col-6 '>
                <CustomInput
                  required={true}
                  formikFields={formik.getFieldProps('username')}
                  label={'Username'}
                  type={'text'}
                  touched={formik.touched.username}
                  errors={formik.errors.username}
                  name={'username'}
                />
                <CustomInput
                  required={true}
                  formikFields={formik.getFieldProps('email')}
                  label={'Email'}
                  type={'email'}
                  touched={formik.touched.email}
                  errors={formik.errors.email}
                  name={'email'}
                />
              </div>
              <div className='col-6'>
                <CustomInput
                  required={true}
                  formikFields={formik.getFieldProps('full_name')}
                  label={'Nome Completo'}
                  type={'text'}
                  touched={formik.touched.full_name}
                  errors={formik.errors.full_name}
                  name={'full_name'}
                />
                <CustomInput
                  required={true}
                  formikFields={formik.getFieldProps('mobilephone')}
                  label={'Telemóvel'}
                  type={'text'}
                  touched={formik.touched.mobilephone}
                  errors={formik.errors.mobilephone}
                  name={'mobilephone'}
                />
              </div>
              <div className='col-sm-6 col-12'>
                <div className='mb-5 w-100'>
                  <label className=' form-label required'>
                    {intl.formatMessage({id: 'Clinica'})}
                  </label>
                  <Select
                    options={clinics}
                    getOptionLabel={(clinic: Clinic) => clinic.name}
                    getOptionValue={(clinic: Clinic) => `${clinic.id}`}
                    {...formik.getFieldProps('clinic_id')}
                    value={clinics.find((value) => {
                      return `${value.id}` == formik.values.clinic_default_id
                    })}
                    onBlur={(event) => {
                      formik.setFieldTouched('clinic_default_id', true)
                      formik.setErrors({...formik.errors, clinic_default_id: undefined})
                    }}
                    onChange={(value) => {
                      formik.setFieldValue('clinic_default_id', `${value?.id}`)
                    }}
                    styles={customStyles(
                      menuMode,
                      formik.errors.clinic_default_id,
                      formik.touched.clinic_default_id
                    )}
                  />
                  <FormikErrors
                    errors={formik.errors.clinic_default_id}
                    touched={formik.touched.clinic_default_id}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <CustomModalFooter
            isValid={formik.isValid}
            isSubmitting={formik.isSubmitting}
            handleClose={handleClose}
            closeLabel={'Close'}
            submitLabel={'Confirmar'}
          />
        </form>
      </Modal>
    </>
  )
}
