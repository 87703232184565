import clsx from "clsx";
import { FC, PropsWithChildren, useMemo } from "react";
import { HeaderProps } from "react-table";
import { useQueryRequest } from "../../core/QueryRequestProvider";
import { useIntl } from "react-intl";
import { Brand } from "../../../../../../models/_brand";

type Props = {
    className?: string
    title?: string
    tableProps: PropsWithChildren<HeaderProps<Brand>>
}
const BrandsCustomHeader: FC<Props> = ({className, title, tableProps}) => {
    const id = tableProps.column.id
    const {state, updateState} = useQueryRequest()
    const intl = useIntl()

    const isSelectedForSorting = useMemo(() => {
        return state.sort && state.sort === id
    }, [state, id])
    const order: 'asc' | 'desc' | undefined = useMemo(() => state.order, [state])

    const sortColumn = () => {
        return
        /*// avoid sorting for these columns
        if (id === 'actions' || id === 'selection') {
            return
        }

        if (!isSelectedForSorting) {
            // enable sort asc
            updateState({sort: id, order: 'asc', ...initialQueryState})
            return
        }

        if (isSelectedForSorting && order !== undefined) {
            if (order === 'asc') {
                // enable sort desc
                updateState({sort: id, order: 'desc', ...initialQueryState})
                return
            }

            // disable sort
            updateState({sort: undefined, order: undefined, ...initialQueryState})
        }*/
    }

    return (
        <th
            {...tableProps.column.getHeaderProps()}
            className={clsx(
                className,
                isSelectedForSorting && order !== undefined && `table-sort-${order}`
            )}
            style={{cursor: "default"}}
            onClick={sortColumn}
        >
            {intl.formatMessage({id: title})}
        </th>
    )
}

export {BrandsCustomHeader}
