import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useIntl } from "react-intl";
import { useFormik } from "formik";
import axios, { AxiosResponse } from "axios";
import { SMTP_URL } from "../../../../../../_const";
import { toast } from "react-toastify";
import { customStyles, toastStyles } from "../../../../../../_styles";
import { Smtp } from "../../../../../../../models/_email";
import CustomInput from "../../../../../_components/CustomInput";
import { useParams } from "react-router-dom";
import { SmtpSchema } from "../../../../../../../schemas/_smtp";
import Select from "react-select";
import { useThemeMode } from "../../../../../../../_metronic/partials";
import CustomModalFooter from "../../../../../_components/modals/CustomModalFooter";
import { generalize } from "../../../../../../_helpers";
import { Clinic } from "../../../../../../../models/_clinic";
import WarningBlock from "../../../../../_components/inputs/WarningBlock";

/*type Props = {
    updateList: (contact: Contact) => void
}*/

type EncryptionOptions = {
  label: string
  value: string
}

const encryptionOptions: EncryptionOptions[] = [
  {
    label: "ssl",
    value: "ssl"
  },
  {
    label: "tls",
    value: "tls"
  },
  {
    label: "-",
    value: "-"
  }
];

export function SmtpSettingsModal(/*props: Props*/) {
  const intl = useIntl();
  const { id } = useParams();
  const [show, setShow] = useState(false);
  const [initialValues, setInitialValues] = useState({
    hostname: "",
    port: "",
    mail_username: "",
    ssl: "-",
    mail_password: "",
    general_email: "",
    general_name: "",
    clinic_id: "",
    clinic: {} as Clinic
  } as Smtp);
  const { menuMode } = useThemeMode();

  useEffect(() => {
    const fetch = async () => {
      const res = await axios
        .get(`${SMTP_URL}/${id}`) /**/
        .then((d: AxiosResponse<{ data: Smtp }>) => d.data);
      setInitialValues({ ...res.data, mail_password: "" });
    };

    if (show) fetch();
  }, [show]);

  const handleClose = () => {
    formik.resetForm();
    setShow(false);
  };
  const handleShow = () => {
    setShow(true);
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: !!initialValues.clinic_id,
    validationSchema: SmtpSchema,
    onSubmit: async (values, { setSubmitting }) => {
      await axios
        .post(`${SMTP_URL}`, { ...values, clinic_id: id })
        .then((res) => {
          toast.success(intl.formatMessage({ id: "Configuração Atualizada" }), toastStyles);
          handleClose();
          setSubmitting(false);
        })
        .catch((err: any) => {
          Object.keys(err.response.data.errors).forEach((key) => {
            formik.setFieldError(key, err.response.data.errors[key][0] ?? "undefined");
          });
          formik.setTouched(generalize(initialValues, true), false);
          setSubmitting(false);
        });
    }
  });

  return (
    <>
      <div className="d-flex justify-content-end" data-kt-user-table-toolbar="base">
        <button type="button" className="btn btn-primary" onClick={handleShow}>
          {intl.formatMessage({ id: "Configurações SMTP" })}
        </button>
      </div>
      <Modal size="lg" show={show} onHide={handleClose}>
        <form onSubmit={formik.handleSubmit} noValidate>
          <Modal.Header closeButton>
            <h3 className="card-title text-primary">
              {intl.formatMessage({ id: "Configurações SMTP" })}
            </h3>
          </Modal.Header>
          <Modal.Body>
            <h4>{intl.formatMessage({ id: "Configurações" })}</h4>
            <hr />
            <div className="row">
              <WarningBlock
                show={formik.isValid}
                warning={"Campos obrigatórios (*), preencha os campos corretamente!"}
              />
              <div className="col-sm-6 col-12">
                <CustomInput
                  required={true}
                  formikFields={formik.getFieldProps("hostname")}
                  label={"Hostname"}
                  type={"text"}
                  touched={formik.touched.hostname}
                  errors={formik.errors.hostname}
                  name={"hostname"}
                />
              </div>
              <div className="col-sm-6 col-12">
                <CustomInput
                  required={true}
                  formikFields={formik.getFieldProps("mail_username")}
                  label={"Username"}
                  type={"text"}
                  touched={formik.touched.mail_username}
                  errors={formik.errors.mail_username}
                  name={"mail_username"}
                />
              </div>
              <div className="col-sm-6 col-12">
                <CustomInput
                  required={true}
                  formikFields={formik.getFieldProps("mail_password")}
                  label={"Password"}
                  type={"password"}
                  touched={formik.touched.mail_password}
                  errors={formik.errors.mail_password}
                  name={"mail_password"}
                />
              </div>
              <div className="col-sm-6 col-12">
                <CustomInput
                  required={true}
                  formikFields={formik.getFieldProps("port")}
                  label={"SMTP Port"}
                  type={"number"}
                  touched={formik.touched.port}
                  errors={formik.errors.port}
                  name={"port"}
                />
              </div>
              <div className="col-sm-6 col-12">
                <label className=" form-label">{intl.formatMessage({ id: "Encriptação" })}</label>
                <Select
                  options={encryptionOptions}
                  placeholder={intl.formatMessage({ id: formik.values.ssl ?? "-" })}
                  getOptionLabel={(enc: EncryptionOptions) => intl.formatMessage({ id: enc.label })}
                  getOptionValue={(enc: EncryptionOptions) => enc.value}
                  isMulti={false}
                  backspaceRemovesValue={true}
                  onChange={(option) => {
                    formik.setFieldValue("ssl", option?.value);
                  }}
                  styles={customStyles(menuMode)}
                />
              </div>
            </div>

            <h4 className={"mt-3"}>{intl.formatMessage({ id: "Remetente" })}</h4>
            <hr />
            <div className="row">
              <div className="col-6">
                <CustomInput
                  required={true}
                  formikFields={formik.getFieldProps("general_email")}
                  label={"E-mail"}
                  type={"text"}
                  touched={formik.touched.general_email}
                  errors={formik.errors.general_email}
                  name={"general_email"}
                />
              </div>
              <div className="col-6">
                <CustomInput
                  required={true}
                  formikFields={formik.getFieldProps("general_name")}
                  label={"Username"}
                  type={"text"}
                  touched={formik.touched.general_name}
                  errors={formik.errors.general_name}
                  name={"general_name"}
                />
              </div>
            </div>
          </Modal.Body>
          <CustomModalFooter
            isValid={formik.isValid}
            isSubmitting={formik.isSubmitting}
            handleClose={handleClose}
            closeLabel={"Close"}
            submitLabel={"Confirmar"}
          />
        </form>
      </Modal>
    </>
  );
}