// @ts-nocheck
import {Column} from 'react-table'
import {Patient} from '../../../../../../../models/_patient'
import {PhotoCell} from '../../../../../_components/dataTable/cells/PhotoCell'
import {ClinicPatientsCustomHeader} from './ClinicPatientsCustomHeader'
import {RgpdStatus} from './RgpdStatus'
import {DevicesCustomHeader} from '../../../clinic-devices-list/devices-list/table/columns/DevicesCustomHeader'
import {CustomHeader} from '../../../../../patient/datatables/patients-list/table/columns/CustomHeader'
import {ActionsCell} from './ActionsCell'

const clinicPatientsColumns: ReadonlyArray<Column<Patient>> = [
  {
    Header: (props) => (
      <ClinicPatientsCustomHeader tableProps={props} title='Foto' className='min-w-125px' />
    ),
    accessor: 'photo',
    Cell: ({...props}) => <PhotoCell photo={props.data[props.row.index].photo} />,
  },
  {
    Header: (props) => (
      <ClinicPatientsCustomHeader tableProps={props} title='Nome' className='min-w-125px' />
    ),
    accessor: 'full_name',
  },
  {
    Header: (props) => (
      <ClinicPatientsCustomHeader tableProps={props} title='Email' className='min-w-125px' />
    ),
    accessor: 'email',
  },
  {
    Header: (props) => (
      <ClinicPatientsCustomHeader tableProps={props} title='Telemóvel' className='min-w-125px' />
    ),
    accessor: 'mobilephone',
  },
  {
    Header: (props) => (
      <ClinicPatientsCustomHeader tableProps={props} title='NISS' className='min-w-125px' />
    ),
    accessor: 'niss',
  },
  {
    Header: (props) => (
      <ClinicPatientsCustomHeader tableProps={props} title='NIF' className='min-w-125px' />
    ),
    accessor: 'tax_number',
  },
  {
    Header: (props) => (
      <ClinicPatientsCustomHeader tableProps={props} title='Cidade' className='min-w-125px' />
    ),
    accessor: 'city',
  },
  {
    Header: (props) => (
      <DevicesCustomHeader tableProps={props} title='RGPD' className='min-w-125px' />
    ),
    Cell: (props) => <RgpdStatus success={props.row.original.is_gdpr_accepted} />,
    accessor: 'is_gdpr_accepted',
  },
  {
    Header: (props) => (
      <CustomHeader tableProps={props} title='Selecionar' className='text-end min-w-100px' />
    ),
    id: 'actions',
    Cell: ({...props}) => <ActionsCell id={props.data[props.row.index].id} />,
  },
]

export {clinicPatientsColumns}
