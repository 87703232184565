import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useParams } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import { CLINICS_URL, USERS_URL } from "../../../_const";
import { Clinic } from "../../../../models/_clinic";
import { Patient } from "../../../../models/_patient";
import ToolBar from "../components/Toolbar";
import { useAuth } from "../../../modules/auth";
import { haveRole } from "../../../_helpers";

function PatientLayout() {
    const {id} = useParams()
    const {auth} = useAuth()
    const location = useLocation()
    const [clinic, setClinic] = useState<Clinic>({} as Clinic)
    const [patient, setPatient] = useState<Patient>({} as Patient)
    const [title, setTitle] = useState("Geral")
    const titles: { [key: string]: string } = {
        "/patients": "Tabela",
        "/patient/base/": "Dados Pessoais",
        "/patient/health/": "Ficha Clinica",
        "/patient/records/": "Registos",
        "/patient/records//ecgs": "Registos",
        "/patient/records//blood-pressure": "Registos",
        "/patient/records//blood-glucose": "Registos",
        "/patient/records//thermometers": "Registos",
        "/patient/records//oximeters": "Registos",
        "/patient/records//daily-check": "Registos",
        "/patient/medications/": "Medicações",
        "/patient/attachments/": "Anexos",
        "/patient/observations/": "Observações",

    }

    useEffect(() => {
        const fetchData = async () => {
            const ruser: AxiosResponse<{ data: Patient }> = await axios
                .get(`${USERS_URL}/${id}`)
            const rclinics: AxiosResponse<{ data: Clinic }> = await axios
                .get(`${CLINICS_URL}/${ruser.data.data.clinic_default_id}`)
            setClinic(rclinics.data.data)
            setPatient(ruser.data.data)
        }
        fetchData()
    }, [])

    useEffect(() => {
        let path = location.pathname.includes(`${id}`) ? location.pathname.replace(`${id}`, "") : location.pathname
        let tTitle = titles[path] ?? "Geral"
        setTitle(tTitle)
    }, [location])

    if (haveRole('patient', auth) && auth?.data.id != id) return <Navigate to={'error/404'}/>

    return (
        <>
            <ToolBar title={title} division="Pacientes" clinic={clinic} user={patient}/>
            <Outlet/>
        </>
    );
}

export default PatientLayout;
