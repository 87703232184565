/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {useAuth} from '../../../../../app/modules/auth'
import {MenuTitle} from './MenuTitle'
import {havePerm, havePerms, haveRole} from '../../../../../app/_helpers'

const SidebarMenuSuper = () => {
  const intl = useIntl()
  const {auth} = useAuth()

  return (
    <>
      {auth?.data?.role === 'super-admin' && <MenuTitle title={'Menu Super Administrador'} />}
      {auth?.data?.role === 'admin' && <MenuTitle title={'Menu Administrador'} />}
      {auth?.data?.role === 'collaborator' && <MenuTitle title={'Menu Colaborador'} />}
      {auth?.data?.role === 'patient' && <MenuTitle title={'Menu Paciente'} />}

      <SidebarMenuItem
        to='/dashboard'
        title={intl.formatMessage({id: 'Dashboard'})}
        fontIcon='fa-solid fa-chart-pie'
      />

      {havePerm('clinics:read', auth) && (
        <SidebarMenuItem
          to='/clinics'
          title={intl.formatMessage({id: 'Clínicas'})}
          fontIcon='fa-solid fa-hospital'
        />
      )}

      {havePerm('collaborators:read', auth) && (
        <SidebarMenuItem
          to='/collaborators'
          title={intl.formatMessage({id: 'Colaboradores'})}
          fontIcon='fa-solid fa-user-doctor'
        />
      )}
      {havePerm('patients:read', auth) && !haveRole('patient', auth) && (
        <SidebarMenuItem
          to='/patients'
          title={intl.formatMessage({id: 'Utentes'})}
          fontIcon='fa-solid fa-users'
        />
      )}
      {havePerm('devices:read', auth) && (
        <SidebarMenuItem
          to='/devices'
          title={intl.formatMessage({id: 'Dispositivos'})}
          fontIcon='fa-solid fa-mobile'
        />
      )}

      {havePerms(
        [
          'specialties:read',
          'pathologies:read',
          'attachmentcategories:read',
          'medicationtypes:read',
          'medications:read',
          'appointmentcategories:read',
          'appointmentpriorities:read',
          'relationships:read',
          'brands:read',
          'devicemodels:read',
        ],
        auth
      ) && (
        <SidebarMenuItemWithSub
          title={intl.formatMessage({id: 'Tabelas de Apoio'})}
          to='support-tables'
          fontIcon='fa-solid fa-table'
        >
          {havePerm('specialties:read', auth) && (
            <SidebarMenuItem
              to='/specialties'
              title={intl.formatMessage({id: 'Especialidades'})}
              hasBullet={true}
            />
          )}
          {havePerm('pathologytypes:read', auth) && (
            <SidebarMenuItem
              to='/pathologies'
              title={intl.formatMessage({id: 'Patologias'})}
              hasBullet={true}
            />
          )}
          {havePerm('medicationtypes:read', auth) && (
            <SidebarMenuItem
              to='/medication-types'
              title={intl.formatMessage({id: 'Tipo de Medicamento'})}
              hasBullet={true}
            />
          )}
          {havePerm('medications:read', auth) && !haveRole('patient', auth) && (
            <SidebarMenuItem
              to='/medications'
              title={intl.formatMessage({id: 'Medicamentos'})}
              hasBullet={true}
            />
          )}
          {havePerm('appointmentcategories:read', auth) && (
            <SidebarMenuItem
              to='/schedule-types'
              title={intl.formatMessage({id: 'Tipo de Marcação'})}
              hasBullet={true}
            />
          )}
          {havePerm('appointmentpriorities:read', auth) && (
            <SidebarMenuItem
              to='/priorities'
              title={intl.formatMessage({id: 'Prioridades'})}
              hasBullet={true}
            />
          )}
          {havePerm('relationships:read', auth) && (
            <SidebarMenuItem
              to='/relationships'
              title={intl.formatMessage({id: 'Grau de Parentesco'})}
              hasBullet={true}
            />
          )}
          {havePerm('attachmentcategories:read', auth) && (
            <SidebarMenuItem
              to='/attachment-types'
              title={intl.formatMessage({id: 'Tipo de Anexos'})}
              hasBullet={true}
            />
          )}
          {havePerm('brands:read', auth) && (
            <SidebarMenuItem
              to='/brands'
              title={intl.formatMessage({id: 'Marcas'})}
              hasBullet={true}
            />
          )}
          {havePerm('devicemodels:read', auth) && (
            <SidebarMenuItem
              to='/device-models'
              title={intl.formatMessage({id: 'Modelos'})}
              hasBullet={true}
            />
          )}
          {havePerm('functionroles:read', auth) && (
            <SidebarMenuItem
              to='/function-roles'
              title={intl.formatMessage({id: 'Funções dos Contactos'})}
              hasBullet={true}
            />
          )}
          {havePerm('professions:read', auth) && (
            <SidebarMenuItem
              to='/professions'
              title={intl.formatMessage({id: 'Profissões'})}
              hasBullet={true}
            />
          )}
        </SidebarMenuItemWithSub>
      )}

      {havePerms(['emailtemplates:read', 'smtps:read', 'permissions:read'], auth) && (
        <SidebarMenuItemWithSub
          title={intl.formatMessage({id: 'Definições'})}
          to='settings'
          fontIcon='fa-solid fa-gear'
        >
          {havePerms(['emailtemplates:read', 'smtps:read'], auth) && (
            <SidebarMenuItem
              to='/mail-settings'
              title={intl.formatMessage({id: 'Mail'})}
              hasBullet={true}
            />
          )}

          {(haveRole('super-admin', auth) || haveRole('admin', auth)) && (
            <SidebarMenuItem
              to='/roles'
              title={intl.formatMessage({id: 'Permissões'})}
              hasBullet={true}
            />
          )}
        </SidebarMenuItemWithSub>
      )}
      {haveRole('patient', auth) && (
        <SidebarMenuItemWithSub
          title={intl.formatMessage({id: 'Os meus dados'})}
          to='settings'
          fontIcon='fa-solid fa-user'
        >
          {havePerm('medicalhealthrecords:read', auth) && (
            <SidebarMenuItem
              to={'/patient/base/' + auth?.data.id}
              title={intl.formatMessage({id: 'Ficha Base'})}
              fontIcon='fa-solid fa-clipboard'
            />
          )}

          {havePerm('medicalhealthrecords:read', auth) && (
            <SidebarMenuItem
              to={'/patient/health/' + auth?.data.id}
              title={intl.formatMessage({id: 'Ficha Clinica'})}
              fontIcon='fa-solid fa-heart-pulse'
            />
          )}
          {havePerms(
            [
              'ecgs:read',
              'bloodglucoses:read',
              'bloodpressures:read',
              'dailychecks:read',
              'oximeters:read',
              'thermometers:read',
            ],
            auth
          ) && (
            <SidebarMenuItem
              to={'/patient/records/' + auth?.data.id + '/ecgs'}
              title={intl.formatMessage({id: 'Registos'})}
              fontIcon='fa-solid fa-list '
            />
          )}
          {havePerm('medications:read', auth) && (
            <SidebarMenuItem
              to={'/patient/medications/' + auth?.data.id}
              title={intl.formatMessage({id: 'Medicação'})}
              fontIcon='fa-solid fa-pills'
            />
          )}
          {havePerm('observations:read', auth) && (
            <SidebarMenuItem
              to={'/patient/observations/' + auth?.data.id}
              title={intl.formatMessage({id: 'Observações'})}
              fontIcon='fa-solid fa-file-lines'
            />
          )}
          {havePerm('attachments:read', auth) && (
            <SidebarMenuItem
              to={'/patient/attachments/' + auth?.data.id}
              title={intl.formatMessage({id: 'Anexos'})}
              fontIcon='fa-solid fa-paperclip '
            />
          )}
          {havePerm('aggregates:read', auth) && (
            <SidebarMenuItem
              to={'/patient/aggregate/' + auth?.data.id}
              title={intl.formatMessage({id: 'Agregado Familiar'})}
              fontIcon='fa-solid fa-people-roof'
            />
          )}
        </SidebarMenuItemWithSub>
      )}
    </>
  )
}

export {SidebarMenuSuper}
