import axios, {AxiosResponse} from 'axios'
import {BRANDS_URL} from "../app/_const";
import {BrandsQueryResponse} from "../models/_brand";


const getBrands = (query: string, id: string): Promise<BrandsQueryResponse> => {
    return axios
        .get(`${BRANDS_URL}?${query}`)
        .then((d: AxiosResponse<BrandsQueryResponse>) => d.data)
}

export {getBrands}
