import axios, {AxiosResponse} from 'axios'
import {ClINICS_PATIENT_URL, USERS_URL} from '../app/_const'
import {PatientQueryResponse} from '../models/_patient'

const getPatients = (query: string, id: string): Promise<PatientQueryResponse> => {
  return axios
    .get(`${ClINICS_PATIENT_URL}/${id}?${query}`) /**/
    .then((d: AxiosResponse<PatientQueryResponse>) => d.data)
}

const getAllPatients = (query: string): Promise<PatientQueryResponse> => {
  return axios
    .get(`${USERS_URL}/my-patients-paginated?${query}`) /**/
    .then((d: AxiosResponse<PatientQueryResponse>) => d.data)
}

export {getPatients, getAllPatients}
