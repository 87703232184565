import axios, {AxiosResponse} from 'axios'
import {PATHOLOGIES_URL} from "../app/_const";
import {PathologysQueryResponse} from "../models/_pathology";


const getPathologies = (query: string, id: string): Promise<PathologysQueryResponse> => {
    return axios
        .get(`${PATHOLOGIES_URL}?${query}`)/**/
        .then((d: AxiosResponse<PathologysQueryResponse>) => d.data)
}

export {getPathologies}
