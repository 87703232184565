import React, {useEffect, useState} from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import {EventClickArg} from '@fullcalendar/react'
import {useIntl} from 'react-intl'
import clsx from 'clsx'
import {API_IMG_URL, APPOINTMENT_URL} from '../../../_const'
import {dateToString} from '../../../_helpers'
import axios from 'axios'
import Select from 'react-select'
import {customStyles} from '../../../_styles'
import {useThemeMode} from '../../../../_metronic/partials'
import {Appointment} from '../../../../models/_appointment'

interface Props {
  open: boolean
  updateOpen: () => void
  updateEvents: (appointment: Appointment) => void
  event: EventClickArg
}

const states = [
  {value: 'Done', label: 'Finalizada'},
  {value: 'Failed', label: 'Sem presença'},
  {value: 'Cancelled', label: 'Cancelada'},
]

export function AppointmentModal({open, updateOpen, event, updateEvents}: Props) {
  const intl = useIntl()
  const [show, setShow] = useState(false)
  const [state, setState] = useState('')
  const {menuMode} = useThemeMode()
  const [appointment, setAppointment] = useState({} as Appointment)

  useEffect(() => {
    setShow(open)
    if (open) setAppointment(event.event._def.extendedProps as Appointment)
  }, [open])

  const handleClose = () => {
    updateOpen()
    setShow(false)
  }
  const handleShow = () => setShow(true)

  const changeStatus = () => {
    const fetch = async () => {
      await axios.patch(`${APPOINTMENT_URL}/${appointment.id}`, {
        subject: appointment.subject,
        location: appointment.location,
        observation: appointment.observation,
        date_begin: appointment.date_begin,
        date_end: appointment.date_end,
        user_id: appointment.user_id,
        state: state,
        appointmentCategory_id: appointment.appointmentCategory_id,
        appointmentPriority_id: appointment.appointmentPriority_id,
        responsible_id: appointment.responsible_id,
      })
    }
    fetch()

    updateEvents({...appointment, state: state})
    handleClose()
  }

  const renderState = () => {
    switch (appointment.state) {
      case 'Waiting':
        return (
          <b>
            {intl.formatMessage({id: 'Estado'})}:{' '}
            <span className='badge badge-light-warning'>
              {intl.formatMessage({id: 'Em Espera'})}
            </span>
          </b>
        )
      case 'Done':
        return (
          <b>
            {intl.formatMessage({id: 'Estado'})}:{' '}
            <span className='badge badge-light-success'>{intl.formatMessage({id: 'Ocorreu'})}</span>
          </b>
        )
      case 'Failed':
        return (
          <b>
            {intl.formatMessage({id: 'Estado'})}:{' '}
            <span className='badge badge-light-danger'>
              {intl.formatMessage({id: 'Não Ocorreu'})}
            </span>
          </b>
        )
      default:
        return (
          <b>
            {intl.formatMessage({id: 'Estado'})}:{' '}
            <span className='badge badge-light-primary'>
              {intl.formatMessage({id: 'Cancelado'})}
            </span>
          </b>
        )
    }
  }

  return (
    <>
      {show && appointment.user && (
        <Modal show={show} onHide={handleClose} size={'lg'}>
          <Modal.Header closeButton>
            <Modal.Title>
              <h3 className='card-title text-primary'>{intl.formatMessage({id: 'Agendamento'})}</h3>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h3>{appointment.user.full_name}</h3>
            <div className='row'>
              <div className='col-12 col-sm-6'>
                <div className='d-flex justify-content-start mt-3'>
                  <div className={clsx('symbol', 'symbol-50px symbol-md-75px')}>
                    <img src={API_IMG_URL + '/' + appointment.user.photo} alt='' />
                  </div>
                  <div className='d-flex flex-column ms-2'>
                    <span>
                      <b>{intl.formatMessage({id: 'Numero de Utente'})}: </b>
                      <span>
                        {appointment.user.sns_number ??
                          intl.formatMessage({
                            id: 'null',
                          })}
                      </span>
                    </span>
                    <span>
                      <b>{intl.formatMessage({id: 'Género'})}: </b>
                      <span>
                        {intl.formatMessage({
                          id: appointment.user.gender ?? 'null',
                        })}
                      </span>
                    </span>
                    <span>
                      <b>{intl.formatMessage({id: 'Data de Nascimento'})}: </b>
                      <span>
                        {appointment.user.birthday ??
                          intl.formatMessage({
                            id: 'null',
                          })}
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div className='col-12 col-sm-6'>
                <div className='w-100 d-flex flex-sm flex-end mt-3'>
                  <div className='d-flex flex-column'>
                    {renderState()}
                    <span>
                      <b>{intl.formatMessage({id: 'Categoria'})}: </b>
                      <span>{appointment.appointment_category.name}</span>
                    </span>
                    <span>
                      <b>{intl.formatMessage({id: 'Prioridade'})}: </b>
                      <span>{appointment.appointment_priority.name}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <h3 className={'mt-5'}>{intl.formatMessage({id: 'Informações da Consulta'})}</h3>
            <div className='row'>
              <div className='col-6'>
                <div className='d-flex justify-content-start mt-3'>
                  <div className='d-flex flex-column ms-2'>
                    <span>
                      <b>{intl.formatMessage({id: 'Assunto'})}: </b>
                      <span>{appointment.subject}</span>
                    </span>
                    <span>
                      <b>{intl.formatMessage({id: 'Local'})}: </b>
                      <span>{appointment.location}</span>
                    </span>
                    <span>
                      <b>{intl.formatMessage({id: 'Observação'})}: </b>
                      <span>{appointment.observation}</span>
                    </span>
                    <span>
                      <b>{intl.formatMessage({id: 'Responsável'})}: </b>
                      <span>{appointment.responsible.full_name}</span>
                    </span>
                  </div>
                </div>
              </div>
              <div className='col-6'>
                <div className='w-100 d-flex flex-start mt-3'>
                  <div className='d-flex flex-column'>
                    <span>
                      <b>{intl.formatMessage({id: 'Data de Inicio'})}: </b>
                      <span>{dateToString(event.event.start ?? new Date())}</span>
                    </span>
                    <span>
                      <b>{intl.formatMessage({id: 'Data de Término'})}: </b>
                      <span>{dateToString(event.event.end ?? new Date())}</span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {appointment.state == 'Waiting' && (
              <>
                <h3 className={'mt-5'}>{intl.formatMessage({id: 'Marcar como:'})}</h3>
                <div className='row'>
                  <div className='col-12 col-sm-6'>
                    <Select
                      options={states}
                      placeholder={intl.formatMessage({id: 'Selecionar...'})}
                      getOptionLabel={(option: {value: string; label: string}) => option.label}
                      getOptionValue={(option: {value: string; label: string}) => option.value}
                      onChange={(newValue: any) => setState(newValue.value)}
                      styles={customStyles(menuMode)}
                    />
                    <Button
                      className={'btn btn-sm mt-3'}
                      variant='primary'
                      disabled={state == ''}
                      onClick={() => {
                        changeStatus()
                      }}
                    >
                      {intl.formatMessage({id: 'Atualizar'})}
                    </Button>
                  </div>
                </div>
              </>
            )}
          </Modal.Body>
        </Modal>
      )}
    </>
  )
}
