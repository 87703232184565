// @ts-nocheck
import clsx from "clsx";
import { FC } from "react";
import { Row } from "react-table";
import { EmailTemplate } from "../../../../../../../models/_email";
import { TemplateMailModal } from "../../components/modals/TemplateMailModal";
import { useAuth } from "../../../../../../modules/auth";
import { havePerms } from "../../../../../../_helpers";

type Props = {
    row: Row<EmailTemplate>
    updateList: (template: EmailTemplate) => void
}

const CustomRow: FC<Props> = ({row, updateList}) => {
    const {auth} = useAuth()

    return (
        <tr {...row.getRowProps()}>
            {row.cells.map((cell) => {

                return (
                    (havePerms(["emailtemplates:update", "emailtemplates:read"], auth) || column.id != "actions") &&
                    <td
                        {...cell.getCellProps()}
                        className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}
                    >
                        {cell.column.id !== 'actions' ? cell.render('Cell') : <>
                            <TemplateMailModal template={row.original} updateList={updateList}/>
                        </>
                        }
                    </td>
                )
            })}
        </tr>
    )
}

export {CustomRow}
