import React, {useEffect, useRef, useState} from 'react'
import axios, {AxiosResponse} from 'axios'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import {relativeSchema} from '../../../../../../../schemas/_relative'
import {PROFESSION_URL, RELATIONSHIPS_URL, RELATIVES_URL} from '../../../../../../_const'
import {customStyles, toastStyles} from '../../../../../../_styles'
import CustomInput from '../../../../../_components/CustomInput'
import {Relative} from '../../../../../../../models/_relative'
import Modal from 'react-bootstrap/Modal'
import Select from 'react-select'
import {Profession} from '../../../../../../../models/_profession'
import {Relationship} from '../../../../../../../models/_relationship'
import FormikTextArea from '../../../../../_components/FormikTextArea'
import {useThemeMode} from '../../../../../../../_metronic/partials'
import CustomModalHeader from '../../../../../_components/modals/CustomModalHeader'
import FormikErrors from '../../../../../_components/FormikErrors'

interface Props {
  updateList: (relative: Relative) => void
  relative: Relative
}

export function EditRelativeModal(props: Props) {
  const intl = useIntl()

  const [initialValues, setInitialValues] = useState<Relative>(props.relative)
  const [relationships, setRelationships] = useState<Relationship[]>([])
  const [professions, setProfessions] = useState<Profession[]>([])

  const {menuMode} = useThemeMode()

  const closeBtn = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    const fetch = async () => {
      setInitialValues(props.relative)
      const response = await axios
        .get(`${PROFESSION_URL}`)
        .then((d: AxiosResponse<{data: Profession[]}>) => d.data)
      setProfessions(response.data)
      const res = await axios
        .get(`${RELATIONSHIPS_URL}`)
        .then((d: AxiosResponse<{data: Relationship[]}>) => d.data)
      setRelationships(res.data)
    }
    fetch()
  }, [props.relative])

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: relativeSchema,
    onSubmit: async (values, {setSubmitting}) => {
      await axios
        .patch(`${RELATIVES_URL}/${props.relative.id}`, values)
        .then((res) => {
          props.updateList(res.data.data)
          toast.success(intl.formatMessage({id: 'Parente Editada'}), toastStyles)
          setSubmitting(false)
          closeBtn?.current?.click()
        })
        .catch((err) => {
          Object.keys(err.response.data.errors).forEach((key) => {
            formik.setFieldError(key, err.response.data.errors[key][0] ?? 'undefined')
          })

          setSubmitting(false)
        })
    },
  })

  return (
    <>
      <div className='modal fade' tabIndex={-1} id='kt_modal_edit_relative'>
        <div className='modal-dialog modal-lg'>
          <div className='modal-content '>
            <CustomModalHeader title={'Editar Parente'} />
            <form onSubmit={formik.handleSubmit}>
              <Modal.Body>
                <div className='row'>
                  <div className='col-12 col-sm-6'>
                    <CustomInput
                      required={true}
                      formikFields={formik.getFieldProps('name')}
                      label={'Nome'}
                      type={'text'}
                      touched={formik.touched.name}
                      errors={formik.errors.name}
                      name={'name'}
                    />
                    <CustomInput
                      required={true}
                      formikFields={formik.getFieldProps('email')}
                      label={'Email'}
                      type={'email'}
                      touched={formik.touched.email}
                      errors={formik.errors.email}
                      name={'email'}
                    />
                    <CustomInput
                      required={true}
                      formikFields={formik.getFieldProps('niss')}
                      label={'NISS'}
                      type={'text'}
                      touched={formik.touched.niss}
                      errors={formik.errors.niss}
                      name={'niss'}
                    />
                    <div className='mb-5 w-100'>
                      <label className=' form-label'>{intl.formatMessage({id: 'Profissão'})}</label>
                      <Select
                        options={professions}
                        getOptionLabel={(profession: Profession) => profession.name}
                        getOptionValue={(profession: Profession) => `${profession.id}`}
                        {...formik.getFieldProps('profession_id')}
                        value={professions.find((value) => {
                          return `${value.id}` == formik.values.profession_id
                        })}
                        onBlur={(event) => {
                          formik.setFieldTouched('profession_id', true)
                          formik.setErrors({...formik.errors, profession_id: undefined})
                        }}
                        onChange={(value) => {
                          formik.setFieldValue('profession_id', `${value?.id}`)
                        }}
                        styles={customStyles(
                          menuMode,
                          formik.errors.profession_id,
                          formik.touched.profession_id
                        )}
                      />
                      <FormikErrors
                        errors={formik.errors.profession_id}
                        touched={formik.touched.profession_id}
                      />
                    </div>
                  </div>
                  <div className='col-12 col-sm-6'>
                    <CustomInput
                      required={true}
                      formikFields={formik.getFieldProps('phone')}
                      label={'Telefone'}
                      type={'text'}
                      touched={formik.touched.phone}
                      errors={formik.errors.phone}
                      name={'phone'}
                    />
                    <CustomInput
                      required={true}
                      formikFields={formik.getFieldProps('mobile_phone')}
                      label={'Telemóvel'}
                      type={'text'}
                      touched={formik.touched.mobile_phone}
                      errors={formik.errors.mobile_phone}
                      name={'mobile_phone'}
                    />
                    <CustomInput
                      required={true}
                      formikFields={formik.getFieldProps('qualifications')}
                      label={'Qualificações'}
                      type={'text'}
                      touched={formik.touched.qualifications}
                      errors={formik.errors.qualifications}
                      name={'qualifications'}
                    />
                    <div className='mb-5 w-100'>
                      <label className=' form-label'>
                        {intl.formatMessage({id: 'Grau de Parentesco'})}
                      </label>
                      <Select
                        options={relationships}
                        getOptionLabel={(relationship: Relationship) => relationship.name}
                        getOptionValue={(relationship: Relationship) => `${relationship.id}`}
                        {...formik.getFieldProps('relationship_id')}
                        value={relationships.find((value) => {
                          return `${value.id}` == formik.values.relationship_id
                        })}
                        onBlur={(event) => {
                          formik.setFieldTouched('relationship_id', true)
                          formik.setErrors({...formik.errors, relationship_id: undefined})
                        }}
                        onChange={(value) => {
                          formik.setFieldValue('relationship_id', `${value?.id}`)
                        }}
                        styles={customStyles(
                          menuMode,
                          formik.errors.relationship_id,
                          formik.touched.relationship_id
                        )}
                      />
                      <FormikErrors
                        errors={formik.errors.relationship_id}
                        touched={formik.touched.relationship_id}
                      />
                    </div>
                  </div>
                  <FormikTextArea
                    formikFields={formik.getFieldProps('notes')}
                    label={'Observações'}
                    touched={formik.touched.notes}
                    errors={formik.errors.notes}
                    name={'notes'}
                  />
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  type='button'
                  className='btn btn-light'
                  data-bs-dismiss='modal'
                  ref={closeBtn}
                >
                  {intl.formatMessage({id: 'Close'})}
                </button>
                <button
                  type='submit'
                  className='btn btn-primary'
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  {intl.formatMessage({id: 'Confirmar'})}
                </button>
              </Modal.Footer>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
