import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useIntl } from "react-intl";
import { useFormik } from "formik";
import axios, { AxiosResponse } from "axios";
import { BRANDS_URL } from "../../../../../_const";
import { toast } from "react-toastify";
import { toastStyles } from "../../../../../_styles";
import CustomInput from "../../../../_components/CustomInput";
import { Brand } from "../../../../../../models/_brand";
import { brandSchema } from "../../../../../../schemas/_brands";
import CustomModalFooter from "../../../../_components/modals/CustomModalFooter";
import FormikTextArea from "../../../../_components/FormikTextArea";
import WarningBlock from "../../../../_components/inputs/WarningBlock";
import { generalize } from "../../../../../_helpers";

interface Props {
  updateTable: (brand: Brand) => void
}

export function AddBrandModal({ updateTable }: Props) {
  const intl = useIntl()
  const [show, setShow] = useState(false)

  const handleClose = () => {
    formik.resetForm()
    setShow(false)
  }
  const handleShow = () => setShow(true)

  const initialValues = { name: '', observation: '' }

  const formik = useFormik({
    initialValues,
    validationSchema: brandSchema,
    onSubmit: async (values, { setSubmitting }) => {
      await axios
        .post(`${BRANDS_URL}`, values)
        .then((res: AxiosResponse<{ data: Brand }>) => {
          updateTable(res.data.data)
          toast.success(intl.formatMessage({ id: 'Marca Criada' }), toastStyles)
          handleClose()
          setSubmitting(false)
        })
        .catch((err: any) => {
          Object.keys(err.response.data.errors).forEach((key) => {
            formik.setFieldError(key, err.response.data.errors[key][0] ?? 'undefined')
          })
          formik.setTouched(generalize(initialValues, true), false)
          setSubmitting(false)
        })
    },
  })

  return (
    <>
      <button type='button' className='btn btn-primary' onClick={handleShow}>
        {intl.formatMessage({ id: 'Criar Marca' })}
      </button>

      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <h3 className='card-title text-primary'>{intl.formatMessage({ id: 'Criar Marca' })}</h3>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <WarningBlock
              show={formik.isValid}
              warning={'Campos obrigatórios (*), preencha os campos corretamente!'}
            />
            <div className='row'>
              <CustomInput
                required={true}
                formikFields={formik.getFieldProps('name')}
                label={'Marca'}
                type={'text'}
                touched={formik.touched.name}
                errors={formik.errors.name}
                name={'name'}
              />
              <FormikTextArea
                formikFields={formik.getFieldProps('observation')}
                label={'Observação'}
                touched={formik.touched.observation}
                errors={formik.errors.observation}
                name={'observation'}
              />
            </div>
          </Modal.Body>
          <CustomModalFooter
            isValid={formik.isValid}
            isSubmitting={formik.isSubmitting}
            handleClose={handleClose}
            closeLabel={'Close'}
            submitLabel={'Confirmar'}
          />
        </form>
      </Modal>
    </>
  )
}