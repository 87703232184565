import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import axios, {AxiosResponse} from 'axios'
import {API_URL, PATHOLOGIES_URL} from '../../../_const'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {customStyles, toastStyles} from '../../../_styles'
import {medicalHealthRecordsSchema} from '../../../../schemas/_medicalHealthRecord'
import {MedicalHealthRecord} from '../../../../models/_medicalHealthRecord'
import Select from 'react-select'
import {useThemeMode} from '../../../../_metronic/partials'
import {BloodGroup} from '../../../../models/_bloodGroup'
import FormikTextArea from '../../_components/FormikTextArea'
import {toast} from 'react-toastify'
import {Pathology, PathologysQueryResponse} from '../../../../models/_pathology'
import {havePerm} from '../../../_helpers'
import {useAuth} from '../../../modules/auth'
import CustomInput from '../../_components/CustomInput'

function MedicalHealthRecordPage() {
  const intl = useIntl()
  const {id} = useParams()
  const {menuMode} = useThemeMode()
  const [initialValues, setinitialValues] = useState<MedicalHealthRecord>({} as MedicalHealthRecord)
  const [bloodGroups, setBloodGroups] = useState<BloodGroup[]>([])
  const [pathologies, setPathologies] = useState<Pathology[]>([])
  const {auth} = useAuth()
  const perm = havePerm('medicalhealthrecords:update', auth)

  useEffect(() => {
    const fetchData = async () => {
      const resIv: AxiosResponse<{data: MedicalHealthRecord}> = await axios.get(
        `${API_URL}/medical-health-records/${id}`
      )

      setinitialValues(resIv.data.data)
      const resBg: AxiosResponse<BloodGroup[]> = await axios.get(`${API_URL}/blood-groups`)
      setBloodGroups(resBg.data)
      if (havePerm('pathologytypes:read', auth)) {
        const resp = await axios
          .get(`${PATHOLOGIES_URL}`) /**/
          .then((d: AxiosResponse<PathologysQueryResponse>) => d.data)
        setPathologies(resp.data || [])
      }
    }
    fetchData()
  }, [])

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: medicalHealthRecordsSchema,
    onSubmit: async (values, {setSubmitting}) => {
      await axios
        .post(`${API_URL}/medical-health-records`, {
          ...values,
          user_id: id,
        })
        .then((res: AxiosResponse<{data: any}>) => {
          toast.success(intl.formatMessage({id: 'Ficha Clinica Guardada'}), toastStyles)
        })
        .catch((err) => {
          toast.error(intl.formatMessage({id: err.response.data.message}), toastStyles)
        })
    },
  })

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className='card card-custom'>
          <div className='card-header'>
            <h3 className='card-title text-primary'>
              {intl.formatMessage({id: 'Ficha Clinica Base'})}
            </h3>
            <div className='card-toolbar'>
              {perm && (
                <button type='submit' className='btn btn-primary btn-sm'>
                  {intl.formatMessage({id: 'Guardar'})}
                </button>
              )}
            </div>
          </div>
          <div className='card-body'>
            <div className='row'>
              <div className='col-12'>
                <h4>{intl.formatMessage({id: 'Descrição do Paciente'})}</h4>
                <hr />
                <div className='w-25'>
                  <CustomInput
                    disable={perm}
                    formikFields={formik.getFieldProps('height')}
                    label={'Peso'}
                    type={'number'}
                    touched={formik.touched.height}
                    errors={formik.errors.height}
                    name={'height'}
                  />
                </div>

                <FormikTextArea
                  disable={perm}
                  formikFields={formik.getFieldProps('description')}
                  label={'Descrição'}
                  touched={formik.touched.description}
                  errors={formik.errors.description}
                  name={'description'}
                />
              </div>
              <div className='col-12 div col-md-4'>
                <h4>{intl.formatMessage({id: 'Grupo Sanguineo'})}</h4>
                <hr />
                <div className='mb-5 w-100'>
                  <Select
                    isDisabled={!perm}
                    options={bloodGroups}
                    placeholder={
                      formik.values.blood_group ? formik.values.blood_group.name : 'Selecionar'
                    }
                    getOptionLabel={(bg: BloodGroup) => bg.name}
                    getOptionValue={(bg: BloodGroup) => `${bg.id}`}
                    isMulti={false}
                    backspaceRemovesValue={true}
                    onChange={(option) => {
                      formik.setFieldValue('blood_group_id', option ? option.id : 1)
                    }}
                    styles={customStyles(menuMode)}
                  />
                </div>
                <h4>{intl.formatMessage({id: 'Patologias'})}</h4>
                <hr />
                <div className='row'>
                  {pathologies.map((element) => {
                    return (
                      <div className='col-12 col-md-6 mb-2' key={`${element.id}_div`}>
                        <div className='form-check form-check-custom form-check-solid'>
                          <input
                            disabled={!perm}
                            key={`${element.id}_checkbox`}
                            checked={formik.values.pathologies.includes(`${element.id}`)}
                            className='form-check-input'
                            type='checkbox'
                            value={element.id}
                            onChange={formik.handleChange}
                            name={'pathologies'}
                            id={`${element.id}`}
                          />
                          <label
                            key={`${element.id}_label`}
                            className='form-check-label'
                            htmlFor={`${element.id}`}
                          >
                            {element.name}
                          </label>
                        </div>
                      </div>
                    )
                  })}
                </div>
                <FormikTextArea
                  disable={perm}
                  formikFields={formik.getFieldProps('another_pathologies')}
                  label={'Outras Patologias'}
                  touched={formik.touched.another_pathologies}
                  errors={formik.errors.another_pathologies}
                  name={'another_pathologies'}
                />
              </div>
              <div className='col-12 col-md-8'>
                <h4>{intl.formatMessage({id: 'Centro de Saude'})}</h4>
                <hr />
                <div className='row'>
                  <CustomInput
                    disable={perm}
                    formikFields={formik.getFieldProps('medical_center_name')}
                    label={'Nome'}
                    type={'text'}
                    touched={formik.touched.medical_center_name}
                    errors={formik.errors.medical_center_name}
                    name={'medical_center_name'}
                  />
                  <CustomInput
                    disable={perm}
                    formikFields={formik.getFieldProps('medical_center_address')}
                    label={'Morada'}
                    type={'text'}
                    touched={formik.touched.medical_center_address}
                    errors={formik.errors.medical_center_address}
                    name={'medical_center_address'}
                  />
                  <div className='row pe-0'>
                    <div className='col-12 col-sm-6'>
                      <CustomInput
                        disable={perm}
                        formikFields={formik.getFieldProps('medical_center_postcode')}
                        label={'Código Postal'}
                        type={'text'}
                        touched={formik.touched.medical_center_postcode}
                        errors={formik.errors.medical_center_postcode}
                        name={'medical_center_postcode'}
                      />
                    </div>
                    <div className='col-12 col-sm-6 pe-0'>
                      <CustomInput
                        disable={perm}
                        formikFields={formik.getFieldProps('medical_center_contact')}
                        label={'Contacto'}
                        type={'text'}
                        touched={formik.touched.medical_center_contact}
                        errors={formik.errors.medical_center_contact}
                        name={'medical_center_contact'}
                      />
                    </div>
                  </div>
                  <CustomInput
                    disable={perm}
                    formikFields={formik.getFieldProps('medical_center_assistant_doctor')}
                    label={'Médico Assistente'}
                    type={'text'}
                    touched={formik.touched.medical_center_assistant_doctor}
                    errors={formik.errors.medical_center_assistant_doctor}
                    name={'medical_center_assistant_doctor'}
                  />
                </div>
                <div className='row'>
                  <h4>{intl.formatMessage({id: 'Médico Externo ou Particular '})}</h4>
                  <hr />
                  <div className='row'>
                    <CustomInput
                      disable={perm}
                      formikFields={formik.getFieldProps('external_doctor_name')}
                      label={'Nome'}
                      type={'text'}
                      touched={formik.touched.external_doctor_name}
                      errors={formik.errors.external_doctor_name}
                      name={'external_doctor_name'}
                    />
                    <CustomInput
                      disable={perm}
                      formikFields={formik.getFieldProps('external_doctor_address')}
                      label={'Morada'}
                      type={'text'}
                      touched={formik.touched.external_doctor_address}
                      errors={formik.errors.external_doctor_address}
                      name={'external_doctor_address'}
                    />
                    <div className='row pe-0'>
                      <div className='col-12 col-sm-6'>
                        <CustomInput
                          disable={perm}
                          formikFields={formik.getFieldProps('external_doctor_postcode')}
                          label={'Código Postal'}
                          type={'text'}
                          touched={formik.touched.external_doctor_postcode}
                          errors={formik.errors.external_doctor_postcode}
                          name={'external_doctor_postcode'}
                        />
                      </div>
                      <div className='col-12 col-sm-6 pe-0'>
                        <CustomInput
                          disable={perm}
                          formikFields={formik.getFieldProps('external_doctor_contact')}
                          label={'Contacto'}
                          type={'text'}
                          touched={formik.touched.external_doctor_contact}
                          errors={formik.errors.external_doctor_contact}
                          name={'external_doctor_contact'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h4>{intl.formatMessage({id: 'Outras Informações'})}</h4>
              <hr />
              <div className='row'>
                <div className='col-12 col-md-4'>
                  <FormikTextArea
                    disable={perm}
                    formikFields={formik.getFieldProps('food_cares')}
                    label={'Cuidados Alimentares'}
                    touched={formik.touched.food_cares}
                    errors={formik.errors.food_cares}
                    name={'food_cares'}
                  />
                </div>
                <div className='col-12 col-md-4'>
                  <FormikTextArea
                    disable={perm}
                    formikFields={formik.getFieldProps('allergies')}
                    label={'Alergias'}
                    touched={formik.touched.allergies}
                    errors={formik.errors.allergies}
                    name={'allergies'}
                  />
                </div>
                <div className='col-12 col-md-4'>
                  <FormikTextArea
                    disable={perm}
                    formikFields={formik.getFieldProps('diseases')}
                    label={'Doenças'}
                    touched={formik.touched.diseases}
                    errors={formik.errors.diseases}
                    name={'diseases'}
                  />
                </div>
                <div className='col-12 col-md-4'>
                  <FormikTextArea
                    disable={perm}
                    formikFields={formik.getFieldProps('operations')}
                    label={'Operações'}
                    touched={formik.touched.operations}
                    errors={formik.errors.operations}
                    name={'operations'}
                  />
                </div>
                <div className='col-12 col-md-4'>
                  <FormikTextArea
                    disable={perm}
                    formikFields={formik.getFieldProps('dependencies')}
                    label={'Dependências'}
                    touched={formik.touched.dependencies}
                    errors={formik.errors.dependencies}
                    name={'dependencies'}
                  />
                </div>
                <div className='col-12 col-md-4'>
                  <FormikTextArea
                    disable={perm}
                    formikFields={formik.getFieldProps('special_cares')}
                    label={'Cuidados Especiais'}
                    touched={formik.touched.special_cares}
                    errors={formik.errors.special_cares}
                    name={'special_cares'}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default MedicalHealthRecordPage
