import axios, {AxiosResponse} from 'axios'
import {USERS_URL} from '../app/_const'
import {User} from '../models/_user'

export const getPossibleCollabs = async (patient_id: string) => {
  const response: AxiosResponse<{data: User[]}> = await axios.get(
    `${USERS_URL}/get-possible-collabs/${patient_id}`
  )
  return response.data.data
}
