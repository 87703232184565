import { ListViewProvider } from "./core/ListViewProvider";
import { QueryRequestProvider } from "./core/QueryRequestProvider";
import { QueryResponseProvider } from "./core/QueryResponseProvider";
import { DcsTable } from "./table/DcsTable";


const DcsListWrapper = () => (
    <QueryRequestProvider>
        <QueryResponseProvider>
            <ListViewProvider>
                <DcsTable/>
            </ListViewProvider>
        </QueryResponseProvider>
    </QueryRequestProvider>
)

export {DcsListWrapper}
