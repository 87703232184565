/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {EmailTemplate} from '../../../../../../../models/_email'

type Props = {
  template: EmailTemplate
}

const DefaultBadge: FC<Props> = ({template}) => {
  return (
    <>
      {template.isDefault ? (
        <span className='badge badge-light-success w-50 d-flex justify-content-center w-25'>
          <i className=' text-success '>Padrão</i>
        </span>
      ) : (
        <span className='badge badge-light-primary w-50 d-flex justify-content-center w-25'>
          <i className=' text-primary'>Alterado</i>
        </span>
      )}
    </>
  )
}

export {DefaultBadge}
