import React, {useEffect, useRef, useState} from 'react'
import axios from 'axios'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import CustomInput from '../../../../_components/CustomInput'
import {toastStyles} from '../../../../../_styles'
import {Relationship} from '../../../../../../models/_relationship'
import {relationshipSchema} from '../../../../../../schemas/_relationships'
import {RELATIONSHIPS_URL} from '../../../../../_const'
import CustomModalHeader from '../../../../_components/modals/CustomModalHeader'
import WarningBlock from '../../../../_components/inputs/WarningBlock'

interface Props {
  updateList: (relationship: Relationship) => void
  relationship: Relationship
}

export function EditRelationshipModal(props: Props) {
  const intl = useIntl()
  const [initialValues, setInitialValues] = useState<Relationship>(props.relationship)
  const closeBtn = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    const fetchData = () => {
      setInitialValues(props.relationship)
    }
    fetchData()
  }, [props])

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: relationshipSchema,
    onSubmit: async (values, {setSubmitting}) => {
      await axios
        .patch(`${RELATIONSHIPS_URL}/${props.relationship.id}`, values)
        .then((res) => {
          props.updateList(res.data)
          toast.success(intl.formatMessage({id: 'Grau de Parentesco Editado'}), toastStyles)
          setSubmitting(false)
          closeBtn?.current?.click()
        })
        .catch((err: any) => {
          Object.keys(err.response.data.errors).forEach((key) => {
            formik.setFieldError(key, err.response.data.errors[key][0] ?? 'undefined')
          })
          setSubmitting(false)
        })
    },
  })

  return (
    <>
      <div className='modal fade' tabIndex={-1} id='kt_modal_edit_relationship'>
        <div className='modal-dialog modal-md'>
          <div className='modal-content '>
            <CustomModalHeader title={'Editar Grau de Parentesco'} />
            <form onSubmit={formik.handleSubmit}>
              <div className='modal-body'>
                <WarningBlock
                  show={formik.isValid}
                  warning={'Campos obrigatórios (*), preencha os campos corretamente!'}
                />
                <div className='row'>
                  <CustomInput
                    required={true}
                    formikFields={formik.getFieldProps('name')}
                    label={'Grau de Parentesco'}
                    type={'text'}
                    touched={formik.touched.name}
                    errors={formik.errors.name}
                    name={'name'}
                  />
                </div>
              </div>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-light'
                  data-bs-dismiss='modal'
                  ref={closeBtn}
                >
                  {intl.formatMessage({id: 'Close'})}
                </button>
                <button
                  type='submit'
                  className='btn btn-primary'
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  {intl.formatMessage({id: 'Confirmar'})}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
