// @ts-nocheck
import {Column} from 'react-table'
import {AppointmentCategoriesCustomHeader} from './AppointmentCategoriesCustomHeader'
import {AppointmentCategory} from '../../../../../../models/_appointmentCategory'

const appointmentCategoriesColumns: ReadonlyArray<Column<AppointmentCategory>> = [
  {
    Header: (props) => (
      <AppointmentCategoriesCustomHeader
        tableProps={props}
        title='Nome do Tipo de Marcação'
        className='min-w-125px'
      />
    ),
    accessor: 'name',
  },

  {
    Header: (props) => (
      <AppointmentCategoriesCustomHeader
        tableProps={props}
        title='Ações'
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
  },
]

export {appointmentCategoriesColumns}
