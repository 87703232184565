import axios, {AxiosResponse} from 'axios'
import {PRIORITIES_URL} from "../app/_const";
import {RelationshipQueryResponse} from "../models/_relationship";


const getRelationships = (query: string): Promise<RelationshipQueryResponse> => {
    return axios
        .get(`${PRIORITIES_URL}?${query}`)/**/
        .then((d: AxiosResponse<RelationshipQueryResponse>) => d.data)
}

export {getRelationships}