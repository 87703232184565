import { ListViewProvider } from "./core/ListViewProvider";
import { QueryRequestProvider } from "./core/QueryRequestProvider";
import { QueryResponseProvider } from "./core/QueryResponseProvider";
import { KTCard } from "../../../../../_metronic/helpers";
import { AttachmentsTable } from "./table/AttachmentsTable";

const AttachmentsList = () => {

    return (
        <>
            <KTCard>
                <AttachmentsTable/>
            </KTCard>
        </>
    )
}

const CollabAttachmentsListWrapper = () => (
    <QueryRequestProvider>
        <QueryResponseProvider>
            <ListViewProvider>
                <AttachmentsList/>
            </ListViewProvider>
        </QueryResponseProvider>
    </QueryRequestProvider>
)

export {CollabAttachmentsListWrapper}
