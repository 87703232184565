import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import { USERS_URL } from "../../../_const";
import { Patient } from "../../../../models/_patient";
import ToolBar from "../components/Toolbar";

function CollaboratorLayout() {
  const {id} = useParams()
  const location = useLocation()
  const [patient, setPatient] = useState<Patient>({} as Patient)
  const [title, setTitle] = useState('Geral')
  const titles: {[key: string]: string} = {
    '/collaborators': 'Tabela',
    '/collaborator/perfil/': 'Dados Pessoais',
    '/collaborator/schedule/': 'Agenda',
    '/collaborator/tecnical/': 'Definições',
    '/collaborator/attachments/': 'Anexos',
  }

  useEffect(() => {
    const fetchData = async () => {
      const ruser: AxiosResponse<{data: Patient}> = await axios.get(`${USERS_URL}/${id}`)
      setPatient(ruser.data.data)
    }
    fetchData()
  }, [])

  useEffect(() => {
    let path = location.pathname.includes(`${id}`)
      ? location.pathname.replace(`${id}`, '')
      : location.pathname
    let tTitle = titles[path] ?? 'Geral'
    setTitle(tTitle)
  }, [location])

  return (
    <>
      <ToolBar title={title} division='Colaborador' user={patient} />
      <Outlet />
    </>
  )
}

export default CollaboratorLayout
