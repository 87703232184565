import * as Yup from 'yup'

export const patientSchema = Yup.object().shape({
  username: Yup.string().required('Field Required'),
  email: Yup.string().required('Field Required').email('Wrong email format'),
  full_name: Yup.string().required('Field Required'),
  mobilephone: Yup.string().required('Field Required'),
})

export const patientAssociateSchema = Yup.object().shape({
  username: Yup.string().required('Field Required'),
  email: Yup.string().required('Field Required').email('Wrong email format'),
  full_name: Yup.string().required('Field Required'),
  mobilephone: Yup.string().required('Field Required'),
  clinic_default_id: Yup.string().required('Field Required'),
})
