import axios, {AxiosResponse} from 'axios'
import {NotesQueryResponse} from "../models/_note";
import {API_URL, ClINICS_NOTES_URL} from "../app/_const";


const getNotes = (query: string, id: string): Promise<NotesQueryResponse> => {
    return axios
        .get(`${ClINICS_NOTES_URL}/${id}?${query}`)/**/
        .then((d: AxiosResponse<NotesQueryResponse>) => d.data)
}

const getPatientsNotes = (query: string, id: string): Promise<NotesQueryResponse> => {
    return axios
        .get(`${API_URL}/users/observations/${id}?${query}`)/**/
        .then((d: AxiosResponse<NotesQueryResponse>) => d.data)
}
export {getNotes, getPatientsNotes}
