/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from "clsx";
import { useQueryResponseLoading, useQueryResponsePagination } from "../../core/QueryResponseProvider";
import { useQueryRequest } from "../../core/QueryRequestProvider";
import { useIntl } from "react-intl";

const mappedLabel = (label: string): string => {
    if (label === '&laquo; Previous') {
        return 'Previous'
    }

    if (label === 'Next &raquo;') {
        return 'Next'
    }

    return label
}

const ClinicsListPagination = () => {
    const pagination = useQueryResponsePagination()
    const isLoading = useQueryResponseLoading()
    const {updateState} = useQueryRequest()
    const intl = useIntl()


    const updatePage = (link: { active: boolean; label: string; page: number | null; url: string | null }) => {

        if (isLoading || pagination.current_page === link.page) {
            return
        }

        if (!link.page) {
            link.page = (link.label.includes("Next") ? ++pagination.current_page : --pagination.current_page)
        }

        updateState({page: link.page, per_page: pagination.per_page})
    }

    return (
        <div className='row'>
            <div
                className='col-sm-12 col-md-5 d-flex align-items-center justify-content-center justify-content-md-start'></div>
            <div className='col-sm-12 col-md-7 d-flex align-items-center justify-content-center justify-content-md-end'>
                <div id='kt_table_users_paginate'>
                    <ul className='pagination'>
                        {pagination.links
                            ?.map((link) => {
                                return {...link, label: mappedLabel(link.label)}
                            })
                            .map((link) => (
                                <li
                                    key={link.label}
                                    className={clsx('page-item rounded-1 p-1', {
                                        active: pagination.current_page === link.page,
                                        disabled: isLoading || (pagination.current_page == 1 && link.label.includes('Prev')) || (pagination.current_page == pagination.last_page && link.label.includes('Next')),
                                        previous: link.label === 'Previous',
                                        next: link.label === 'Next',
                                    })}
                                >
                                    <a
                                        className={clsx('page-link rounded-1', {
                                            'page-text': link.label === 'Previous' || link.label === 'Next',
                                        })}
                                        onClick={() => updatePage(link)}
                                        style={{cursor: 'pointer'}}
                                    >
                                        {(link.label == "Previous" || link.label == "Next") ? intl.formatMessage({id: mappedLabel(link.label)}) : link.label}
                                    </a>
                                </li>
                            ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export {ClinicsListPagination}
