// @ts-nocheck
import { Column } from "react-table";
import { AttachmentCustomHeader } from "./AttachmentCustomHeader";
import { CustomHeader } from "./CustomHeader";
import { Attachment } from "../../../../../../../models/_attachment";
import { PrivacBadge } from "./PrivacyBadge";

const attachmentsColumns: ReadonlyArray<Column<Attachment>> = [
    {
        Header: (props) => <AttachmentCustomHeader tableProps={props} title='Ficheiro' className='min-w-125px'/>,
        accessor: 'name',
    },
    {
        Header: (props) => (
            <CustomHeader tableProps={props} title='Privacidade' className='min-w-125px'/>
        ),
        id: 'privacy',
        Cell: ({...props}) => <PrivacBadge attachment={props.data[props.row.index]}/>
    },
    {
        Header: (props) => <AttachmentCustomHeader tableProps={props} title='Descrição'
                                                   className='min-w-125px'/>,
        accessor: 'description',
    },
    {
        Header: (props) => <AttachmentCustomHeader tableProps={props} title='Categoria' className='min-w-125px'/>,
        accessor: 'attachment_category.name',
    },
    {
        Header: (props) => <AttachmentCustomHeader tableProps={props} title='Tamanho' className='min-w-125px'/>,
        accessor: 'size',
    },
    {
        Header: (props) => <AttachmentCustomHeader tableProps={props} title='Inserido por:' className='min-w-125px'/>,
        accessor: 'responsible.full_name',
    },
    {
        Header: (props) => (
            <CustomHeader tableProps={props} title='Ações' className='text-end min-w-100px'/>
        ),
        id: 'actions',
    },
]

export {attachmentsColumns}
