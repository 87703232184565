import React, {FC} from 'react'
import {Link, useLocation, useParams} from 'react-router-dom'
import PageTitle from '../../_components/PageTitle'
import {KTSVG} from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import {Clinic} from '../../../../models/_clinic'
import {havePerm, havePerms} from '../../../_helpers'
import {useAuth} from '../../../modules/auth'

interface Breadcrumb {
  title: string
  division: string
  clinic: Clinic
}

const ToolBar: FC<Breadcrumb> = (props): JSX.Element => {
  const location = useLocation()
  const intl = useIntl()
  const {id} = useParams()
  const {auth} = useAuth()

  return (
    <>
      <PageTitle title={props.title} division={props.division} />

      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <p className='text-gray-800 fs-2 fw-bolder me-1'>
                    {props.clinic.name ?? intl.formatMessage({id: 'Não Definido'})}
                  </p>
                </div>
                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2'>
                  <p className='d-flex align-items-center text-gray-400  me-5 mb-2'>
                    <KTSVG
                      path='/media/icons/duotune/communication/com006.svg'
                      className='svg-icon-4 me-1'
                    />
                    {props.clinic.responsible
                      ? props.clinic.responsible.full_name
                      : intl.formatMessage({id: 'Não Definido'})}
                  </p>
                  <p className='d-flex align-items-center text-gray-400  me-5 mb-2'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen018.svg'
                      className='svg-icon-4 me-1'
                    />
                    {props.clinic.city ?? intl.formatMessage({id: 'Não Definido'})}
                  </p>
                  <p className='d-flex align-items-center text-gray-400  mb-2'>
                    <KTSVG
                      path='/media/icons/duotune/communication/com011.svg'
                      className='svg-icon-4 me-1'
                    />
                    {props.clinic.email ?? intl.formatMessage({id: 'Não Definido'})}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/clinic/general/' + id && 'active')
                  }
                  to={'/clinic/general/' + id}
                >
                  <span className='menu-icon'>
                    <i className={'fa-solid fa-house me-1'}></i>
                  </span>
                  {intl.formatMessage({id: 'Geral'})}
                </Link>
              </li>
              {havePerm('contacts:read', auth) && (
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === '/clinic/contacts/' + id && 'active')
                    }
                    to={'/clinic/contacts/' + id}
                  >
                    <span className='menu-icon'>
                      <i className={'fa-solid fa-users me-1'}></i>
                    </span>
                    {intl.formatMessage({id: 'Contactos'})}
                  </Link>
                </li>
              )}
              {havePerm('collaborators:read', auth) && (
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === '/clinic/collaborators/' + id && 'active')
                    }
                    to={'/clinic/collaborators/' + id}
                  >
                    <span className='menu-icon'>
                      <i className={'fa-solid fa-user-doctor me-1'}></i>
                    </span>
                    {intl.formatMessage({id: 'Colaboradores'})}
                  </Link>
                </li>
              )}
              {havePerm('patients:read', auth) && (
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === '/clinic/patients/' + id && 'active')
                    }
                    to={'/clinic/patients/' + id}
                  >
                    <span className='menu-icon'>
                      <i className={'fa-solid fa-briefcase me-1'}></i>
                    </span>
                    {intl.formatMessage({id: 'Utentes'})}
                  </Link>
                </li>
              )}
              {havePerm('devices:read', auth) && (
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === '/clinic/devices/' + id && 'active')
                    }
                    to={'/clinic/devices/' + id}
                  >
                    <span className='menu-icon'>
                      <i className={'fa-solid fa-mobile me-1'}></i>
                    </span>
                    {intl.formatMessage({id: 'Dispositivos'})}
                  </Link>
                </li>
              )}
              {havePerm('observations:read', auth) && (
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === '/clinic/notes/' + id && 'active')
                    }
                    to={'/clinic/notes/' + id}
                  >
                    <span className='menu-icon'>
                      <i className={'fa-solid fa-file-lines me-1'}></i>
                    </span>
                    {intl.formatMessage({id: 'Notas'})}
                  </Link>
                </li>
              )}
              {havePerm('attachments:read', auth) && (
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === '/clinic/attachments/' + id && 'active')
                    }
                    to={'/clinic/attachments/' + id}
                  >
                    <span className='menu-icon'>
                      <i className={'fa-solid fa-paperclip me-1'}></i>
                    </span>
                    {intl.formatMessage({id: 'Anexos'})}
                  </Link>
                </li>
              )}
              {havePerms(['smtps:update', 'emailtemplates:read'], auth) && (
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === '/clinic/mail-settings/' + id && 'active')
                    }
                    to={'/clinic/mail-settings/' + id}
                  >
                    <span className='menu-icon'>
                      <i className={'fa-solid fa-envelope me-1'}></i>
                    </span>
                    {intl.formatMessage({id: 'E-mail'})}
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default ToolBar
