import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useIntl } from "react-intl";
import { useFormik } from "formik";
import axios, { AxiosResponse } from "axios";
import { DEVICES_URL, MODELS_URL } from "../../../../../../../_const";
import { toast } from "react-toastify";
import { customStyles, toastStyles } from "../../../../../../../_styles";
import CustomInput from "../../../../../../_components/CustomInput";
import { Device } from "../../../../../../../../models/_device";
import Select from "react-select";
import { useThemeMode } from "../../../../../../../../_metronic/partials";
import { Model } from "../../../../../../../../models/_deviceModel";
import { deviceSchema } from "../../../../../../../../schemas/_device";
import { useParams } from "react-router-dom";
import CustomModalFooter from "../../../../../../_components/modals/CustomModalFooter";
import { generalize } from "../../../../../../../_helpers";
import FormikTextArea from "../../../../../../_components/FormikTextArea";
import FormikErrors from "../../../../../../_components/FormikErrors";
import WarningBlock from "../../../../../../_components/inputs/WarningBlock";

interface Props {
  updateTable: (model: Device) => void;
}

export function AddDeviceModal({ updateTable }: Props) {
  const intl = useIntl();
  const { id } = useParams();
  const { menuMode } = useThemeMode();
  const [show, setShow] = useState(false);
  const [models, setModels] = useState<Model[]>([]);

  const handleClose = () => {
    setShow(false);
    formik.resetForm();
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    const fetchData = async () => {
      const response: AxiosResponse<{ data: Model[] }> = await axios.get(`${MODELS_URL}/all`);
      setModels(response.data.data);
    };
    fetchData();
  }, []);

  const initialValues = { mac_address: "", observation: "", device_model_id: "" };

  const formik = useFormik({
    initialValues,
    validationSchema: deviceSchema,
    onSubmit: async (values, { setSubmitting }) => {
      await axios
        .post(`${DEVICES_URL}`, {
          ...values,
          clinic_id: id
        })
        .then((res: AxiosResponse<{ data: Device }>) => {
          updateTable(res.data.data);
          toast.success(intl.formatMessage({ id: "Dispositivo Criado" }), toastStyles);
          handleClose();
          setSubmitting(false);
        })
        .catch((err: any) => {
          Object.keys(err.response.data.errors).forEach((key) => {
            formik.setFieldError(key, err.response.data.errors[key][0] ?? "undefined");
          });
          formik.setTouched(generalize(initialValues, true), false);
          setSubmitting(false);
        });
    }
  });

  return (
    <>
      <button type="button" className="btn btn-primary" onClick={handleShow}>
        {intl.formatMessage({ id: "Criar Dispositivo" })}
      </button>

      <Modal show={show} onHide={handleClose} size={"lg"}>
        <Modal.Header closeButton>
          <h3 className="card-title text-primary">
            {intl.formatMessage({ id: "Criar Dispositivo" })}
          </h3>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit} noValidate>
          <Modal.Body>
            <div className="row">
              <WarningBlock
                show={formik.isValid}
                warning={"Campos obrigatórios (*), preencha os campos corretamente!"}
              />
              <div className="col-12 col-md-6">
                <CustomInput
                  required={true}
                  formikFields={formik.getFieldProps("mac_address")}
                  label={"MAC Address"}
                  type={"text"}
                  touched={formik.touched.mac_address}
                  errors={formik.errors.mac_address}
                />
              </div>
              <div className="col-12 col-md-6">
                <label className="form-label required">
                  {intl.formatMessage({ id: "Modelo" })}
                </label>
                <Select
                  options={models}
                  getOptionLabel={(model: Model) => model.name}
                  getOptionValue={(model: Model) => `${model.id}`}
                  {...formik.getFieldProps("device_model_id")}
                  value={models.find((value) => {
                    return `${value.id}` == formik.values.device_model_id;
                  })}
                  onBlur={(event) => {
                    formik.setFieldTouched("device_model_id", true);
                    formik.setErrors({ ...formik.errors, device_model_id: undefined });
                  }}
                  onChange={(value) => {
                    formik.setFieldValue("device_model_id", `${value?.id}`);
                  }}
                  styles={customStyles(
                    menuMode,
                    formik.errors.device_model_id,
                    formik.touched.device_model_id
                  )}
                />
                <FormikErrors
                  errors={formik.errors.device_model_id}
                  touched={formik.touched.device_model_id}
                />
              </div>
            </div>
            <FormikTextArea
              formikFields={formik.getFieldProps("observation")}
              label={"Observação"}
              touched={formik.touched.observation}
              errors={formik.errors.observation}
              name={"observation"}
            />
          </Modal.Body>
          <CustomModalFooter
            isValid={formik.isValid}
            isSubmitting={formik.isSubmitting}
            handleClose={handleClose}
            closeLabel={"Close"}
            submitLabel={"Confirmar"}
          />
        </form>
      </Modal>
    </>
  );
}