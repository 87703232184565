import { FC } from "react";
import { useIntl } from "react-intl";

type Props = {
    success: boolean
}

const RgpdStatus: FC<Props> = ({success}) => {
    const intl = useIntl()
    return <>
        {success ? (<span className="badge badge-light-success">{intl.formatMessage({id: "Aceite"})}</span>) :
            <span className="badge badge-light-danger">{intl.formatMessage({id: "Por Aceitar"})}</span>
        }
    </>
}

export {RgpdStatus}
