// @ts-nocheck
import { Column } from "react-table";
import { DevicesCustomHeader } from "./DevicesCustomHeader";
import { StatusCell } from "./StatusCell";
import { Device } from "../../../../../../../../models/_device";

const devicesColumns: ReadonlyArray<Column<Device>> = [
  {
    Header: (props) => (
      <DevicesCustomHeader tableProps={props} title='MAC Address' className='min-w-125px' />
    ),
    accessor: 'mac_address',
  },
  {
    Header: (props) => (
      <DevicesCustomHeader tableProps={props} title='Modelo' className='min-w-125px' />
    ),
    accessor: 'model.name',
  },
  {
    Header: (props) => (
      <DevicesCustomHeader tableProps={props} title='Marca' className='min-w-125px' />
    ),
    accessor: 'model.brand.name',
  },
  {
    Header: (props) => (
      <DevicesCustomHeader tableProps={props} title='Estado' className='min-w-125px' />
    ),
    Cell: (props) => <StatusCell success={props.row.original.status} />,
    accessor: 'status',
  },
  {
    Header: (props) => (
      <DevicesCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'actions',
  },
]

export {devicesColumns}
