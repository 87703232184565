/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from "react";
import { MenuComponent } from "../../../../../../../_metronic/assets/ts/components";
import { ID } from "../../../../../../../_metronic/helpers";
import { useNavigate } from "react-router-dom";
import { AssociateCollaborator } from "../../../../modals/AssociateCollaboratorModal";
import { havePerm } from "../../../../../../_helpers";
import { useAuth } from "../../../../../../modules/auth";

type Props = {
  id: ID
}

const ActionsCell: FC<Props> = ({id}) => {
  const navigation = useNavigate()
  const {auth} = useAuth()

  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  const goto = (id: ID) => {
    navigation(`/patient/base/${id}`)
  }

  return (
    <>
      {havePerm('associations:update', auth) && (
        <AssociateCollaborator patient_id={id?.toString() ?? ''} />
      )}
      <button
        className='btn btn-light btn-active-light-primary btn-sm p-3 ps-4 pe-4'
        data-kt-menu-trigger='click'
        data-kt-menu-placement='bottom-end'
        onClick={() => {
          goto(id)
        }}
      >
        <i className='fa-sharp fa-solid fa-eye text-primary p-0'></i>
      </button>
    </>
  )
}

export {ActionsCell}
