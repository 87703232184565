import React, {useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import axios, {AxiosResponse} from 'axios'
import {toast} from 'react-toastify'
import {useThemeMode} from '../../../../_metronic/partials'
import {Medication, Prescription} from '../../../../models/_schedule'
import {PRESCRIPTIONS_STORE_URL} from '../../../_const'
import {customStyles, toastStyles} from '../../../_styles'
import {options_du, options_f, options_fu, prescriptionSchema} from '../../../../schemas/_schedules'
import {dateToString, generalize} from '../../../_helpers'
import Select from 'react-select'
import CustomInput from '../../_components/CustomInput'
import FormikTextArea from '../../_components/FormikTextArea'
import {useParams} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import ReactDatePicker from 'react-datepicker'
import CustomModalFooter from '../../_components/modals/CustomModalFooter'
import clsx from 'clsx'
import FormikErrors from '../../_components/FormikErrors'
import WarningBlock from '../../_components/inputs/WarningBlock'

interface Props {
  updateTable: (prescription: Prescription) => void
  medications: Medication[]
}

interface Option {
  readonly label: string
  readonly value: string
}

export function AddPrescriptionModal({updateTable, medications}: Props) {
  const intl = useIntl()
  const {id} = useParams()
  const {auth} = useAuth()
  const {menuMode} = useThemeMode()
  const [show, setShow] = useState(false)

  const handleClose = () => {
    formik.resetForm()
    setShow(false)
  }
  const handleShow = () => setShow(true)

  const initialValues = {
    start_at: '',
    date_first_schedule: '',
    end_at: '',
    medication_id: '',
    user_id: id ?? '',
    dosage: '',
    dosage_unit: '',
    frequency_unit: '',
    frequency: '',
    observation: '',
    responsible_id: auth?.data.id ?? '',
  } as Prescription

  const formik = useFormik({
    initialValues,
    validationSchema: prescriptionSchema,
    onSubmit: async (values, {setSubmitting}) => {
      await axios
        .post(`${PRESCRIPTIONS_STORE_URL}`, values)
        .then((res: {data: AxiosResponse<Prescription>}) => {
          updateTable(res.data.data)
          toast.success(intl.formatMessage({id: 'Prescrição Criada'}), toastStyles)
          handleClose()
          setSubmitting(false)
        })
        .catch((err) => {
          Object.keys(err.response.data.errors).forEach((key) => {
            formik.setFieldError(key, err.response.data.errors[key][0] ?? 'undefined')
          })
          formik.setTouched(generalize(initialValues, true), false)
          setSubmitting(false)
        })
    },
  })

  return (
    <>
      <button type='button' className='btn btn-primary' onClick={handleShow}>
        {intl.formatMessage({id: 'Registar Prescrição'})}
      </button>

      <Modal show={show} onHide={handleClose} size={'lg'}>
        <Modal.Header closeButton>
          <h3 className='card-title text-primary'>
            {intl.formatMessage({id: 'Registar Prescrição'})}
          </h3>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className='row'>
              <WarningBlock
                show={formik.isValid}
                warning={'Campos obrigatórios (*), preencha os campos corretamente!'}
              />
              <div className='col-6 d-flex flex-column gap-2'>
                <label className='form-label fs-6 text-dark mt-2 required'>
                  {intl.formatMessage({id: 'Data de inicio'})}
                </label>
                <ReactDatePicker
                  className={clsx('form-control required', {
                    'is-invalid': formik.errors.start_at && formik.touched.start_at,
                  })}
                  placeholderText={
                    formik.values.start_at === ''
                      ? 'Selecionar data...'
                      : formik.values.start_at ?? 'Selecionar data...'
                  }
                  onChange={(date: Date) => formik.setFieldValue('start_at', dateToString(date))}
                  selected={formik.values.start_at ? new Date(formik.values.start_at) : null}
                  showTimeSelect
                  timeFormat='HH:mm'
                  dateFormat='yyyy-MM-dd HH:mm'
                  popperPlacement='bottom'
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                />
                <FormikErrors errors={formik.errors.start_at} touched={formik.touched.start_at} />
                <label className='form-label fs-6 text-dark required'>
                  {intl.formatMessage({id: 'Primeira dose'})}
                </label>
                <ReactDatePicker
                  className={clsx('form-control', {
                    'is-invalid':
                      formik.errors.date_first_schedule && formik.touched.date_first_schedule,
                  })}
                  placeholderText={
                    formik.values.date_first_schedule === ''
                      ? 'Selecionar data...'
                      : formik.values.date_first_schedule ?? 'Selecionar data...'
                  }
                  onChange={(date: Date) =>
                    formik.setFieldValue('date_first_schedule', dateToString(date))
                  }
                  selected={
                    formik.values.date_first_schedule
                      ? new Date(formik.values.date_first_schedule)
                      : null
                  }
                  minDate={formik.values.start_at ? new Date(formik.values.start_at) : null}
                  maxDate={formik.values.end_at ? new Date(formik.values.end_at) : null}
                  showTimeSelect
                  timeFormat='HH:mm'
                  dateFormat='yyyy-MM-dd HH:mm'
                  popperPlacement='bottom'
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                />
                <FormikErrors
                  errors={formik.errors.date_first_schedule}
                  touched={formik.touched.date_first_schedule}
                />
              </div>
              <div className='col-6 d-flex flex-column gap-2'>
                <label className='form-label fs-6 text-dark mt-2 required'>
                  {intl.formatMessage({id: 'Data de fim'})}
                </label>
                <ReactDatePicker
                  className={clsx('form-control', {
                    'is-invalid': formik.errors.end_at && formik.touched.end_at,
                  })}
                  placeholderText={
                    formik.values.end_at === ''
                      ? 'Selecionar data...'
                      : formik.values.end_at ?? 'Selecionar data...'
                  }
                  onChange={(date: Date) => formik.setFieldValue('end_at', dateToString(date))}
                  selected={formik.values.end_at ? new Date(formik.values.end_at) : null}
                  showTimeSelect
                  minDate={formik.values.start_at ? new Date(formik.values.start_at) : null}
                  timeFormat='HH:mm'
                  dateFormat='yyyy-MM-dd HH:mm'
                  popperPlacement='bottom'
                  showMonthDropdown
                  showYearDropdown
                  scrollableYearDropdown
                />
                <FormikErrors errors={formik.errors.end_at} touched={formik.touched.end_at} />
                <label className='form-label fs-6 text-dark mt-2 required'>
                  {intl.formatMessage({id: 'Medicamento'})}
                </label>
                <Select
                  options={medications}
                  getOptionLabel={(medication: Medication) => medication.name}
                  getOptionValue={(medication: Medication) => `${medication.id}`}
                  {...formik.getFieldProps('medication_id')}
                  value={medications.find((value) => {
                    return `${value.id}` === formik.values.medication_id
                  })}
                  onBlur={(event) => {
                    formik.setFieldTouched('medication_id', true)
                    formik.setErrors({...formik.errors, medication_id: undefined})
                  }}
                  onChange={(value) => {
                    formik.setValues({
                      ...formik.values,
                      dosage_unit: `${value?.dosage_unit}`,
                      medication_id: `${value?.id}`,
                      dosage: `${value?.dosage}`,
                      frequency: `${value?.frequency}`,
                      frequency_unit: `${value?.frequency_unit}`,
                    })
                  }}
                  styles={customStyles(
                    menuMode,
                    formik.errors.medication_id,
                    formik.touched.medication_id && !formik.values.medication_id
                  )}
                />
                <FormikErrors
                  errors={formik.errors.medication_id}
                  touched={formik.touched.medication_id && !formik.values.medication_id}
                />
              </div>
            </div>
            {formik.values.medication_id && (
              <div className='row'>
                <div className='col-6'>
                  <label className='form-label fs-6 text-dark mt-2 required'>
                    {intl.formatMessage({id: 'Unidade de Dosagem'})}
                  </label>
                  <Select
                    options={options_du}
                    placeholder={intl.formatMessage({id: formik.values.dosage_unit})}
                    getOptionLabel={(option: Option) => {
                      return intl.formatMessage({id: option.label})
                    }}
                    getOptionValue={(option: Option) => option.value}
                    isMulti={false}
                    backspaceRemovesValue={true}
                    onChange={(newValue) => {
                      formik.setFieldValue('dosage_unit', newValue?.value)
                    }}
                    styles={customStyles(menuMode)}
                  />

                  <label className='form-label fs-6 text-dark mt-2 required'>
                    {intl.formatMessage({id: 'Unidade de Frequência'})}
                  </label>
                  <Select
                    options={options_fu}
                    placeholder={intl.formatMessage({id: formik.values.frequency_unit})}
                    getOptionLabel={(option: Option) => {
                      return intl.formatMessage({id: option.label})
                    }}
                    getOptionValue={(option: Option) => option.value}
                    isMulti={false}
                    backspaceRemovesValue={true}
                    onChange={(newValue) => {
                      formik.setFieldValue('frequency_unit', newValue?.value)
                    }}
                    styles={customStyles(menuMode)}
                  />
                </div>
                <div className='col-6 mt-3 mb-3'>
                  <CustomInput
                    required={true}
                    formikFields={formik.getFieldProps('dosage')}
                    label={'Dosagem'}
                    type={'number'}
                    touched={formik.touched.dosage}
                    errors={formik.errors.dosage}
                    name={'dosage'}
                  />
                  <label className='form-label fs-6 text-dark required '>
                    {intl.formatMessage({id: 'Frequência'})}
                  </label>
                  <Select
                    options={options_f}
                    placeholder={intl.formatMessage({id: formik.values.frequency})}
                    getOptionLabel={(option: Option) => {
                      return intl.formatMessage({id: option.label})
                    }}
                    getOptionValue={(option: Option) => option.value}
                    isMulti={false}
                    backspaceRemovesValue={true}
                    onChange={(newValue) => {
                      formik.setFieldValue('frequency', newValue?.value)
                    }}
                    styles={customStyles(menuMode)}
                  />
                </div>
                <FormikTextArea
                  formikFields={formik.getFieldProps('observation')}
                  label={'Observações'}
                  touched={formik.touched.observation}
                  errors={formik.errors.observation}
                  name={'observation'}
                />
              </div>
            )}
          </Modal.Body>
          <CustomModalFooter
            isValid={formik.isValid}
            isSubmitting={formik.isSubmitting}
            handleClose={handleClose}
            closeLabel={'Close'}
            submitLabel={'Confirmar'}
          />
        </form>
      </Modal>
    </>
  )
}
