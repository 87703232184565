// @ts-nocheck
import { Column } from "react-table";
import { SpecialtyCustomHeader } from "./SpecialtyCustomHeader";
import { Specialty } from "../../../../../../models/_specialty";

const specialtiesColumns: ReadonlyArray<Column<Specialty>> = [
    {
        Header: (props) => <SpecialtyCustomHeader tableProps={props} title='Especialidade' className='min-w-125px'/>,
        accessor: 'name',
    },
    {
        Header: (props) => <SpecialtyCustomHeader tableProps={props} title='Código' className='min-w-125px'/>,
        accessor: 'code',
    },
    {
        Header: (props) => (
            <SpecialtyCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px'/>
        ),
        id: 'actions',

    },
]

export {specialtiesColumns}
