import React from "react";
import Modal from "react-bootstrap/Modal";
import { useIntl } from "react-intl";

interface Props {
  isValid: boolean;
  isSubmitting: boolean;
  handleClose: () => void;
  closeLabel: string;
  submitLabel: string;
}

function CustomModalFooter({ isSubmitting, isValid, handleClose, closeLabel, submitLabel }: Props) {
  const intl = useIntl();

  return (
    <Modal.Footer>
      <button type="button" className="btn btn-light" onClick={handleClose}>
        {intl.formatMessage({ id: closeLabel })}
      </button>
      <button type="submit" className="btn btn-primary" disabled={isSubmitting || !isValid}>
        {
          isSubmitting ? <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            : intl.formatMessage({ id: submitLabel })
        }
      </button>
    </Modal.Footer>
  );
}

export default CustomModalFooter;