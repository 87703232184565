import * as Yup from 'yup'
import {Clinic} from '../../../models/_clinic'
import {ID} from '../../../_metronic/helpers'

export type GenderOption = {
  label: string
  value: string
}

export type Response = {
  data: Clinic
}

export type CountryOption = {
  id: string
  name: string
}

export type FunctionRole = {
  id: ID
  name: string
}

export const ClinicSchema = Yup.object().shape({
  name: Yup.string().required('Field Required'),
  email: Yup.string().required('Field Required').email('Wrong email format'),
  address: Yup.string().required('Field Required').optional().nullable(),
  city: Yup.string().required('Field Required').optional().nullable(),
  observations: Yup.string().optional().nullable(),
  postcode: Yup.string()
    .required('Field Required')
    .matches(/^\d{4}(-\d{3})?$/, 'Invalid postcode')
    .optional()
    .nullable(),
  latitude: Yup.number().max(90, 'Max: 90').min(-90, 'Min: -90').optional().nullable(),
  longitude: Yup.number().max(180, 'Max: 180').min(-180, 'Min: -180').optional().nullable(),
  website: Yup.string().url('Wrong url format').optional().nullable(),
  mobilephone: Yup.string().required('Field Required'),
  phone: Yup.string()
    .required('Field Required')
    .matches(/^[0-9]*$/, 'Example: 244945954'),
  tax_number: Yup.string()
    .required('Field Required')
    .matches(/^[0-9]*$/, 'Invalid tax_number'),
  responsible_id: Yup.string().required('Field Required').optional().nullable(),
  country_id: Yup.string().required('Field Required'),
})

export const NoteSchema = Yup.object().shape({
  content: Yup.string().required('Field Required'),
})

export const ContactSchema = Yup.object().shape({
  name: Yup.string().required('Field Required'),
  email: Yup.string().required('Field Required').email('Wrong email format'),
  observation: Yup.string().optional().nullable().max(255, 'Limite de caracteres atingido'),
  phone: Yup.string().required('Field Required'),
  functionRole_id: Yup.string().required('Field Required'),
})

export const UserSchema = Yup.object().shape({
  full_name: Yup.string().required('Field Required'),
  gender: Yup.string().required('Field Required').optional().nullable(),
  email: Yup.string().required('Field Required').email('Wrong email format'),
  birthday: Yup.date().required('Field Required').optional().nullable(),
  mobilephone: Yup.string().required('Field Required'),
  city: Yup.string().required('Field Required').optional().nullable(),
  address: Yup.string().required('Field Required').optional().nullable(),
  postcode: Yup.string()
    .required('Field Required')
    .matches(/^\d{4}(-\d{3})?$/, 'Invalid postcode')
    .optional()
    .nullable(),
  tax_number: Yup.string()
    .required('Field Required')
    .matches(/^[0-9]*$/, 'Invalid tax_number')
    .optional()
    .nullable(),
  cc: Yup.string()
    .required('Field Required')
    .matches(/^[0-9]*$/, 'Invalid cc')
    .optional()
    .nullable(),
  niss: Yup.string()
    .required('Field Required')
    .matches(/^[0-9]*$/, 'Invalid niss')
    .optional()
    .nullable(),
  sns_number: Yup.string()
    .required('Field Required')
    .matches(/^[0-9]*$/, 'Invalid sns number')
    .optional()
    .nullable(),
})

export const specialtySchema = Yup.object().shape({
  name: Yup.string().required('Field Required'),
  code: Yup.string().required('Field Required'),
})
