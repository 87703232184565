import React, {useEffect, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import axios, {AxiosResponse} from 'axios'
import {BRANDS_URL, MODELS_URL} from '../../../../../_const'
import {toast} from 'react-toastify'
import {customStyles, toastStyles} from '../../../../../_styles'
import CustomInput from '../../../../_components/CustomInput'
import {Model} from '../../../../../../models/_deviceModel'
import {modelSchema} from '../../../../../../schemas/_models'
import Select from 'react-select'
import {useThemeMode} from '../../../../../../_metronic/partials'
import {Brand} from '../../../../../../models/_brand'
import CustomModalFooter from '../../../../_components/modals/CustomModalFooter'
import FormikTextArea from '../../../../_components/FormikTextArea'
import FormikErrors from '../../../../_components/FormikErrors'
import {generalize} from '../../../../../_helpers'
import WarningBlock from '../../../../_components/inputs/WarningBlock'

interface Props {
  updateTable: (model: Model) => void
}

export function AddModelModal({updateTable}: Props) {
  const intl = useIntl()
  const {menuMode} = useThemeMode()
  const [show, setShow] = useState(false)
  const [brands, setBrands] = useState<Brand[]>([])

  const handleClose = () => {
    formik.resetForm()
    setShow(false)
  }
  const handleShow = () => setShow(true)

  useEffect(() => {
    const fetchData = async () => {
      const response: AxiosResponse<{data: Brand[]}> = await axios.get(`${BRANDS_URL}/all`)
      setBrands(response.data.data)
      formik.setErrors({brand_id: 'missing'})
    }
    fetchData()
  }, [])

  const initialValues = {name: '', observation: '', brand_id: ''}
  const formik = useFormik({
    initialValues,
    validationSchema: modelSchema,
    onSubmit: async (values, {setSubmitting}) => {
      await axios
        .post(`${MODELS_URL}`, values)
        .then((res: AxiosResponse<{data: Model}>) => {
          updateTable(res.data.data)
          toast.success(intl.formatMessage({id: 'Modelo Criado'}), toastStyles)
          handleClose()
          setSubmitting(false)
        })
        .catch((err: any) => {
          Object.keys(err.response.data.errors).forEach((key) => {
            formik.setFieldError(key, err.response.data.errors[key][0] ?? 'undefined')
          })
          formik.setTouched(generalize(initialValues, true), false)
          setSubmitting(false)
        })
    },
  })

  return (
    <>
      <button type='button' className='btn btn-primary' onClick={handleShow}>
        {intl.formatMessage({id: 'Criar Modelo'})}
      </button>

      <Modal show={show} onHide={handleClose} size={'lg'}>
        <Modal.Header closeButton>
          <h3 className='card-title text-primary'>{intl.formatMessage({id: 'Criar Modelo'})}</h3>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <div className='row'>
              <WarningBlock
                show={formik.isValid}
                warning={'Campos obrigatórios (*), preencha os campos corretamente!'}
              />
              <div className='col-12 col-sm-6'>
                <CustomInput
                  required={true}
                  formikFields={formik.getFieldProps('name')}
                  label={'Modelo'}
                  type={'text'}
                  touched={formik.touched.name}
                  errors={formik.errors.name}
                  name={'name'}
                />
              </div>
              <div className='col-12 col-sm-6'>
                <label className='form-label required '>{intl.formatMessage({id: 'Marca'})}</label>
                <Select
                  options={brands}
                  getOptionLabel={(brand: Brand) => brand.name}
                  getOptionValue={(brand: Brand) => `${brand.id}`}
                  {...formik.getFieldProps('brand_id')}
                  value={brands.find((value) => {
                    return `${value.id}` == formik.values.brand_id
                  })}
                  onBlur={(event) => {
                    formik.setFieldTouched('brand_id', true)
                    formik.setErrors({...formik.errors, brand_id: undefined})
                  }}
                  onChange={(value) => {
                    formik.setFieldValue('brand_id', `${value?.id}`)
                  }}
                  styles={customStyles(menuMode, formik.errors.brand_id, formik.touched.brand_id)}
                />
                <FormikErrors errors={formik.errors.brand_id} touched={formik.touched.brand_id} />
              </div>

              <FormikTextArea
                formikFields={formik.getFieldProps('observation')}
                label={'Observação'}
                touched={formik.touched.observation}
                errors={formik.errors.observation}
                name={'observation'}
              />
            </div>
          </Modal.Body>
          <CustomModalFooter
            isValid={formik.isValid}
            isSubmitting={formik.isSubmitting}
            handleClose={handleClose}
            closeLabel={'Close'}
            submitLabel={'Confirmar'}
          />
        </form>
      </Modal>
    </>
  )
}
