// @ts-nocheck
import { Column } from "react-table";
import { ClinicNotesCustomHeader } from "./ClinicNotesCustomHeader";
import { Note } from "../../../../../../../models/_note";

const notesColumns: ReadonlyArray<Column<Note>> = [
    {
        Header: (props) => <ClinicNotesCustomHeader tableProps={props} title='Nota' className='min-w-125px'/>,
        accessor: 'content',
    },
    {
        Header: (props) => (
            <ClinicNotesCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px'/>
        ),
        id: 'actions',

    },
]

export {notesColumns}
