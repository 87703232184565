// @ts-nocheck
import {Column} from 'react-table'
import {MedicationTypesCustomHeader} from './MedicationTypesCustomHeader'
import {MedicationType} from '../../../../../../models/_medicationType'

const medicationTypesColumns: ReadonlyArray<Column<MedicationType>> = [
  {
    Header: (props) => (
      <MedicationTypesCustomHeader tableProps={props} title='Patologia' className='min-w-125px' />
    ),
    accessor: 'name',
  },
  {
    Header: (props) => (
      <MedicationTypesCustomHeader tableProps={props} title='Código' className='min-w-125px' />
    ),
    accessor: 'code',
  },
  {
    Header: (props) => (
      <MedicationTypesCustomHeader
        tableProps={props}
        title='Ações'
        className='text-end min-w-100px'
      />
    ),
    id: 'actions',
  },
]

export {medicationTypesColumns}
