import {Auth} from '../models/_user'

export const havePerm = (perm: string, auth: Auth | undefined) => {
  /*if (auth?.data.role == "super-admin") return true*/

  if (auth === undefined) return false
  return auth?.data.perm.includes(perm)
}

export const havePerms = (perm: string[], auth: Auth | undefined) => {
  /*if (auth?.data.role == "super-admin") return true*/
  if (auth === undefined) return false
  for (const p of perm) {
    if (auth?.data.perm.includes(p)) {
      return true
    }
  }

  return false
}

export const haveRole = (role: string, auth: Auth | undefined) => {
  if (auth === undefined) return false

  return auth?.data.role.includes(role)
}

export const dateToString = (date: Date) => {
  return (
    date.getFullYear() +
    '-' +
    ((date.getMonth() < 10 ? '0' : '') + (date.getMonth() + 1)) +
    '-' +
    ((date.getDate() < 10 ? '0' : '') + date.getDate()) +
    ' ' +
    ((date.getHours() < 10 ? '0' : '') + date.getHours()) +
    ':' +
    ((date.getMinutes() < 10 ? '0' : '') + date.getMinutes())
  )
}

export const dateToStringWithoutHours = (date: Date) => {
  return (
    date.getFullYear() +
    '-' +
    ((date.getMonth() < 10 ? '0' : '') + (date.getMonth() + 1)) +
    '-' +
    ((date.getDate() < 10 ? '0' : '') + date.getDate())
  )
}

export const hourToString = (date: Date) => {
  return (
    (date.getHours() < 10 ? '0' : '') +
    date.getHours() +
    ':' +
    ((date.getMinutes() < 10 ? '0' : '') + date.getMinutes())
  )
}

export const generalize = (object: any, value: undefined | true | false) => {
  const temp = object
  Object.keys(temp).forEach((key) => (temp[key] = value))
  return temp
}

export const generalizeOnNull = (object: any, value: '') => {
  Object.keys(object).forEach((key) => {
    if (object[key] == null) object[key] = value
  })
  return object
}
