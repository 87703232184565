import * as Yup from 'yup'

export enum DosageUnit {
  AMPOULE = 'AMPOULE',
  APPLICATION = 'APPLICATION',
  SPOON = 'SPOON',
  PILL = 'PILL',
  CAPSULE = 'CAPSULE',
  DRAGEIA = 'DRAGEIA',
  DROP = 'DROP',
  INHALATION = 'INHALATION',
  INJECTION = 'INJECTION',
  MILLILITER = 'MILLILITER',
  NEBULIZATION = 'NEBULIZATION',
  PASTILLE = 'PASTILLE',
  PILULE = 'PILULE',
  SEAL = 'SEAL',
  SUPPOSITORY = 'SUPPOSITORY',
  OVULE = 'OVULE',
  SACHET = 'SACHET',
}

export const options_du = [
  {value: 'AMPOULE', label: 'AMPOULE'},
  {value: 'APPLICATION', label: 'APPLICATION'},
  {value: 'SPOON', label: 'SPOON'},
  {value: 'PILL', label: 'PILL'},
  {value: 'CAPSULE', label: 'CAPSULE'},
  {value: 'DRAGEIA', label: 'DRAGEIA'},
  {value: 'DROP', label: 'DROP'},
  {value: 'INHALATION', label: 'INHALATION'},
  {value: 'INJECTION', label: 'INJECTION'},
  {value: 'MILLILITER', label: 'MILLILITER'},
  {value: 'NEBULIZATION', label: 'NEBULIZATION'},
  {value: 'PASTILLE', label: 'PASTILLE'},
  {value: 'PILULE', label: 'PILULE'},
  {value: 'SEAL', label: 'SEAL'},
  {value: 'SUPPOSITORY', label: 'SUPPOSITORY'},
  {value: 'OVULE', label: 'OVULE'},
  {value: 'SACHET', label: 'SACHET'},
]

export enum Frequency {
  ONE_IN_ONE = 'ONE_IN_ONE',
  TWO_IN_TWO = 'TWO_IN_TWO',
  THREE_IN_THREE = 'THREE_IN_THREE',
  FOUR_IN_FOUR = 'FOUR_IN_FOUR',
  FIVE_IN_FIVE = 'FIVE_IN_FIVE',
  SIX_IN_SIX = 'SIX_IN_SIX',
  SEVEN_IN_SEVEN = 'SEVEN_IN_SEVEN',
  EIGHT_IN_EIGHT = 'EIGHT_IN_EIGHT',
  NINE_IN_NINE = 'NINE_IN_NINE',
  TEN_IN_TEN = 'TEN_IN_TEN',
  ELEVEN_IN_ELEVEN = 'ELEVEN_IN_ELEVEN',
  TWELVE_IN_TWELVE = 'TWELVE_IN_TWELVE',
  THIRTEEN_IN_THIRTEEN = 'THIRTEEN_IN_THIRTEEN',
  FOURTEEN_IN_FOURTEEN = 'FOURTEEN_IN_FOURTEEN',
  FIFTEEN_IN_FIFTEEN = 'FIFTEEN_IN_FIFTEEN',
  SIXTEEN_IN_SIXTEEN = 'SIXTEEN_IN_SIXTEEN',
  SEVENTEEN_IN_SEVENTEEN = 'SEVENTEEN_IN_SEVENTEEN',
  EIGHTEEN_IN_EIGHTEEN = 'EIGHTEEN_IN_EIGHTEEN',
  NINETEEN_IN_NINETEEN = 'NINETEEN_IN_NINETEEN',
  TWENTY_IN_TWENTY = 'TWENTY_IN_TWENTY',
  TWENTY_ONE_IN_TWENTY_ONE = 'TWENTY_ONE_IN_TWENTY_ONE',
  TWENTY_TWO_IN_TWENTY_TWO = 'TWENTY_TWO_IN_TWENTY_TWO',
  TWENTY_THREE_IN_TWENTY_THREE = 'TWENTY_THREE_IN_TWENTY_THREE',
  TWENTY_FOUR_IN_TWENTY_FOUR = 'TWENTY_FOUR_IN_TWENTY_FOUR',
}

export const options_f = [
  {value: 'ONE_IN_ONE', label: 'ONE_IN_ONE'},
  {value: 'TWO_IN_TWO', label: 'TWO_IN_TWO'},
  {value: 'THREE_IN_THREE', label: 'THREE_IN_THREE'},
  {value: 'FOUR_IN_FOUR', label: 'FOUR_IN_FOUR'},
  {value: 'FIVE_IN_FIVE', label: 'FIVE_IN_FIVE'},
  {value: 'SIX_IN_SIX', label: 'SIX_IN_SIX'},
  {value: 'SEVEN_IN_SEVEN', label: 'SEVEN_IN_SEVEN'},
  {value: 'EIGHT_IN_EIGHT', label: 'EIGHT_IN_EIGHT'},
  {value: 'NINE_IN_NINE', label: 'NINE_IN_NINE'},
  {value: 'TEN_IN_TEN', label: 'TEN_IN_TEN'},
  {value: 'ELEVEN_IN_ELEVEN', label: 'ELEVEN_IN_ELEVEN'},
  {value: 'TWELVE_IN_TWELVE', label: 'TWELVE_IN_TWELVE'},
  {value: 'THIRTEEN_IN_THIRTEEN', label: 'THIRTEEN_IN_THIRTEEN'},
  {value: 'FOURTEEN_IN_FOURTEEN', label: 'FOURTEEN_IN_FOURTEEN'},
  {value: 'FIFTEEN_IN_FIFTEEN', label: 'FIFTEEN_IN_FIFTEEN'},
  {value: 'SIXTEEN_IN_SIXTEEN', label: 'SIXTEEN_IN_SIXTEEN'},
  {value: 'SEVENTEEN_IN_SEVENTEEN', label: 'SEVENTEEN_IN_SEVENTEEN'},
  {value: 'EIGHTEEN_IN_EIGHTEEN', label: 'EIGHTEEN_IN_EIGHTEEN'},
  {value: 'NINETEEN_IN_NINETEEN', label: 'NINETEEN_IN_NINETEEN'},
  {value: 'TWENTY_IN_TWENTY', label: 'TWENTY_IN_TWENTY'},
  {value: 'TWENTY_ONE_IN_TWENTY_ONE', label: 'TWENTY_ONE_IN_TWENTY_ONE'},
  {value: 'TWENTY_TWO_IN_TWENTY_TWO', label: 'TWENTY_TWO_IN_TWENTY_TWO'},
  {value: 'TWENTY_THREE_IN_TWENTY_THREE', label: 'TWENTY_THREE_IN_TWENTY_THREE'},
  {value: 'TWENTY_FOUR_IN_TWENTY_FOUR', label: 'TWENTY_FOUR_IN_TWENTY_FOUR'},
]

export enum FrequencyUnit {
  HOUR = 'HOUR',
}

export const options_fu = [{value: 'HOUR', label: 'HOUR'}]

export const medicationSchema = Yup.object().shape({
  name: Yup.string().required('Field Required'),
  description: Yup.string().required('Field Required'),
  dosage: Yup.number().required('Field Required'),
  dosage_unit: Yup.mixed<DosageUnit>().oneOf(Object.values(DosageUnit)).required('Field Required'),
  frequency: Yup.mixed<Frequency>().oneOf(Object.values(Frequency)).required('Field Required'),
  frequency_unit: Yup.mixed<FrequencyUnit>()
    .oneOf(Object.values(FrequencyUnit))
    .required('Field Required'),
  medicationType_id: Yup.number().required('Field Required'),
})

export const prescriptionSchema = Yup.object().shape({
  start_at: Yup.date().required('Field Required'),
  end_at: Yup.date().required('Field Required').min(Yup.ref('start_at')),
  date_first_schedule: Yup.date()
    .required('Field Required')
    .min(Yup.ref('start_at'))
    .max(Yup.ref('end_at')),
  dosage: Yup.number().required('Field Required'),
  dosage_unit: Yup.mixed<DosageUnit>().oneOf(Object.values(DosageUnit)).required('Field Required'),
  frequency: Yup.mixed<Frequency>().oneOf(Object.values(Frequency)).required('Field Required'),
  frequency_unit: Yup.mixed<FrequencyUnit>()
    .oneOf(Object.values(FrequencyUnit))
    .required('Field Required'),
  observation: Yup.string().optional().nullable(),
  user_id: Yup.string().required('Field Required'),
  medication_id: Yup.string().required('Field Required'),
  responsible_id: Yup.string().required('Field Required'),
})
