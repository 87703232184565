import axios, {AxiosResponse} from 'axios'
import {DEVICES_URL} from "../app/_const";
import {DevicesQueryResponse} from "../models/_device";


const getDevices = (query: string, id: string): Promise<DevicesQueryResponse> => {
    return axios
        .get(`${DEVICES_URL}?clinic_id=${id}&${query}`)
        .then((d: AxiosResponse<DevicesQueryResponse>) => d.data)
}

export {getDevices}
