import { FC } from "react";
import { useIntl } from "react-intl";

type Props = {
    success: boolean
}

const StatusCell: FC<Props> = ({success}) => {
    const intl = useIntl()
    return <>
        {success ? (<span className="badge badge-light-success">{intl.formatMessage({id: "Associado"})}</span>) :
            <span className="badge badge-light-danger">{intl.formatMessage({id: "Desassociado"})}</span>
        }
    </>
}

export {StatusCell}
