import { ListViewProvider } from "./core/ListViewProvider";
import { QueryRequestProvider } from "./core/QueryRequestProvider";
import { QueryResponseProvider } from "./core/QueryResponseProvider";
import { ClinicsTable } from "./table/ClinicsTable";
import { KTCard } from "../../../../../_metronic/helpers";

const ClinicsListWrapper = () => (
    <QueryRequestProvider>
        <QueryResponseProvider>
            <ListViewProvider>
                <KTCard>
                    <ClinicsTable/>
                </KTCard>
            </ListViewProvider>
        </QueryResponseProvider>
    </QueryRequestProvider>
)

export {ClinicsListWrapper}
