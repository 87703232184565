import {useNavigate, useParams} from 'react-router-dom'
import {useIntl} from 'react-intl'
import {useEffect, useRef, useState} from 'react'
import SignaturePad from 'react-signature-canvas'
import SignatureCanvas from 'react-signature-canvas'
import axios from 'axios'
import {RGPD_URL} from '../core/_requests'
import {toast} from 'react-toastify'
import {toastStyles} from '../../../_styles'

export function RgpdSignature() {
  const intl = useIntl()
  const {token} = useParams()
  const navigate = useNavigate()
  const [form, setForm] = useState<{ckb: boolean; ckb2: boolean}>({ckb: false, ckb2: false})
  const signCanvas = useRef<SignatureCanvas>(null)

  useEffect(() => {
    const fetch = async () => {
      await axios.post(RGPD_URL + '/verify', {token: token}).then((res) => {
        if (res.status == 204) {
          toast.error(intl.formatMessage({id: 'Token inválido'}), toastStyles)
          navigate('/auth')
        }
      })
    }
    fetch()
  }, [])

  const clear = () => {
    signCanvas.current?.clear()
  }
  const trim = async () => {
    const fileDownload = require('js-file-download')
    signCanvas.current?.getTrimmedCanvas().toBlob(async (blob) => {
      if (blob != null) {
        let formData = new FormData()
        formData.append('token', token ?? '')
        var file = new File([blob], 'signature.jpg')
        if (file) {
          var reader = new FileReader()
          reader.readAsText(file)
          formData.append('file', file)
        }
        await axios.post(RGPD_URL, formData).then((res) => {
          if (res.status == 200) {
            toast.success(intl.formatMessage({id: 'Rgpd Aceite'}), toastStyles)
            navigate('/auth')
          }
        })
      }
    })
  }

  return (
    <form className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'>
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>
          {intl.formatMessage({id: 'Regulamento Geral sobre a Proteção de Dados'})}
        </h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-500 fw-semibold fs-6'>
          {intl.formatMessage({id: 'Aceite os termos em baixo e assine'})}
        </div>
        {/* end::Link */}
      </div>

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <div className='mb-10'>
          <div className='form-check form-switch form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              value=''
              id='flexSwitchDefault1'
              onChange={() => {
                setForm({...form, ckb: !form.ckb})
              }}
            />
            <label className='form-check-label' htmlFor='flexSwitchDefault1'>
              {intl.formatMessage({
                id: 'Permito que meus dados pessoais sejam processados pela GUISOFT Lda..',
              })}
            </label>
          </div>
        </div>
      </div>
      {/* end::Form group */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <div className='mb-10'>
          <div className='form-check form-switch form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              value=''
              id='flexSwitchDefault2'
              onChange={() => {
                setForm({...form, ckb2: !form.ckb2})
              }}
            />
            <label className='form-check-label' htmlFor='flexSwitchDefault2'>
              {intl.formatMessage({
                id: 'Autorizo a utilização dos meus dados para receber informações sobre produtos e serviços ou para fins comerciais.',
              })}
            </label>
          </div>
        </div>
      </div>
      {/* end::Form group */}
      {form.ckb2 && form.ckb && (
        <>
          <SignaturePad ref={signCanvas} canvasProps={{height: 200, className: 'bg-white w-100'}} />
          <div className={'d-flex flex-end'}>
            <button type='button' className='btn btn-sm btn-light-danger' onClick={clear}>
              {intl.formatMessage({id: 'Limpar'})}
            </button>
            <button type='button' className='btn btn-sm btn-primary ms-1' onClick={trim}>
              {intl.formatMessage({id: 'Guardar'})}
            </button>
          </div>
        </>
      )}
    </form>
  )
}
