import React from "react";

// @ts-ignore
import logo from "../../../../assets/ecg.gif";
import { useIntl } from "react-intl";


const ListLoading = () => {
    const intl = useIntl()
    return <>
        <div className="w-100 h-100 d-flex justify-content-center">
            <img src={logo} alt=""/>
        </div>
        <h3 className={"text-center text-muted w-100"}>{intl.formatMessage({id: "Please wait..."})}</h3>
    </>
}

export {ListLoading}
