/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from "react";
import { MenuComponent } from "../../../../../../../_metronic/assets/ts/components";
import { DcRecordModal } from "../../../../modals/DcRecordModal";
import { DailyCheck } from "../../../../../../../models/_dailyCheck";

type Props = {
    dc: DailyCheck
}

const ActionsCell: FC<Props> = ({dc}) => {

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])


    return (
        <>
            <DcRecordModal dc={dc}/>
        </>
    )
}

export {ActionsCell}
