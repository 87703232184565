import React from 'react'
import {useIntl} from 'react-intl'

interface Props {
  title: string
}

function CustomModalHeader({title}: Props) {
  const intl = useIntl()

  return (
    <div className='modal-header'>
      <h3 className='card-title text-primary'>{intl.formatMessage({id: title})}</h3>
      <button
        type='button'
        className='btn-close'
        aria-label='Close'
        data-bs-dismiss='modal'
      ></button>
    </div>
  )
}

export default CustomModalHeader
