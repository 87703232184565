import * as Yup from 'yup'

export const appointmentSchema = Yup.object().shape({
  subject: Yup.string().required('Field Required'),
  location: Yup.string().required('Field Required'),
  observation: Yup.string().optional().nullable(),
  appointmentCategory_id: Yup.string().required('Field Required'),
  appointmentPriority_id: Yup.string().required('Field Required'),
  responsible_id: Yup.string().required('Field Required'),
  user_id: Yup.string().required('Field Required'),
  date_begin: Yup.date().required('Field Required'),
  date_end: Yup.date().required('Field Required'),
})
