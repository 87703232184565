import axios, {AxiosResponse} from 'axios'
import {PRIORITIES_URL} from "../app/_const";
import {PriorityQueryResponse} from "../models/_priority";


const getPriorities = (query: string): Promise<PriorityQueryResponse> => {
    return axios
        .get(`${PRIORITIES_URL}?${query}`)/**/
        .then((d: AxiosResponse<PriorityQueryResponse>) => d.data)
}

export {getPriorities}