import {useEffect, useMemo, useState} from 'react'
import {ColumnInstance, Row, useTable} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {ListLoading} from '../../../../_components/dataTable/loading/ListLoading'
import {KTCardBody, stringifyRequestQuery} from '../../../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import {DcsListSearchComponent} from '../components/header/DcsListSearchComponent'
import {DailyCheck, DailyChecksQueryResponse} from '../../../../../../models/_dailyCheck'
import {dcColumns} from './columns/_columns'
import {DailyChecksListPagination} from '../components/header/DcListPagination'
import {useQueryRequest} from '../../ecgs-list/core/QueryRequestProvider'
import {useOutletContext, useParams} from 'react-router-dom'
import {Ecg, EcgsQueryResponse} from '../../../../../../models/_ecg'
import {MenuComponent} from '../../../../../../_metronic/assets/ts/components'
import axios, {AxiosResponse} from 'axios'
import {API_URL} from '../../../../../_const'

const DcsTable = () => {
  const intl = useIntl()
  const {id} = useParams()
  const {state} = useQueryRequest()
  const interval: Date[] = useOutletContext()
  const [dcs, setDcs] = useState<DailyCheck[]>([])
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])
  const [isLoading, setLoading] = useState(useQueryResponseLoading())

  useEffect(() => {
    MenuComponent.reinitialization()
    setLoading(true)
    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
    const fetchData = async () => {
      const date_start: string =
        interval[0].getFullYear() +
        '-' +
        (interval[0].getMonth() + 1 > 10
          ? interval[0].getMonth() + 1
          : '0' + (interval[0].getMonth() + 1)) +
        '-' +
        interval[0].getDate()
      const date_end: string =
        interval[1].getFullYear() +
        '-' +
        (interval[1].getMonth() + 1 > 10
          ? interval[1].getMonth() + 1
          : '0' + (interval[1].getMonth() + 1)) +
        '-' +
        interval[1].getDate()
      const res = await axios
        .get(
          `${API_URL}/daily-checks?user_id=${id}&date_start=${date_start} 00:00:00&date_end=${date_end} 23:59:59&${updatedQuery}`
        ) /**/
        .then((d: AxiosResponse<DailyChecksQueryResponse>) => d.data)
      setDcs(res.data || [])
    }
    if (interval[0] && interval[1]) fetchData()

    setLoading(false)
  }, [updatedQuery, interval])

  const columns = useMemo(() => dcColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data: dcs,
  })

  return (
    <>
      <div className='card-header border-0 pt-6'>
        <DcsListSearchComponent />
        {/* begin::Card toolbar */}
        <div className='card-toolbar'>
          <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
            {/*<AssociatePatientModal/>*/}
          </div>
        </div>
        {/* end::Card toolbar */}
      </div>
      <KTCardBody className='py-4'>
        {!isLoading ? (
          <>
            <div className='table-responsive'>
              <table
                id='kt_table_users'
                className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                {...getTableProps()}
              >
                <thead>
                  <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                    {headers.map((column: ColumnInstance<DailyCheck>) => (
                      <CustomHeaderColumn key={column.id} column={column} />
                    ))}
                  </tr>
                </thead>
                <tbody className='text-gray-600' {...getTableBodyProps()}>
                  {rows.length > 0 ? (
                    rows.map((row: Row<DailyCheck>, i) => {
                      prepareRow(row)
                      return <CustomRow row={row} key={`row-${i}-${row.id}`} />
                    })
                  ) : (
                    <tr>
                      <td colSpan={11}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                          {intl.formatMessage({id: 'Não foram encontrados resultados'})}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <DailyChecksListPagination />
          </>
        ) : (
          <ListLoading />
        )}
      </KTCardBody>
    </>
  )
}

export {DcsTable}
