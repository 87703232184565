// @ts-nocheck
import { Column } from "react-table";
import { ClinicContactsCustomHeader } from "./ClinicContactsCustomHeader";
import { Contact } from "../../../../../../../models/_contacts";

const contactsColumns: ReadonlyArray<Column<Contact>> = [
    {
        Header: (props) => <ClinicContactsCustomHeader tableProps={props} title='Nome' className='min-w-125px'/>,
        accessor: 'name',
    },
    {
        Header: (props) => <ClinicContactsCustomHeader tableProps={props} title='Contacto' className='min-w-125px'/>,
        accessor: 'phone',
    },
    {
        Header: (props) => <ClinicContactsCustomHeader tableProps={props} title='Email' className='min-w-125px'/>,
        accessor: 'email',
    },
    {
        Header: (props) => <ClinicContactsCustomHeader tableProps={props} title='Função' className='min-w-125px'/>,
        accessor: 'function_role.name',
    },
    {
        Header: (props) => <ClinicContactsCustomHeader tableProps={props} title='Descrição' className='min-w-125px'/>,
        accessor: 'observation',
    },
    {
        Header: (props) => (
            <ClinicContactsCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px'/>
        ),
        id: 'actions',
    },
]

export {contactsColumns}
