// @ts-nocheck
import {Column} from 'react-table'
import {RelativeCustomHeader} from './RelativeCustomHeader'
import {Relative} from '../../../../../../../models/_relative'

const relativesColumns: ReadonlyArray<Column<Relative>> = [
  {
    Header: (props) => (
      <RelativeCustomHeader tableProps={props} title='Nome' className='min-w-125px' />
    ),
    accessor: 'name',
  },
  {
    Header: (props) => (
      <RelativeCustomHeader tableProps={props} title='Grau de Parentesco' className='min-w-125px' />
    ),
    accessor: 'relationship.name',
  },
  {
    Header: (props) => (
      <RelativeCustomHeader tableProps={props} title='Telemóvel' className='min-w-125px' />
    ),
    accessor: 'mobile_phone',
  },
  {
    Header: (props) => (
      <RelativeCustomHeader tableProps={props} title='Telefone' className='min-w-125px' />
    ),
    accessor: 'phone',
  },
  {
    Header: (props) => (
      <RelativeCustomHeader tableProps={props} title='NISS' className='min-w-125px' />
    ),
    accessor: 'niss',
  },
  {
    Header: (props) => (
      <RelativeCustomHeader tableProps={props} title='Email' className='min-w-125px' />
    ),
    accessor: 'email',
  },
  {
    Header: (props) => (
      <RelativeCustomHeader tableProps={props} title='Qualificações' className='min-w-125px' />
    ),
    accessor: 'qualifications',
  },
  {
    Header: (props) => (
      <RelativeCustomHeader tableProps={props} title='Profissão' className='min-w-125px' />
    ),
    accessor: 'profession.name',
  },

  {
    Header: (props) => (
      <RelativeCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px' />
    ),
    id: 'actions',
  },
]

export {relativesColumns}
