/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect } from "react";
import { MenuComponent } from "../../../../../../../_metronic/assets/ts/components";
import { ATTACHMENT_URL } from "../../../../../../_const";
import axios from "axios";
import { Attachment } from "../../../../../../../models/_attachment";
import { useParams } from "react-router-dom";
import { ID } from "../../../../../../../_metronic/helpers";
import { useAuth } from "../../../../../../modules/auth";
import { havePerms } from "../../../../../../_helpers";


type Props = {
    attachment: Attachment
    openModalDelete: (id: ID) => void

}

const DownloadCell: FC<Props> = ({attachment, openModalDelete}) => {
    const {id} = useParams()
    const {auth} = useAuth();

    useEffect(() => {
        MenuComponent.reinitialization()
    }, [])

    const download = (attachment: Attachment) => {
        const fetch = async () => {
            const fileDownload = require('js-file-download');
            await axios.post(ATTACHMENT_URL + "/download", {
                id: attachment.id,
                clinic_id: id
            }, {responseType: "blob"}).then((response) => fileDownload(response.data, attachment.name))
        }
        fetch()
    }

    return (

        <>
            {havePerms(['attachments:delete'], auth) &&
                < button
                    className='btn btn-light btn-active-light-danger btn-sm me-1 p-3 ps-4 pe-4 '
                    data-bs-toggle="modal" data-bs-target="#kt_modal_delete_item"
                    onClick={() => openModalDelete(attachment.id)}
                >
                    <i className="fa-sharp fa-solid p-0 fa-trash-can text-danger"></i>
                </button>}
            <button
                className='btn btn-light btn-active-light-primary btn-sm p-3 ps-4 pe-4 '
                onClick={() => download(attachment)}
            >
                <i className="fa-sharp fa-solid p-0 fa-arrow-up-from-bracket text-primary"></i>
            </button>

        </>
    )
}

export {DownloadCell}
