// @ts-nocheck
import clsx from 'clsx'
import {FC} from 'react'
import {Row} from 'react-table'
import {ID} from '../../../../../../_metronic/helpers'
import {IntlShape} from 'react-intl'
import {useAuth} from '../../../../../modules/auth'
import {havePerm, havePerms} from '../../../../../_helpers'
import {Role} from '../../../../../../models/_role'

type Props = {
  row: Row<Role>
  openModal: (id: ID) => void
  openModalDelete: (id: ID) => void
  intl: IntlShape
}

const CustomRow: FC<Props> = ({row, openModal, openModalDelete}) => {
  const {auth} = useAuth()

  return (
    <tr {...row.getRowProps()}>
      {row.cells.map((cell) => {
        return (
          <>
            {((havePerms(['brands:update', 'brands:delete'], auth) &&
              cell.column.id === 'actions') ||
              cell.column.id !== 'actions') && (
              <td
                {...cell.getCellProps()}
                className={clsx({'text-end min-w-100px': cell.column.id === 'actions'})}
              >
                {cell.column.id !== 'actions' ? (
                  cell.render('Cell')
                ) : (
                  <>
                    {havePerm('brands:update', auth) && (
                      <button
                        className='btn btn-light btn-active-light-primary btn-sm me-1 p-3 ps-4 pe-4 '
                        onClick={() => openModal(row.original.id)}
                      >
                        <i className='fa-sharp fa-solid p-0 fa-pen text-primary'></i>
                      </button>
                    )}
                    {havePerm('brands:delete', auth) && (
                      <button
                        className='btn btn-light btn-active-light-danger btn-sm p-3 ps-4 pe-4 '
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_delete_item'
                        onClick={() => openModalDelete(row.original.id)}
                      >
                        <i className='fa-sharp fa-solid p-0 fa-trash-can text-danger'></i>
                      </button>
                    )}
                  </>
                )}
              </td>
            )}
          </>
        )
      })}
    </tr>
  )
}

export {CustomRow}
