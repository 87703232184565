import React, { useEffect, useState } from "react";
import ToolBar from "../components/ToolBar";
import { Outlet, useLocation, useParams } from "react-router-dom";
import axios, { AxiosResponse } from "axios";
import { CLINICS_URL } from "../../../_const";
import { Clinic } from "../../../../models/_clinic";

export type GlobalContent = {
    copy: string
    setCopy: (c: string) => void
}

function ClinicLayout() {
    const {id} = useParams()
    const location = useLocation()
    const [clinic, setClinic] = useState<Clinic>({} as Clinic)
    const [title, setTitle] = useState("Geral")
    const titles: { [key: string]: string } = {
        "/clinics": "Tabela",
        "/clinic/base/": "Geral",
        "/clinic/health/": "RGPD",
        "/clinic/records/": "Contactos",
        "/clinic/patients/": "Pacientes",
        "/clinic/collaborators/": "Colaboradores",
        "/clinic/notes/": "Notas",
        "/clinic/devices/": "Dispositivos"
    }

    useEffect(() => {
        const fetchData = async () => {
            const rclinics: AxiosResponse<{ data: Clinic }> = await axios
                .get(`${CLINICS_URL}/${id}`).catch((err) => err)
            setClinic(rclinics.data.data)
        }

        fetchData()
    }, [])

    useEffect(() => {
        let path = location.pathname.includes(`${id}`) ? location.pathname.replace(`${id}`, "") : location.pathname
        let tTitle = titles[path] ?? "Geral"
        setTitle(tTitle)
    }, [location])

    return (
        <>
            <ToolBar title={title} division="Clinica" clinic={clinic}/>
            <Outlet context={[clinic, setClinic]}/>
        </>
    );
}

export default ClinicLayout;

