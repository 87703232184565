import axios, {AxiosResponse} from 'axios'
import {MEDICATION_URL, PRESCRIPTIONS_URL, SCHEDULES_URL} from "../app/_const";
import {MedicationsQueryResponse, PrescriptionsQueryResponse, SchedulesQueryResponse} from "../models/_schedule";


const getSchedules = (query: string, id: string): Promise<SchedulesQueryResponse> => {
    if (query == "") return axios
        .get(`${SCHEDULES_URL}?prescription_id=${id}`)
        .then((d: AxiosResponse<SchedulesQueryResponse>) => d.data)
    else return axios
        .get(`${SCHEDULES_URL}?prescription_id=${id}&status=${query}`)
        .then((d: AxiosResponse<SchedulesQueryResponse>) => d.data)
}
const getPrescriptions = (query: string, id: string): Promise<PrescriptionsQueryResponse> => {
    return axios
        .get(`${PRESCRIPTIONS_URL}?user_id=${id}&${query}`)
        .then((d: AxiosResponse<PrescriptionsQueryResponse>) => d.data)
}
const getMedications = (query: string, id: string): Promise<MedicationsQueryResponse> => {
    return axios
        .get(`${MEDICATION_URL}?user_id=${id}&${query}`)
        .then((d: AxiosResponse<MedicationsQueryResponse>) => d.data)
}

export {getSchedules, getPrescriptions, getMedications}