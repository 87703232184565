// @ts-nocheck
import { Column } from "react-table";
import { ClinicEmailTemplatesCustomHeader } from "./ClinicEmailTemplatesCustomHeader";
import { EmailTemplate } from "../../../../../../../models/_email";
import { DefaultBadge } from "./DefaultBadge";

const emailTemplatesColumns: ReadonlyArray<Column<EmailTemplate>> = [
    {
        Header: (props) => <ClinicEmailTemplatesCustomHeader tableProps={props} title='Assunto'
                                                             className='min-w-125px'/>,
        accessor: 'subject',
    },
    {
        Header: (props) => <ClinicEmailTemplatesCustomHeader tableProps={props} title='Padrão'
                                                             className='min-w-125px'/>,
        accessor: 'isDefault',
        Cell: ({...props}) => <DefaultBadge template={props.data[props.row.index]}/>
    },
    {
        Header: (props) => (
            <ClinicEmailTemplatesCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px'/>
        ),
        id: 'actions',

    },
]

export {emailTemplatesColumns}
