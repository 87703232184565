import { ListViewProvider } from "./core/ListViewProvider";
import { QueryRequestProvider } from "./core/QueryRequestProvider";
import { QueryResponseProvider } from "./core/QueryResponseProvider";
import { KTCard } from "../../../../../_metronic/helpers";
import { CollabsTable } from "./table/CollabsTable";


const CollabList = () => {
    return (
        <>
            <KTCard>
                <CollabsTable/>
            </KTCard>
        </>
    )
}

const CollabListWrapper = () => (
    <QueryRequestProvider>
        <QueryResponseProvider>
            <ListViewProvider>
                <CollabList/>
            </ListViewProvider>
        </QueryResponseProvider>
    </QueryRequestProvider>
)

export {CollabListWrapper}
