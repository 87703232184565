import { ListViewProvider } from "./core/ListViewProvider";
import { QueryRequestProvider } from "./core/QueryRequestProvider";
import { QueryResponseProvider } from "./core/QueryResponseProvider";
import { ClinicsCollabTable } from "./table/ClinicsCollabTable";
import { KTCard } from "../../../../../_metronic/helpers";


const ClinicsCollabList = () => {
    return (
        <>
            <KTCard>
                <ClinicsCollabTable/>
            </KTCard>
        </>
    )
}

const ClinicsCollabListWrapper = () => (
    <QueryRequestProvider>
        <QueryResponseProvider>
            <ListViewProvider>
                <ClinicsCollabList/>
            </ListViewProvider>
        </QueryResponseProvider>
    </QueryRequestProvider>
)

export {ClinicsCollabListWrapper}
