import { ListViewProvider } from "./core/ListViewProvider";
import { QueryRequestProvider } from "./core/QueryRequestProvider";
import { QueryResponseProvider } from "./core/QueryResponseProvider";
import { KTCard } from "../../../../../../_metronic/helpers";
import { DevicesTable } from "./table/DevicesTable";

const DevicesList = () => {
  return (
    <>
      <KTCard>
        <DevicesTable />
      </KTCard>
    </>
  )
}

const DevicesListWrapper = () => (
  <QueryRequestProvider>
    <QueryResponseProvider>
      <ListViewProvider>
        <DevicesList />
      </ListViewProvider>
    </QueryResponseProvider>
  </QueryRequestProvider>
)

export {DevicesListWrapper}
