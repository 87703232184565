import axios, {AxiosResponse} from 'axios'
import {ClinicsQueryResponse} from "../models/_clinic";
import {CLINICS_URL} from "../app/_const";


const getClinics = (query: string): Promise<ClinicsQueryResponse> => {
    return axios
        .get(`${CLINICS_URL}?${query}`)/**/
        .then((d: AxiosResponse<ClinicsQueryResponse>) => d.data)
}


export {getClinics}