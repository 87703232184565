import {useEffect, useMemo, useState} from 'react'
import {ColumnInstance, Row, useTable} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {ListLoading} from '../../../_components/dataTable/loading/ListLoading'
import {ID, KTCardBody, stringifyRequestQuery} from '../../../../../_metronic/helpers'
import {MedicationsListPagination} from '../components/header/MedicationsListPagination'
import {useQueryRequest} from '../core/QueryRequestProvider'
import {useParams} from 'react-router-dom'
import axios, {AxiosResponse} from 'axios'
import {MEDICATION_TYPE_URL, MEDICATION_URL} from '../../../../_const'
import {useIntl} from 'react-intl'
import {DeleteItemModal} from '../../../../modals/DeleteItemModal'
import {MedicationsSearchComponent} from '../components/header/MedicationsSearchComponent'
import {MenuComponent} from '../../../../../_metronic/assets/ts/components'
import {medicationsColumns} from './columns/_columns'
import {Medication, MedicationsQueryResponse} from '../../../../../models/_schedule'
import {AddMedModal} from '../components/modals/AddMedModal'
import {MedicationType} from '../../../../../models/_medicationType'
import {EditMedModal} from '../components/modals/EditMedModal'
import {useAuth} from '../../../../modules/auth'
import {havePerm, havePerms} from '../../../../_helpers'

const MedicationsTable = () => {
  //CONTEXTS
  const {id} = useParams()
  const intl = useIntl()
  const {state} = useQueryRequest()
  const {auth} = useAuth()

  //STATES
  const [isLoading, setLoading] = useState(true) // CAN BE DISOLVED USING REACT-QUERY
  const [deletable, setDeletable] = useState<ID>(-1)
  const [editable, setEditable] = useState<Medication>({} as Medication)
  const [medications, setMedications] = useState<Medication[]>([])
  const [query, setQuery] = useState<string>(stringifyRequestQuery(state))
  const [types, setTypes] = useState<MedicationType[]>([])
  const updatedQuery = useMemo(() => stringifyRequestQuery(state), [state])

  useEffect(() => {
    MenuComponent.reinitialization()
    setLoading(true)

    if (query !== updatedQuery) {
      setQuery(updatedQuery)
    }
    const fetchData = async () => {
      const res = await axios
        .get(`${MEDICATION_URL}?${updatedQuery}`) /**/
        .then((d: AxiosResponse<MedicationsQueryResponse>) => d.data)
      setMedications(res.data || [])
      setTypes(
        await axios
          .get(`${MEDICATION_TYPE_URL}/all`) /**/
          .then((d: AxiosResponse<{data: MedicationType[]}>) => d.data.data)
      )
    }
    fetchData()

    setLoading(false)
  }, [updatedQuery])

  const columns = useMemo(() => medicationsColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data: medications,
  })

  const openModalFunc = async (id: ID) => {
    await setEditable(medications.find((element) => (element.id ?? 0) === id) ?? medications[0])
  }
  const openModalDel = async (id: ID) => {
    await setDeletable(
      medications.find((element) => (element.id ?? 0) === id)?.id ?? medications[0].id
    )
  }

  return (
    <>
      <div className='card-header border-0 pt-6'>
        <MedicationsSearchComponent />
        {/* begin::Card toolbar */}
        <div className='card-toolbar'>
          {havePerm('medications:create', auth) && (
            <AddMedModal
              types={types}
              updateTable={(medication: Medication) => {
                setMedications([...medications, medication])
              }}
            />
          )}
        </div>
        {/* end::Card toolbar */}
      </div>
      <KTCardBody className='py-4'>
        <div className='table-responsive'>
          <table
            id='kt_table_users'
            className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
            {...getTableProps()}
          >
            <thead>
              <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gies-0'>
                {headers.map(
                  (column: ColumnInstance<Medication>) =>
                    (havePerms(['medications:update', 'medications:delete'], auth) ||
                      column.id != 'actions') && (
                      <CustomHeaderColumn key={column.id} column={column} />
                    )
                )}
              </tr>
            </thead>
            <tbody className='text-gray-600' {...getTableBodyProps()}>
              {rows.length > 0 ? (
                rows.map((row: Row<Medication>, i) => {
                  prepareRow(row)
                  return (
                    <CustomRow
                      row={row}
                      key={`row-${i}-${row.id}`}
                      openModal={(id: ID) => openModalFunc(id)}
                      openModalDelete={(id: ID) => openModalDel(id)}
                      intl={intl}
                    />
                  )
                })
              ) : (
                <tr>
                  <td colSpan={7}>
                    <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                      {intl.formatMessage({id: 'Não foram encontrados resultados'})}
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <MedicationsListPagination />
        {isLoading && <ListLoading />}

        <DeleteItemModal
          toastTitle={'Tipo de Medicação Eliminado'}
          url={MEDICATION_URL}
          id={deletable}
          deleteRecord={(id: ID) => {
            setMedications((current) =>
              current.filter((obj) => {
                return obj.id !== id
              })
            )
          }}
        />
        {
          <EditMedModal
            medication={editable}
            updateList={(medication: Medication) => {
              setMedications((current) =>
                current.map((obj) => {
                  if (obj.id === medication.id) {
                    return medication
                  }
                  return obj
                })
              )
            }}
            types={types}
          />
        }
      </KTCardBody>
    </>
  )
}

export {MedicationsTable}
