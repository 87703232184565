import axios, {AxiosResponse} from 'axios'
import {MEDICATION_TYPE_URL} from "../app/_const";
import {PathologysQueryResponse} from "../models/_pathology";
import {MedicationTypesQueryResponse} from "../models/_medicationType";


const getMedicationTypes = (query: string, id: string): Promise<MedicationTypesQueryResponse> => {
    return axios
        .get(`${MEDICATION_TYPE_URL}?${query}`)/**/
        .then((d: AxiosResponse<PathologysQueryResponse>) => d.data)
}

export {getMedicationTypes}
