import {useEffect, useMemo, useState} from 'react'
import {ColumnInstance, Row, useTable} from 'react-table'
import {CustomHeaderColumn} from './columns/CustomHeaderColumn'
import {CustomRow} from './columns/CustomRow'
import {useQueryResponseData, useQueryResponseLoading} from '../core/QueryResponseProvider'
import {ID, KTCardBody} from '../../../../../_metronic/helpers'
import {ListLoading} from '../../../_components/dataTable/loading/ListLoading'
import {useAuth} from '../../../../modules/auth'
import {havePerm} from '../../../../_helpers'
import {useIntl} from 'react-intl'
import {RolesListSearchComponent} from '../components/header/RolesListSearchComponent'
import {Role} from '../../../../../models/_role'
import {RolesListPagination} from '../components/header/RolesListPagination'
import {rolesColumns} from './columns/_columns'
import {AddRoleModal} from '../components/modals/AddRoleModal'
import {DeleteItemModal} from '../../../../modals/DeleteItemModal'
import {ROLE_URL} from '../../../../_const'
import {useNavigate} from 'react-router-dom'

const RolesTable = () => {
  const intl = useIntl()
  const {auth} = useAuth()
  const navigate = useNavigate()
  const roles = useQueryResponseData()
  const isLoading = useQueryResponseLoading()
  const data = useMemo(() => roles, [roles])
  const [rowsTable, setRowsTable] = useState(data)
  const [deletable, setDeletable] = useState<ID>(-1)
  const columns = useMemo(() => rolesColumns, [])
  const {getTableProps, getTableBodyProps, headers, rows, prepareRow} = useTable({
    columns,
    data: rowsTable,
  })
  useEffect(() => {
    setRowsTable(data)
  }, [data])
  return (
    <>
      <div className='card-header border-0 pt-6'>
        <RolesListSearchComponent />
        {/* begin::Card toolbar */}
        <div className='card-toolbar'>
          {havePerm('users:list', auth) && havePerm('clinics:create', auth) && (
            <AddRoleModal
              updateTable={(role) => {
                setRowsTable([...rowsTable, role])
              }}
            />
          )}
        </div>
        {/* end::Card toolbar */}
      </div>
      <KTCardBody className='py-4'>
        {!isLoading ? (
          <>
            <div className='table-responsive'>
              <table
                id='kt_table_users'
                className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer  '
                {...getTableProps()}
              >
                <thead>
                  <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                    {headers.map((column: ColumnInstance<Role>) => (
                      <CustomHeaderColumn key={column.id} column={column} />
                    ))}
                  </tr>
                </thead>
                <tbody className='text-gray-600' {...getTableBodyProps()}>
                  {rows.length > 0 ? (
                    rows.map((row: Row<Role>, i) => {
                      prepareRow(row)
                      return (
                        <CustomRow
                          row={row}
                          key={`row-${i}-${row.id}`}
                          intl={intl}
                          openModal={(id) => {
                            navigate(`/role/${id}`)
                          }}
                          openModalDelete={(id) => {
                            setDeletable(id)
                          }}
                        />
                      )
                    })
                  ) : (
                    <tr>
                      <td colSpan={9}>
                        <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                          {intl.formatMessage({id: 'Não foram encontrados resultados'})}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <RolesListPagination />
          </>
        ) : (
          <ListLoading />
        )}
      </KTCardBody>
      <DeleteItemModal
        toastTitle={'Função Eliminada'}
        url={ROLE_URL}
        id={deletable}
        deleteRecord={(id: ID) => {
          setRowsTable((current) =>
            current.filter((obj) => {
              return obj.id !== id
            })
          )
        }}
      />
    </>
  )
}

export {RolesTable}
