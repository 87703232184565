import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useIntl } from "react-intl";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { DailyCheck } from "../../../../models/_dailyCheck";

interface Props {
    dc: DailyCheck
}

export function DcRecordModal({dc}: Props) {
    const intl = useIntl()
    const [show, setShow] = useState(false);
    const [data, setData] = useState<{ name: string, dc: number, amt: number }[]>([]);
    const [ticks, setTicks] = useState<string[]>(["1s", "2s", "3s", "4s", "5s", "6s", "7s", "8s", "9s", "10s", "11s", "12s", "13s", "14s", "15s", "16s", "17s", "18s", "19s", "20s", "21s", "22s", "23s", "24s", "25s", "26s", "27s", "28s", "29s", "30s"]);
    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false)
    };

    useEffect(() => {
        if (show) {
            const data_temp: { name: string, dc: number, amt: number, }[] = []
            if (dc.graphic_data) {
                dc.graphic_data.forEach((point, index) => {
                    data_temp.push({name: `${((index + 1) * 2) / 1000}s`, amt: (index + 1), dc: point})
                    setData(data_temp)
                })
            }
        }
    }, [show, dc])

    return (
        <>
            {/* begin::Add Clinic */}
            <button type='button' className='btn btn-light-primary btn-sm' onClick={handleShow}>
                <i className="fa-solid fa-chart-line"></i>
            </button>
            {/* end::Add Clinic */}


            <Modal
                show={show}
                onHide={handleClose}

                size={"xl"}
            >
                <div className="modal-content ">
                    <div className="modal-header">
                        <h3 className='card-title text-primary'>{intl.formatMessage({id: "Gráfico"})}</h3>

                        <div className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                             data-bs-dismiss="modal" onClick={() => setShow(false)}
                             aria-label="Close">
                            <span className="svg-icon svg-icon-1 text-dark">X</span>
                        </div>
                    </div>
                    <div className="modal-body  ">
                        <div className="scroll h-500px">
                            <ResponsiveContainer height='100%' width='500%'>
                                <LineChart
                                    data={data}
                                    margin={{
                                        top: 5,
                                        right: 30,
                                        left: 20,
                                        bottom: 5,
                                    }}
                                >
                                    <CartesianGrid strokeDasharray="0" stroke="#FF0000" fill={"#f9c5cb"}/>
                                    <XAxis dataKey="name"
                                           ticks={ticks}/>
                                    <YAxis domain={[-1000, 1000]} tickFormatter={(value, index) => {
                                        return value + "mV"
                                    }}/>
                                    <Tooltip/>
                                    <Legend/>
                                    <Line type="monotone" dataKey="dc" stroke="#000000" dot={false}/>
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

