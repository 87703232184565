/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {Prescription} from '../../../../../../../models/_schedule'
import {PrescriptionModal} from '../../../../modals/PrescriptionModal'

type Props = {
  prescription: Prescription
}

const ActionsCell: FC<Props> = ({prescription}) => {
  useEffect(() => {
    MenuComponent.reinitialization()
  }, [])

  return (
    <>
      <PrescriptionModal prescription={prescription} updateTable={(prescription) => {}} />
    </>
  )
}

export {ActionsCell}
