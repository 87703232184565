import axios, {AxiosResponse} from 'axios'
import {PROFESSION_URL} from '../app/_const'
import {ProfessionQueryResponse} from '../models/_profession'

const getProfessions = (query: string): Promise<ProfessionQueryResponse> => {
  return axios
    .get(`${PROFESSION_URL}?${query}`) /**/
    .then((d: AxiosResponse<ProfessionQueryResponse>) => d.data)
}

export {getProfessions}
