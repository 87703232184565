import { ListViewProvider } from "./core/ListViewProvider";
import { QueryRequestProvider } from "./core/QueryRequestProvider";
import { QueryResponseProvider } from "./core/QueryResponseProvider";
import { EcgsTable } from "./table/EcgsTable";


const EcgListWrapper = () => (
    <QueryRequestProvider>
        <QueryResponseProvider>
            <ListViewProvider>
                <EcgsTable/>
            </ListViewProvider>
        </QueryResponseProvider>
    </QueryRequestProvider>
)

export {EcgListWrapper}
