import React, {useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import axios, {AxiosResponse} from 'axios'
import {MEDICATION_URL} from '../../../../../_const'
import {toast} from 'react-toastify'
import {customStyles, toastStyles} from '../../../../../_styles'
import CustomInput from '../../../../_components/CustomInput'
import {Medication} from '../../../../../../models/_schedule'
import {
  medicationSchema,
  options_du,
  options_f,
  options_fu,
} from '../../../../../../schemas/_schedules'
import Select from 'react-select'
import {useThemeMode} from '../../../../../../_metronic/partials'
import {MedicationType} from '../../../../../../models/_medicationType'
import FormikTextArea from '../../../../_components/FormikTextArea'
import WarningBlock from '../../../../_components/inputs/WarningBlock'
import {generalize} from '../../../../../_helpers'
import CustomModalFooter from '../../../../_components/modals/CustomModalFooter'
import FormikErrors from '../../../../_components/FormikErrors'

interface Props {
  updateTable: (medication: Medication) => void
  types: MedicationType[]
}

interface Option {
  readonly label: string
  readonly value: string
}

export function AddMedModal({updateTable, types}: Props) {
  const intl = useIntl()
  const {menuMode} = useThemeMode()
  const [show, setShow] = useState(false)
  const handleClose = () => {
    formik.resetForm()
    setShow(false)
  }
  const handleShow = () => setShow(true)

  const initialValues = {
    name: '',
    description: '',
    dosage: '',
    dosage_unit: '',
    frequency: '',
    frequency_unit: '',
    label_dosage_unit: '',
    label_frequency: '',
    label_frequency_unit: '',
    observation: '',
    medicationType_id: '',
  }
  const formik = useFormik({
    initialValues,
    validationSchema: medicationSchema,
    onSubmit: async (values, {setSubmitting}) => {
      await axios
        .post(`${MEDICATION_URL}`, values)
        .then((res: {data: AxiosResponse<Medication>}) => {
          updateTable(res.data.data)
          toast.success(intl.formatMessage({id: 'Medicação Criada'}), toastStyles)
          handleClose()
          setSubmitting(false)
        })
        .catch((err: any) => {
          Object.keys(err.response.data.errors).forEach((key) => {
            formik.setFieldError(key, err.response.data.errors[key][0] ?? 'undefined')
          })
          formik.setTouched(generalize(initialValues, true), false)
          setSubmitting(false)
        })
    },
  })

  return (
    <>
      <button type='button' className='btn btn-primary' onClick={handleShow}>
        {intl.formatMessage({id: 'Registar Medicamento'})}
      </button>

      <Modal show={show} onHide={handleClose} size={'lg'}>
        <Modal.Header closeButton>
          <h3 className='card-title text-primary'>
            {intl.formatMessage({id: 'Criar Medicamento'})}
          </h3>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body className='modal-body'>
            <div className='row'>
              <WarningBlock
                show={formik.isValid}
                warning={'Campos obrigatórios (*), preencha os campos corretamente!'}
              />
              <div className='col-6'>
                <CustomInput
                  required={true}
                  formikFields={formik.getFieldProps('name')}
                  label={'Nome'}
                  type={'text'}
                  touched={formik.touched.name}
                  errors={formik.errors.name}
                  name={'name'}
                />
                <label className='form-label required '>
                  {intl.formatMessage({id: 'Unidade de Dosagem'})}
                </label>
                <Select
                  options={options_du}
                  getOptionLabel={(option: Option) => intl.formatMessage({id: option.label})}
                  getOptionValue={(option: Option) => `${option.value}`}
                  {...formik.getFieldProps('dosage_unit')}
                  value={options_du.find((value) => {
                    return `${value.value}` == formik.values.dosage_unit
                  })}
                  onBlur={(event) => {
                    formik.setFieldTouched('dosage_unit', true)
                    formik.setErrors({...formik.errors, dosage_unit: undefined})
                  }}
                  onChange={(value) => {
                    formik.setFieldValue('dosage_unit', `${value?.value}`)
                  }}
                  styles={customStyles(
                    menuMode,
                    formik.errors.dosage_unit,
                    formik.touched.dosage_unit
                  )}
                />
                <FormikErrors
                  errors={formik.errors.dosage_unit}
                  touched={formik.touched.dosage_unit}
                />

                <label className='form-label required mt-2'>
                  {intl.formatMessage({id: 'Unidade de Frequência'})}
                </label>
                <Select
                  options={options_fu}
                  getOptionLabel={(option: Option) => intl.formatMessage({id: option.label})}
                  getOptionValue={(option: Option) => `${option.value}`}
                  {...formik.getFieldProps('frequency_unit')}
                  value={options_fu.find((value) => {
                    return `${value.value}` == formik.values.frequency_unit
                  })}
                  onBlur={(event) => {
                    formik.setFieldTouched('frequency_unit', true)
                    formik.setErrors({...formik.errors, frequency_unit: undefined})
                  }}
                  onChange={(value) => {
                    formik.setFieldValue('frequency_unit', `${value?.value}`)
                  }}
                  styles={customStyles(
                    menuMode,
                    formik.errors.frequency_unit,
                    formik.touched.frequency_unit
                  )}
                />
                <FormikErrors
                  errors={formik.errors.frequency_unit}
                  touched={formik.touched.frequency_unit}
                />
                <label className='form-label required mt-2'>
                  {intl.formatMessage({id: 'Tipo de medicamento'})}
                </label>
                <Select
                  options={types}
                  getOptionLabel={(type: MedicationType) => type.name}
                  getOptionValue={(type: MedicationType) => `${type.id}`}
                  {...formik.getFieldProps('medicationType_id')}
                  value={types.find((value) => {
                    return `${value.id}` == formik.values.medicationType_id
                  })}
                  onBlur={(event) => {
                    formik.setFieldTouched('medicationType_id', true)
                    formik.setErrors({...formik.errors, medicationType_id: undefined})
                  }}
                  onChange={(value) => {
                    formik.setFieldValue('medicationType_id', `${value?.id}`)
                  }}
                  styles={customStyles(
                    menuMode,
                    formik.errors.medicationType_id,
                    formik.touched.medicationType_id
                  )}
                />
                <FormikErrors
                  errors={formik.errors.medicationType_id}
                  touched={formik.touched.medicationType_id}
                />
              </div>
              <div className='col-6'>
                <CustomInput
                  required={true}
                  formikFields={formik.getFieldProps('description')}
                  label={'Descrição'}
                  type={'text'}
                  touched={formik.touched.description}
                  errors={formik.errors.description}
                  name={'description'}
                />
                <CustomInput
                  required={true}
                  formikFields={formik.getFieldProps('dosage')}
                  label={'Dosagem'}
                  type={'number'}
                  touched={formik.touched.dosage}
                  errors={formik.errors.dosage}
                  name={'dosage'}
                />
                <label className='form-label required '>
                  {intl.formatMessage({id: 'Frequência'})}
                </label>
                <Select
                  options={options_f}
                  getOptionLabel={(option: Option) => intl.formatMessage({id: option.label})}
                  getOptionValue={(option: Option) => `${option.value}`}
                  {...formik.getFieldProps('frequency')}
                  value={options_f.find((value) => {
                    return `${value.value}` == formik.values.frequency
                  })}
                  onBlur={(event) => {
                    formik.setFieldTouched('frequency', true)
                    formik.setErrors({...formik.errors, frequency: undefined})
                  }}
                  onChange={(value) => {
                    formik.setFieldValue('frequency', `${value?.value}`)
                  }}
                  styles={customStyles(menuMode, formik.errors.frequency, formik.touched.frequency)}
                />
                <FormikErrors errors={formik.errors.frequency} touched={formik.touched.frequency} />
              </div>
              <FormikTextArea
                formikFields={formik.getFieldProps('observation')}
                label={'Observações'}
                touched={formik.touched.observation}
                errors={formik.errors.observation}
                name={'observation'}
              />
            </div>
          </Modal.Body>
          <CustomModalFooter
            isValid={formik.isValid}
            isSubmitting={formik.isSubmitting}
            handleClose={handleClose}
            closeLabel={'Close'}
            submitLabel={'Confirmar'}
          />
        </form>
      </Modal>
    </>
  )
}
