// @ts-nocheck
import { Column } from "react-table";
import { ClinicActionsCell } from "./ClinicActionsCell";
import { ClinicCustomHeader } from "./ClinicCustomHeader";
import { ClinicPhotoCell } from "./ClinicPhotoCell";
import { Collaborator } from "../../../../../../../models/_collab";

const collabsColumns: ReadonlyArray<Column<Collaborator>> = [
    {
        Header: (props) => <ClinicCustomHeader tableProps={props} title='Foto' className='min-w-125px'/>,
        accessor: 'photo',
        Cell: ({...props}) => <ClinicPhotoCell photo={props.data[props.row.index].photo}/>
    },
    {
        Header: (props) => <ClinicCustomHeader tableProps={props} title='Nome' className='min-w-125px'/>,
        accessor: 'full_name',
    },
    {
        Header: (props) => <ClinicCustomHeader tableProps={props} title='Email' className='min-w-125px'/>,
        accessor: 'email',
    },
    {
        Header: (props) => <ClinicCustomHeader tableProps={props} title='Telemóvel' className='min-w-125px'/>,
        accessor: 'mobilephone',
    },
    {
        Header: (props) => <ClinicCustomHeader tableProps={props} title='NISS' className='min-w-125px'/>,
        accessor: 'niss',
    },
    {
        Header: (props) => (
            <ClinicCustomHeader tableProps={props} title='NIF' className='min-w-125px'/>
        ),
        accessor: 'tax_number',
    },
    {
        Header: (props) => (
            <ClinicCustomHeader tableProps={props} title='Cidade' className='min-w-125px'/>
        ),
        accessor: 'city',
    },
    {
        Header: (props) => (
            <ClinicCustomHeader tableProps={props} title='Selecionar' className='text-end min-w-100px'/>
        ),
        id: 'actions',
        Cell: ({...props}) => <ClinicActionsCell id={props.data[props.row.index].id}/>,
    },
]

export {collabsColumns}
