import * as Yup from 'yup'

export const relativeSchema = Yup.object().shape({
  name: Yup.string().required('Field Required'),
  email: Yup.string().required('Field Required').email('Wrong email format'),
  niss: Yup.string().required('Field Required'),
  phone: Yup.string().required('Field Required'),
  mobile_phone: Yup.string().required('Field Required'),
  notes: Yup.string().nullable().optional(),
  qualifications: Yup.string().required('Field Required'),
  relationship_id: Yup.string().required('Field Required'),
  profession_id: Yup.string().required('Field Required'),
})
