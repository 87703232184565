import React, {FC} from 'react'
import {Link, useLocation, useParams} from 'react-router-dom'
import PageTitle from '../../_components/PageTitle'
import {KTSVG} from '../../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import {Patient} from '../../../../models/_patient'
import {Clinic} from '../../../../models/_clinic'
import {useAuth} from '../../../modules/auth'
import {havePerm, havePerms} from '../../../_helpers'

interface Breadcrumb {
  title: string
  division: string
  user: Patient
  clinic: Clinic
}

const ToolBar: FC<Breadcrumb> = (props): JSX.Element => {
  const intl = useIntl()
  const {auth} = useAuth()
  const {id} = useParams()
  const location = useLocation()

  return (
    <>
      <PageTitle title={props.title} division={props.division} />
      <div className='card mb-5 mb-xl-10'>
        <div className='card-body pt-9 pb-0'>
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <p className='text-gray-800 fs-2 fw-bolder me-1'>{props.user.full_name}</p>
                </div>
                <div className='d-flex flex-wrap fw-bold fs-6 mb-4 pe-2 me-1'>
                  <p className='d-flex align-items-center text-gray-400  me-5 mb-2'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen018.svg'
                      className='svg-icon-4 me-1'
                    />
                    {props.user.city ?? intl.formatMessage({id: 'Não Definido'})}
                  </p>
                  <p className='d-flex align-items-center text-gray-400  mb-2 me-2'>
                    <KTSVG
                      path='/media/icons/duotune/communication/com011.svg'
                      className='svg-icon-4 me-1'
                    />
                    {props.user.email}
                  </p>
                  <p className='d-flex align-items-center text-gray-400  me-5 mb-2'>
                    <KTSVG
                      path='/media/icons/duotune/medicine/med001.svg'
                      className='svg-icon-4 me-1'
                    />
                    {props.clinic.name}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className='d-flex overflow-auto h-55px'>
            <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
              {havePerm('patients:read', auth) && (
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === '/patient/base/' + id && 'active')
                    }
                    to={'/patient/base/' + id}
                  >
                    <span className='menu-icon'>
                      <i className={'fa-solid fa-clipboard me-1'}></i>
                    </span>
                    {intl.formatMessage({id: 'Perfil'})}
                  </Link>
                </li>
              )}
              {havePerm('medicalhealthrecords:read', auth) && (
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === '/patient/health/' + id && 'active')
                    }
                    to={'/patient/health/' + id}
                  >
                    <span className='menu-icon'>
                      <i className={'fa-solid fa-heart-pulse me-1'}></i>
                    </span>
                    {intl.formatMessage({id: 'Ficha Clinica'})}
                  </Link>
                </li>
              )}
              {havePerms(
                [
                  'ecgs:read',
                  'bloodglucoses:read',
                  'bloodpressures:read',
                  'dailychecks:read',
                  'oximeters:read',
                  'thermometers:read',
                ],
                auth
              ) && (
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname.includes('/patient/records/' + id) && 'active')
                    }
                    to={'/patient/records/' + id + '/ecgs'}
                  >
                    <span className='menu-icon'>
                      <i className={'fa-solid fa-list me-1'}></i>
                    </span>
                    {intl.formatMessage({id: 'Registos'})}
                  </Link>
                </li>
              )}
              {havePerm('prescriptions:read', auth) && (
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === '/patient/medications/' + id && 'active')
                    }
                    to={'/patient/medications/' + id}
                  >
                    <span className='menu-icon'>
                      <i className={'fa-solid fa-pills me-1'}></i>
                    </span>
                    {intl.formatMessage({id: 'Medicação'})}
                  </Link>
                </li>
              )}
              {havePerm('observations:read', auth) && (
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === '/patient/observations/' + id && 'active')
                    }
                    to={'/patient/observations/' + id}
                  >
                    <span className='menu-icon'>
                      <i className={'fa-solid fa-file-lines me-1'}></i>
                    </span>
                    {intl.formatMessage({id: 'Observações'})}
                  </Link>
                </li>
              )}
              {havePerm('attachments:read', auth) && (
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === '/patient/attachments/' + id && 'active')
                    }
                    to={'/patient/attachments/' + id}
                  >
                    <span className='menu-icon'>
                      <i className={'fa-solid fa-paperclip me-1'}></i>
                    </span>
                    {intl.formatMessage({id: 'Anexos'})}
                  </Link>
                </li>
              )}
              {havePerm('aggregates:read', auth) && (
                <li className='nav-item'>
                  <Link
                    className={
                      `nav-link text-active-primary me-6 ` +
                      (location.pathname === '/patient/aggregate/' + id && 'active')
                    }
                    to={'/patient/aggregate/' + id}
                  >
                    <span className='menu-icon'>
                      <i className={'fa-solid fa-people-roof me-1'}></i>
                    </span>
                    {intl.formatMessage({id: 'Agregado'})}
                  </Link>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}

export default ToolBar
