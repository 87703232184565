// @ts-nocheck
import { Column } from "react-table";
import { ModelsCustomHeader } from "./ModelsCustomHeader";
import { Model } from "../../../../../../models/_deviceModel";

const modelsColumns: ReadonlyArray<Column<Model>> = [
    {
        Header: (props) => <ModelsCustomHeader tableProps={props} title='Nome' className='min-w-125px'/>,
        accessor: 'name',
    },
    {
        Header: (props) => <ModelsCustomHeader tableProps={props} title='Marca' className='min-w-125px'/>,
        accessor: 'brand.name',
    },
    {
        Header: (props) => <ModelsCustomHeader tableProps={props} title='Observação' className='min-w-125px'/>,
        accessor: 'observation',
    },
    {
        Header: (props) => (
            <ModelsCustomHeader tableProps={props} title='Ações' className='text-end min-w-100px'/>
        ),
        id: 'actions',
    },
]

export {modelsColumns}
