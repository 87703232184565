import React, {useEffect, useRef, useState} from 'react'
import axios, {AxiosResponse} from 'axios'
import {useFormik} from 'formik'
import {useIntl} from 'react-intl'
import {toast} from 'react-toastify'
import CustomInput from '../../../../_components/CustomInput'
import {BRANDS_URL, MODELS_URL} from '../../../../../_const'
import {customStyles, toastStyles} from '../../../../../_styles'
import {Model} from '../../../../../../models/_deviceModel'
import {modelSchema} from '../../../../../../schemas/_models'
import Select from 'react-select'
import {Brand} from '../../../../../../models/_brand'
import {useThemeMode} from '../../../../../../_metronic/partials'
import FormikErrors from '../../../../_components/FormikErrors'
import FormikTextArea from '../../../../_components/FormikTextArea'
import Modal from 'react-bootstrap/Modal'
import WarningBlock from '../../../../_components/inputs/WarningBlock'
import CustomModalHeader from '../../../../_components/modals/CustomModalHeader'

interface Props {
  updateList: (model: Model) => void
  model: Model
}

export function EditModelModal(props: Props) {
  const intl = useIntl()
  const [initialValues, setInitialValues] = useState<Model>(props.model)
  const {menuMode} = useThemeMode()
  const [brands, setBrands] = useState<Brand[]>([])
  const closeBtn = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    const fetchData = async () => {
      setInitialValues(props.model)
      const response: AxiosResponse<{data: Brand[]}> = await axios.get(`${BRANDS_URL}/all`)
      setBrands(response.data.data)
    }
    fetchData()
  }, [props])

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: modelSchema,
    onSubmit: async (values, {setSubmitting}) => {
      await axios
        .patch(`${MODELS_URL}/${props.model.id}`, values)
        .then((res) => {
          props.updateList(res.data.data)
          toast.success(intl.formatMessage({id: 'Modelo Editado'}), toastStyles)
          setSubmitting(false)
          closeBtn?.current?.click()
        })
        .catch((err: any) => {
          Object.keys(err.response.data.errors).forEach((key) => {
            formik.setFieldError(key, err.response.data.errors[key][0] ?? 'undefined')
          })
          setSubmitting(false)
        })
    },
  })

  return (
    <>
      <div className='modal fade' tabIndex={-1} id='kt_modal_edit_device_model'>
        <div className='modal-dialog modal-md'>
          <div className='modal-content '>
            <CustomModalHeader title={'Editar Modelo'} />

            <form onSubmit={formik.handleSubmit}>
              <Modal.Body>
                <div className='row'>
                  <WarningBlock
                    show={formik.isValid}
                    warning={'Campos obrigatórios (*), preencha os campos corretamente!'}
                  />
                  <div className='col-12 col-sm-6'>
                    <CustomInput
                      required={true}
                      formikFields={formik.getFieldProps('name')}
                      label={'Modelo'}
                      type={'text'}
                      touched={formik.touched.name}
                      errors={formik.errors.name}
                      name={'name'}
                    />
                  </div>
                  <div className='col-12 col-sm-6'>
                    <label className='form-label required '>
                      {intl.formatMessage({id: 'Marca'})}
                    </label>
                    <Select
                      options={brands}
                      getOptionLabel={(brand: Brand) => brand.name}
                      getOptionValue={(brand: Brand) => `${brand.id}`}
                      {...formik.getFieldProps('brand_id')}
                      value={brands.find((value) => {
                        return `${value.id}` == `${formik.values.brand_id}`
                      })}
                      onBlur={(event) => {
                        formik.setFieldTouched('brand_id', true)
                        formik.setErrors({...formik.errors, brand_id: undefined})
                      }}
                      onChange={(value) => {
                        formik.setFieldValue('brand_id', `${value?.id}`)
                      }}
                      styles={customStyles(
                        menuMode,
                        formik.errors.brand_id,
                        formik.touched.brand_id
                      )}
                    />
                    <FormikErrors
                      errors={formik.errors.brand_id}
                      touched={formik.touched.brand_id}
                    />
                  </div>

                  <FormikTextArea
                    formikFields={formik.getFieldProps('observation')}
                    label={'Observação'}
                    touched={formik.touched.observation}
                    errors={formik.errors.observation}
                    name={'observation'}
                  />
                </div>
              </Modal.Body>
              <div className='modal-footer'>
                <button
                  type='button'
                  className='btn btn-light'
                  data-bs-dismiss='modal'
                  ref={closeBtn}
                >
                  {intl.formatMessage({id: 'Close'})}
                </button>
                <button
                  type='submit'
                  className='btn btn-primary'
                  disabled={!formik.isValid || formik.isSubmitting}
                >
                  {intl.formatMessage({id: 'Confirmar'})}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
