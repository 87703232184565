import axios, {AxiosResponse} from 'axios'
import {RelativeQueryResponse} from '../models/_relative'
import {RELATIVES_URL} from '../app/_const'

const getRelatives = (query: string): Promise<RelativeQueryResponse> => {
  return axios
    .get(`${RELATIVES_URL}?${query}`) /**/
    .then((d: AxiosResponse<RelativeQueryResponse>) => d.data)
}

export {getRelatives}
