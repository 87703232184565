import {FC, useEffect, useState} from 'react'
import {MenuComponent} from '../../../../../../../_metronic/assets/ts/components'
import {Prescription} from '../../../../../../../models/_schedule'
import {dateToString} from '../../../../../../_helpers'
import {useIntl} from 'react-intl'

type Props = {
  prescription: Prescription
}

const StatusCell: FC<Props> = ({prescription}) => {
  const intl = useIntl()

  const [status, setStatus] = useState<{badge: string; label: string}>({
    badge: 'light-success',
    label: 'Terminada',
  })

  useEffect(() => {
    MenuComponent.reinitialization()
    const now = dateToString(new Date())
    if (prescription.active) {
      if (prescription.start_at >= now) {
        setStatus({badge: 'warning', label: 'Por iniciar'})
      } else if (prescription.start_at <= now && prescription.end_at >= now) {
        setStatus({badge: 'primary', label: 'A decorrer'})
      } else {
        setStatus({badge: 'success', label: 'Terminada'})
      }
    } else {
      setStatus({badge: 'danger', label: 'Cancelada'})
    }
  }, [])

  return (
    <>
      <span className={`badge badge-light-${status.badge}`}>
        {intl.formatMessage({id: status.label})}
      </span>
    </>
  )
}

export {StatusCell}
