import {Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {AuthLayout} from './AuthLayout'
import {ToastContainer} from 'react-toastify'
import React from 'react'
import {ChangePassword} from './components/ChangePassword'
import {RgpdSignature} from './components/RgpdSignature'

const AuthPage = () => (
  <>
    <Routes>
      <Route element={<AuthLayout />}>
        <Route path='login' element={<Login />} />
        <Route path='registration' element={<Registration />} />
        <Route path='forgot-password' element={<ForgotPassword />} />
        <Route path='change-password/:token' element={<ChangePassword />} />
        <Route path='accept-rgpd/:token' element={<RgpdSignature />} />
        <Route index element={<Login />} />
      </Route>
    </Routes>
    <ToastContainer
      position='top-right'
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
    />
  </>
)

export {AuthPage}
