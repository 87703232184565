import axios, {AxiosResponse} from 'axios'
import {APPOINTMENT_CATEGORIES_URL} from "../app/_const";
import {AppointmentCategoryQueryResponse} from "../models/_appointmentCategory";


const getAppointmentCategories = (query: string): Promise<AppointmentCategoryQueryResponse> => {
    return axios
        .get(`${APPOINTMENT_CATEGORIES_URL}?${query}`)/**/
        .then((d: AxiosResponse<AppointmentCategoryQueryResponse>) => d.data)
}

export {getAppointmentCategories}