import React, {useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import {useIntl} from 'react-intl'
import {useParams} from 'react-router-dom'
import {NoteSchema} from '../_model'
import {useFormik} from 'formik'
import axios from 'axios'
import {NOTES_URL} from '../../../_const'
import {toast} from 'react-toastify'
import {toastStyles} from '../../../_styles'
import {Note} from '../../../../models/_note'
import CustomModalFooter from '../../_components/modals/CustomModalFooter'
import FormikTextArea from '../../_components/FormikTextArea'
import {generalize} from '../../../_helpers'
import WarningBlock from '../../_components/inputs/WarningBlock'

type Props = {
  updateList: (note: Note) => void
}

export function AddNoteModal(props: Props) {
  const intl = useIntl()
  const {id} = useParams()
  const [show, setShow] = useState(false)

  const handleClose = () => {
    formik.resetForm()
    setShow(false)
  }
  const handleShow = () => {
    setShow(true)
  }

  const initialValues = {
    content: '',
    clinic_id: id,
  }
  const formik = useFormik({
    initialValues,
    validationSchema: NoteSchema,
    onSubmit: async (values, {setSubmitting}) => {
      await axios
        .post(`${NOTES_URL}`, {...values})
        .then((res) => {
          toast.success(intl.formatMessage({id: 'Nota Criada'}), toastStyles)
          props.updateList(res.data.data)
          handleClose()
          setSubmitting(false)
        })
        .catch((err: any) => {
          Object.keys(err.response.data.errors).forEach((key) => {
            formik.setFieldError(key, err.response.data.errors[key][0] ?? 'undefined')
          })
          formik.setTouched(generalize(initialValues, true), false)
          setSubmitting(false)
        })
    },
  })

  return (
    <>
      <div className='d-flex justify-content-end' data-kt-user-table-toolbar='base'>
        <button type='button' className='btn btn-primary' onClick={handleShow}>
          {intl.formatMessage({id: 'Adicionar Observação'})}
        </button>
      </div>

      <Modal size='lg' show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <h3 className='card-title text-primary'>
            {intl.formatMessage({id: 'Adicionar Observação'})}
          </h3>
        </Modal.Header>
        <form onSubmit={formik.handleSubmit}>
          <Modal.Body>
            <WarningBlock
              show={formik.isValid}
              warning={'Campos obrigatórios (*), preencha os campos corretamente!'}
            />
            <FormikTextArea
              required={true}
              formikFields={formik.getFieldProps('content')}
              label={'Nota'}
              touched={formik.touched.content}
              errors={formik.errors.content}
              name={'content'}
            />
          </Modal.Body>
          <CustomModalFooter
            isValid={formik.isValid}
            isSubmitting={formik.isSubmitting}
            handleClose={handleClose}
            closeLabel={'Close'}
            submitLabel={'Confirmar'}
          />
        </form>
      </Modal>
    </>
  )
}
