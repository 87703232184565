import clsx from "clsx";
import { FC, PropsWithChildren, useMemo } from "react";
import { HeaderProps } from "react-table";
import { useQueryRequest } from "../../core/QueryRequestProvider";
import { useIntl } from "react-intl";
import { Note } from "../../../../../../../models/_note";

type Props = {
    className?: string
    title?: string
    tableProps: PropsWithChildren<HeaderProps<Note>>
}
const PatientNotesCustomHeader: FC<Props> = ({className, title, tableProps}) => {
    const id = tableProps.column.id
    const {state, updateState} = useQueryRequest()
    const intl = useIntl()

    const isSelectedForSorting = useMemo(() => {
        return state.sort && state.sort === id
    }, [state, id])
    const order: 'asc' | 'desc' | undefined = useMemo(() => state.order, [state])

    const sortColumn = () => {
        return
    }

    return (
        <th
            {...tableProps.column.getHeaderProps()}
            className={clsx(
                className,
                isSelectedForSorting && order !== undefined && `table-sort-${order}`
            )}
            style={{cursor: "default"}}
            onClick={sortColumn}
        >
            {intl.formatMessage({id: title})}
        </th>
    )
}

export {PatientNotesCustomHeader}
