import React, {FC} from 'react'
import {useIntl} from "react-intl";

type Props = {

    title: string

}

const MenuTitle: FC<Props> = ({title,}) => {

    const intl = useIntl()
    return (
        <>
            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    <span className='menu-section text-muted text-uppercase fs-8 ls-1'>
                        {intl.formatMessage({id: title})}
                    </span>
                </div>
            </div>
        </>
    )
}

export {MenuTitle}
